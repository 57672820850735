import React from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import classNames from 'classnames';

export interface PriorityButtonProps
  extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  direction: 'up' | 'down';
}

export const PriorityButton: React.FC<PriorityButtonProps> = ({ className, direction, disabled, ...buttonProps }) => {
  const Icon = direction === 'up' ? ChevronUpIcon : ChevronDownIcon;

  return (
    <button
      type="button"
      className={classNames(
        'text-gray-500 w-6 h-6 rounded flex items-center justify-center hover:bg-gray-200 hover:text-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-500 mb-2 disabled:bg-transparent',
        className
      )}
      disabled={disabled}
      {...buttonProps}
    >
      <Icon className={classNames('w-4 h-4', { 'text-gray-300': disabled })} />
    </button>
  );
};
