import { gql, useMutation } from '@apollo/client';
import {
  DeleteAddressBookAddressMutation,
  DeleteAddressBookAddressMutationVariables,
} from './__generated__/delete-address-book-address.gql.generated';

export const DELETE_ADDRESS_BOOK_ADDRESS = gql`
  mutation DeleteAddressBookAddress($id: ID!) {
    deleteAddressBookAddress(id: $id)
  }
`;

export const useDeleteAddressBookAddressMutation = () =>
  useMutation<DeleteAddressBookAddressMutation, DeleteAddressBookAddressMutationVariables>(
    DELETE_ADDRESS_BOOK_ADDRESS,
    {
      refetchQueries: ['AddressBookAddresses'],
    }
  );
