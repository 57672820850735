import React from 'react';
import { RuleSectionHeading } from '../atoms/RuleSectionHeading';
import { Button } from '../../Button';
import { PlusIcon } from '@heroicons/react/solid';
import { RuleSectionEmptyState } from '../atoms/RuleSectionEmptyState';
import { RateFilterRow } from './RateFilterRow';
import { RuleSection } from '../atoms/RuleSection';
import { RateFilterValues, useRulesForm } from '../RuleForm';
import { FieldArray } from 'formik';
import { nanoid } from 'nanoid';
import classNames from 'classnames';
import styles from './RateFiltersSection.module.scss';
import { useRateFilterFieldOptionsQuery } from '../../../operations/rate-filter-field-options.gql';

const initialRateFilterValues = (): RateFilterValues => ({
  field: '',
  operator: '',
  value: [],
  key: nanoid(5),
});

export interface RateFiltersSectionProps {
  disabled?: boolean;
}

export const RateFiltersSection: React.FC<RateFiltersSectionProps> = ({ disabled }) => {
  const form = useRulesForm();
  const isEmpty = !form.values.rateFilters.length;
  const { data } = useRateFilterFieldOptionsQuery();

  const rateFilterOptions = data?.rateFilterFieldOptions ?? [];

  return (
    <FieldArray name={`rateFilters`}>
      {({ push, remove }) => (
        <RuleSection>
          <RuleSectionHeading
            title="Rate Filters"
            actions={
              <Button
                type="button"
                kind="white"
                size="sm"
                icon={PlusIcon}
                onClick={() => push(initialRateFilterValues())}
                disabled={disabled}
              >
                Filter
              </Button>
            }
          />
          {isEmpty ? (
            <RuleSectionEmptyState
              title="No rate filters added."
              message="Rate filters determine which rates to keep. If no rate filters are provided, the software will return all
              rates for the enabled carriers, sorted by the cheapest."
            />
          ) : (
            <div className={classNames('mb-8', styles.rateFilterGrid)}>
              {form.values.rateFilters.map((rateFilter, rateFilterIndex) => (
                <RateFilterRow
                  key={rateFilter.key}
                  rateFilterIndex={rateFilterIndex}
                  onDelete={() => remove(rateFilterIndex)}
                  rateFilterOptions={rateFilterOptions}
                  disabled={disabled}
                />
              ))}
            </div>
          )}
        </RuleSection>
      )}
    </FieldArray>
  );
};
