import React from 'react';
import { Breadcrumb } from '../components/Breadcrumbs';
import { PageHeading } from '../components/PageHeading';
import { CreateManifestV2Form } from '../components/Manifest/CreateManifestV2Form';

const breadcrumbs: Breadcrumb[] = [
  { label: 'Manifests', path: '/manifest' },
  { label: 'Create Manifest', path: '/manifest/create' },
];

export const CreateManifestV2: React.FC = () => {
  return (
    <div className="max-w-7xl mx-auto p-6 lg:p-8 flex-1 w-full bg-gray-100">
      <PageHeading title="Create Manifest" breadcrumbs={breadcrumbs} />
      <CreateManifestV2Form />
    </div>
  );
};
