import React, { useMemo } from 'react';
import {
  CurrencyDollarIcon,
  ArchiveIcon,
  HandIcon,
  PrinterIcon,
  ReceiptRefundIcon,
  HomeIcon,
} from '@heroicons/react/solid';
import classNames from 'classnames';
import { useOrderEventsQuery } from '../../operations/order-events.gql';
import { format } from 'date-fns';
import { OrderTimelineSkeletonLoader } from '../Loaders/OrderTimelineSkeletonLoader';
import { ClipboardCheckIcon, ClockIcon } from '@heroicons/react/outline';
import { useOrderEventCreatedSubscription } from '../../operations/order-event-created.gql';
import { OrderEventType } from '../../types/__generated__/graphql';
import { GetOrderQuery } from '../../operations/__generated__/order.gql.generated';

interface TimelineEvent {
  eventText: string;
  userText: string;
  icon: React.ComponentType<any>;
  timestamp: string;
  bgColorClass: string;
  eventName: string;
  date: string;
  time: string;
}

const eventTypes = {
  [OrderEventType.ShipmentPurchased]: {
    icon: CurrencyDollarIcon,
    bgColorClass: 'bg-green-500',
    eventText: 'Label purchased',
  },
  [OrderEventType.ShipmentRefunded]: {
    icon: ReceiptRefundIcon,
    bgColorClass: 'bg-red-500',
    eventText: 'Label voided',
  },
  [OrderEventType.LabelPrinted]: { icon: PrinterIcon, bgColorClass: 'bg-gray-500', eventText: 'Label printed' },
  [OrderEventType.LabelReprinted]: { icon: PrinterIcon, bgColorClass: 'bg-gray-500', eventText: 'Label re-printed' },
  [OrderEventType.LabelPrintFailed]: {
    icon: PrinterIcon,
    bgColorClass: 'bg-red-500',
    eventText: 'Label failed to print',
  },
  [OrderEventType.AddressUpdated]: {
    icon: HomeIcon,
    bgColorClass: 'bg-blue-500',
    eventText: 'Ship To address was updated',
  },
  [OrderEventType.QualityControlCheck]: {
    icon: ClipboardCheckIcon,
    bgColorClass: 'bg-green-600',
    eventText: 'Quality control check performed',
  },
  picked: { icon: HandIcon, bgColorClass: 'bg-blue-500', eventText: 'Order picked' },
  packed: { icon: ArchiveIcon, bgColorClass: 'bg-blue-500', eventText: 'Order packed' },
};

interface OrderTimelineProps {
  order: GetOrderQuery['order'];
}

const formatTimestampDate = (timestamp: string) => format(new Date(timestamp), 'yyyy-MM-dd');
const formatTimestampTime = (timestamp: string) => format(new Date(timestamp), '@ p');

export const OrderTimeline: React.FC<OrderTimelineProps> = ({ order }) => {
  const { data, loading, error } = useOrderEventsQuery(order!.id);
  useOrderEventCreatedSubscription(order!.id);

  const timeline = useMemo(() => {
    const pickedEvent: TimelineEvent = {
      ...eventTypes.picked,
      userText: 'Unknown',
      timestamp: order!.pickedTimestamp!,
      eventName: 'picked',
      date: formatTimestampDate(order!.pickedTimestamp!),
      time: formatTimestampTime(order!.pickedTimestamp!),
    };

    const packedEvent: TimelineEvent = {
      ...eventTypes.packed,
      userText: 'Unknown',
      timestamp: order!.packedTimestamp!,
      eventName: 'packed',
      date: formatTimestampDate(order!.packedTimestamp!),
      time: formatTimestampTime(order!.packedTimestamp!),
    };

    // @ts-ignore
    const events: TimelineEvent[] =
      data?.orderEvents
        .map((event) => {
          if (!eventTypes[event.name]) {
            return null;
          }
          return {
            ...eventTypes[event.name],
            userText: `${event.createdBy}`,
            timestamp: event.createdAt,
            eventName: event.name,
            date: formatTimestampDate(event.createdAt),
            time: formatTimestampTime(event.createdAt),
          };
        })
        .filter((event) => event !== null) ?? [];

    return [...events, pickedEvent, packedEvent].sort((a, b) =>
      a.timestamp < b.timestamp ? -1 : a.timestamp === b.timestamp ? 0 : 1
    );
  }, [data?.orderEvents, order]);

  const isEmpty = !loading && !error && timeline.length === 0;

  return (
    <section aria-labelledby="timeline-title" className="lg:col-start-3 lg:col-span-1 row-start-1 row-end-2">
      <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
        <h2 id="timeline-title" className="text-lg font-medium text-gray-900">
          Timeline
        </h2>

        {/* Activity Feed */}
        <div className="mt-6 flow-root">
          {loading ? (
            <OrderTimelineSkeletonLoader />
          ) : isEmpty ? (
            <div className="flex flex-col justify-center items-center text-center p-4 text-gray-700">
              <ClockIcon className="w-8 h-8 text-gray-500" />
              <div>No events yet...</div>
            </div>
          ) : error ? (
            <div className="text-red-700">An error occurred while trying to load the order's events.</div>
          ) : (
            <ul className="-mb-8">
              {timeline.map((item, itemIdx) => (
                <li key={`${item.eventName}-${item.timestamp}`}>
                  <div className="relative pb-8">
                    {itemIdx !== timeline.length - 1 ? (
                      <span className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
                    ) : null}
                    <div className="relative flex space-x-3">
                      <div>
                        <span
                          className={classNames(
                            item.bgColorClass,
                            'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white'
                          )}
                        >
                          <item.icon className="w-5 h-5 text-white" aria-hidden="true" />
                        </span>
                      </div>
                      <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                        <div>
                          <p className="text-sm text-gray-500">
                            {item.eventText} by <strong>{item.userText}</strong>
                          </p>
                        </div>
                        <div className="text-right text-sm whitespace-nowrap text-gray-500">
                          <time dateTime={item.date}>{item.date}</time>
                          <br />
                          <time dateTime={item.time}>{item.time}</time>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </section>
  );
};
