/* This example requires Tailwind CSS v2.0+ */
import { InformationCircleIcon } from '@heroicons/react/solid';
import React from 'react';
import classNames from 'classnames';

export interface BannerAlertProps {
  message?: string;
  className?: string;
}

export const BannerAlert: React.FC<BannerAlertProps> = ({ className, message, children }) => {
  return (
    <div className={classNames('rounded-md rounded-l-none bg-yellow-50 p-4 border-l-4 border-yellow-500', className)}>
      <div className="flex">
        <div className="flex-shrink-0">
          <InformationCircleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
        </div>
        <div className="ml-3 flex-1 md:flex md:justify-between">
          <p className="text-sm text-yellow-700">{message ?? children}</p>
        </div>
      </div>
    </div>
  );
};
