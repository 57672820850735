import React from 'react';
import { ShipForm, ShipFormValues } from '../components/Ship/ShipForm';
import { FormikHelpers } from 'formik';
import { nanoid } from 'nanoid';
import { useHistory } from 'react-router-dom';
import { usePurchaseLabelV2Mutation } from '../operations/purchase-label-v2.gql';
import { usePrintNode } from '../providers/PrintNodeProvider';
import mixpanel from 'mixpanel-browser';
import { ErrorModal } from '../components/ErrorModal';
import { useErrorModal } from '../utilities/use-error-modal';
import { usePrintNodeApi } from '../utilities/print-node';

export const ShipAdHoc: React.FC = () => {
  const history = useHistory();

  const [purchaseLabel, { error }] = usePurchaseLabelV2Mutation();
  const { showErrorModal, handleErrorModalClose } = useErrorModal(error?.message);
  const { printNodeApi } = usePrintNodeApi();
  const { selectedPrinter } = usePrintNode();

  const onSubmit = async (values: ShipFormValues, helpers: FormikHelpers<ShipFormValues>) => {
    helpers.setSubmitting(true);
    try {
      const result = await purchaseLabel({
        variables: {
          input: {
            integrationId: 'adhoc',
            orderChannelId: 'adhoc',
            printerId: selectedPrinter?.toString(),
            ...(values.packages.length > 1
              ? {
                  epOrderId: values.rateShop.selectedRate!.epOrderId,
                  carrier: values.rateShop.selectedRate!.carrier,
                  service: values.rateShop.selectedRate!.service,
                }
              : {
                  shipmentId: values.rateShop.selectedRate!.shipmentId,
                  selectedRateId: values.rateShop.selectedRate!.id,
                }),
          },
        },
      });

      history.push('/ship');

      if (!result.data) {
        // TODO: Capture in Sentry
        return;
      }

      if (result.data.purchaseLabelV2.printed) {
        return;
      }

      if (!selectedPrinter) {
        // TODO: Capture in Sentry
        return;
      }

      for (const label of result.data.purchaseLabelV2.labels) {
        const payload = {
          printerId: selectedPrinter,
          contentType: 'raw_uri',
          content: label.labelFileUrl,
        };
        try {
          await printNodeApi?.createPrintJob(payload);
          mixpanel.track('Print Request Successful');
        } catch {
          mixpanel.track('Print Request Failed');
        }
      }
    } catch (err) {
      // TODO: capture in Sentry
      helpers.setSubmitting(false);
    }
  };

  const initialValues: ShipFormValues | null = {
    shipped: false,
    integrationId: 'adhoc',
    orderChannelId: 'adhoc',
    addresses: {
      returnAddressId: '',
      shipFromAddressId: '',
      shipToAddressId: null,
      shipToAddress: {
        company: '',
        name: '',
        street1: '',
        street2: '',
        city: '',
        state: '',
        zip: '',
        country: 'US',
        email: '',
        phone: '',
        verifications: null,
      },
      shipToAddressValidatedAttempted: false,
      shipToAddressValidated: false,
    },
    packages: [{ key: nanoid(), length: 0, width: 0, height: 0, weight: 0, weightLocked: false }],
    rateShop: {
      selectedRate: null,
    },
  };

  return (
    <>
      <ShipForm
        key={initialValues.shipped ? 'shipped' : 'shipping'}
        initialValues={initialValues}
        onSubmit={onSubmit}
      />
      <ErrorModal isOpen={showErrorModal} onClose={handleErrorModalClose} message={error?.message} />
    </>
  );
};
