import React from 'react';
import { FormInput } from '../../Form/FormInput';

interface NumberFieldProps {
  label: string;
  name: string;
  disabled?: boolean;
}

export const NumberField: React.FC<NumberFieldProps> = ({ disabled, name, label }) => {
  return <FormInput label={label} name={name} type="number" disabled={disabled} hideErrorMessage />;
};
