/* This example requires Tailwind CSS v2.0+ */
import React from 'react';
import { AddressDetails } from '../AddressDetails';
import { useAddressBookAddressesQuery } from '../../operations/address-book-addresses.gql';
import { OrderDetailsFragment } from '../../operations/fragments/__generated__/order-details.gql.generated';
import { Card } from '../Card';
import { CardHeading } from '../CardHeading';

export interface OrderSummaryProps {
  order: OrderDetailsFragment;
}

export const OrderSummary: React.FC<OrderSummaryProps> = ({ order }) => {
  const { data: addressBookData } = useAddressBookAddressesQuery({ limit: 50 });

  const shipFromAddressName = addressBookData?.addressBookAddresses.items.find(
    (item) => item.easyPostAddressId === order.shipFromAddressId
  )?.addressName;

  const returnAddressName = addressBookData?.addressBookAddresses.items.find(
    (item) => item.easyPostAddressId === order.returnAddressId
  )?.addressName;

  return (
    <Card>
      <CardHeading>Order Summary</CardHeading>
      <div>
        <dl className="sm:divide-y sm:divide-gray-200">
          {order.shipToAddress ? (
            <div className="px-4 py-4 sm:py-5 sm:px-6 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt className="text-sm font-medium text-gray-500">Ship To Address</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <AddressDetails address={order.shipToAddress} />
              </dd>
            </div>
          ) : null}
          {shipFromAddressName ? (
            <div className="px-4 py-4 sm:py-5 sm:px-6 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt className="text-sm font-medium text-gray-500">Ship From Address</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{shipFromAddressName}</dd>
            </div>
          ) : null}
          {returnAddressName ? (
            <div className="px-4 py-4 sm:py-5 sm:px-6 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt className="text-sm font-medium text-gray-500">Return Address</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{returnAddressName}</dd>
            </div>
          ) : null}
        </dl>
      </div>
    </Card>
  );
};
