import { gql, LazyQueryHookOptions, useLazyQuery, useQuery } from '@apollo/client';
import {
  ModifierValueOptionsQuery,
  ModifierValueOptionsQueryVariables,
} from './__generated__/modifier-value-options.gql.generated';
import { ModifierField } from '../types/__generated__/graphql';

export const MODIFIER_VALUE_OPTIONS_QUERY = gql`
  query ModifierValueOptions($field: ModifierField!) {
    modifierValueOptions(field: $field) {
      label
      value
    }
  }
`;

type Options = Pick<LazyQueryHookOptions<ModifierValueOptionsQuery>, 'onCompleted' | 'onError'>;

export const useModifierValueOptionsLazyQuery = ({ onCompleted, onError }: Options = {}) =>
  useLazyQuery<ModifierValueOptionsQuery, ModifierValueOptionsQueryVariables>(MODIFIER_VALUE_OPTIONS_QUERY, {
    notifyOnNetworkStatusChange: true,
    onCompleted,
    onError,
  });

export const useModifierValueOptionsQuery = (modifierField: ModifierField) =>
  useQuery<ModifierValueOptionsQuery, ModifierValueOptionsQueryVariables>(MODIFIER_VALUE_OPTIONS_QUERY, {
    variables: {
      field: modifierField,
    },
    notifyOnNetworkStatusChange: true,
  });
