import { AddressDetailsFragment } from '../operations/fragments/__generated__/address-details.gql.generated';

export const formatAddressEnding = (address: AddressDetailsFragment) => {
  const stateAndPostalCode = [address.state ?? '', address.zip ?? ''].join(' ').trim();

  const parts = [];
  if (address.city) {
    parts.push(address.city);
  }

  if (stateAndPostalCode) {
    parts.push(stateAndPostalCode);
  }

  if (address.country) {
    parts.push(address.country);
  }

  return parts.join(', ');
};
