import React from 'react';
import { TplcIntegrationForm, TplcIntegrationFormValues } from './TplcIntegrationForm';
import { useHistory } from 'react-router-dom';
import { useCreateTplcIntegration } from '../../operations/create-tplc-integration.gql';

export const NewTplcIntegrationFormController: React.FC = () => {
  const history = useHistory();
  const [create, { loading, error }] = useCreateTplcIntegration();

  const handleSubmit = async (values: TplcIntegrationFormValues) => {
    try {
      await create({
        variables: values,
      });
    } catch (err) {
      // TODO: Report to sentry
    }

    history.push('/settings/integrations');
  };

  return (
    <TplcIntegrationForm
      initialValues={{ clientId: '', clientSecret: '', description: '', userLogin: '' }}
      onSubmit={handleSubmit}
      loading={loading}
      submitText="Submit"
      error={error?.message ?? null}
    />
  );
};
