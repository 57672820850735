import React from 'react';
import { useOrder } from '../../providers/Order/OrderProvider';
import { Address } from '../Address';
import { Card } from '../Card';
import { CardHeading } from '../CardHeading';
import { CardContent } from '../CardContent';

export const OrderInfo: React.FC = () => {
  const { order } = useOrder();
  return (
    <Card className="mb-6">
      <CardHeading>Order Details</CardHeading>
      <CardContent>
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Ship To</dt>
            <dd className="mt-1 text-sm text-gray-900">
              <Address address={order!.shipToOriginal} />
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Ship To (Validated)</dt>
            <dd className="mt-1 text-sm text-gray-900">
              <Address address={order!.shipToValidated} />
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Shipping Method</dt>
            <dd className="mt-1 text-sm text-gray-900">{order!.shippingMethod ?? 'Unknown'}</dd>
          </div>
          {order!.shipped ? (
            <>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Service</dt>
                <dd className="mt-1 text-sm text-gray-900">{order!.serviceType}</dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Tracking Number</dt>
                <dd className="mt-1 text-sm text-gray-900">{order!.trackingCode}</dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Carrier</dt>
                <dd className="mt-1 text-sm text-gray-900">{order!.carrier}</dd>
              </div>
            </>
          ) : null}
        </dl>
      </CardContent>
    </Card>
  );
};
