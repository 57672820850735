import { gql, useMutation } from '@apollo/client';
import { ALL_RULE_DETAILS_FRAGMENT } from './fragments/all-rule-details.gql';
import { UpdateRuleMutation, UpdateRuleMutationVariables } from './__generated__/update-rule.gql.generated';

export const UPDATE_RULE_MUTATION = gql`
  mutation UpdateRule($input: UpdateRuleInput!) {
    updateRule(input: $input) {
      ...AllRuleDetails
    }
  }

  ${ALL_RULE_DETAILS_FRAGMENT}
`;

export const useUpdateRuleMutation = () =>
  useMutation<UpdateRuleMutation, UpdateRuleMutationVariables>(UPDATE_RULE_MUTATION, {
    refetchQueries: ['ListRules'],
  });
