import { gql, useQuery } from '@apollo/client';
import { RateShopV3Query } from './__generated__/rate-shop-v3.gql.generated';
import { RateShopV3QueryVariables } from './__generated__/rate-shop-v3.gql.generated';
import { RateShopV3Input } from '../types/__generated__/graphql';

export const RATE_SHOP_V3_QUERY = gql`
  query RateShopV3($input: RateShopV3Input!) {
    rateShopV3(input: $input) {
      messages {
        carrier
        message
        type
      }
      rates {
        id
        rate
        currency
        carrier
        service
        serviceReadable
        shipmentId
        deliveryDays
        deliveryDaysGuaranteed
        timeInTransit {
          percentile_50
          percentile_75
          percentile_85
          percentile_90
          percentile_95
          percentile_97
          percentile_99
        }
        epOrderId
        preferredRate
      }
    }
  }
`;

export const useRateShopV3Query = (input: RateShopV3Input, disabled: boolean = false) =>
  useQuery<RateShopV3Query, RateShopV3QueryVariables>(RATE_SHOP_V3_QUERY, {
    variables: {
      input,
    },
    fetchPolicy: 'network-only',
    skip: disabled,
  });
