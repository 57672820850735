import { gql, useQuery } from '@apollo/client';
import {
  RateFilterFieldOptionsQuery,
  RateFilterFieldOptionsQueryVariables,
} from './__generated__/rate-filter-field-options.gql.generated';

export const RATE_FILTER_FIELD_OPTIONS_QUERY = gql`
  query RateFilterFieldOptions {
    rateFilterFieldOptions {
      label
      value
      dataType
      operators {
        label
        value
      }
    }
  }
`;

export const useRateFilterFieldOptionsQuery = () =>
  useQuery<RateFilterFieldOptionsQuery, RateFilterFieldOptionsQueryVariables>(RATE_FILTER_FIELD_OPTIONS_QUERY);
