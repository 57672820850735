import { gql, useMutation } from '@apollo/client';
import {
  UpdateGeneralSettingsMutation,
  UpdateGeneralSettingsMutationVariables,
} from './__generated__/update-general-settings.gql.generated';

export const UPDATE_GENERAL_SETTINGS_MUTATION = gql`
  mutation UpdateGeneralSettings($input: GeneralSettingsInput!) {
    updateGeneralSettings(input: $input) {
      defaultReturnAddressId
      defaultShipFromAddressId
      customsSigner
      defaultEmail
      defaultPhoneNumber
    }
  }
`;

export const useUpdateGeneralSettingsMutation = () =>
  useMutation<UpdateGeneralSettingsMutation, UpdateGeneralSettingsMutationVariables>(UPDATE_GENERAL_SETTINGS_MUTATION);
