import React from 'react';

/* This example requires Tailwind CSS v2.0+ */
import { ExclamationCircleIcon } from '@heroicons/react/outline';

export const ErrorState: React.FC = () => {
  return (
    <div className="text-center border border-red-300 rounded-lg p-4">
      <ExclamationCircleIcon className="w-12 h-auto text-red-400 mx-auto" />
      <h3 className="mt-2 text-sm font-medium text-gray-900">Uh oh!</h3>
      <p className="mt-1 text-sm text-gray-500">There was an error loading your data.</p>
    </div>
  );
};
