import React from 'react';
import { ShipFormFooter } from '../atoms/ShipFormFooter';
import { Button } from '../../Button';
import { ShipFormContent } from '../atoms/ShipFormContent';
import { AddressFields } from '../../AddressFields';
import { FormSelect } from '../../Form/FormSelect';
import { useAddressBookAddressesQuery } from '../../../operations/address-book-addresses.gql';
import { useFormValidator, useMarkSectionAsTouched, useShipForm } from '../ShipForm';
import { useHistory } from 'react-router-dom';
import { InlineAlert } from '../../InlineAlert';
import { FormCheckbox } from '../../Form/FormCheckbox';
import { useValidateAddressMutation } from '../../../operations/validate-address.gql';

export const AddressesSection: React.FC = () => {
  const history = useHistory();
  const form = useShipForm();
  const { isAddressSectionValid } = useFormValidator();
  const { data: addressBookData, loading: addressBookLoading } = useAddressBookAddressesQuery({ limit: 50 });
  const [validateAddress, { data: validateAddressData, loading: validatingAddress }] = useValidateAddressMutation();
  const { markAddressesAsTouched } = useMarkSectionAsTouched();

  const options = addressBookData?.addressBookAddresses.items.map((item) => ({
    label: item.addressName,
    value: item.easyPostAddressId,
  }));

  const handleNextClick = async () => {
    markAddressesAsTouched();

    if (isAddressSectionValid) {
      history.push('packages');
    }
  };

  console.log(form.errors);

  const shipToAddressId = form.values.addresses.shipToAddressId;

  const enableAddressEdit = () => {
    form.setFieldValue('addresses.shipToAddressId', null);
    form.setFieldValue('addresses.shipToAddressValidated', false);
    form.setFieldValue('addresses.shipToAddressValidatedAttempted', false);
  };

  const validationErrors =
    (validateAddressData?.validateAddress ?? form.values.addresses.shipToAddress).verifications?.delivery?.errors.map(
      (error) => `[${error.field}]: ${error.message}`
    ) ?? [];

  const handleValidateAddress = async () => {
    const address = form.values.addresses.shipToAddress;
    try {
      const { data } = await validateAddress({
        variables: {
          address: {
            company: address.company,
            name: address.name,
            street1: address.street1,
            street2: address.street2,
            city: address.city,
            state: address.state,
            zip: address.zip,
            country: address.country,
            email: address.email,
            phone: address.phone,
          },
        },
      });

      if (data?.validateAddress.verifications?.delivery?.success) {
        const validatedAddress = data.validateAddress;

        form.setFieldValue(
          'addresses.shipToAddress',
          {
            company: validatedAddress.company ?? '',
            name: validatedAddress.name ?? '',
            street1: validatedAddress.street1 ?? '',
            street2: validatedAddress.street2 ?? '',
            city: validatedAddress.city ?? '',
            state: validatedAddress.state ?? '',
            zip: validatedAddress.zip ?? '',
            country: validatedAddress.country ?? '',
            verifications: validatedAddress.verifications,
          },
          true
        );
        form.setFieldValue('addresses.shipToAddressId', data.validateAddress.easyPostId, true);
        form.setFieldValue('addresses.shipToAddressValidated', true, true);
      } else {
        form.setFieldValue('addresses.shipToAddressId', data?.validateAddress.easyPostId);
        form.setFieldValue('addresses.shipToAddressValidated', false, true);
      }
      form.setFieldValue('addresses.shipToAddressValidatedAttempted', true);
    } catch (err) {
      // TODO: capture in sentru
    }
  };

  return (
    <>
      <ShipFormContent>
        <div className="grid grid-cols-2 gap-4 mb-8">
          <div className="col-span-1 space-y-2">
            <AddressFields
              parent="addresses.shipToAddress"
              label="Ship To"
              disabled={Boolean(shipToAddressId) || form.isSubmitting}
            />
            {!form.values.addresses.shipToAddressValidated ? (
              <>
                <Button
                  type="button"
                  kind="secondary"
                  disabled={form.isSubmitting}
                  onClick={handleValidateAddress}
                  loading={validatingAddress}
                >
                  Validate Address
                </Button>
                <FormCheckbox name="addresses.allowInvalidAddress" label="Allow shipping to an invalid address." />
                {form.values.addresses.shipToAddressValidatedAttempted ? (
                  <InlineAlert
                    className="self-stretch"
                    title="Address not valid"
                    type="error"
                    errors={validationErrors}
                  />
                ) : null}
              </>
            ) : null}
            {shipToAddressId ? (
              <div className="flex justify-end">
                <Button type="button" kind="secondary" onClick={enableAddressEdit} disabled={form.isSubmitting}>
                  Edit Address
                </Button>
              </div>
            ) : null}
          </div>
          <div className="space-y-4">
            <FormSelect
              label="Ship From Address"
              name="addresses.shipFromAddressId"
              disabled={addressBookLoading || form.isSubmitting}
              hideErrorMessage
            >
              <option value="">Select an address...</option>
              {options?.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </FormSelect>
            <FormSelect
              label="Return Address"
              name="addresses.returnAddressId"
              disabled={addressBookLoading || form.isSubmitting}
              hideErrorMessage
            >
              <option value="">Select an address...</option>
              {options?.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </FormSelect>
          </div>
        </div>
      </ShipFormContent>
      <ShipFormFooter>
        <Button type="button" onClick={handleNextClick} disabled={form.isSubmitting} size="lg">
          Next
        </Button>
      </ShipFormFooter>
    </>
  );
};
