import React, { FC } from 'react';
import classNames from 'classnames';

export const CardFooter: FC<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>> = ({
  children,
  className,
  ...divProps
}) => {
  return (
    <div className={classNames('px-4 py-4 sm:px-6', className)} {...divProps}>
      {children}
    </div>
  );
};
