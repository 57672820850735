import { gql, useQuery } from '@apollo/client';
import { CarrierAccountsQuery, CarrierAccountsQueryVariables } from './__generated__/carrier-accounts.gql.generated';

export const CARRIER_ACCOUNTS_QUERY = gql`
  query CarrierAccounts {
    carrierAccounts {
      carrier
      description
      id
      name
    }
  }
`;

export const useCarrierAccountsQuery = () =>
  useQuery<CarrierAccountsQuery, CarrierAccountsQueryVariables>(CARRIER_ACCOUNTS_QUERY);
