import React from 'react';
import { RuleSectionHeading } from '../atoms/RuleSectionHeading';
import { Button } from '../../Button';
import { PlusIcon } from '@heroicons/react/solid';
import { RuleSectionEmptyState } from '../atoms/RuleSectionEmptyState';
import { RuleSection } from '../atoms/RuleSection';
import { ModifierValues, useRulesForm } from '../RuleForm';
import { FieldArray } from 'formik';
import styles from './RuleModifierSection.module.scss';
import { RuleModifierRow } from './RuleModifierRow';
import { nanoid } from 'nanoid';
import { useModifierFieldOptionsQuery } from '../../../operations/modifier-field-options.gql';

export const initialModifierValue = (): ModifierValues => ({
  key: nanoid(5),
  modifier: '',
  value: [''],
});

export interface RuleModifierSectionProps {
  disabled?: boolean;
}

export const RuleModifierSection: React.FC<RuleModifierSectionProps> = ({ disabled }) => {
  const form = useRulesForm();
  const isEmpty = !form.values.modifiers.length;
  const { data, loading } = useModifierFieldOptionsQuery();

  const modifierOptions = data?.modifierFieldOptions ?? [];
  return (
    <FieldArray name={`modifiers`}>
      {({ push, remove }) => (
        <RuleSection>
          <RuleSectionHeading
            title="Modifiers"
            actions={
              <Button
                type="button"
                kind="white"
                size="sm"
                icon={PlusIcon}
                onClick={() => push(initialModifierValue())}
                disabled={disabled}
              >
                Modifier
              </Button>
            }
          />
          {isEmpty ? (
            <RuleSectionEmptyState
              title="No modifiers added."
              message="Modifiers change attributes about the package or shipment, such as packaging type or delivery options."
            />
          ) : (
            <div className={styles.modifierGrid}>
              {form.values.modifiers.map((modifier, modifierIndex) => (
                <RuleModifierRow
                  loading={loading}
                  modifierOptions={modifierOptions}
                  key={modifier.key}
                  modifierIndex={modifierIndex}
                  onDelete={() => remove(modifierIndex)}
                  disabled={disabled}
                />
              ))}
            </div>
          )}
        </RuleSection>
      )}
    </FieldArray>
  );
};
