import { SplitFactory } from '@splitsoftware/splitio';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useUser } from './UserContext';

const factory = SplitFactory({
  core: { authorizationKey: process.env.REACT_APP_SPLIT_TOKEN! },
  streamingEnabled: true,
});

type SplitClient = ReturnType<ReturnType<typeof SplitFactory>['client']>;

interface FeatureFlagState {
  ready: boolean;
  client: SplitClient | null;
}

const FeatureFlagContext = createContext<FeatureFlagState | undefined>(undefined);

export const FeatureFlagProvider: React.FC = ({ children }) => {
  const [ready, setReady] = useState(false);
  const [client, setClient] = useState<SplitClient | null>(null);
  const { user } = useUser();

  const values = useMemo(() => ({ ready, client }), [ready, client]);

  useEffect(() => {
    if (user?.attributes?.email) {
      const client = factory.client(user.attributes.email);

      client.on(client.Event.SDK_READY, () => {
        setReady(true);
      });

      setClient(client);
    }
  }, [user]);

  return <FeatureFlagContext.Provider value={values}>{children}</FeatureFlagContext.Provider>;
};

export const useFeatureFlag = (flag: string) => {
  const context = useContext(FeatureFlagContext);

  if (!context) {
    throw new Error('useFeatureFlag must be used within a FeatureFlagProvider');
  }
  const { client, ready } = context;

  const defaultValue = ready ? client!.getTreatment(flag) === 'on' : false;

  const [featureFlag, setFeatureFlag] = useState<boolean>(defaultValue);

  useEffect(() => {
    if (!ready || !client) {
      return;
    }

    setFeatureFlag(client.getTreatment(flag) === 'on');

    client.on(client.Event.SDK_UPDATE, () => {
      setFeatureFlag(client.getTreatment(flag) === 'on');
    });
  }, [client, flag, ready]);

  return featureFlag;
};
