import React from 'react';
import { UserTable } from '../components/UserManagement/UserTable';
import { useAllUsersQuery } from '../operations/all-users.gql';
import { LoadingPanel } from '../components/LoadingPanel';
import { ErrorState } from '../components/ErrorState';
import { SectionHeadingWithAction } from '../components/Layout/SectionHeadingWithAction';
import { Link, Route, Switch, useRouteMatch } from 'react-router-dom';
import { NewUser } from './NewUser';
import { Button } from '../components/Button';
import { EditUser } from './EditUser';

export const UserManagement: React.FC = () => {
  const { data, loading, error } = useAllUsersQuery(50, null);
  const { path } = useRouteMatch();

  const users = data?.allUsers.items ?? [];
  const newUserPath = `${path}/new-user`;
  const editUserPath = `${path}/edit-user/:userId`;

  return (
    <Switch>
      <Route path={newUserPath} component={NewUser} />
      <Route path={editUserPath} component={EditUser} />
      <Route path={path} exact>
        <div className="flex-1 flex flex-col h-full">
          <main className="flex-1 overflow-y-auto focus:outline-none bg-gray-100">
            <div className="relative max-w-4xl mx-auto px-6 md:p-6 2xl:p-0 h-full">
              <div className="pt-10 pb-16 h-full">
                <SectionHeadingWithAction
                  title="User Management"
                  actions={
                    <Link to={newUserPath}>
                      <Button>New User</Button>
                    </Link>
                  }
                />
                {loading ? (
                  <LoadingPanel />
                ) : error ? (
                  <ErrorState />
                ) : (
                  <UserTable users={users} editUserPath={editUserPath} />
                )}
              </div>
            </div>
          </main>
        </div>
      </Route>
    </Switch>
  );
};
