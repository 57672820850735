import React, { FC } from 'react';
import classNames from 'classnames';
import { NavigationItem } from '../../pages/Home';
import { Link, useLocation } from 'react-router-dom';
import { ReactComponent as Logo } from '../../assets/LogoWhiteNoBackground.svg';

export interface NarrowSidebarProps {
  navigationItems: NavigationItem[];
}

export const NarrowSidebar: FC<NarrowSidebarProps> = ({ navigationItems }) => {
  const location = useLocation();

  const isCurrent = (href: string) => location.pathname.startsWith(href);

  return (
    <div className="hidden w-28 bg-gray-800 overflow-y-auto md:block">
      <div className="w-full py-6 flex flex-col items-center">
        <div className="flex-shrink-0 flex items-center">
          <Logo className="h-8 w-auto" />
        </div>
        <div className="flex-1 mt-6 w-full px-2 space-y-1">
          {navigationItems.map((item) => (
            <Link
              key={item.name}
              to={item.href}
              className={classNames(
                isCurrent(item.href) ? 'bg-blue-500 text-white' : 'text-blue-300 hover:bg-blue-500 hover:text-white',
                'group w-full p-3 rounded-md flex flex-col items-center text-xs font-medium text-center'
              )}
              aria-current={isCurrent(item.href) ? 'page' : undefined}
            >
              <item.icon
                className={classNames(
                  isCurrent(item.href) ? 'text-white' : 'text-blue-200 group-hover:text-white',
                  'h-6 w-6'
                )}
                aria-hidden="true"
              />
              <span className="mt-2">{item.name}</span>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};
