import { FC } from 'react';
import { PrinterIcon } from '@heroicons/react/outline';
import { PrintNodeStatus, usePrintNode } from '../providers/PrintNodeProvider';

export const PrinterStatusIcon: FC = () => {
  const { status } = usePrintNode();

  const text =
    status === PrintNodeStatus.initializing
      ? 'PrintNode connecting...'
      : PrintNodeStatus.ready
      ? `Ready for printing.`
      : 'Check Printers & Scales settings.';
  const color = PrintNodeStatus.initializing ? 'yellow' : PrintNodeStatus.ready ? 'green' : 'red';

  return (
    <div className="inline-flex items-center justify-center relative h-8" title={text}>
      <svg
        className={`h-3 w-3 text-${color}-400 absolute -top-1 -right-1 ${
          PrintNodeStatus.initializing ? 'animate-pulse' : ''
        }`}
        fill="currentColor"
        viewBox="0 0 8 8"
      >
        <circle cx={4} cy={4} r={3} />
      </svg>
      <PrinterIcon className={`h-6 text-gray-500`} />
    </div>
  );
};
