import React, { FC } from 'react';
import { useField } from 'formik';
import { MultiSelect, MultiSelectProps } from '../MultiSelect';

export interface FormMultiSelectProps extends Omit<MultiSelectProps, 'error'> {
  name: string;
  hideErrorMessage?: boolean;
  disabled?: boolean;
}

export const FormMultiSelect: FC<FormMultiSelectProps> = ({
  className,
  label,
  onChange,
  hideErrorMessage,
  options,
  disabled,
  ...props
}) => {
  const [{ onChange: fieldOnChange, value, ...field }, meta, { setValue, setTouched }] = useField(props);

  const selected = value.map((v: string) => options?.find((option: any) => option.value === v));

  return (
    <MultiSelect
      className={className}
      label={label}
      id={props.name}
      error={meta.touched ? meta.error : null}
      value={selected}
      options={options}
      onChange={(value) => {
        setValue(
          value.map((option) => option.value),
          true
        );
        setTouched(true);
        onChange?.(value);
      }}
      isDisabled={disabled}
      hideErrorMessage={hideErrorMessage}
      {...props}
      {...field}
    />
  );
};
