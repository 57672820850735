import { gql, LazyQueryHookOptions, useLazyQuery, useQuery } from '@apollo/client';
import {
  RateFilterValueOptionsQuery,
  RateFilterValueOptionsQueryVariables,
} from './__generated__/rate-filter-value-options.gql.generated';
import { RateFilterField } from '../types/__generated__/graphql';

export const RATE_FILTER_VALUE_OPTIONS_QUERY = gql`
  query RateFilterValueOptions($field: RateFilterField!) {
    rateFilterValueOptions(field: $field) {
      label
      value
    }
  }
`;

type Options = Pick<LazyQueryHookOptions<RateFilterValueOptionsQuery>, 'onCompleted' | 'onError'>;

export const useRateFilterValueOptionsLazyQuery = ({ onCompleted, onError }: Options = {}) =>
  useLazyQuery<RateFilterValueOptionsQuery, RateFilterValueOptionsQueryVariables>(RATE_FILTER_VALUE_OPTIONS_QUERY, {
    notifyOnNetworkStatusChange: true,
    onCompleted,
    onError,
  });

export const useRateFilterValueOptionsQuery = (field: RateFilterField) =>
  useQuery<RateFilterValueOptionsQuery, RateFilterValueOptionsQueryVariables>(RATE_FILTER_VALUE_OPTIONS_QUERY, {
    variables: {
      field,
    },
    notifyOnNetworkStatusChange: true,
  });
