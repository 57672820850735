import { gql, useQuery } from '@apollo/client';
import {
  AddressBookAddressesQuery,
  AddressBookAddressesQueryVariables,
} from './__generated__/address-book-addresses.gql.generated';

export const ADDRESS_BOOK_ADDRESSES_QUERY = gql`
  query AddressBookAddresses($input: ListAddressBookAddressesInput!) {
    addressBookAddresses(input: $input) {
      limit
      count
      nextToken
      items {
        id
        easyPostAddressId
        addressName
        createdAt
        updatedAt
      }
    }
  }
`;

export const useAddressBookAddressesQuery = (input: AddressBookAddressesQueryVariables['input']) =>
  useQuery<AddressBookAddressesQuery, AddressBookAddressesQueryVariables>(ADDRESS_BOOK_ADDRESSES_QUERY, {
    variables: {
      input,
    },
    notifyOnNetworkStatusChange: true,
  });
