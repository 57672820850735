import React, { useEffect } from 'react';
import { DeleteButton } from '../atoms/DeleteButton';
import { FormSelect } from '../../Form/FormSelect';
import { RateFilterFieldOption, RuleDataType, RuleOperator } from '../../../types/__generated__/graphql';
import { useField } from 'formik';
import { useRateFilterValueOptionsLazyQuery } from '../../../operations/rate-filter-value-options.gql';
import { StringField } from '../rule-triggers/StringField';
import { BooleanField } from '../rule-triggers/BooleanField';
import { NumberField } from '../rule-triggers/NumberField';
import { TimeField } from '../rule-triggers/TimeField';
import { InputGroup } from '../../InputGroup';

export interface RateFilterRowProps {
  rateFilterIndex: number;
  onDelete?: () => void;
  rateFilterOptions: RateFilterFieldOption[];
  disabled?: boolean;
}

export const RateFilterRow: React.FC<RateFilterRowProps> = ({
  disabled,
  rateFilterIndex,
  onDelete,
  rateFilterOptions,
}) => {
  const fieldName = `rateFilters[${rateFilterIndex}].field`;
  const operatorName = `rateFilters[${rateFilterIndex}].operator`;
  const valueName = `rateFilters[${rateFilterIndex}].value`;

  const [
    valueOptionsQuery,
    { data: valueOptionsData, loading: valueOptionsLoading },
  ] = useRateFilterValueOptionsLazyQuery();

  const [field] = useField(fieldName);
  const [operator, , operatorHelpers] = useField(operatorName);
  const [, , valueHelpers] = useField(valueName);

  const operatorsDisabled = Boolean(!field.value);
  const valuesDisabled = Boolean(!operator.value);

  const selectedField = rateFilterOptions.find((fieldOption) => fieldOption.value === field.value);
  const selectedOperator: RuleOperator | null = (operator.value as RuleOperator) ?? null;
  const operatorOptions = selectedField?.operators ?? [];
  const dataType = selectedField?.dataType ?? null;
  const isMulti = [RuleOperator.In, RuleOperator.Nin].includes(selectedOperator);
  const isStringField = [RuleDataType.String, RuleDataType.Enum].includes(dataType as any);
  const isEnum = dataType === RuleDataType.Enum;
  const valueOptions = isEnum
    ? !valueOptionsLoading && valueOptionsData
      ? valueOptionsData.rateFilterValueOptions
      : []
    : null;

  const handleFieldChanged = () => {
    operatorHelpers.setValue('');
    operatorHelpers.setTouched(false);

    valueHelpers.setValue(['']);
    valueHelpers.setTouched(false);
  };

  const handleOperatorChanged = () => {
    valueHelpers.setValue(['']);
    valueHelpers.setTouched(false);
  };

  useEffect(() => {
    if (isEnum && selectedField) {
      valueOptionsQuery({ variables: { field: selectedField.value } });
    }
  }, [isEnum, selectedField, valueOptionsQuery]);

  return (
    <>
      <FormSelect name={fieldName} label="Field" hideErrorMessage onChange={handleFieldChanged} disabled={disabled}>
        <option value="" disabled>
          Select an option...
        </option>
        {rateFilterOptions.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </FormSelect>
      <FormSelect
        label="Operator"
        name={operatorName}
        hideErrorMessage
        disabled={operatorsDisabled || disabled}
        onChange={handleOperatorChanged}
      >
        <option value="">Select an option</option>
        {operatorOptions.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </FormSelect>
      {selectedField && selectedOperator ? (
        isStringField ? (
          <StringField label="Value" multi={isMulti} name={valueName} options={valueOptions} disabled={disabled} />
        ) : dataType === RuleDataType.Boolean ? (
          <BooleanField label="Value" name={valueName} disabled={disabled} />
        ) : dataType === RuleDataType.Number ? (
          <NumberField label="Value" name={valueName} disabled={disabled} />
        ) : dataType === RuleDataType.Time ? (
          <TimeField label="Value" name={valueName} disabled={disabled} hideErrorMessage />
        ) : null
      ) : (
        <InputGroup label="Value" type="text" hideErrorMessage disabled={valuesDisabled || disabled} />
      )}
      <DeleteButton onClick={onDelete} disabled={disabled} className="mt-5" />
    </>
  );
};
