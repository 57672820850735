import React from 'react';
import { SectionHeadingWithAction } from '../components/Layout/SectionHeadingWithAction';
import { Breadcrumb } from '../components/Breadcrumbs';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { AddressBookAddressForm, AddressValues } from '../components/AddressBook/AddressBookAddressForm';
import { LoadingPanel } from '../components/LoadingPanel';
import { useAddressBookAddressQuery } from '../operations/address-book-address.gql';
import { useUpdateAddressBookAddressMutation } from '../operations/update-address-book-address.gql';
import { useDeleteAddressBookAddressMutation } from '../operations/delete-address-book-address.gql';

export const AddressBookEdit: React.FC = () => {
  const { url } = useRouteMatch('/settings/address-book') ?? { url: '#' };
  const {
    params: { addressId },
  } = useRouteMatch<{ addressId: string }>();
  const history = useHistory();

  const { data, loading } = useAddressBookAddressQuery(addressId);
  const [updateAddress, { loading: updating }] = useUpdateAddressBookAddressMutation();
  const [deleteAddress, { loading: deleting }] = useDeleteAddressBookAddressMutation();

  const breadcrumbs: Breadcrumb[] = [
    { path: url, label: 'Address Book' },
    { path: '#', label: 'New Address' },
  ];

  const handleSave = async (values: AddressValues) => {
    try {
      await updateAddress({
        variables: {
          id: addressId,
          input: values,
        },
      });

      history.push(url);
    } catch (err) {
      // TODO: capture in sentry
    }
  };

  const handleDelete = async () => {
    try {
      await deleteAddress({
        variables: {
          id: addressId,
        },
      });

      history.push(url);
    } catch (err) {
      // TODO: capture in sentry
    }
  };

  const address = data?.addressBookAddress.address;

  return (
    <>
      <SectionHeadingWithAction title="New Address" breadcrumbs={breadcrumbs} />
      {loading ? (
        <LoadingPanel />
      ) : data ? (
        <AddressBookAddressForm
          initialValues={{
            addressName: data?.addressBookAddress?.addressName,
            city: address?.city ?? '',
            name: address?.name ?? '',
            company: address?.company ?? '',
            country: address?.country ?? '',
            state: address?.state ?? '',
            zip: address?.zip ?? '',
            street1: address?.street1 ?? '',
            street2: address?.street2 ?? '',
            email: address?.email ?? '',
            phone: address?.phone ?? '',
          }}
          edit
          onDelete={handleDelete}
          onSubmit={handleSave}
          saving={updating}
          deleting={deleting}
        />
      ) : null}
    </>
  );
};
