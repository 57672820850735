import { ComponentType, FC } from 'react';
import classNames from 'classnames';

export interface BadgeProps {
  className?: string;
  color?: string;
  icon?: ComponentType<any>;
}

export const Badge: FC<BadgeProps> = ({ children, className, color = 'gray', icon: Icon }) => {
  return (
    <span
      className={classNames(
        `inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-${color}-100 text-${color}-800`,
        className
      )}
    >
      {Icon ? <Icon className="h-4 mr-1" /> : null}
      <span>{children}</span>
    </span>
  );
};
