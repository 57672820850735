import { gql } from '@apollo/client/core';
import { useMutation } from '@apollo/client';
import { CreateManifestMutation, CreateManifestMutationVariables } from './__generated__/create-manifest.gql.generated';

export const CREATE_MANIFEST_MUTATION = gql`
  mutation CreateManifest($carrier: EasyPostCarrier!, $locationId: Int!) {
    createManifest(carrier: $carrier, locationId: $locationId) {
      id
      createdAt
      locationId
      status
      carrier
      formUrl
    }
  }
`;

export const useCreateManifestMutation = () => {
  return useMutation<CreateManifestMutation, CreateManifestMutationVariables>(CREATE_MANIFEST_MUTATION);
};
