import { gql } from '@apollo/client/core';
import { useQuery } from '@apollo/client';
import {
  AllOrderChannelsQuery,
  AllOrderChannelsQueryVariables,
} from './__generated__/all-order-channels.gql.generated';

export const ALL_CUSTOMERS_QUERY = gql`
  query AllOrderChannels {
    integrations {
      id
      type
      description
      orderChannels {
        id
        name
      }
    }
  }
`;

export const useAllOrderChannelsQuery = () => {
  return useQuery<AllOrderChannelsQuery, AllOrderChannelsQueryVariables>(ALL_CUSTOMERS_QUERY);
};
