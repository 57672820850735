import React from 'react';
import { useField } from 'formik';
import classNames from 'classnames';
import { RateTableProps } from '../elements/RateTable';

export interface RateRadioProps {
  name: string;
  value: RateTableProps['rates'][number];
  disabled?: boolean;
}

export const RateRadio: React.FC<RateRadioProps> = ({ name, disabled, value }) => {
  const [field, , { setValue }] = useField({ name, type: 'radio', value: value as any });

  return (
    <input
      {...field}
      type="radio"
      className={classNames(
        'focus:ring-blue-500 h-4 w-4 border-gray-300 rounded',
        disabled ? 'text-gray-300' : 'text-blue-600'
      )}
      disabled={disabled}
      onChange={(e) => {
        setValue(value);
      }}
    />
  );
};
