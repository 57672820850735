/* This example requires Tailwind CSS v2.0+ */

import { User } from '../../types/__generated__/graphql';
import React, { useState } from 'react';
import { useDeleteUserMutation } from '../../operations/delete-user.gql';
import { useErrorModal } from '../../utilities/use-error-modal';
import { ConfirmationModal } from '../ConfirmationModal';
import { ExclamationIcon } from '@heroicons/react/solid';
import { ErrorModal } from '../ErrorModal';
import { Link } from 'react-router-dom';

interface UserTableProps {
  users: User[];
  editUserPath: string;
}

export const UserTable: React.FC<UserTableProps> = ({ editUserPath, users }) => {
  const [deleteUser, { error: deleteError, loading: deletingUser }] = useDeleteUserMutation();
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const [deleteCandidateId, setDeleteCandidateId] = useState<string | null>(null);
  const { showErrorModal, handleErrorModalClose } = useErrorModal(deleteError?.message);

  const handleDeleteClicked = (id: string) => {
    setShowDeleteConfirmationModal(true);
    setDeleteCandidateId(id);
  };

  const handleDeleteCancel = () => {
    setShowDeleteConfirmationModal(false);
    setDeleteCandidateId(null);
  };

  const handleDeleteUser = async () => {
    try {
      await deleteUser({ variables: { id: deleteCandidateId! }, refetchQueries: ['AllUsers'] });
    } catch (err) {
      // TODO: capture in sentry
    } finally {
      setShowDeleteConfirmationModal(false);
      setDeleteCandidateId(null);
    }
  };

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Email
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Roles
                  </th>
                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">Edit</span>
                  </th>
                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">Delete</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {users.map((user, index) => (
                  <tr key={user.email} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{user.email}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{user.name}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{user.roles.join(', ')}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <Link to={editUserPath.replace(':userId', user.id)}>
                        <button className="text-blue-600 hover:text-blue-900">Edit</button>
                      </Link>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <button
                        className="text-blue-600 hover:text-blue-900"
                        onClick={() => handleDeleteClicked(user.id)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <ConfirmationModal
        header="Delete user"
        Icon={ExclamationIcon}
        kind="error"
        isOpen={showDeleteConfirmationModal}
        message="This action cannot be undone."
        onAccept={handleDeleteUser}
        onCancel={handleDeleteCancel}
        loading={deletingUser}
      />
      <ErrorModal isOpen={showErrorModal} onClose={handleErrorModalClose} message={deleteError?.message} />
    </div>
  );
};
