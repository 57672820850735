import { Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import { SectionHeadingWithAction } from '../components/Layout/SectionHeadingWithAction';
import { FormInput } from '../components/Form/FormInput';
import * as yup from 'yup';
import { addMonths } from 'date-fns';
import { useAllOrderChannelsQuery } from '../operations/all-order-channels.gql';
import { LoadingPanel } from '../components/LoadingPanel';
import { FormSelect } from '../components/Form/FormSelect';
import { formatOrderChannelName } from '../utilities/format-order-channel-name';
import { FormDatePicker } from '../components/Form/FormDatePicker';
import { Button } from '../components/Button';
import { useCreateReportMutation } from '../operations/create-report.gql';
import { ReportType, SortOrder } from '../types/__generated__/graphql';
import { useHistory } from 'react-router-dom';
import { REPORTS_QUERY } from '../operations/reports.gql';
import { ReportsQuery, ReportsQueryVariables } from '../operations/__generated__/reports.gql.generated';

interface CreateReportValues {
  orderChannelId: string; // integrationId#orderChannelId
  name: string;
  startDate: Date;
  endDate: Date;
}

const validationSchema = yup.object().shape({
  orderChannelId: yup.string().required(),
  name: yup.string().required(),
  startDate: yup.date().required(),
  endDate: yup.date().required(),
});

const initialValues: CreateReportValues = {
  orderChannelId: '',
  name: '',
  startDate: addMonths(new Date(), -1),
  endDate: new Date(),
};

export const CreateReport: React.FC = () => {
  const { data: orderChannelsData, loading: orderChannelsLoading } = useAllOrderChannelsQuery();
  const [createReport] = useCreateReportMutation();
  const history = useHistory();

  const orderChannels =
    orderChannelsData?.integrations.flatMap((integration) => [
      { label: integration.description, value: `${integration.id}#global` },
      ...integration.orderChannels.map((orderChannel) => ({
        label: formatOrderChannelName(orderChannel.name, integration.type),
        value: `${integration.id}#${orderChannel.id}`,
      })),
    ]) ?? [];

  const handleSubmit = async (values: CreateReportValues, helpers: FormikHelpers<CreateReportValues>) => {
    try {
      const parts = values.orderChannelId.split('#');
      const integrationId = parts[0];
      const orderChannelId = parts[1];
      await createReport({
        variables: {
          input: {
            integrationId,
            orderChannelId,
            name: values.name,
            type: ReportType.Shipments,
            startDate: values.startDate.toISOString(),
            endDate: values.endDate.toISOString(),
          },
        },
        refetchQueries: ['Reports'],
        update: (cache, { data }) => {
          if (!data) {
            return;
          }

          const listQuery = cache.readQuery<ReportsQuery, ReportsQueryVariables>({
            query: REPORTS_QUERY,
            variables: {
              input: {
                limit: 50,
                sortOrder: SortOrder.Desc,
                nextToken: null,
              },
            },
          });

          if (listQuery) {
            cache.writeQuery<ReportsQuery, ReportsQueryVariables>({
              query: REPORTS_QUERY,
              variables: {
                input: {
                  limit: 50,
                  sortOrder: SortOrder.Desc,
                  nextToken: null,
                },
              },
              data: {
                reports: {
                  ...listQuery.reports,
                  items: [data.createReport, ...listQuery.reports.items],
                },
              },
            });
          }
        },
      });

      history.push('/reports');
    } catch (err) {
      // TODO: Capture in sentry
      helpers.setSubmitting(false);
    }
  };

  return (
    <div className="flex-1 flex flex flex-col h-full bg-white">
      <main className="flex-1 flex flex-col overflow-y-auto focus:outline-none">
        <div className="relative max-w-2xl mx-auto px-6 2xl:px-0 w-full flex flex-col flex-1">
          <div className="pt-10 pb-6 flex flex-col flex-1">
            <SectionHeadingWithAction title="New Report" breadcrumbs={[{ label: 'Reports', path: '/reports' }]} />
            {orderChannelsLoading ? (
              <LoadingPanel className="flex-1" />
            ) : (
              <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                {({ values, setFieldValue, isSubmitting }) => {
                  return (
                    <Form className="space-y-4">
                      <FormSelect name="orderChannelId" label="Order Channel" hideErrorMessage disabled={isSubmitting}>
                        <option value="" disabled>
                          Select an order channel
                        </option>
                        {orderChannels.map((orderChannel) => (
                          <option key={orderChannel.value} value={orderChannel.value}>
                            {orderChannel.label}
                          </option>
                        ))}
                      </FormSelect>
                      <FormInput
                        name="name"
                        label="Report Name"
                        type="text"
                        placeholder="Enter a report name"
                        hideErrorMessage
                        disabled={isSubmitting}
                      />
                      <div className="grid grid-cols-2 gap-x-4">
                        <FormDatePicker label="Start Date" name="startDate" showTimeSelect disabled={isSubmitting} />
                        <FormDatePicker label="End Date" name="endDate" showTimeSelect disabled={isSubmitting} />
                      </div>
                      <div className="flex justify-end">
                        <Button type="submit" loading={isSubmitting}>
                          Submit
                        </Button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            )}
          </div>
        </div>
      </main>
    </div>
  );
};
