import { createContext, useContext } from 'react';

interface UserContextValue {
  user: null | any;
  tempUser: null | any; // For the "complete password" flow
  setTempUser: (user: any) => void;
  groups: string[];
}

export const UserContext = createContext<UserContextValue | undefined>(undefined);

export const useUser = () => {
  const context = useContext(UserContext);

  if (context === undefined) {
    throw new Error('useUser must be within a `UserProvider`');
  }

  return context;
};
