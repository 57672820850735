import { gql, useMutation } from '@apollo/client';
import { RefundOrderV2Mutation, RefundOrderV2MutationVariables } from './__generated__/refund-order-v2.gql.generated';

export const REFUND_ORDER_V2_MUTATION = gql`
  mutation RefundOrderV2($input: RefundOrderV2Input!) {
    refundOrderV2(input: $input) {
      message
    }
  }
`;

export const useRefundOrderV2Mutation = () =>
  useMutation<RefundOrderV2Mutation, RefundOrderV2MutationVariables>(REFUND_ORDER_V2_MUTATION);
