import { gql } from '@apollo/client/core';
import { useQuery } from '@apollo/client';
import { ShippingStatsQuery, ShippingStatsQueryVariables } from './__generated__/shipping-stats.gql.generated';

export const SHIPPING_STATS_QUERY = gql`
  query ShippingStats {
    shippingStats {
      refundedCount
      shippedCount
    }
  }
`;

export const useShippingStatsQuery = () => {
  return useQuery<ShippingStatsQuery, ShippingStatsQueryVariables>(SHIPPING_STATS_QUERY);
};
