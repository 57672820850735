/* This example requires Tailwind CSS v2.0+ */
import React from 'react';
import { Switch } from '@headlessui/react';
import classNames from 'classnames';
import { CarrierAccountTypeToLogoMap } from '../../utilities/get-carrier-logo';
import { CarrierAccountType } from '../../types/__generated__/graphql';

export interface CarrierRowProps {
  accountName: string;
  carrier: CarrierAccountType;
  description?: string;
  checked: boolean;
  onChange: (enabled: boolean) => void;
  disabled?: boolean;
}

export const CarrierRow: React.FC<CarrierRowProps> = ({
  accountName,
  carrier,
  checked,
  description,
  disabled,
  onChange,
}) => {
  const Logo = CarrierAccountTypeToLogoMap[carrier];

  return (
    <Switch.Group as="div" className="flex items-center justify-between">
      <Logo className="w-8 h-auto max-h-8 mr-4" />

      <span className="flex-grow flex flex-col">
        <Switch.Label as="span" className="text-sm font-medium text-gray-900" passive>
          {accountName}
        </Switch.Label>
        <Switch.Description as="span" className="text-sm text-gray-500">
          {description}
        </Switch.Description>
      </span>
      <Switch
        disabled={disabled}
        checked={checked}
        onChange={onChange}
        className={classNames(
          checked ? 'bg-blue-600' : 'bg-gray-200',
          'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            checked ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
          )}
        />
      </Switch>
    </Switch.Group>
  );
};
