import React from 'react';
import { useField } from 'formik';

export interface FormCheckboxProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  name: string;
  label: string;
  description?: string;
}

export const FormCheckbox: React.FC<FormCheckboxProps> = ({ label, description, ...inputProps }) => {
  const [field] = useField({ type: 'checkbox', ...inputProps });

  return (
    <div className="relative flex items-start">
      <div className="flex items-center h-5">
        <input
          {...field}
          type="checkbox"
          className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
        />
      </div>
      <div className="ml-3 text-sm">
        <label htmlFor="comments" className="font-medium text-gray-700">
          {label}
        </label>
        {description ? (
          <p id="comments-description" className="text-gray-500">
            {description}
          </p>
        ) : null}
      </div>
    </div>
  );
};
