import React, { useState } from 'react';
import { SectionHeadingWithAction } from '../components/Layout/SectionHeadingWithAction';
import { OrderChannelSelect } from '../components/OrderChannelSelect';
import { useAllOrderChannelsQuery } from '../operations/all-order-channels.gql';
import { RateAdjustmentForm, RateAdjustmentFormValues } from '../components/RateAdjustment/RateAdjustmentForm';
import { useCarrierAccountsQuery } from '../operations/carrier-accounts.gql';
import { useRateAdjustmentQuery } from '../operations/rate-adjustment.gql';
import { LoadingPanel } from '../components/LoadingPanel';
import { isNil } from 'lodash';

const convertAdjustment = (value: number) => (Math.round((value - 1) * 100 * 100) / 100).toString();

export const RateAdjustment: React.FC = () => {
  const [integrationId, setIntegrationId] = useState('global');
  const [orderChannelId, setOrderChannelId] = useState('global');
  const { data: integrationsData } = useAllOrderChannelsQuery();
  const { data: carrierAccountsData } = useCarrierAccountsQuery();
  const { data: rateAdjustmentData, loading: rateAdjustmentLoading } = useRateAdjustmentQuery({
    integrationId,
    orderChannelId,
  });

  const integrations = integrationsData?.integrations ?? [];

  const handleOrderChannelSelect = (value: { integrationId: string; orderChannelId: string }) => {
    setIntegrationId(value.integrationId);
    setOrderChannelId(value.orderChannelId);
  };

  const adjustment = rateAdjustmentData?.rateAdjustment;

  const initialValues: RateAdjustmentFormValues | null = rateAdjustmentLoading
    ? null
    : adjustment
    ? {
        integrationId,
        orderChannelId,
        adjustment: convertAdjustment(adjustment.adjustment),
        carrierAdjustments: adjustment.carrierAdjustments?.reduce(
          (carrierAdjustments, current) => ({
            ...carrierAdjustments,
            [current.accountId]: {
              adjustment: isNil(current.adjustment) ? '' : convertAdjustment(current.adjustment),
              serviceAdjustments: current.serviceAdjustments
                ? current.serviceAdjustments.reduce(
                    (serviceAdjustments, { service, adjustment }) => ({
                      ...serviceAdjustments,
                      [service]: isNil(adjustment) ? '' : convertAdjustment(adjustment),
                    }),
                    {}
                  )
                : null,
            },
          }),
          {}
        ),
      }
    : {
        integrationId,
        orderChannelId,
        adjustment: '0',
      };

  const integrationName = integrationsData?.integrations
    .find((integration) => integration.id === integrationId)
    ?.orderChannels.find((channel) => channel.id === orderChannelId)?.name;

  const formTitle = integrationId === 'global' ? 'Global Rate Adjustments' : `Adjustments for ${integrationName}`;

  const carrierAccounts = carrierAccountsData?.carrierAccounts ?? [];

  return (
    <div className="flex-1 flex flex flex-col h-full bg-gray-100">
      <main className="flex-1 flex flex-col overflow-y-auto focus:outline-none">
        <div className="relative max-w-4xl mx-auto px-6 2xl:px-0 w-full flex flex-col flex-1">
          <div className="pt-10 pb-6 flex flex-col flex-1">
            <SectionHeadingWithAction
              title="Rate Adjustment"
              actions={
                <OrderChannelSelect
                  integrations={integrations}
                  integrationId={integrationId}
                  orderChannelId={orderChannelId}
                  onChange={handleOrderChannelSelect}
                />
              }
            />
            {!initialValues ? (
              <LoadingPanel />
            ) : (
              <RateAdjustmentForm
                key={`${integrationId}-${orderChannelId}`}
                carrierAccounts={carrierAccounts}
                initialValues={initialValues}
                title={formTitle}
              />
            )}
          </div>
        </div>
      </main>
    </div>
  );
};
