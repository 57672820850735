import { Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import { FormSelect } from '../Form/FormSelect';
import * as yup from 'yup';
import { useCreateManifestMutation } from '../../operations/create-manifest.gql';
import { useHistory } from 'react-router-dom';
import { Button } from '../Button';
import { useFacilities } from '../../providers/FacilityProvider';
import { MANIFESTS_QUERY } from '../../operations/manifests.gql';
import { Card } from '../Card';
import { CardFooter } from '../CardFooter';
import mixpanel from 'mixpanel-browser';
import { AllFacilitiesQuery } from '../../operations/__generated__/all-facilities.gql.generated';
import { EasyPostCarrier } from '../../types/__generated__/graphql';
import { ManifestsQuery } from '../../operations/__generated__/manifests.gql.generated';
import { CardContent } from '../CardContent';

type Facilities = AllFacilitiesQuery['allFacilities'];

interface CreateManifestValues {
  carrier: EasyPostCarrier;
  locationId?: number;
}

export const defaultValues = (facilities: Facilities, selectedFacilityId: string | null): CreateManifestValues => {
  return {
    carrier: EasyPostCarrier.DhlEcs,
    locationId: selectedFacilityId ? +selectedFacilityId : +facilities[0].id,
  };
};

const schema = yup.object().shape({
  carrier: yup.string().required(),
  locationId: yup.string().required(),
});

export interface CreateManifestFormProps {
  facilities: Facilities;
}

export const CreateManifestForm: React.FC<CreateManifestFormProps> = ({ facilities }) => {
  const { selectedFacilityId } = useFacilities();
  const [createManifest, { loading }] = useCreateManifestMutation();
  const history = useHistory();

  const handleFormSubmit = async (values: CreateManifestValues, formikHelpers: FormikHelpers<CreateManifestValues>) => {
    if (!schema.validate(values)) {
      return;
    }

    try {
      mixpanel.track('Create Manifest Submit');
      await createManifest({
        variables: {
          carrier: values.carrier,
          locationId: +values.locationId!,
        },
        update(cache, { data }) {
          const newManifest = data?.createManifest;
          const existingManifests = cache.readQuery<ManifestsQuery>({
            query: MANIFESTS_QUERY,
            variables: { facilityId: +values.locationId! },
          });

          console.log({ newManifest, existingManifests });

          if (existingManifests && newManifest) {
            cache.writeQuery({
              query: MANIFESTS_QUERY,
              variables: { facilityId: +values.locationId! },
              data: {
                manifests: [newManifest, ...existingManifests.manifests],
              },
            });
          }
        },
      });
      mixpanel.track('Create Manifest Succeeded');
      history.push('/manifest');
    } catch (err) {
      mixpanel.track('Create Manifest Failed');
      console.error('An error occurred while creating manifest:', err);
    }
  };

  return (
    <Formik
      initialValues={defaultValues(facilities, selectedFacilityId)}
      onSubmit={handleFormSubmit}
      validationSchema={schema}
    >
      {({ isValid }) => (
        <Form>
          <Card>
            <CardContent>
              <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className="md:col-span-1">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">Create Manifest</h3>
                  <p className="mt-1 text-sm text-gray-500">
                    Creates a carrier-specific shipping manifest to hand-off to the carrier. Some carriers, such as DHL,
                    require a manifest.
                  </p>
                  <p className="mt-1 text-sm text-gray-500">
                    All un-manifested shipments for the selected carrier and facility will be included in the manifest.
                  </p>
                </div>
                <div className="mt-5 md:mt-0 md:col-span-2">
                  <FormSelect label="Facility" name="locationId" className="mb-4 block w-48 w-full" required>
                    {facilities.map((facility) => (
                      <option key={facility.id} value={+facility.id}>
                        {facility.name} ({facility.id})
                      </option>
                    ))}
                  </FormSelect>
                  <FormSelect label="Carrier" name="carrier" className="mb-4 block w-48 w-full" required>
                    {Object.values(EasyPostCarrier).map((carrier) => (
                      <option key={carrier} value={carrier}>
                        {carrier}
                      </option>
                    ))}
                  </FormSelect>
                </div>
              </div>
            </CardContent>
            <CardFooter className="flex justify-end">
              <Button disabled={!isValid} type="submit" loading={loading}>
                Create manifest
              </Button>
            </CardFooter>
          </Card>
        </Form>
      )}
    </Formik>
  );
};
