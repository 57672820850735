import { gql, useQuery } from '@apollo/client';
import { CarrierAccountType } from '../types/__generated__/graphql';
import {
  CarrierAccountServicesQuery,
  CarrierAccountServicesQueryVariables,
} from './__generated__/carrier-account-services.gql.generated';

export const CARRIER_ACCOUNT_SERVICES_QUERY = gql`
  query CarrierAccountServices($carrierAccountType: CarrierAccountType!) {
    carrierAccountServices(carrierAccountType: $carrierAccountType) {
      label
      value
    }
  }
`;

export const useCarrierAccountServicesQuery = (carrierAccountType: CarrierAccountType) =>
  useQuery<CarrierAccountServicesQuery, CarrierAccountServicesQueryVariables>(CARRIER_ACCOUNT_SERVICES_QUERY, {
    variables: {
      carrierAccountType,
    },
  });
