/* This example requires Tailwind CSS v2.0+ */
import React from 'react';
import { TableHead } from './TableHead';
import { TableHeaderCell } from './TableHeaderCell';
import { TableCell } from './TableCell';
import { format } from 'date-fns';
import { Badge } from './Badge';
import { ManifestStatus } from '../types/__generated__/graphql';

interface TableProps {
  manifests: {
    id: string;
    createdAt: string;
    carrier: string;
    status: ManifestStatus;
    formUrl?: string | null;
  }[];
}

const formatDate = (isoString: string): string => {
  return format(new Date(isoString), 'yyyy-MM-dd @ hh:mm:ss:aa');
};

const getBadgeColor = (status: ManifestStatus) => {
  switch (status) {
    case ManifestStatus.Created:
      return 'green';
    case ManifestStatus.Creating:
      return 'orange';
    case ManifestStatus.Failed:
      return 'red';
  }
};

/**
 * UPPERCASE first char of each word.
 */
function properCase(str: string) {
  return str.toLowerCase().replace(/^\w|\s\w/g, (s) => s.toUpperCase());
}

export const ManifestTable: React.FC<TableProps> = ({ manifests }) => {
  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <TableHead>
                <tr>
                  <TableHeaderCell>Created At</TableHeaderCell>
                  <TableHeaderCell>Carrier</TableHeaderCell>
                  <TableHeaderCell>Status</TableHeaderCell>
                  <TableHeaderCell>
                    <span className="sr-only">Download</span>
                  </TableHeaderCell>
                </tr>
              </TableHead>

              <tbody>
                {manifests.map((manifest, index) => (
                  <tr key={manifest.id} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                    <TableCell>{formatDate(manifest.createdAt)}</TableCell>
                    <TableCell>{manifest.carrier}</TableCell>
                    <TableCell>
                      <Badge color={getBadgeColor(manifest.status)}>{properCase(manifest.status)}</Badge>
                    </TableCell>
                    <TableCell>
                      {manifest.formUrl ? (
                        <a
                          href={manifest.formUrl}
                          className="text-blue-600 hover:text-blue-900"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Download
                        </a>
                      ) : null}
                    </TableCell>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
