/* This example requires Tailwind CSS v2.0+ */
import React, { FC } from 'react';
import classNames from 'classnames';

export const Card: FC<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>> = ({
  children,
  className,
  ...divProps
}) => {
  return (
    <div
      className={classNames('bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200', className)}
      {...divProps}
    >
      {children}
    </div>
  );
};
