import { gql } from '@apollo/client/core';
import { useMutation } from '@apollo/client';
import { RefundOrderMutation, RefundOrderMutationVariables } from './__generated__/refund-order.gql.generated';

export const REFUND_ORDER_MUTATION = gql`
  mutation RefundOrder($orderId: ID!) {
    refundOrder(tplcOrderId: $orderId) {
      id
      trackingCode
      parcels {
        trackingCode
        labelUrl
      }
      shipped
      shippingMethod
      carrier
      serviceType
    }
  }
`;

export const useRefundOrderMutation = () => {
  return useMutation<RefundOrderMutation, RefundOrderMutationVariables>(REFUND_ORDER_MUTATION);
};
