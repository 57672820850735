import React, { useContext, useEffect, useState } from 'react';
import { Computer, Printer, Scale, usePrintNodeApi } from '../utilities/print-node';

export enum PrintNodeStatus {
  initializing,
  ready,
  error,
}

export interface PrintNodeState {
  computers: Computer[];
  printers: Printer[];
  scaleMeasurement: number | null;
  scaleStatus: PrintNodeStatus;
  scales: Scale[];
  selectedComputer: number | null;
  selectedPrinter: number | null;
  selectedScale: Scale | null;
  setSelectedComputer: (id: number | null) => void;
  setSelectedPrinter: (id: number | null) => void;
  setSelectedScale: (scale: Scale | null) => void;
  status: PrintNodeStatus;
}

const PrintNodeContext = React.createContext<PrintNodeState | undefined>(undefined);

export const PrintNodeProvider: React.FC = ({ children }) => {
  const savedComputer = localStorage.getItem('default-computer');
  const savedPrinter = localStorage.getItem('default-printer');
  const savedScale: Scale | null = localStorage.getItem('default-scale')
    ? JSON.parse(localStorage.getItem('default-scale')!)
    : null;

  const [status, setStatus] = useState<PrintNodeStatus>(PrintNodeStatus.initializing);
  const [scaleStatus, setScaleStatus] = useState<PrintNodeStatus>(PrintNodeStatus.initializing);
  const [computers, setComputers] = useState<Computer[]>([]);
  const [printers, setPrinters] = useState<Printer[]>([]);
  const [scales, setScales] = useState<Scale[]>([]);
  const [selectedComputer, setSelectedComputer] = useState<number | null>(savedComputer ? +savedComputer : null);
  const [selectedPrinter, setSelectedPrinter] = useState<number | null>(savedPrinter ? +savedPrinter : null);
  const [selectedScale, setSelectedScale] = useState<Scale | null>(savedScale ? savedScale : null);
  const [scaleMeasurement, setScaleMeasurement] = useState<number | null>(null);
  const { printNodeApi } = usePrintNodeApi();

  useEffect(() => {
    printNodeApi
      ?.listComputers()
      .then((computers) => {
        setComputers(computers);
      })
      .catch((err) => {
        console.error('Error in PrintNodeApi:', err);
      });
  }, [printNodeApi]);

  useEffect(() => {
    if (selectedComputer !== null && printNodeApi) {
      printNodeApi.listPrinters(selectedComputer).then(setPrinters);
      printNodeApi.listScales(selectedComputer).then(setScales);
    }
  }, [printNodeApi, selectedComputer]);

  useEffect(() => {
    if (selectedComputer && selectedPrinter) {
      if (
        computers.find((computer) => computer.id === selectedComputer)?.state === 'connected' &&
        printers.find((printer) => printer.id === selectedPrinter)?.state === 'online'
      ) {
        setStatus(PrintNodeStatus.ready);
      } else {
        setStatus(PrintNodeStatus.error);
      }
    }

    if (selectedScale) {
      if (scales.find((scale) => scale.deviceNum === selectedScale.deviceNum)) {
        setScaleStatus(PrintNodeStatus.ready);
      } else {
        setScaleStatus(PrintNodeStatus.error);
      }
    }
  }, [selectedPrinter, selectedComputer, printers, computers, selectedScale, scales]);

  useEffect(() => {
    if (printNodeApi && selectedComputer && selectedScale && scaleStatus === PrintNodeStatus.ready) {
      let subscription: any = {};
      printNodeApi
        .connect()
        .then(() =>
          printNodeApi.listenToScale(selectedComputer.toString(), selectedScale, (data) => {
            setScaleMeasurement(Math.round(100 * data.mass[0] * 2.2046e-9) / 100);
          })
        )
        .then((sub) => (subscription.value = sub));
      return () => {
        printNodeApi.stopListening(subscription.value);
      };
    }
  }, [printNodeApi, scaleStatus, selectedComputer, selectedScale]);

  return (
    <PrintNodeContext.Provider
      value={{
        computers,
        printers,
        scaleMeasurement,
        scaleStatus,
        scales,
        selectedComputer,
        selectedPrinter,
        selectedScale,
        setSelectedComputer,
        setSelectedPrinter,
        setSelectedScale,
        status,
      }}
    >
      {children}
    </PrintNodeContext.Provider>
  );
};

export const usePrintNode = () => {
  const context = useContext(PrintNodeContext);

  if (context === undefined) {
    throw new Error('usePrintNode must be used within a PrintNodeContext');
  }

  return context;
};
