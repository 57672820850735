import React from 'react';
import { Card } from '../Card';
import * as Yup from 'yup';
import { CardFooter } from '../CardFooter';
import { Button } from '../Button';
import { Form, Formik } from 'formik';
import { FormInput } from '../Form/FormInput';
import { CardContent } from '../CardContent';

export interface AddressValues {
  addressName: string;
  company: string;
  name: string;
  street1: string;
  street2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  email: string;
  phone: string;
}

const validationSchema = Yup.object().shape(
  {
    addressName: Yup.string().required(),
    company: Yup.string().when('name', {
      is: (name: string) => !name || name.length === 0,
      then: Yup.string().required('At least one of the fields is required'),
    }),
    name: Yup.string().when('company', {
      is: (company: string) => !company || company.length === 0,
      then: Yup.string().required('At least one of the fields is required'),
    }),
    street1: Yup.string().required(),
    street2: Yup.string(),
    state: Yup.string().required(),
    zip: Yup.string().required(),
    city: Yup.string().required(),
    country: Yup.string().required(),
    email: Yup.string().required(),
    phone: Yup.string().required(),
  },
  [['company', 'name']]
);

export interface AddressBookAddressFormProps {
  initialValues: AddressValues;
  onSubmit: (values: AddressValues) => void;
  onCancel?: () => void;
  onDelete?: () => void;
  saving?: boolean;
  deleting?: boolean;
  edit?: boolean;
}

export const AddressBookAddressForm: React.FC<AddressBookAddressFormProps> = ({
  deleting,
  edit = false,
  initialValues,
  onCancel,
  onDelete,
  onSubmit,
  saving,
}) => {
  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {() => (
        <Form>
          <Card>
            <CardContent className="grid grid-cols-6 gap-x-2 gap-y-3">
              <FormInput
                name="addressName"
                className="col-span-6"
                id="addressName"
                label="Address name"
                placeholder="E.g. Plano Warehouse"
                hideErrorMessage
                disabled={saving}
              />
              <FormInput
                name="company"
                className="col-span-3"
                id="company"
                label="Company name"
                placeholder="Company, LLC."
                disabled={saving}
              />
              <FormInput
                name="name"
                className="col-span-3"
                id="name"
                label="Contact name"
                placeholder="John Smith"
                disabled={saving}
              />
              <FormInput
                name="street1"
                className="col-span-6"
                id="street1"
                label="Address line 1"
                placeholder="Line 1"
                hideErrorMessage
                disabled={saving}
              />
              <FormInput
                name="street2"
                className="col-span-6"
                id="street2"
                label="Address line 2"
                placeholder="Line 2"
                hideErrorMessage
                disabled={saving}
              />
              <FormInput
                name="city"
                className="col-span-2"
                id="city"
                label="City"
                placeholder="e.g. Plano"
                hideErrorMessage
                disabled={saving}
              />
              <FormInput
                name="state"
                className="col-span-2"
                id="state"
                label="State"
                placeholder="e.g. TX"
                hideErrorMessage
                disabled={saving}
              />
              <FormInput
                name="zip"
                className="col-span-2"
                id="zip"
                label="Postal code"
                placeholder="e.g. 75074"
                hideErrorMessage
                disabled={saving}
              />
              <FormInput
                name="country"
                className="col-span-2"
                id="country"
                label="Country"
                placeholder="e.g. US"
                hideErrorMessage
                disabled={saving}
              />
              <FormInput
                name="email"
                className="col-span-2"
                id="email"
                label="Email"
                type="email"
                hideErrorMessage
                disabled={saving}
              />
              <FormInput
                name="phone"
                className="col-span-2"
                id="phone"
                label="Phone"
                type="tel"
                hideErrorMessage
                disabled={saving}
              />
            </CardContent>
            <CardFooter className="flex justify-end space-x-4">
              {edit ? (
                <Button type="button" kind="destructive" disabled={saving} loading={deleting} onClick={onDelete}>
                  Delete
                </Button>
              ) : (
                <Button type="button" kind="secondary" disabled={saving} onClick={onCancel}>
                  Cancel
                </Button>
              )}
              <Button type="submit" loading={saving}>
                Save
              </Button>
            </CardFooter>
          </Card>
        </Form>
      )}
    </Formik>
  );
};
