import { gql, useMutation } from '@apollo/client';
import {
  UpdateAddressBookAddressMutation,
  UpdateAddressBookAddressMutationVariables,
} from './__generated__/update-address-book-address.gql.generated';
import { ADDRESS_DETAILS_FRAGMENT } from './fragments/address-details.gql';

export const UPDATE_ADDRESS_BOOK_ADDRESS_MUTATION = gql`
  mutation UpdateAddressBookAddress($id: ID!, $input: AddressBookAddressInput!) {
    updateAddressBookAddress(id: $id, input: $input) {
      id
      createdAt
      updatedAt
      addressName
      address {
        ...AddressDetails
      }
    }
  }

  ${ADDRESS_DETAILS_FRAGMENT}
`;

export const useUpdateAddressBookAddressMutation = () =>
  useMutation<UpdateAddressBookAddressMutation, UpdateAddressBookAddressMutationVariables>(
    UPDATE_ADDRESS_BOOK_ADDRESS_MUTATION,
    { refetchQueries: ['AddressBookAddresses'] }
  );
