import React from 'react';
import { OrderShipmentsQuery } from '../../operations/__generated__/order-shipments.gql.generated';
import { CalendarIcon, TruckIcon } from '@heroicons/react/solid';
import { Badge } from '../Badge';

export interface ShipmentListProps {
  shipments: OrderShipmentsQuery['orderShipments'];
}

export const ShipmentList: React.FC<ShipmentListProps> = ({ shipments }) => {
  return (
    <ul className="divide-y divide-gray-200">
      {shipments.map((shipment) => (
        <li key={shipment.id}>
          <div className="px-4 py-4 sm:px-6">
            <div className="flex items-center justify-between">
              <p className="text-sm font-medium truncate">Tracking Number: {shipment.trackingNumber}</p>
              <div className="ml-2 flex-shrink-0 flex">
                {shipment.refundRequested ? (
                  <Badge color="red">Refund Status: {shipment.refundStatus}</Badge>
                ) : (
                  <Badge color="blue">Ship Status: {shipment.status}</Badge>
                )}
              </div>
            </div>
            <div className="mt-2 sm:flex sm:justify-between">
              <div className="sm:flex">
                <p className="flex items-center text-sm text-gray-500">
                  <TruckIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                  {shipment.carrier} {shipment.service}
                </p>
                {/*<p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">*/}
                {/*  <LocationMarkerIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />*/}
                {/*  {shipment.service}*/}
                {/*</p>*/}
              </div>
              <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                <CalendarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                <p>
                  Created at <time dateTime={shipment.createdAt}>{new Date(shipment.createdAt).toLocaleString()}</time>
                </p>
              </div>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
};
