import { gql, useMutation } from '@apollo/client';
import { CreateUserMutation, CreateUserMutationVariables } from './__generated__/create-user.gql.generated';

export const CREATE_USER_MUTATION = gql`
  mutation CreateUser($email: String!, $name: String, $roles: [UserRole!]!) {
    createUser(name: $name, email: $email, roles: $roles) {
      id
      email
      name
      roles
    }
  }
`;

export const useCreateUserMutation = () => {
  return useMutation<CreateUserMutation, CreateUserMutationVariables>(CREATE_USER_MUTATION);
};
