import { gql, useMutation } from '@apollo/client';
import {
  CreateQualityControlRecordMutation,
  CreateQualityControlRecordMutationVariables,
} from './__generated__/create-quality-control-record.gql.generated';

export const CREATE_QUALITY_CONTROL_RECORD_MUTATION = gql`
  mutation CreateQualityControlRecord($integrationId: ID!, $orderId: ID!) {
    createQualityControlRecord(integrationId: $integrationId, orderId: $orderId) {
      id
    }
  }
`;

export const useCreateQualityControlRecordMutation = () =>
  useMutation<CreateQualityControlRecordMutation, CreateQualityControlRecordMutationVariables>(
    CREATE_QUALITY_CONTROL_RECORD_MUTATION
  );
