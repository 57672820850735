import React from 'react';
import { SmartRatePercentile } from '../../types/__generated__/graphql';
import { RuleCard } from './RuleCard';
import * as yup from 'yup';
import { Form, Formik, useFormikContext } from 'formik';

export interface SmartRateConfigurationValues {
  deliveryDays: number;
  percentile: SmartRatePercentile;
  defaultService: string;
}

export interface TriggerValues {
  field: string;
  operator: string;
  value: Date | number | string | string[];
  key: string;
}

export interface ModifierValues {
  modifier: string;
  value: string | string[];
  key: string;
}

export interface RateFilterValues {
  field: string;
  operator: string;
  value: string[];
  key: string;
}

export interface RuleFormValues {
  name: string;
  triggers: TriggerValues[];
  modifiers: ModifierValues[];
  rateFilters: RateFilterValues[];
  smartRateConfiguration: SmartRateConfigurationValues | null;
}

const validationSchema = yup
  .object()
  .shape({
    name: yup.string().required('The rule name is required.'),
    triggers: yup.array().of(
      yup.object({
        field: yup.string().required('Required'),
        operator: yup.string().required('Required'),
        value: yup.lazy((value) => {
          return Array.isArray(value)
            ? yup.array().of(yup.string().required()).min(1).required()
            : typeof value === 'string'
            ? yup.string().required()
            : value instanceof Date
            ? yup.date().required()
            : yup.number().required();
        }),
      })
    ),
    modifiers: yup.array().of(
      yup.object({
        modifier: yup.string().required('Required'),
        value: yup.lazy((value) => {
          if (Array.isArray(value)) {
            return yup.array().of(yup.string().required('Required')).required('Required');
          } else {
            return yup.string().required('Required');
          }
        }),
      })
    ),
    rateFilters: yup.array().of(
      yup.object({
        field: yup.string().required('Required'),
        operator: yup.string().required('Required'),
        value: yup.lazy((value) => {
          if (Array.isArray(value)) {
            return yup.array().of(yup.string().required('Required')).required('Required');
          } else {
            return yup.string().required('Required');
          }
        }),
      })
    ),
    smartRateConfiguration: yup
      .object({
        deliveryDays: yup.number().required('Please provide a delivery days requirement'),
        percentile: yup.string().oneOf(Object.values(SmartRatePercentile)),
      })
      .nullable(),
  })
  .required();

export interface RuleFormProps {
  initialValues: RuleFormValues;
  edit?: boolean;
  onSubmit: (values: RuleFormValues) => void;
  onDelete?: () => void;
  saving?: boolean;
  deleting?: boolean;
}

export const RuleForm: React.FC<RuleFormProps> = ({
  edit = false,
  initialValues,
  saving,
  deleting,
  onSubmit,
  onDelete,
}) => {
  const handleSubmit = async (values: RuleFormValues) => {
    onSubmit(values);
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {() => {
        return (
          <Form>
            <RuleCard showDeleteButton={edit} saving={saving} deleting={deleting} onDelete={onDelete} />
          </Form>
        );
      }}
    </Formik>
  );
};

export const useRulesForm = () => useFormikContext<RuleFormValues>();
