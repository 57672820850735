import { gql } from '@apollo/client/core';
import { useMutation } from '@apollo/client';
import {
  CreateManifestV2Mutation,
  CreateManifestV2MutationVariables,
} from './__generated__/create-manifest-v2.gql.generated';

export const CREATE_MANIFEST_V2_MUTATION = gql`
  mutation CreateManifestV2($carrier: EasyPostCarrier!) {
    createManifestV2(carrier: $carrier) {
      id
      createdAt
      status
      carrier
      formUrl
    }
  }
`;

export const useCreateManifestV2Mutation = () => {
  return useMutation<CreateManifestV2Mutation, CreateManifestV2MutationVariables>(CREATE_MANIFEST_V2_MUTATION);
};
