import { FC } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { Order } from './Order';
import { OrderProvider } from '../providers/Order/OrderProvider';
import { FulFillEmptyState } from '../components/Fulfill/FulFillEmptyState';

export const Fulfill: FC = () => {
  const { path } = useRouteMatch();

  return (
    <>
      {/* Main content */}
      <div className="w-full flex-1 flex flex-col h-full min-h-0 overflow-auto px-6 pb-6">
        <Switch>
          <Route path={path} exact>
            <FulFillEmptyState />
          </Route>
          <Route
            path={`${path}/:orderId`}
            render={(props) => (
              <OrderProvider orderId={props.match.params.orderId!}>
                <Order />
              </OrderProvider>
            )}
          />
        </Switch>
      </div>
    </>
  );
};

//
