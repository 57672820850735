import React, { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import { Card } from '../Card';
import { CardContent } from '../CardContent';
import { CardHeading } from '../CardHeading';
import { InputGroup } from '../InputGroup';
import { QualityControlRow } from './QualityControlRow';
import classNames from 'classnames';
import styles from './QualityControlForm.module.scss';
import { useErrorModal } from '../../utilities/use-error-modal';
import { ErrorModal } from '../ErrorModal';
import { Button } from '../Button';
import * as yup from 'yup';

const validationSchema = yup.object().shape({
  parcels: yup.array().of(
    yup.object().shape({
      items: yup.array().of(
        yup.object().shape({
          expectedQuantity: yup.number().required(),
          scannedQuantity: yup.number().when('expectedQuantity', (val: number) => {
            return yup.number().required().oneOf([val]);
          }),
        })
      ),
    })
  ),
});

export interface QualityControlFormValues {
  parcels: {
    key: string;
    items: {
      key: string;
      sku?: string | null;
      name?: string | null;
      upc?: string | null;
      description?: string | null;
      expectedQuantity: number;
      scannedQuantity: number;
    }[];
  }[];
}

export interface QualityControlFormProps {
  initialValues: QualityControlFormValues;
  onSubmit: (values: QualityControlFormValues, helpers: FormikHelpers<QualityControlFormValues>) => void;
}

export const QualityControlForm: React.FC<QualityControlFormProps> = ({ initialValues, onSubmit }) => {
  const [upc, setUpc] = useState('');
  const [validationError, setValidationError] = useState<string | null>(null);
  const upcInputRef = useRef<HTMLInputElement | null>(null);

  const { showErrorModal, handleErrorModalClose: _handleErrorModalClose } = useErrorModal(validationError ?? undefined);

  const handleErrorModalClose = () => {
    setTimeout(() => {
      upcInputRef.current?.focus();
    }, 300);
    setValidationError(null);
    _handleErrorModalClose();
  };

  const handleUpcChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUpc(e.target.value);
  };

  useEffect(() => {
    upcInputRef.current?.focus();
  }, []);

  return (
    <>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} validateOnMount>
        {({ values, setFieldValue, isValid, isSubmitting }) => {
          const handleUpcSubmit = (e: FormEvent) => {
            e.preventDefault();

            setValidationError(null);

            let convertedUpc = upc;

            if (upc.startsWith('^') || upc.endsWith('^')) {
              const matches = /\^#\^([a-zA-Z0-9-]*)\^/.exec(upc);
              if (!matches?.length) {
                setValidationError('Invalid scan. Please try scanning again.');
                setUpc('');
                return;
              }

              convertedUpc = Number.parseInt(matches[1], 16).toString();
            }

            if (!upc) {
              setValidationError('Invalid scan. Please try scanning again.');
              setUpc('');
              return;
            }

            setUpc('');

            let foundOne = false;

            for (let i = 0; i < values.parcels.length; i++) {
              const parcel = values.parcels[i];
              const itemIndex = parcel.items.findIndex(
                (item) =>
                  item.upc?.toLowerCase().replace(/^0+/, '') === convertedUpc.toLowerCase().replace(/^0+/, '') ||
                  item.sku?.toLowerCase() === convertedUpc.toLowerCase()
              );

              if (itemIndex >= 0) {
                setFieldValue(
                  `parcels[${i}].items[${itemIndex}].scannedQuantity`,
                  values.parcels[i].items[itemIndex].scannedQuantity + 1
                );
                foundOne = true;
                break;
              }
            }

            if (!foundOne) {
              setValidationError(`Item "${convertedUpc}" not found! Please check that this is the right item.`);
            }
          };

          const decreaseItem = (parcelIndex: number, itemIndex: number) => {
            setFieldValue(
              `parcels[${parcelIndex}].items[${itemIndex}].scannedQuantity`,
              Math.max(0, values.parcels[parcelIndex].items[itemIndex].scannedQuantity - 1)
            );
          };

          return (
            <>
              <form onSubmit={(e) => handleUpcSubmit(e)} className="max-w-2xl mx-auto w-full">
                <InputGroup
                  ref={upcInputRef}
                  className="my-4 flex-shrink-0 px-2"
                  name="upc"
                  placeholder="Scan the UPC/SKU"
                  value={upc}
                  onChange={handleUpcChange}
                />
              </form>

              <Form className="flex-1 flex flex-col overflow-hidden">
                <div className="overflow-y-auto flex-1 max-w-2xl mx-auto w-full">
                  <div className="flex flex-col space-y-4 px-2 pb-4 overflow-y-auto">
                    {values.parcels.map((parcel, index) => (
                      <Card key={parcel.key}>
                        <CardHeading>Package #{index + 1}</CardHeading>
                        <CardContent className={classNames('grid grid-cols-4 gap-x-4', styles.itemGrid)}>
                          <span className="font-medium text-sm">Item</span>
                          <span className="font-medium text-sm">Expected</span>
                          <span className="font-medium text-sm">Actual</span>
                          {parcel.items.map((item, itemIndex) => (
                            <QualityControlRow
                              key={item.key}
                              item={item}
                              onDecrease={() => decreaseItem(index, itemIndex)}
                            />
                          ))}
                        </CardContent>
                      </Card>
                    ))}
                  </div>
                </div>

                <div className="flex-shrink-0 p-4 bg-white">
                  <div className="flex justify-end max-w-2xl mx-auto w-full">
                    <Button type="submit" disabled={!isValid} loading={isSubmitting}>
                      Submit
                    </Button>
                  </div>
                </div>
              </Form>

              <ErrorModal isOpen={showErrorModal} onClose={handleErrorModalClose} message={validationError} />
            </>
          );
        }}
      </Formik>
    </>
  );
};
