import React, { ChangeEvent, FormEvent, useEffect, useMemo, useRef, useState } from 'react';
import { Link, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { Card } from '../components/Card';
import { CardContent } from '../components/CardContent';
import { useAlIntegrationsQuery } from '../operations/all-integrations.gql';
import { CardFooter } from '../components/CardFooter';
import { Button } from '../components/Button';
import { SearchIcon } from '@heroicons/react/outline';
import { InputGroup } from '../components/InputGroup';
import { Select } from '../components/Select';
import { useOrderV2LazyQuery } from '../operations/order-v2.gql';
import { InlineAlert } from '../components/InlineAlert';
import { Toggle } from '../components/Toggle';

export const LAST_USED_INTEGRATION_KEY = 'last_integration_used_id';

export const Ship: React.FC = () => {
  const { url } = useRouteMatch();
  const { search } = useLocation();
  const [integrationId, setIntegrationId] = useState(localStorage.getItem(LAST_USED_INTEGRATION_KEY) ?? '');
  const [orderId, setOrderId] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [validationError, setValidationError] = useState<string | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const history = useHistory();
  const [autoPurchaseEnabled, setAutoPurchasedEnabled] = useState(
    JSON.parse(localStorage.getItem('AUTO_PURCHASE_ENABLED') ?? 'false')
  );

  const { data: integrationsData, loading: integrationsLoading } = useAlIntegrationsQuery();
  const [getOrder, { data: orderData, loading: orderLoading, error: orderError, variables }] = useOrderV2LazyQuery();

  const integrationOptions = useMemo(
    () =>
      integrationsData?.integrations.map((integration) => ({
        label: integration.description,
        value: integration.id,
      })) ?? [],
    [integrationsData?.integrations]
  );

  useEffect(() => {
    if (integrationOptions.length && !integrationId) {
      setIntegrationId(integrationOptions[0].value);
      localStorage.setItem(LAST_USED_INTEGRATION_KEY, integrationOptions[0].value);
    }
  }, [integrationOptions, integrationId]);

  useEffect(() => {
    if (!orderLoading && orderData?.orderV2 === null) {
      setError(`Order ${variables?.orderId} not found.`);
    } else if (!orderLoading && orderData?.orderV2) {
      history.push(`/order/${variables?.integrationId}/${variables?.orderId}`);
    }
  }, [history, orderData, orderLoading, url, variables?.integrationId, variables?.orderId]);

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    setValidationError(null);

    let convertedOrderId = orderId;

    if (orderId.startsWith('^') || orderId.endsWith('^')) {
      const matches = /\^#\^([a-zA-Z0-9-]*)\^/.exec(orderId);
      if (!matches?.length) {
        setValidationError('Invalid scan. Please try scanning again.');
        return;
      }

      convertedOrderId = Number.parseInt(matches[1], 16).toString();
    }

    setError(null);
    getOrder({ variables: { integrationId, orderId: convertedOrderId } });
  };

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  useEffect(() => {
    if (search) {
      const params = new URLSearchParams(search);
      const integrationIdParam = params.get('integrationId');
      const orderIdParam = params.get('orderId');
      if (integrationIdParam && orderIdParam) {
        setIntegrationId(integrationIdParam);
        localStorage.setItem(LAST_USED_INTEGRATION_KEY, integrationIdParam);
        setOrderId(orderIdParam);
        setError(null);
        getOrder({ variables: { integrationId: integrationIdParam, orderId: orderIdParam } });
      }
      history.replace({ search: '' });
    }
  }, [getOrder, history, search]);

  useEffect(() => {
    if (orderError) {
      setError(orderError.message);
    }
  }, [orderError]);

  useEffect(() => {
    localStorage.setItem('AUTO_PURCHASE_ENABLED', JSON.stringify(autoPurchaseEnabled));
  }, [autoPurchaseEnabled]);

  const handleIntegrationSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    setIntegrationId(e.target.value);
    localStorage.setItem(LAST_USED_INTEGRATION_KEY, e.target.value);
  };

  return (
    <div className="w-full flex-1 flex flex-col min-h-0 overflow-hidden items-center justify-center">
      <form onSubmit={onSubmit} className="contents">
        <Card className="w-full max-w-xl">
          <CardContent>
            <div className="grid grid-cols-2 gap-4">
              <Select
                className="col-span-1"
                label="Integration"
                disabled={integrationsLoading || orderLoading}
                value={integrationId}
                onChange={handleIntegrationSelect}
                required
              >
                {integrationsLoading ? (
                  <option value="">Loading...</option>
                ) : (
                  <option value="" disabled>
                    Select an integration...
                  </option>
                )}
                {integrationOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Select>
              <InputGroup
                ref={inputRef}
                className="col-span-1"
                label="Order ID"
                placeholder="Order ID"
                value={orderId}
                onChange={(e) => setOrderId(e.target.value)}
                required
                disabled={orderLoading}
              />
              <div className="justify-self-end col-span-2">
                <div className="flex items-center space-x-2">
                  <span>Auto Purchase</span>
                  <Toggle enabled={autoPurchaseEnabled} onChange={setAutoPurchasedEnabled} />
                </div>
              </div>
            </div>
            {error ? <InlineAlert className="mt-4" title={error} /> : null}
          </CardContent>
          <CardFooter className="flex justify-end space-x-4">
            <Link to="ship/custom">
              <Button type="button" kind="secondary" disabled={orderLoading}>
                Custom Shipment
              </Button>
            </Link>
            <Button type="submit" icon={SearchIcon} loading={orderLoading}>
              Search
            </Button>
          </CardFooter>
        </Card>
        {validationError ? <InlineAlert className="mt-4" type="error" title={validationError} /> : null}
      </form>
    </div>
  );
};
