import React from 'react';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import { FormInput } from '../Form/FormInput';
import { Button } from '../Button';
import { InlineAlert } from '../InlineAlert';

const schema = yup.object({
  description: yup.string().required('Description is a required field.'),
  apiKey: yup.string().required('API Key is a required field.'),
  apiUrl: yup.string().required('API URL is a required field.'),
});

export interface LocateInvIntegrationFormValues {
  description: string;
  apiKey: string;
  apiUrl: string;
}

export interface LocateInvIntegrationFormProps {
  initialValues: LocateInvIntegrationFormValues;
  onSubmit: (values: LocateInvIntegrationFormValues) => void;
  submitText: string;
  loading?: boolean;
  error?: string | null;
}

export const LocateInvIntegrationForm: React.FC<LocateInvIntegrationFormProps> = ({
  initialValues,
  submitText,
  onSubmit,
  loading,
  error,
}) => {
  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={schema}>
      {({ isSubmitting, errors }) => (
        <Form className="space-y-4">
          {error ? <InlineAlert type="error" title="Uh oh!" errors={[error]} /> : null}
          <FormInput name="description" type="text" label="Description" />
          <FormInput name="apiKey" type="text" label="API Key" />
          <FormInput name="apiUrl" type="text" label="API URL" />

          <div className="flex justify-end">
            <Button loading={loading} type="submit">
              {submitText}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
