import React, { useState } from 'react';
import { useTimeoutFn } from 'react-use';
import { LoadingPanel } from '../LoadingPanel';

export const OrderLoadingState: React.FC = () => {
  const [loadingText, setLoadingText] = useState('Loading order...');
  useTimeoutFn(() => setLoadingText('Working on it...'), 5000);

  return <LoadingPanel className="flex-1" text={loadingText} />;
};
