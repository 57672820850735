import React from 'react';
import { SectionHeadingWithAction } from '../components/Layout/SectionHeadingWithAction';
import { Button } from '../components/Button';
import { PlusIcon } from '@heroicons/react/outline';
import { Link, useRouteMatch } from 'react-router-dom';
import { useAddressBookAddressesQuery } from '../operations/address-book-addresses.gql';
import { LoadingPanel } from '../components/LoadingPanel';
import { EmptyState } from '../components/EmptyState';
import { AddressBookTable } from '../components/AddressBook/AddressBookTable';

export const AddressBookList: React.FC = () => {
  const { url } = useRouteMatch();

  const { data, loading } = useAddressBookAddressesQuery({ limit: 50 });

  const addresses = data?.addressBookAddresses.items ?? [];

  return (
    <>
      <SectionHeadingWithAction
        title="Address Book"
        actions={
          <Link to={`${url}/new`}>
            <Button icon={PlusIcon}>Address</Button>
          </Link>
        }
      />
      {loading ? (
        <LoadingPanel />
      ) : addresses.length ? (
        <AddressBookTable addresses={addresses} />
      ) : (
        <EmptyState
          title="No addresses yet."
          subtext="Get started by adding an address!"
          action={
            <Link to={`${url}/new`}>
              <Button>Add an address</Button>
            </Link>
          }
        />
      )}
    </>
  );
};
