import React, { FC } from 'react';
import classNames from 'classnames';

export interface SelectProps
  extends React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement> {
  error?: string | null;
  label?: string;
  options?: { label: string; value: any; disabled?: boolean }[];
  hideErrorMessage?: boolean;
}

export const Select: FC<SelectProps> = ({
  children,
  className,
  error,
  label,
  hideErrorMessage,
  id,
  options,
  ...selectProps
}) => {
  const outlineColor = error ? 'red' : 'blue';
  return (
    <div className={className}>
      {label ? (
        <label htmlFor={id} className="block text-sm font-medium text-gray-700 mb-1">
          {label}
        </label>
      ) : null}
      <select
        id={id}
        className={classNames(
          `block w-full py-2 pl-3 pr-9 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-${outlineColor}-500 focus:border-${outlineColor}-500 sm:text-sm`,
          { 'border-red-500': error }
        )}
        {...selectProps}
      >
        {options
          ? options.map((option) => (
              <option key={option.value ?? 'empty'} value={option.value} disabled={option.disabled}>
                {option.label}
              </option>
            ))
          : children}
      </select>
      {error && !hideErrorMessage ? (
        <p className="mt-2 text-sm text-red-600" id="email-error">
          {error}
        </p>
      ) : null}
    </div>
  );
};
