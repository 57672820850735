import React from 'react';
import { useHistory } from 'react-router-dom';
import { LocateInvIntegrationForm, LocateInvIntegrationFormValues } from './LocateInvIntegrationForm';
import { useCreateLocateInvIntegrationMutation } from '../../operations/create-locate-inv-integration.gql';

export const NewLocateInvIntegrationFormController: React.FC = () => {
  const history = useHistory();
  const [create, { loading, error }] = useCreateLocateInvIntegrationMutation();

  const handleSubmit = async (values: LocateInvIntegrationFormValues) => {
    try {
      await create({
        variables: values,
      });
      history.push('/settings/integrations');
    } catch (err) {
      // TODO: Report to sentry
    }
  };

  return (
    <LocateInvIntegrationForm
      initialValues={{ apiKey: '', apiUrl: '', description: '' }}
      onSubmit={handleSubmit}
      loading={loading}
      submitText="Submit"
      error={error?.message ?? null}
    />
  );
};
