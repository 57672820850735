import React from 'react';
import { Card } from '../components/Card';
import { CardHeading } from '../components/CardHeading';
import { usePrintNode } from '../providers/PrintNodeProvider';
import { Select } from '../components/Select';
import { Scale } from '../utilities/print-node';
import { CardContent } from '../components/CardContent';
import { SectionHeadingWithAction } from '../components/Layout/SectionHeadingWithAction';

export const PrintersAndScales: React.FC = () => {
  const {
    computers,
    printers,
    scales,
    selectedComputer,
    selectedPrinter,
    selectedScale,
    setSelectedComputer,
    setSelectedPrinter,
    setSelectedScale,
  } = usePrintNode();

  const computerOptions = [
    { label: 'Select a computer', value: undefined },
    ...computers.map((computer) => ({ label: computer.name, value: computer.id })),
  ];

  const printerOptions = [
    { label: 'Select a printer', value: undefined },
    ...printers.map((printer) => ({ label: `${printer.name} (${printer.state})`, value: printer.id })),
  ];

  const scaleOptions = [
    { label: 'Select a scale', value: undefined },
    ...scales.map((scale) => ({ label: `${scale.deviceName} (${scale.vendor})`, value: scale.deviceNum })),
  ];

  console.log({ selectedPrinter, selectedScale, selectedComputer });

  const handleComputerSelect = (computerId: number) => {
    if (Number.isNaN(computerId)) {
      localStorage.removeItem('default-computer');
      localStorage.removeItem('default-printer');
      localStorage.removeItem('default-scale');
      console.log('clearing');
      setSelectedComputer(null);
      setSelectedScale(null);
      setSelectedPrinter(null);
    } else if (computerId !== selectedComputer) {
      localStorage.setItem('default-computer', computerId.toString());
      localStorage.removeItem('default-printer');
      localStorage.removeItem('default-scale');
      setSelectedComputer(computerId);
      setSelectedScale(null);
      setSelectedPrinter(null);
    }
  };

  const handlePrinterSelect = (printerId: number) => {
    if (Number.isNaN(printerId)) {
      setSelectedPrinter(null);
      localStorage.removeItem('default-printer');
    } else {
      setSelectedPrinter(printerId);
      localStorage.setItem('default-printer', printerId.toString());
    }
  };

  const handleScaleSelect = (scale: Scale | null) => {
    if (scale === null) {
      setSelectedScale(null);
      localStorage.removeItem('default-scale');
    } else {
      setSelectedScale(scale);
      localStorage.setItem('default-scale', JSON.stringify(scale));
    }
  };

  const scaleValue = selectedScale?.deviceNum ?? null;

  return (
    <div className="flex-1 flex flex flex-col h-full bg-gray-100">
      <main className="flex-1 flex flex-col overflow-y-auto focus:outline-none">
        <div className="relative max-w-4xl mx-auto px-6 2xl:px-0 w-full flex flex-col flex-1">
          <div className="pt-10 pb-6 flex flex-col flex-1">
            <SectionHeadingWithAction title="Printers & Scales" />
            <Card>
              <CardHeading>Printers &amp; Scales</CardHeading>
              <CardContent className="space-y-4">
                <Select
                  label="Computer"
                  options={computerOptions}
                  value={selectedComputer?.toString()}
                  placeholder="Select a computer"
                  onChange={(evt) => {
                    handleComputerSelect(+evt.target.value ?? null);
                  }}
                />
                <Select
                  label="Printer"
                  options={printerOptions}
                  value={selectedPrinter?.toString()}
                  placeholder="Select a printer"
                  onChange={(evt) => {
                    handlePrinterSelect(+evt.target.value ?? null);
                  }}
                />
                <Select
                  label="Scale"
                  options={scaleOptions}
                  value={scaleValue?.toString()}
                  placeholder="Select a scale"
                  onChange={(evt) => {
                    handleScaleSelect(scales.find((scale) => scale.deviceNum === +evt.target.value) ?? null);
                  }}
                />
              </CardContent>
            </Card>
          </div>
        </div>
      </main>
    </div>
  );
};
