import React from 'react';
import ReactDatePicker from 'react-datepicker';
import { useField } from 'formik';
import 'react-datepicker/dist/react-datepicker.css';
import { InputGroup } from '../../InputGroup';

export interface TimeFieldProps {
  label?: string;
  id?: string;
  name: string;
  disabled?: boolean;
  hideErrorMessage?: boolean;
}

export const TimeField: React.FC<TimeFieldProps> = ({ disabled, label, id, name, hideErrorMessage }) => {
  const [field, meta, helpers] = useField(name);

  const isDate = field.value instanceof Date;

  const handleChange = (date: Date) => {
    helpers.setValue(date, true);
  };

  const selected = isDate
    ? field.value
    : Array.isArray(field.value) && field.value[0]
    ? new Date(field.value[0])
    : typeof field.value === 'string'
    ? new Date(field.value)
    : null;

  return (
    <div>
      <ReactDatePicker
        name={name}
        autoComplete="off"
        selected={selected}
        onChange={handleChange}
        onBlur={field.onBlur}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        timeCaption="Time"
        dateFormat="h:mm aa"
        customInput={
          <InputGroup
            label={label}
            error={meta.touched ? meta.error : null}
            type="text"
            aria-autocomplete="none"
            hideErrorMessage={hideErrorMessage}
          />
        }
        id={id}
        disabled={disabled}
      />
    </div>
  );
};
