import React from 'react';
import { CreateManifestForm } from '../components/Manifest/CreateManifestForm';
import { LoadingPanel } from '../components/LoadingPanel';
import { useFacilities } from '../providers/FacilityProvider';
import { Breadcrumb } from '../components/Breadcrumbs';
import { PageHeading } from '../components/PageHeading';

const breadcrumbs: Breadcrumb[] = [
  { label: 'Manifests', path: '/manifest' },
  { label: 'Create Manifest', path: '/manifest/create' },
];

export const CreateManifest: React.FC = () => {
  const { facilitiesLoading, facilities } = useFacilities();

  return (
    <div className="max-w-7xl mx-auto p-6 lg:p-8 flex-1 w-full bg-gray-100">
      <PageHeading title="Create Manifest" breadcrumbs={breadcrumbs} />
      {facilitiesLoading ? <LoadingPanel /> : facilities ? <CreateManifestForm facilities={facilities} /> : null}
    </div>
  );
};
