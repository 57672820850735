/* This example requires Tailwind CSS v2.0+ */
import {
  AdjustmentsIcon,
  BookOpenIcon,
  CogIcon,
  CurrencyDollarIcon,
  PrinterIcon,
  PuzzleIcon,
  TruckIcon,
  UsersIcon,
} from '@heroicons/react/outline';
import React, { useMemo } from 'react';
import classNames from 'classnames';
import { Link, Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { PrintersAndScales } from './PrintersAndScales';
import { CarrierAccounts } from './CarrierAccounts';
import { ShippingRules } from './ShippingRules';
import { UserManagement } from './UserManagement';
import { useFeatureFlag } from '../providers/FeatureFlagProvider';
import { Integrations } from './Integrations';
import { AddressBook } from './AddressBook';
import { GeneralSettings } from './GeneralSettings';
import { Disclosure } from '@headlessui/react';
import { RateAdjustment } from './RateAdjustment';
import { useUser } from '../providers/UserContext';

export interface NestedNavigationItem {
  name: string;
  icon: React.ComponentType<any>;
  current?: boolean;
  href: string;
  children?: Omit<NestedNavigationItem, 'icon'>[];
  hidden?: boolean;
}

export const Settings: React.FC = () => {
  const { path } = useRouteMatch();
  const { pathname } = useLocation();
  const { groups } = useUser();

  const billingFlag = useFeatureFlag('Billing');

  const isAdmin = groups.includes('admin');

  const navigation = useMemo<NestedNavigationItem[]>(
    () => [
      {
        name: 'Printers & Scales',
        icon: PrinterIcon,
        current: pathname.includes('printers-and-scales'),
        href: `${path}/printers-and-scales`,
      },
      {
        name: 'General Settings',
        icon: CogIcon,
        current: pathname.includes('general-settings'),
        href: `${path}/general-settings`,
        hidden: !isAdmin,
      },
      {
        name: 'User Management',
        icon: UsersIcon,
        current: pathname.includes('user-management'),
        href: `${path}/user-management`,
        hidden: !isAdmin,
      },
      {
        name: 'AddressBook',
        icon: BookOpenIcon,
        current: pathname.includes('address-book'),
        href: `${path}/address-book`,
        hidden: !isAdmin,
      },
      {
        name: 'Integrations',
        icon: PuzzleIcon,
        current: pathname.includes('integrations'),
        href: `${path}/integrations`,
        hidden: !isAdmin,
      },
      {
        name: 'Carrier Accounts',
        icon: TruckIcon,
        current: pathname.includes('carriers-accounts'),
        href: `${path}/carriers-accounts`,
        hidden: !isAdmin,
      },
      {
        name: 'Shipping Rules',
        icon: AdjustmentsIcon,
        current: pathname.includes('shipping-rules'),
        href: `${path}/shipping-rules`,
        hidden: !isAdmin,
      },
      {
        name: 'Billing',
        icon: CurrencyDollarIcon,
        current: pathname.includes('billing'),
        href: `#`,
        hidden: !billingFlag || !isAdmin,
        children: [
          {
            name: 'Rate Adjustment',
            current: pathname.includes('rate-markup'),
            href: `${path}/billing/rate-adjustment`,
          },
        ],
      },
    ],
    [billingFlag, isAdmin, path, pathname]
  );

  return (
    <div className="lg:grid lg:grid-cols-12 overflow-auto flex-1 bg-white">
      <aside className="py-6 px-2 sm:px-6 lg:col-span-3 lg:border-r">
        <nav className="flex-1 space-y-1 bg-white" aria-label="Sidebar">
          {navigation.map((item) =>
            item.hidden ? null : !item.children ? (
              <div key={item.name}>
                <Link
                  to={item.href}
                  className={classNames(
                    item.current
                      ? 'bg-gray-100 text-gray-900'
                      : 'bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                    'group w-full flex items-center pl-2 py-2 text-sm font-medium rounded-md'
                  )}
                >
                  <item.icon
                    className={classNames(
                      item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                      'mr-3 flex-shrink-0 h-6 w-6'
                    )}
                    aria-hidden="true"
                  />
                  {item.name}
                </Link>
              </div>
            ) : (
              <Disclosure as="div" key={item.name} className="space-y-1">
                {({ open }) => (
                  <>
                    <Disclosure.Button
                      className={classNames(
                        item.current
                          ? 'bg-gray-100 text-gray-900'
                          : 'bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                        'group w-full flex items-center pl-2 pr-1 py-2 text-left text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500'
                      )}
                    >
                      <item.icon
                        className="mr-3 flex-shrink-0 h-6 w-6 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                      <span className="flex-1">{item.name}</span>
                      <svg
                        className={classNames(
                          open ? 'text-gray-400 rotate-90' : 'text-gray-300',
                          'ml-3 flex-shrink-0 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150'
                        )}
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                      >
                        <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
                      </svg>
                    </Disclosure.Button>
                    <Disclosure.Panel className="space-y-1">
                      {item.children
                        ? item.children.map((subItem) => (
                            <Link
                              key={subItem.name}
                              to={subItem.href}
                              className="group w-full flex items-center pl-11 pr-2 py-2 text-sm font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50"
                            >
                              {subItem.name}
                            </Link>
                          ))
                        : null}
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            )
          )}
        </nav>
      </aside>

      <div className="lg:col-span-9 bg-white overflow-hidden">
        <Switch>
          {isAdmin ? (
            <Route path={`${path}/general-settings`}>
              <GeneralSettings />
            </Route>
          ) : null}
          <Route path={`${path}/printers-and-scales`}>
            <PrintersAndScales />
          </Route>
          {isAdmin ? (
            <Route path={`${path}/carriers-accounts`}>
              <CarrierAccounts />
            </Route>
          ) : null}
          {isAdmin ? (
            <Route path={`${path}/address-book`}>
              <AddressBook />
            </Route>
          ) : null}
          {isAdmin ? (
            <Route path={`${path}/integrations`}>
              <Integrations />
            </Route>
          ) : null}
          {isAdmin ? (
            <Route path={`${path}/shipping-rules`}>
              <ShippingRules />
            </Route>
          ) : null}
          {isAdmin ? (
            <Route path={`${path}/user-management`}>
              <UserManagement />
            </Route>
          ) : null}
          {billingFlag && isAdmin ? (
            <Route path={`${path}/billing/rate-adjustment`}>
              <RateAdjustment />
            </Route>
          ) : null}
          <Route path={path} exact>
            <Redirect to={`${path}/printers-and-scales`} />
          </Route>
        </Switch>
      </div>
    </div>
  );
};
