import { ConfirmationModalProps } from '../components/ConfirmationModal';
import { useState } from 'react';

export interface UseConfirmationModalParams extends Omit<ConfirmationModalProps, 'isOpen' | 'onCancel'> {
  onCancel?: () => void;
}

export const useConfirmationModal = (
  params: UseConfirmationModalParams
): { openConfirmationModal: () => void; props: ConfirmationModalProps } => {
  const { onCancel, onAccept, ...modalProps } = params;
  const [isOpen, setIsOpen] = useState(false);

  const openConfirmationModal = () => setIsOpen(true);

  const handleCancel = () => {
    setIsOpen(false);
    onCancel?.();
  };

  const handleAccept = () => {
    setIsOpen(false);
    onAccept();
  };

  return {
    openConfirmationModal,
    props: {
      isOpen,
      onCancel: handleCancel,
      onAccept: handleAccept,
      ...modalProps,
    },
  };
};
