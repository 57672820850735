import React from 'react';
import { CarrierAccountType } from '../../types/__generated__/graphql';
import * as yup from 'yup';
import { isNil, mapValues } from 'lodash';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import { Card } from '../Card';
import { CardContent } from '../CardContent';
import { CardFooter } from '../CardFooter';
import { Button } from '../Button';
import { SaveIcon } from '@heroicons/react/outline';
import { FormInput } from '../Form/FormInput';
import { CardHeading } from '../CardHeading';
import { CarrierAdjustmentSection } from './atoms/CarrierAdjustmentSection';
import { useSetRateAdjustmentMutation } from '../../operations/set-rate-adjustment.gql';
import { useDeleteRateAdjustmentMutation } from '../../operations/delete-rate-adjustment.gql';

export interface RateAdjustmentFormValues {
  integrationId: string;
  orderChannelId: string;
  adjustment: string;
  carrierAdjustments?: {
    [key: string]: {
      adjustment: string;
      serviceAdjustments?: { [key: string]: string };
    };
  };
}

const validationSchema = yup.object().shape({
  adjustment: yup.number().required(),
  carrierAdjustments: yup.lazy((obj) =>
    yup.object(
      mapValues(obj, () =>
        yup.object().shape({
          adjustment: yup.number().nullable(),
          serviceAdjustments: yup.lazy((obj) => yup.object(mapValues(obj, () => yup.number().nullable())).nullable()),
        })
      )
    )
  ),
});

export interface CarrierAccountSegment {
  id: string;
  carrier: CarrierAccountType;
  name: string;
  description: string;
}

export interface RateAdjustmentFormProps {
  title: string;
  initialValues: RateAdjustmentFormValues;
  carrierAccounts: CarrierAccountSegment[];
}

export const RateAdjustmentForm: React.FC<RateAdjustmentFormProps> = ({ carrierAccounts, initialValues, title }) => {
  const [setRateAdjustment] = useSetRateAdjustmentMutation();
  const [deleteRateAdjustment, { loading: deleting }] = useDeleteRateAdjustmentMutation();

  const handleSubmit = async (values: RateAdjustmentFormValues, helpers: FormikHelpers<RateAdjustmentFormValues>) => {
    console.log('submitting', values);
    try {
      helpers.setSubmitting(true);
      await setRateAdjustment({
        variables: {
          input: {
            integrationId: values.integrationId,
            orderChannelId: values.orderChannelId,
            adjustment: {
              adjustment: +values.adjustment,
              carrierAdjustments: values.carrierAdjustments
                ? Object.keys(values.carrierAdjustments)
                    .filter(
                      (accountId) =>
                        (values.carrierAdjustments![accountId]!.adjustment !== '' &&
                          !isNil(values.carrierAdjustments![accountId]!.adjustment)) ||
                        values.carrierAdjustments![accountId]!.serviceAdjustments
                    )
                    .map((accountId) => ({
                      accountId,
                      adjustment: +values.carrierAdjustments![accountId]!.adjustment,
                      serviceAdjustments: values.carrierAdjustments![accountId]!.serviceAdjustments
                        ? Object.keys(values.carrierAdjustments![accountId]!.serviceAdjustments!)
                            .filter(
                              (service) =>
                                values.carrierAdjustments![accountId]!.serviceAdjustments![service] !== '' &&
                                !isNil(values.carrierAdjustments![accountId]!.serviceAdjustments![service])
                            )
                            .map((service) => ({
                              service,
                              adjustment: +values.carrierAdjustments![accountId]!.serviceAdjustments![service],
                            }))
                        : null,
                    }))
                : null,
            },
          },
        },
      });
    } catch (err) {
      // TODO: capture with sentry
      helpers.setSubmitting(false);
    }
  };

  const handleDelete = async () => {
    try {
      await deleteRateAdjustment({
        variables: { integrationId: initialValues.integrationId, orderChannelId: initialValues.orderChannelId },
        refetchQueries: ['RateAdjustment'],
      });
    } catch (err) {
      // TODO: capture in sentry
    }
  };

  const initialCarrierAccountsValues = carrierAccounts.reduce((map, account) => {
    return { ...map, [account.id]: { adjustment: '' } };
  }, {});

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {({ values, errors, setFieldValue, isSubmitting }) => {
        console.log(errors);

        const handleEnableCarrierAdjustments = () => {
          setFieldValue('carrierAdjustments', initialCarrierAccountsValues);
        };

        const handleDisableCarrierAdjustments = () => {
          setFieldValue('carrierAdjustments', undefined);
        };

        return (
          <Form>
            <fieldset disabled={isSubmitting || deleting}>
              <Card>
                <CardHeading>{title}</CardHeading>
                <CardContent>
                  <FormInput
                    className="max-w-xs"
                    name="adjustment"
                    label="Default Adjustment %"
                    type="number"
                    id="default-adjustment"
                    trailingAddon="%"
                  />
                </CardContent>
                <CardContent>
                  {!values.carrierAdjustments ? (
                    <div className="flex flex-col items-center justify-center p-8">
                      <p className="text-gray-500 mb-4 max-w-md text-center">
                        Adjustments can be added for a particular carrier account and/or service type. Begin by enabled
                        carrier adjustments.
                      </p>
                      <Button
                        type="button"
                        kind="secondary"
                        onClick={handleEnableCarrierAdjustments}
                        disabled={isSubmitting || deleting}
                      >
                        Enable carrier adjustments
                      </Button>
                    </div>
                  ) : (
                    <div
                      className="grid grid-cols-4 gap-x-4 gap-y-2"
                      style={{ gridTemplateColumns: 'repeat(3, 1fr) auto' }}
                    >
                      <div className="col-span-4 justify-self-end pb-4">
                        <Button
                          type="button"
                          kind="destructive"
                          onClick={handleDisableCarrierAdjustments}
                          disabled={isSubmitting || deleting}
                        >
                          Disable carrier adjustments
                        </Button>
                      </div>
                      {carrierAccounts.map((account) => (
                        <CarrierAdjustmentSection key={account.id} account={account} />
                      ))}
                    </div>
                  )}
                </CardContent>
                <CardFooter className="flex justify-end space-x-4">
                  <Button type="button" kind="destructive" onClick={handleDelete} loading={deleting}>
                    Delete
                  </Button>
                  <Button type="submit" icon={SaveIcon} loading={isSubmitting} disabled={deleting}>
                    Save
                  </Button>
                </CardFooter>
              </Card>
            </fieldset>
          </Form>
        );
      }}
    </Formik>
  );
};

export const useRateAdjustmentForm = () => useFormikContext<RateAdjustmentFormValues>();
