import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { OrderV2Query, OrderV2QueryVariables } from './__generated__/order-v2.gql.generated';
import { ORDER_DETAILS_FRAGMENT } from './fragments/order-details.gql';

export const ORDER_V2_QUERY = gql`
  query OrderV2($integrationId: ID!, $orderId: ID!) {
    orderV2(integrationId: $integrationId, orderId: $orderId) {
      ...OrderDetails
    }
  }

  ${ORDER_DETAILS_FRAGMENT}
`;

export interface UseOrderV2QueryParams {
  integrationId: string;
  orderId: string;
}

export const useOrderV2Query = ({ integrationId, orderId }: UseOrderV2QueryParams) =>
  useQuery<OrderV2Query, OrderV2QueryVariables>(ORDER_V2_QUERY, {
    variables: { integrationId, orderId },
    notifyOnNetworkStatusChange: true,
  });

export const useOrderV2LazyQuery = () => useLazyQuery<OrderV2Query, OrderV2QueryVariables>(ORDER_V2_QUERY);
