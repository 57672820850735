import { gql } from '@apollo/client/core';
import { useSubscription } from '@apollo/client';
import { ORDER_EVENTS_QUERY } from './order-events.gql';
import { isEqual } from 'lodash';
import { OrderEventsQuery, OrderEventsQueryVariables } from './__generated__/order-events.gql.generated';
import {
  OrderEventCreatedSubscription,
  OrderEventCreatedSubscriptionVariables,
} from './__generated__/order-event-created.gql.generated';

export const ORDER_EVENT_CREATED_SUBSCRIPTION = gql`
  subscription OrderEventCreated($orderId: String!) {
    createdOrderEvent(orderId: $orderId) {
      createdAt
      createdBy
      metadataJson
      name
      orderId
    }
  }
`;

export const useOrderEventCreatedSubscription = (orderId: string) => {
  return useSubscription<OrderEventCreatedSubscription, OrderEventCreatedSubscriptionVariables>(
    ORDER_EVENT_CREATED_SUBSCRIPTION,
    {
      variables: { orderId: `tplc_${orderId}` },
      onSubscriptionData({ client, subscriptionData }) {
        const newEvent = subscriptionData.data?.createdOrderEvent;
        const existingEvents = client.cache.readQuery<OrderEventsQuery, OrderEventsQueryVariables>({
          query: ORDER_EVENTS_QUERY,
          variables: { tplcOrderId: orderId },
        });

        if (newEvent && existingEvents && !existingEvents.orderEvents.some((existing) => isEqual(existing, newEvent))) {
          client.cache.writeQuery<OrderEventsQuery, OrderEventsQueryVariables>({
            query: ORDER_EVENTS_QUERY,
            variables: { tplcOrderId: orderId },
            data: {
              orderEvents: [...existingEvents.orderEvents, newEvent],
            },
          });
        }
      },
    }
  );
};
