import { Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import { FormSelect } from '../Form/FormSelect';
import * as yup from 'yup';
import { useHistory } from 'react-router-dom';
import { Button } from '../Button';
import { Card } from '../Card';
import { CardFooter } from '../CardFooter';
import mixpanel from 'mixpanel-browser';
import { EasyPostCarrier, SortOrder } from '../../types/__generated__/graphql';
import { CardContent } from '../CardContent';
import { useCreateManifestV2Mutation } from '../../operations/create-manifest-v2.gql';
import { ManifestsV2Query, ManifestsV2QueryVariables } from '../../operations/__generated__/manifests-v2.gql.generated';
import { MANIFESTS_V2_QUERY } from '../../operations/manifests-v2.gql';
import { useErrorModal } from '../../utilities/use-error-modal';
import { ErrorModal } from '../ErrorModal';

interface CreateManifestValues {
  carrier: EasyPostCarrier;
  locationId?: number;
}

const initialValues = {
  carrier: EasyPostCarrier.DhlEcs,
};

const schema = yup.object().shape({
  carrier: yup.string().required(),
});

export const CreateManifestV2Form: React.FC = () => {
  const [createManifest, { loading, error }] = useCreateManifestV2Mutation();
  const history = useHistory();
  const { showErrorModal, handleErrorModalClose } = useErrorModal(error?.message);

  const handleFormSubmit = async (values: CreateManifestValues, formikHelpers: FormikHelpers<CreateManifestValues>) => {
    try {
      formikHelpers.setSubmitting(true);
      mixpanel.track('Create Manifest Submit');
      await createManifest({
        variables: {
          carrier: values.carrier,
        },
        refetchQueries: ['ManifestsV2'],
        update(cache, { data }) {
          const newManifest = data?.createManifestV2;
          const existingManifests = cache.readQuery<ManifestsV2Query, ManifestsV2QueryVariables>({
            query: MANIFESTS_V2_QUERY,
            variables: {
              limit: 50,
              sortOrder: SortOrder.Desc,
              nextToken: null,
            },
          });

          if (existingManifests && newManifest) {
            cache.writeQuery<ManifestsV2Query, ManifestsV2QueryVariables>({
              query: MANIFESTS_V2_QUERY,
              variables: { limit: 50, sortOrder: SortOrder.Desc, nextToken: null },
              data: {
                manifestsV2: {
                  ...existingManifests.manifestsV2,
                  items: [newManifest, ...existingManifests.manifestsV2.items.slice(0, 49)],
                },
              },
            });
          }
        },
      });
      mixpanel.track('Create Manifest Succeeded');
      history.push('/manifest');
    } catch (err) {
      mixpanel.track('Create Manifest Failed');
      console.error('An error occurred while creating manifest:', err);
      formikHelpers.setSubmitting(false);
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleFormSubmit} validationSchema={schema}>
      {({ isValid }) => (
        <Form>
          <ErrorModal isOpen={showErrorModal} onClose={handleErrorModalClose} message={error?.message} />
          <Card>
            <CardContent>
              <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className="md:col-span-1">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">Create Manifest</h3>
                  <p className="mt-1 text-sm text-gray-500">
                    Creates a carrier-specific shipping manifest to hand-off to the carrier. Some carriers, such as DHL,
                    require a manifest.
                  </p>
                  <p className="mt-1 text-sm text-gray-500">
                    All un-manifested shipments for the selected carrier and facility will be included in the manifest.
                  </p>
                </div>
                <div className="mt-5 md:mt-0 md:col-span-2">
                  <FormSelect label="Carrier" name="carrier" className="mb-4 block w-48 w-full" required>
                    {Object.values(EasyPostCarrier).map((carrier) => (
                      <option key={carrier} value={carrier}>
                        {carrier}
                      </option>
                    ))}
                  </FormSelect>
                </div>
              </div>
            </CardContent>
            <CardFooter className="flex justify-end">
              <Button disabled={!isValid} type="submit" loading={loading}>
                Create manifest
              </Button>
            </CardFooter>
          </Card>
        </Form>
      )}
    </Formik>
  );
};
