export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type Address = {
  __typename?: 'Address';
  name?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  country: Scalars['String'];
  isResidential?: Maybe<Scalars['Boolean']>;
  isValid?: Maybe<Scalars['Boolean']>;
  errors: Array<Scalars['String']>;
  easyPostId?: Maybe<Scalars['ID']>;
};

export type AddressBookAddress = {
  __typename?: 'AddressBookAddress';
  id: Scalars['ID'];
  easyPostAddressId: Scalars['ID'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  addressName: Scalars['String'];
  address: AddressV2;
};

export type AddressBookAddressInput = {
  addressName: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  street1: Scalars['String'];
  street2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  zip: Scalars['String'];
  country: Scalars['String'];
  email: Scalars['String'];
  phone: Scalars['String'];
};

export type AddressInput = {
  name?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  postalCode: Scalars['String'];
  country: Scalars['String'];
};

export type AddressV2 = {
  __typename?: 'AddressV2';
  company?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  street1?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  verifications: AddressVerifications;
  easyPostId?: Maybe<Scalars['ID']>;
};

export type AddressV2Input = {
  company?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  street1?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  country: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type AddressVerification = {
  __typename?: 'AddressVerification';
  success: Scalars['Boolean'];
  errors: Array<FieldError>;
  details?: Maybe<AddressVerificationDetails>;
};

export type AddressVerificationDetails = {
  __typename?: 'AddressVerificationDetails';
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  time_zone: Scalars['String'];
};

export type AddressVerifications = {
  __typename?: 'AddressVerifications';
  zip4?: Maybe<AddressVerification>;
  delivery?: Maybe<AddressVerification>;
};

export type AllUsersResponse = {
  __typename?: 'AllUsersResponse';
  items: Array<User>;
  limit: Scalars['Int'];
  nextToken?: Maybe<Scalars['String']>;
};

export type CarrierAccount = {
  __typename?: 'CarrierAccount';
  id: Scalars['ID'];
  name: Scalars['String'];
  carrier: CarrierAccountType;
  description: Scalars['String'];
};

export enum CarrierAccountType {
  BetterTrucksAccount = 'BetterTrucksAccount',
  DhlEcsAccount = 'DhlEcsAccount',
  FedexAccount = 'FedexAccount',
  FedexSmartpostAccount = 'FedexSmartpostAccount',
  UpsAccount = 'UpsAccount',
  UpsSurepostAccount = 'UpsSurepostAccount',
  UspsAccount = 'UspsAccount',
  Unknown = 'Unknown'
}

export type CarrierRateAdjustment = {
  __typename?: 'CarrierRateAdjustment';
  accountId: Scalars['ID'];
  adjustment?: Maybe<Scalars['Float']>;
  serviceAdjustments?: Maybe<Array<ServiceRateAdjustment>>;
};

export type CarrierRateAdjustmentInput = {
  accountId: Scalars['ID'];
  adjustment?: Maybe<Scalars['Float']>;
  serviceAdjustments?: Maybe<Array<ServiceRateAdjustmentInput>>;
};

export type CreateLocateInvIntegrationInput = {
  description: Scalars['String'];
  apiKey: Scalars['String'];
  apiUrl: Scalars['String'];
};

export type CreateRuleInput = {
  integrationId: Scalars['ID'];
  orderChannelId: Scalars['ID'];
  rule: RuleInput;
};

export type CreateShipStationIntegrationInput = {
  description: Scalars['String'];
  apiKey: Scalars['String'];
  apiSecret: Scalars['String'];
};

export type CreateTplcIntegrationInput = {
  description: Scalars['String'];
  clientId: Scalars['String'];
  clientSecret: Scalars['String'];
  userLogin: Scalars['String'];
};

export enum EasyPostCarrier {
  AmazonMws = 'AmazonMws',
  Apc = 'APC',
  Aramex = 'Aramex',
  ArrowXl = 'ArrowXL',
  Asendia = 'Asendia',
  AustraliaPost = 'AustraliaPost',
  AxlehireV3 = 'AxlehireV3',
  BetterTrucks = 'BetterTrucks',
  BorderGuru = 'BorderGuru',
  Cainiao = 'Cainiao',
  CanadaPost = 'CanadaPost',
  Canpar = 'Canpar',
  ColumbusLastMile = 'ColumbusLastMile',
  Chronopost = 'Chronopost',
  ColisPrive = 'ColisPrive',
  Colissimo = 'Colissimo',
  CouriersPlease = 'CouriersPlease',
  DaiPost = 'DaiPost',
  Deliv = 'Deliv',
  DeutschePost = 'DeutschePost',
  DeutschePostUk = 'DeutschePostUK',
  DhlEcommerceAsia = 'DHLEcommerceAsia',
  DhlEcs = 'DhlEcs',
  DhlExpress = 'DHLExpress',
  DhlFreight = 'DHLFreight',
  DhlGermany = 'DHLGermany',
  Dicom = 'Dicom',
  Dpd = 'DPD',
  Dpduk = 'DPDUK',
  ChinaEms = 'ChinaEMS',
  Estafeta = 'Estafeta',
  Estes = 'Estes',
  Fastway = 'Fastway',
  FedEx = 'FedEx',
  FedExCrossBorder = 'FedExCrossBorder',
  FedExMailview = 'FedExMailview',
  FedExSameDayCity = 'FedExSameDayCity',
  FedExSmartPost = 'FedExSmartPost',
  FirstMile = 'FirstMile',
  Globegistics = 'Globegistics',
  Gso = 'GSO',
  Hermes = 'Hermes',
  HongKongPost = 'HongKongPost',
  InterlinkExpress = 'InterlinkExpress',
  JancoFreight = 'JancoFreight',
  JpPost = 'JPPost',
  KuronekoYamato = 'KuronekoYamato',
  LaPoste = 'LaPoste',
  LaserShipV2 = 'LaserShipV2',
  Liefery = 'Liefery',
  LoomisExpress = 'LoomisExpress',
  Lso = 'LSO',
  Newgistics = 'Newgistics',
  OnTrac = 'OnTrac',
  OnTracDirectPost = 'OnTracDirectPost',
  OsmWorldwide = 'OsmWorldwide',
  Parcelforce = 'Parcelforce',
  Passport = 'Passport',
  Pilot = 'Pilot',
  PostNl = 'PostNL',
  Posten = 'Posten',
  PostNord = 'PostNord',
  Purolator = 'Purolator',
  RoyalMail = 'RoyalMail',
  RrDonnelley = 'RRDonnelley',
  Seko = 'Seko',
  OmniParcel = 'OmniParcel',
  SingaporePost = 'SingaporePost',
  SpeeDee = 'SpeeDee',
  SprintShip = 'SprintShip',
  StarTrack = 'StarTrack',
  Toll = 'Toll',
  TForce = 'TForce',
  Uds = 'UDS',
  Ukrposhta = 'Ukrposhta',
  Ups = 'UPS',
  UpsSurePost = 'UPSSurePost',
  UpsIparcel = 'UPSIparcel',
  UpsMailInnovations = 'UPSMailInnovations',
  Usps = 'USPS',
  Veho = 'Veho',
  Yanwen = 'Yanwen',
  Yodel = 'Yodel'
}

export enum EasyPostObjectType {
  Order = 'Order',
  Shipment = 'Shipment'
}

export type EnabledCarrierAccountInput = {
  carrier: CarrierAccountType;
  id: Scalars['ID'];
};

export type Facility = {
  __typename?: 'Facility';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type FieldError = {
  __typename?: 'FieldError';
  field: Scalars['String'];
  message: Scalars['String'];
};

export type GeneralSettings = {
  __typename?: 'GeneralSettings';
  defaultShipFromAddressId?: Maybe<Scalars['ID']>;
  defaultReturnAddressId?: Maybe<Scalars['ID']>;
  customsSigner?: Maybe<Scalars['String']>;
  defaultEmail?: Maybe<Scalars['String']>;
  defaultPhoneNumber?: Maybe<Scalars['String']>;
};

export type GeneralSettingsInput = {
  defaultShipFromAddressId?: Maybe<Scalars['ID']>;
  defaultReturnAddressId?: Maybe<Scalars['ID']>;
  customsSigner?: Maybe<Scalars['String']>;
  defaultEmail?: Maybe<Scalars['String']>;
  defaultPhoneNumber?: Maybe<Scalars['String']>;
};

export type Integration = {
  id: Scalars['ID'];
  type: IntegrationType;
  readable: Scalars['String'];
  description: Scalars['String'];
  orderChannels: Array<OrderChannel>;
};

export enum IntegrationType {
  Tplc = 'TPLC',
  ShipStation = 'ShipStation',
  LocateInv = 'LocateInv'
}

export type Label = {
  __typename?: 'Label';
  labelFileType: Scalars['String'];
  labelFileUrl: Scalars['String'];
};

export type LinearDimensions = {
  __typename?: 'LinearDimensions';
  length?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  unit?: Maybe<SeLinearUnit>;
};

export type LinearDimensionsInput = {
  length: Scalars['Float'];
  width: Scalars['Float'];
  height: Scalars['Float'];
  unit: SeLinearUnit;
};

export type ListAddressBookAddressesInput = {
  limit: Scalars['Int'];
  nextToken?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<SortOrder>;
};

export type ListAddressBookAddressesResponse = {
  __typename?: 'ListAddressBookAddressesResponse';
  limit: Scalars['Int'];
  count: Scalars['Int'];
  nextToken?: Maybe<Scalars['String']>;
  items: Array<AddressBookAddress>;
};

export type ListReportsInput = {
  limit: Scalars['Int'];
  sortOrder: SortOrder;
  nextToken?: Maybe<Scalars['String']>;
};

export type ListReportsResponse = {
  __typename?: 'ListReportsResponse';
  items: Array<Report>;
  count: Scalars['Int'];
  sortOrder: SortOrder;
  nextToken?: Maybe<Scalars['String']>;
};

export type ListRulesInput = {
  integrationId: Scalars['ID'];
  orderChannelId: Scalars['ID'];
  pagination: PaginationInput;
  sortOrder: SortOrder;
};

export type ListRulesResponse = {
  __typename?: 'ListRulesResponse';
  items: Array<Rule>;
  count: Scalars['Int'];
  limit: Scalars['Int'];
  nextToken?: Maybe<Scalars['String']>;
};

export type LocateInvIntegration = Integration & {
  __typename?: 'LocateInvIntegration';
  id: Scalars['ID'];
  type: IntegrationType;
  readable: Scalars['String'];
  description: Scalars['String'];
  orderChannels: Array<OrderChannel>;
  apiKey: Scalars['String'];
  apiUrl: Scalars['String'];
};

export type Manifest = {
  __typename?: 'Manifest';
  id: Scalars['ID'];
  createdAt: Scalars['String'];
  carrier: Scalars['String'];
  locationId: Scalars['Int'];
  status: ManifestStatus;
  formUrl?: Maybe<Scalars['String']>;
};

export enum ManifestStatus {
  Creating = 'creating',
  Created = 'created',
  Failed = 'failed'
}

export type ManifestV2 = {
  __typename?: 'ManifestV2';
  id: Scalars['ID'];
  createdAt: Scalars['String'];
  carrier: Scalars['String'];
  status: ManifestStatus;
  formUrl?: Maybe<Scalars['String']>;
};

export type ManifestsV2Input = {
  limit: Scalars['Int'];
  sortOrder: SortOrder;
  nextToken?: Maybe<Scalars['String']>;
};

export type ManifestsV2Response = {
  __typename?: 'ManifestsV2Response';
  limit: Scalars['Int'];
  sortOrder: SortOrder;
  count: Scalars['Int'];
  items: Array<ManifestV2>;
  nextToken?: Maybe<Scalars['String']>;
};

export type Modifier = {
  __typename?: 'Modifier';
  field: ModifierField;
  value: Array<Scalars['String']>;
};

export enum ModifierField {
  SetPackageType = 'SET_PACKAGE_TYPE',
  SetAdultSignatureRequired = 'SET_ADULT_SIGNATURE_REQUIRED',
  SetHazmat = 'SET_HAZMAT'
}

export type ModifierFieldOption = {
  __typename?: 'ModifierFieldOption';
  value: ModifierField;
  label: Scalars['String'];
};

export type ModifierInput = {
  field: ModifierField;
  value: Array<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  changeRulePriority: Scalars['Int'];
  createAddressBookAddress: AddressBookAddress;
  createLocateInvIntegration: LocateInvIntegration;
  createManifest: Manifest;
  createManifestV2: ManifestV2;
  createOrderEvent: OrderEvent;
  createOrderEventV2: OrderEventV2;
  createQualityControlRecord: OrderEventV2;
  createReport: Report;
  createRule: Rule;
  createShipStationIntegration: ShipStationIntegration;
  createTplcIntegration: TplcIntegration;
  createUser: User;
  deleteAddressBookAddress: Scalars['ID'];
  deleteRateAdjustment: Scalars['Boolean'];
  deleteRule: Scalars['ID'];
  deleteUser: Scalars['Boolean'];
  fireReportSubscription: Report;
  provisionTenant: Tenant;
  purchaseLabel: PurchaseLabelResponse;
  purchaseLabelV2: PurchaseLabelV2Response;
  refundOrder?: Maybe<Order>;
  refundOrderV2: RefundOrderV2Response;
  setEnabledCarrierAccountIds: SetEnabledCarrierAccountsResponse;
  setRateAdjustment: RateAdjustment;
  setUserRoles: User;
  updateAddressBookAddress: AddressBookAddress;
  updateGeneralSettings: GeneralSettings;
  updateManifest: Manifest;
  updateManifestV2: ManifestV2;
  updateRule: Rule;
  updateShipTo: Order;
  updateUser: User;
  validateAddress: AddressV2;
};


export type MutationChangeRulePriorityArgs = {
  id: Scalars['ID'];
  increase: Scalars['Boolean'];
};


export type MutationCreateAddressBookAddressArgs = {
  input: AddressBookAddressInput;
};


export type MutationCreateLocateInvIntegrationArgs = {
  input: CreateLocateInvIntegrationInput;
};


export type MutationCreateManifestArgs = {
  carrier: EasyPostCarrier;
  locationId: Scalars['Int'];
};


export type MutationCreateManifestV2Args = {
  carrier: EasyPostCarrier;
};


export type MutationCreateOrderEventArgs = {
  orderId: Scalars['ID'];
  input: OrderEventInput;
};


export type MutationCreateOrderEventV2Args = {
  integrationId: Scalars['ID'];
  orderId: Scalars['ID'];
  orderEvent: OrderEventV2Input;
};


export type MutationCreateQualityControlRecordArgs = {
  integrationId: Scalars['ID'];
  orderId: Scalars['ID'];
};


export type MutationCreateReportArgs = {
  input: ReportInput;
};


export type MutationCreateRuleArgs = {
  input: CreateRuleInput;
};


export type MutationCreateShipStationIntegrationArgs = {
  input: CreateShipStationIntegrationInput;
};


export type MutationCreateTplcIntegrationArgs = {
  input: CreateTplcIntegrationInput;
};


export type MutationCreateUserArgs = {
  email: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  roles: Array<UserRole>;
};


export type MutationDeleteAddressBookAddressArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteRateAdjustmentArgs = {
  integrationId: Scalars['ID'];
  orderChannelId: Scalars['ID'];
};


export type MutationDeleteRuleArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['ID'];
};


export type MutationFireReportSubscriptionArgs = {
  id: Scalars['ID'];
};


export type MutationProvisionTenantArgs = {
  input: ProvisionTenantInput;
};


export type MutationPurchaseLabelArgs = {
  input: PurchaseLabelInput;
};


export type MutationPurchaseLabelV2Args = {
  input: PurchaseLabelV2Input;
};


export type MutationRefundOrderArgs = {
  tplcOrderId: Scalars['ID'];
};


export type MutationRefundOrderV2Args = {
  input: RefundOrderV2Input;
};


export type MutationSetEnabledCarrierAccountIdsArgs = {
  input: SetEnabledCarrierAccountsInput;
};


export type MutationSetRateAdjustmentArgs = {
  input: SetRateAdjustmentInput;
};


export type MutationSetUserRolesArgs = {
  roles: Array<UserRole>;
  userId: Scalars['ID'];
};


export type MutationUpdateAddressBookAddressArgs = {
  id: Scalars['ID'];
  input: AddressBookAddressInput;
};


export type MutationUpdateGeneralSettingsArgs = {
  input: GeneralSettingsInput;
};


export type MutationUpdateManifestArgs = {
  batchId: Scalars['ID'];
  status: ManifestStatus;
  formUrl?: Maybe<Scalars['String']>;
};


export type MutationUpdateManifestV2Args = {
  batchId: Scalars['ID'];
  status: ManifestStatus;
  formUrl?: Maybe<Scalars['String']>;
};


export type MutationUpdateRuleArgs = {
  input: UpdateRuleInput;
};


export type MutationUpdateShipToArgs = {
  tplcOrderId: Scalars['ID'];
  address: UpdateShipToInput;
};


export type MutationUpdateUserArgs = {
  id: Scalars['ID'];
  updates: UpdateUserInput;
};


export type MutationValidateAddressArgs = {
  address: AddressV2Input;
};

export type Option = {
  __typename?: 'Option';
  value: Scalars['String'];
  label: Scalars['String'];
};

export type Order = {
  __typename?: 'Order';
  id: Scalars['ID'];
  shipToOriginal: Address;
  shipToValidated: Address;
  parcels: Array<ParcelV2>;
  trackingCode?: Maybe<Scalars['String']>;
  shipped: Scalars['Boolean'];
  pickedTimestamp?: Maybe<Scalars['String']>;
  packedTimestamp?: Maybe<Scalars['String']>;
  shippingMethod?: Maybe<Scalars['String']>;
  carrier?: Maybe<Scalars['String']>;
  serviceType?: Maybe<Scalars['String']>;
};

export type OrderChannel = {
  __typename?: 'OrderChannel';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type OrderEvent = {
  __typename?: 'OrderEvent';
  orderId: Scalars['String'];
  name: OrderEventType;
  createdAt: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  metadataJson?: Maybe<Scalars['String']>;
};

export type OrderEventInput = {
  name: OrderEventType;
  createdAt: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  metadataJson?: Maybe<Scalars['String']>;
};

export enum OrderEventType {
  ShipmentPurchased = 'SHIPMENT_PURCHASED',
  ShipmentRefunded = 'SHIPMENT_REFUNDED',
  LabelPrinted = 'LABEL_PRINTED',
  LabelReprinted = 'LABEL_REPRINTED',
  LabelPrintFailed = 'LABEL_PRINT_FAILED',
  AddressUpdated = 'ADDRESS_UPDATED',
  QualityControlCheck = 'QUALITY_CONTROL_CHECK'
}

export type OrderEventV2 = {
  __typename?: 'OrderEventV2';
  id: Scalars['ID'];
  integrationId: Scalars['ID'];
  orderId: Scalars['ID'];
  type: OrderEventType;
  createdAt: Scalars['String'];
  createdBy: Scalars['String'];
  metadataJson?: Maybe<Scalars['String']>;
};

export type OrderEventV2Input = {
  tenantId: Scalars['ID'];
  type: OrderEventType;
  createdAt: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  metadataJson?: Maybe<Scalars['String']>;
};

export type OrderItem = {
  __typename?: 'OrderItem';
  name?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  upc?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  hsTariffCode?: Maybe<Scalars['String']>;
  countryOfOrigin?: Maybe<Scalars['String']>;
};

export type OrderLabelsInput = {
  integrationId: Scalars['ID'];
  orderId: Scalars['ID'];
};

export enum OrderShipStatus {
  PartiallyShipped = 'PARTIALLY_SHIPPED',
  FullyShipped = 'FULLY_SHIPPED',
  PendingShipment = 'PENDING_SHIPMENT'
}

export type OrderShipmentsInput = {
  integrationId: Scalars['ID'];
  orderId: Scalars['ID'];
};

export type OrderV2 = {
  __typename?: 'OrderV2';
  id: Scalars['ID'];
  integrationId: Scalars['ID'];
  orderChannelId: Scalars['ID'];
  orderId: Scalars['ID'];
  salesOrderNumber?: Maybe<Scalars['String']>;
  shipToAddress?: Maybe<AddressV2>;
  shipToAddressId: Scalars['ID'];
  shipFromAddressId?: Maybe<Scalars['ID']>;
  returnAddressId?: Maybe<Scalars['ID']>;
  parcels: Array<ParcelV3>;
  requestedServiceType?: Maybe<Scalars['String']>;
  shipped: Scalars['Boolean'];
  /** @deprecated No longer supported */
  trackingNumber?: Maybe<Scalars['String']>;
  trackingNumbers: Array<Scalars['String']>;
  carrier?: Maybe<Scalars['String']>;
  service?: Maybe<Scalars['String']>;
  orderDate?: Maybe<Scalars['String']>;
  shipDate?: Maybe<Scalars['String']>;
};

export type PaginationInput = {
  limit: Scalars['Int'];
  nextToken?: Maybe<Scalars['String']>;
};

export type ParcelInput = {
  dimensions?: Maybe<LinearDimensionsInput>;
  weight: WeightInput;
};

export type ParcelV2 = {
  __typename?: 'ParcelV2';
  id: Scalars['ID'];
  length: Scalars['Float'];
  width: Scalars['Float'];
  height: Scalars['Float'];
  linearUnit: SeLinearUnit;
  weight: Scalars['Float'];
  weightUnit: SeWeightUnit;
  trackingCode?: Maybe<Scalars['String']>;
  labelUrl?: Maybe<Scalars['String']>;
};

export type ParcelV2Input = {
  id: Scalars['ID'];
  length: Scalars['Float'];
  width: Scalars['Float'];
  height: Scalars['Float'];
  weight: Scalars['Float'];
};

export type ParcelV3 = {
  __typename?: 'ParcelV3';
  length?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
  predefinedPackaging?: Maybe<Scalars['String']>;
  internalId?: Maybe<Scalars['String']>;
  items: Array<OrderItem>;
};

export type ParcelV3Input = {
  length: Scalars['Float'];
  width: Scalars['Float'];
  height: Scalars['Float'];
  weight: Scalars['Float'];
  predefinedPackaging?: Maybe<Scalars['String']>;
  internalId?: Maybe<Scalars['String']>;
};

export enum PredefinedPackaging {
  FedExEnvelope = 'FedExEnvelope',
  FedExBox = 'FedExBox',
  FedExPak = 'FedExPak',
  FedExTube = 'FedExTube',
  FedEx10kgBox = 'FedEx10kgBox',
  FedEx25kgBox = 'FedEx25kgBox',
  FedExSmallBox = 'FedExSmallBox',
  FedExMediumBox = 'FedExMediumBox',
  FedExLargeBox = 'FedExLargeBox',
  FedExExtraLargeBox = 'FedExExtraLargeBox',
  JumboDocument = 'JumboDocument',
  JumboParcel = 'JumboParcel',
  Document = 'Document',
  DhlFlyer = 'DHLFlyer',
  Domestic = 'Domestic',
  ExpressDocument = 'ExpressDocument',
  DhlExpressEnvelope = 'DHLExpressEnvelope',
  JumboBox = 'JumboBox',
  JumboJuniorDocument = 'JumboJuniorDocument',
  JuniorJumboBox = 'JuniorJumboBox',
  JumboJuniorParcel = 'JumboJuniorParcel',
  OtherDhlPackaging = 'OtherDHLPackaging',
  Parcel = 'Parcel',
  YourPackaging = 'YourPackaging',
  UpsLetter = 'UPSLetter',
  UpsExpressBox = 'UPSExpressBox',
  Ups25kgBox = 'UPS25kgBox',
  Ups10kgBox = 'UPS10kgBox',
  Tube = 'Tube',
  Pak = 'Pak',
  SmallExpressBox = 'SmallExpressBox',
  MediumExpressBox = 'MediumExpressBox',
  LargeExpressBox = 'LargeExpressBox',
  Card = 'Card',
  Letter = 'Letter',
  Flat = 'Flat',
  FlatRateEnvelope = 'FlatRateEnvelope',
  FlatRateLegalEnvelope = 'FlatRateLegalEnvelope',
  FlatRatePaddedEnvelope = 'FlatRatePaddedEnvelope',
  FlatRateGiftCardEnvelope = 'FlatRateGiftCardEnvelope',
  FlatRateWindowEnvelope = 'FlatRateWindowEnvelope',
  FlatRateCardboardEnvelope = 'FlatRateCardboardEnvelope',
  SmallFlatRateEnvelope = 'SmallFlatRateEnvelope',
  LargeParcel = 'LargeParcel',
  IrregularParcel = 'IrregularParcel',
  SoftPack = 'SoftPack',
  SmallFlatRateBox = 'SmallFlatRateBox',
  MediumFlatRateBox = 'MediumFlatRateBox',
  LargeFlatRateBox = 'LargeFlatRateBox',
  LargeFlatRateBoxApofpo = 'LargeFlatRateBoxAPOFPO',
  LargeFlatRateBoardGameBox = 'LargeFlatRateBoardGameBox',
  RegionalRateBoxA = 'RegionalRateBoxA',
  RegionalRateBoxB = 'RegionalRateBoxB',
  FlatTubTrayBox = 'FlatTubTrayBox',
  EmmTrayBox = 'EMMTrayBox',
  FullTrayBox = 'FullTrayBox',
  HalfTrayBox = 'HalfTrayBox',
  PmodSack = 'PMODSack'
}

export type ProvisionTenantInput = {
  adminEmail: Scalars['String'];
  companyName: Scalars['String'];
  tenantType: TenantType;
  apiKey?: Maybe<Scalars['String']>;
};

export type PurchaseLabelInput = {
  tplcOrderId: Scalars['ID'];
  easyPostShipmentId: Scalars['ID'];
  selectedRateId: Scalars['ID'];
  carrier: EasyPostCarrier;
  service: Scalars['String'];
  allowInvalidAddress?: Maybe<Scalars['Boolean']>;
  locationId: Scalars['Int'];
};

export type PurchaseLabelResponse = {
  __typename?: 'PurchaseLabelResponse';
  order: Order;
  labels: Array<Label>;
};

export type PurchaseLabelV2Input = {
  integrationId?: Maybe<Scalars['ID']>;
  orderId?: Maybe<Scalars['ID']>;
  orderChannelId?: Maybe<Scalars['ID']>;
  salesOrderNumber?: Maybe<Scalars['String']>;
  requestedServiceType?: Maybe<Scalars['String']>;
  shipmentId?: Maybe<Scalars['ID']>;
  selectedRateId?: Maybe<Scalars['ID']>;
  epOrderId?: Maybe<Scalars['ID']>;
  carrier?: Maybe<Scalars['String']>;
  service?: Maybe<Scalars['String']>;
  printerId?: Maybe<Scalars['ID']>;
};

export type PurchaseLabelV2Response = {
  __typename?: 'PurchaseLabelV2Response';
  labels: Array<Label>;
  trackingNumbers: Array<Scalars['String']>;
  carrier: EasyPostCarrier;
  service: Scalars['String'];
  printed: Scalars['Boolean'];
};

export type PurchasedShipment = {
  __typename?: 'PurchasedShipment';
  label: Label;
  trackingCode: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  addressBookAddress: AddressBookAddress;
  addressBookAddresses: ListAddressBookAddressesResponse;
  allFacilities: Array<Facility>;
  allTenants: Array<Tenant>;
  allUsers: AllUsersResponse;
  carrierAccountServices: Array<Option>;
  carrierAccounts: Array<CarrierAccount>;
  enabledCarrierAccounts: Array<Scalars['ID']>;
  generalSettings: GeneralSettings;
  integrations: Array<Integration>;
  manifests: Array<Manifest>;
  manifestsV2: ManifestsV2Response;
  modifierFieldOptions: Array<ModifierFieldOption>;
  modifierValueOptions: Array<Option>;
  order?: Maybe<Order>;
  orderEvents: Array<OrderEvent>;
  orderEventsV2: Array<OrderEventV2>;
  orderLabels: Array<Label>;
  orderShipments: Array<Shipment>;
  orderV2?: Maybe<OrderV2>;
  printNodeApiKey: Scalars['String'];
  rateAdjustment?: Maybe<RateAdjustment>;
  rateFilterFieldOptions: Array<RateFilterFieldOption>;
  rateFilterValueOptions: Array<Option>;
  rateShop: Array<SeRate>;
  rateShopV2: RateShopV2Response;
  rateShopV3: RateShopV3Response;
  report: Report;
  reports: ListReportsResponse;
  rule: Rule;
  rules: ListRulesResponse;
  shippingStats: ShippingStats;
  tenant?: Maybe<Tenant>;
  triggerFieldOptions: Array<TriggerFieldOption>;
  triggerValueOptions: Array<TriggerValueOption>;
  user: User;
};


export type QueryAddressBookAddressArgs = {
  id: Scalars['ID'];
};


export type QueryAddressBookAddressesArgs = {
  input: ListAddressBookAddressesInput;
};


export type QueryAllUsersArgs = {
  pagination: PaginationInput;
};


export type QueryCarrierAccountServicesArgs = {
  carrierAccountType: CarrierAccountType;
};


export type QueryEnabledCarrierAccountsArgs = {
  integrationId: Scalars['ID'];
  orderChannelId: Scalars['ID'];
};


export type QueryManifestsArgs = {
  locationId: Scalars['Int'];
};


export type QueryManifestsV2Args = {
  input: ManifestsV2Input;
};


export type QueryModifierValueOptionsArgs = {
  field: ModifierField;
};


export type QueryOrderArgs = {
  tplcOrderId: Scalars['ID'];
};


export type QueryOrderEventsArgs = {
  tplcOrderId: Scalars['ID'];
};


export type QueryOrderEventsV2Args = {
  integrationId: Scalars['ID'];
  orderId: Scalars['ID'];
};


export type QueryOrderLabelsArgs = {
  input: OrderLabelsInput;
};


export type QueryOrderShipmentsArgs = {
  input: OrderShipmentsInput;
};


export type QueryOrderV2Args = {
  integrationId: Scalars['ID'];
  orderId: Scalars['ID'];
};


export type QueryRateAdjustmentArgs = {
  integrationId: Scalars['ID'];
  orderChannelId: Scalars['ID'];
};


export type QueryRateFilterValueOptionsArgs = {
  field: RateFilterField;
};


export type QueryRateShopArgs = {
  input: RateShopInput;
};


export type QueryRateShopV2Args = {
  input: RateShopInput;
};


export type QueryRateShopV3Args = {
  input: RateShopV3Input;
};


export type QueryReportArgs = {
  id: Scalars['ID'];
};


export type QueryReportsArgs = {
  input: ListReportsInput;
};


export type QueryRuleArgs = {
  id: Scalars['ID'];
};


export type QueryRulesArgs = {
  input: ListRulesInput;
};


export type QueryShippingStatsArgs = {
  date?: Maybe<Scalars['String']>;
};


export type QueryTenantArgs = {
  id: Scalars['ID'];
};


export type QueryTriggerValueOptionsArgs = {
  field: TriggerField;
};


export type QueryUserArgs = {
  id: Scalars['ID'];
};

export type Rate = {
  __typename?: 'Rate';
  id: Scalars['ID'];
  service: Scalars['String'];
  serviceReadable?: Maybe<Scalars['String']>;
  carrier: Scalars['String'];
  carrierReadable?: Maybe<Scalars['String']>;
  carrierAccountId: Scalars['ID'];
  shipmentId: Scalars['ID'];
  epOrderId?: Maybe<Scalars['ID']>;
  rate: Scalars['Float'];
  currency?: Maybe<Scalars['String']>;
  retailRate?: Maybe<Scalars['Float']>;
  retailCurrency?: Maybe<Scalars['String']>;
  listRate?: Maybe<Scalars['Float']>;
  listCurrency?: Maybe<Scalars['String']>;
  deliveryDays?: Maybe<Scalars['Int']>;
  deliveryDate?: Maybe<Scalars['String']>;
  deliveryDaysGuaranteed: Scalars['Boolean'];
  timeInTransit?: Maybe<SmartRateTimeInTransitInfo>;
  preferredRate: Scalars['Boolean'];
};

export type RateAdjustment = {
  __typename?: 'RateAdjustment';
  id: Scalars['ID'];
  integrationId: Scalars['ID'];
  orderChannelId: Scalars['ID'];
  adjustment: Scalars['Float'];
  carrierAdjustments?: Maybe<Array<CarrierRateAdjustment>>;
};

export type RateAdjustmentInput = {
  adjustment: Scalars['Float'];
  carrierAdjustments?: Maybe<Array<CarrierRateAdjustmentInput>>;
};

export type RateFilter = {
  __typename?: 'RateFilter';
  field: RateFilterField;
  operator: RuleOperator;
  value: Array<Scalars['String']>;
};

export enum RateFilterField {
  CarrierServiceType = 'CarrierServiceType',
  Cost = 'Cost'
}

export type RateFilterFieldOption = {
  __typename?: 'RateFilterFieldOption';
  value: RateFilterField;
  label: Scalars['String'];
  operators: Array<RuleOperatorOption>;
  dataType: RuleDataType;
};

export type RateFilterInput = {
  field: RateFilterField;
  operator: RuleOperator;
  value: Array<Scalars['String']>;
};

export type RateMessage = {
  __typename?: 'RateMessage';
  carrier?: Maybe<EasyPostCarrier>;
  type?: Maybe<Scalars['String']>;
  message: Scalars['String'];
};

export type RateShopInput = {
  tplcOrderId: Scalars['ID'];
  easyPostAddressId: Scalars['ID'];
  parcels: Array<ParcelV2Input>;
  allowInvalidAddress?: Maybe<Scalars['Boolean']>;
};

export type RateShopV2Response = {
  __typename?: 'RateShopV2Response';
  messages: Array<RateMessage>;
  rates: Array<SeRate>;
};

export type RateShopV3Input = {
  integrationId: Scalars['ID'];
  orderChannelId: Scalars['ID'];
  orderId?: Maybe<Scalars['ID']>;
  shipToAddress?: Maybe<AddressV2Input>;
  shipToAddressId?: Maybe<Scalars['ID']>;
  shipFromAddress?: Maybe<AddressV2Input>;
  shipFromAddressId?: Maybe<Scalars['ID']>;
  returnAddress?: Maybe<AddressV2Input>;
  returnAddressId?: Maybe<Scalars['ID']>;
  requestedServiceType?: Maybe<Scalars['String']>;
  parcels: Array<ParcelV3Input>;
  timezone: Scalars['String'];
};

export type RateShopV3Response = {
  __typename?: 'RateShopV3Response';
  messages: Array<RateMessage>;
  rates: Array<Rate>;
};

export type RefundOrderV2Input = {
  integrationId: Scalars['ID'];
  orderChannelId: Scalars['ID'];
  orderId: Scalars['ID'];
};

export type RefundOrderV2Response = {
  __typename?: 'RefundOrderV2Response';
  message?: Maybe<Scalars['String']>;
};

export enum RefundStatus {
  Submitted = 'submitted',
  Refunded = 'refunded',
  Rejected = 'rejected',
  NotApplicable = 'not_applicable'
}

export type Report = {
  __typename?: 'Report';
  id: Scalars['ID'];
  tenantId: Scalars['ID'];
  integrationId?: Maybe<Scalars['ID']>;
  orderChannelId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  type: ReportType;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  status: ReportStatus;
  fileUrl?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
  endDate: Scalars['String'];
};

export type ReportInput = {
  integrationId: Scalars['ID'];
  orderChannelId: Scalars['ID'];
  name: Scalars['String'];
  type: ReportType;
  startDate: Scalars['String'];
  endDate: Scalars['String'];
};

export enum ReportStatus {
  Queued = 'queued',
  Processing = 'processing',
  Complete = 'complete',
  Failed = 'failed'
}

export enum ReportType {
  Shipments = 'shipments'
}

export type Rule = {
  __typename?: 'Rule';
  id: Scalars['ID'];
  name: Scalars['String'];
  active: Scalars['Boolean'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  priority: Scalars['Int'];
  triggers: Array<Trigger>;
  modifiers: Array<Modifier>;
  rateFilters: Array<RateFilter>;
  smartRateConfiguration?: Maybe<SmartRateConfiguration>;
};

export enum RuleDataType {
  String = 'string',
  Number = 'number',
  Enum = 'enum',
  Boolean = 'boolean',
  Time = 'time',
  Date = 'date',
  Datetime = 'datetime'
}

export type RuleInput = {
  name: Scalars['String'];
  triggers: Array<TriggerInput>;
  modifiers: Array<ModifierInput>;
  rateFilters: Array<RateFilterInput>;
  smartRateConfiguration?: Maybe<SmartRateConfigurationInput>;
};

export enum RuleOperator {
  Gt = 'gt',
  Gte = 'gte',
  Lt = 'lt',
  Lte = 'lte',
  Eq = 'eq',
  Ne = 'ne',
  In = 'in',
  Nin = 'nin',
  Contains = 'contains',
  NotContains = 'not_contains',
  StartsWith = 'starts_with',
  NotStartsWith = 'not_starts_with'
}

export type RuleOperatorOption = {
  __typename?: 'RuleOperatorOption';
  value: RuleOperator;
  label: Scalars['String'];
};

export enum SeLinearUnit {
  In = 'IN',
  Cm = 'CM'
}

export type SeRate = {
  __typename?: 'SERate';
  id: Scalars['String'];
  easyPostShipmentId: Scalars['String'];
  easyPostRateId: Scalars['String'];
  carrier: Scalars['String'];
  serviceCode: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  cost: Scalars['Float'];
  deliveryDays?: Maybe<Scalars['Int']>;
  deliveryDate?: Maybe<Scalars['String']>;
  deliveryDateGuaranteed?: Maybe<Scalars['Boolean']>;
  timeInTransit?: Maybe<SmartRateTimeInTransitInfo>;
};

export enum SeWeightUnit {
  Lb = 'LB',
  Kg = 'KG',
  Oz = 'OZ'
}

export type ServiceRateAdjustment = {
  __typename?: 'ServiceRateAdjustment';
  service: Scalars['String'];
  adjustment?: Maybe<Scalars['Float']>;
};

export type ServiceRateAdjustmentInput = {
  service: Scalars['String'];
  adjustment?: Maybe<Scalars['Float']>;
};

export type SetEnabledCarrierAccountsInput = {
  integrationId: Scalars['ID'];
  orderChannelId: Scalars['ID'];
  carrierAccounts: Array<EnabledCarrierAccountInput>;
};

export type SetEnabledCarrierAccountsResponse = {
  __typename?: 'SetEnabledCarrierAccountsResponse';
  carrierAccountIds: Array<Scalars['ID']>;
};

export type SetRateAdjustmentInput = {
  integrationId: Scalars['ID'];
  orderChannelId: Scalars['ID'];
  adjustment: RateAdjustmentInput;
};

export type ShipStationIntegration = Integration & {
  __typename?: 'ShipStationIntegration';
  id: Scalars['ID'];
  type: IntegrationType;
  readable: Scalars['String'];
  description: Scalars['String'];
  orderChannels: Array<OrderChannel>;
  apiKey: Scalars['String'];
  apiSecret: Scalars['String'];
};

export type Shipment = {
  __typename?: 'Shipment';
  id: Scalars['ID'];
  trackingNumber: Scalars['String'];
  carrier: Scalars['String'];
  service: Scalars['String'];
  deliveryDays?: Maybe<Scalars['Int']>;
  deliveryBusinessDays?: Maybe<Scalars['Int']>;
  uspsZone: Scalars['Int'];
  status?: Maybe<TrackerStatus>;
  cost: Scalars['Float'];
  adjustedCost: Scalars['Float'];
  refundStatus?: Maybe<RefundStatus>;
  refundRequested: Scalars['Boolean'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type ShippingStats = {
  __typename?: 'ShippingStats';
  shippedCount: Scalars['Int'];
  refundedCount: Scalars['Int'];
};

export type SmartRateConfiguration = {
  __typename?: 'SmartRateConfiguration';
  deliveryDays: Scalars['Int'];
  percentile: SmartRatePercentile;
  defaultCarrier?: Maybe<EasyPostCarrier>;
  defaultService?: Maybe<Scalars['String']>;
};

export type SmartRateConfigurationInput = {
  deliveryDays: Scalars['Int'];
  percentile: SmartRatePercentile;
  defaultCarrier?: Maybe<EasyPostCarrier>;
  defaultService?: Maybe<Scalars['String']>;
};

export enum SmartRatePercentile {
  Percentile_50 = 'percentile_50',
  Percentile_75 = 'percentile_75',
  Percentile_85 = 'percentile_85',
  Percentile_90 = 'percentile_90',
  Percentile_95 = 'percentile_95',
  Percentile_97 = 'percentile_97',
  Percentile_99 = 'percentile_99'
}

export type SmartRateTimeInTransitInfo = {
  __typename?: 'SmartRateTimeInTransitInfo';
  percentile_50?: Maybe<Scalars['Int']>;
  percentile_75?: Maybe<Scalars['Int']>;
  percentile_85?: Maybe<Scalars['Int']>;
  percentile_90?: Maybe<Scalars['Int']>;
  percentile_95?: Maybe<Scalars['Int']>;
  percentile_97?: Maybe<Scalars['Int']>;
  percentile_99?: Maybe<Scalars['Int']>;
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type Subscription = {
  __typename?: 'Subscription';
  createdOrderEvent?: Maybe<OrderEvent>;
  createdOrderEventV2?: Maybe<OrderEventV2>;
  reportUpdated?: Maybe<Report>;
  updatedManifest?: Maybe<Manifest>;
  updatedManifestV2?: Maybe<ManifestV2>;
};


export type SubscriptionCreatedOrderEventArgs = {
  orderId: Scalars['String'];
};


export type SubscriptionCreatedOrderEventV2Args = {
  integrationId: Scalars['ID'];
  orderId: Scalars['ID'];
};


export type SubscriptionReportUpdatedArgs = {
  tenantId: Scalars['ID'];
  id?: Maybe<Scalars['ID']>;
};

export type Tenant = {
  __typename?: 'Tenant';
  id: Scalars['ID'];
  adminUser: User;
  adminEmail: Scalars['String'];
  companyName: Scalars['String'];
  tenantType: TenantType;
};

export enum TenantType {
  Managed = 'Managed',
  Basic = 'Basic'
}

export type TplcIntegration = Integration & {
  __typename?: 'TplcIntegration';
  id: Scalars['ID'];
  type: IntegrationType;
  readable: Scalars['String'];
  description: Scalars['String'];
  orderChannels: Array<OrderChannel>;
  clientId: Scalars['String'];
  clientSecret: Scalars['String'];
  userLogin: Scalars['String'];
};

export enum TrackerStatus {
  Unknown = 'unknown',
  PreTransit = 'pre_transit',
  InTransit = 'in_transit',
  OutForDelivery = 'out_for_delivery',
  Delivered = 'delivered',
  AvailableForPickup = 'available_for_pickup',
  ReturnToSender = 'return_to_sender',
  Failure = 'failure',
  Cancelled = 'cancelled',
  Error = 'error'
}

export type Trigger = {
  __typename?: 'Trigger';
  field: TriggerField;
  operator: RuleOperator;
  value: Array<Scalars['String']>;
};

export enum TriggerField {
  RequestedService = 'REQUESTED_SERVICE',
  PackageVolume = 'PACKAGE_VOLUME',
  PackageWeight = 'PACKAGE_WEIGHT',
  TimeOfDay = 'TIME_OF_DAY',
  DayOfWeek = 'DAY_OF_WEEK'
}

export type TriggerFieldOption = {
  __typename?: 'TriggerFieldOption';
  value: TriggerField;
  label: Scalars['String'];
  operators: Array<RuleOperatorOption>;
  dataType: RuleDataType;
};

export type TriggerInput = {
  field: TriggerField;
  operator: RuleOperator;
  value: Array<Scalars['String']>;
};

export type TriggerValueOption = {
  __typename?: 'TriggerValueOption';
  value: Scalars['String'];
  label: Scalars['String'];
};

export type UpdateParcelInput = {
  orderId: Scalars['ID'];
  parcels: Array<ParcelV2Input>;
};

export type UpdateRuleInput = {
  id: Scalars['ID'];
  rule: RuleInput;
};

export type UpdateShipToInput = {
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  zip: Scalars['String'];
  country: Scalars['String'];
  state: Scalars['String'];
};

export type UpdateUserInput = {
  name: Scalars['String'];
  roles: Array<UserRole>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  email: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  roles: Array<UserRole>;
};

export enum UserRole {
  Admin = 'ADMIN',
  Shipper = 'SHIPPER'
}

export type Weight = {
  __typename?: 'Weight';
  value: Scalars['Float'];
  unit: SeWeightUnit;
};

export type WeightInput = {
  value: Scalars['Float'];
  unit: SeWeightUnit;
};
