import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import React, { useMemo } from 'react';
import { AddressV2, MakeOptional } from '../../types/__generated__/graphql';
import * as yup from 'yup';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { AddressesSection } from './sections/AddressesSection';
import { PackagesSection } from './sections/PackagesSection';
import { RateShopSection } from './sections/RateShopSection';
import { FormAutomator } from './FormAutomator';
import { RateShopV3Query } from '../../operations/__generated__/rate-shop-v3.gql.generated';
import { ShipFormVerticalSteps } from './elements/ShipFormVerticalSteps';
import { ShipFormHeader } from './elements/ShipFormHeader';

export interface ShipFormValues {
  shipped?: boolean;
  integrationId: string;
  orderChannelId: string;
  orderId?: string | null;
  requestedServiceType?: string | null;
  orderDate?: string | null;
  trackingNumber?: string | null;
  shipDate?: string | null;
  carrier?: string | null;
  service?: string | null;
  addresses: {
    shipFromAddressId: string;
    returnAddressId: string;
    shipToAddressId: string | null;
    shipToAddress: MakeOptional<AddressV2, 'verifications'> & { country: string };
    shipToAddressValidatedAttempted: boolean;
    shipToAddressValidated: boolean;
    allowInvalidAddress?: boolean;
  };
  packages: {
    key: string;
    length: number;
    width: number;
    height: number;
    weight: number;
    weightLocked: boolean;
    internalId?: string | null;
  }[];
  rateShop: {
    selectedRate: RateShopV3Query['rateShopV3']['rates'][number] | null;
  };
}

export const shipFormValidationSchema = yup
  .object()
  .shape({
    integrationId: yup.string().required(),
    orderChannelId: yup.string().required(),
    orderId: yup.string(),
    addresses: yup
      .object()
      .shape({
        shipFromAddressId: yup.string().required(),
        returnAddressId: yup.string().required(),
        shipToAddressId: yup.string().nullable(),
        shipToAddress: yup.object().shape(
          {
            company: yup.string().when('name', {
              is: (name: string) => !name || name.length === 0,
              then: yup.string().required('At least one of the fields is required'),
            }),
            name: yup.string().when('company', {
              is: (company: string) => !company || company.length === 0,
              then: yup.string().required('At least one of the fields is required'),
            }),
            street1: yup.string().required(),
            street2: yup.string(),
            city: yup.string().required(),
            state: yup.string().required(),
            zip: yup.string().required(),
            country: yup.string().required(),
            phone: yup.string(),
            email: yup.string(),
          },
          [['company', 'name']]
        ),
        allowInvalidAddress: yup.bool().nullable(),
        shipToAddressValidated: yup.bool().when('allowInvalidAddress', {
          is: (allowInvalidAddress: boolean) => allowInvalidAddress,
          then: yup.bool(),
          otherwise: yup.bool().oneOf([true]),
        }),
      })
      .required(),

    packages: yup
      .array()
      .of(
        yup.object().shape({
          length: yup.number().positive().required(),
          width: yup.number().positive().required(),
          height: yup.number().positive().required(),
          weight: yup.number().positive().required(),
          weightLocked: yup.boolean(),
        })
      )
      .min(1),

    rateShop: yup.object().shape({
      selectedRate: yup.object().required(),
    }),
  })
  .required();

export interface ShipFormProps {
  initialValues: ShipFormValues;
  onSubmit: (values: ShipFormValues, helpers: FormikHelpers<ShipFormValues>) => void;
}

export const ShipForm: React.FC<ShipFormProps> = ({ initialValues, onSubmit }) => {
  const { path, url } = useRouteMatch();

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={shipFormValidationSchema}>
      {({ values }) => (
        <Form className="bg-white w-full flex-1 flex flex-col min-h-0 overflow-hidden">
          {!values.shipped ? <FormAutomator /> : null}
          <ShipFormHeader />
          <div className="flex-1 flex min-h-0 overflow-hidden">
            <ShipFormVerticalSteps className="flex-shrink-0 max-w-md" style={{ width: '30%' }} />
            <div className="flex-1 flex flex-col overflow-hidden">
              <Switch>
                <Route path={`${path}/addresses`} component={AddressesSection} />
                <Route path={`${path}/packages`} component={PackagesSection} />
                <Route path={`${path}/rate-shop`} component={RateShopSection} />
                <Route>
                  <Redirect to={`${url}/addresses`} />
                </Route>
              </Switch>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export const useShipForm = () => useFormikContext<ShipFormValues>();

export const useFormValidator = () => {
  const { values } = useShipForm();

  const isAddressSectionValid = useMemo(
    () => yup.reach(shipFormValidationSchema, 'addresses').isValidSync(values.addresses),
    [values.addresses]
  );

  const isPackagesSectionValid = useMemo(
    () => yup.reach(shipFormValidationSchema, 'packages').isValidSync(values.packages),
    [values.packages]
  );

  const isRateShopSectionValid = useMemo(
    () => yup.reach(shipFormValidationSchema, 'rateShop').isValidSync(values.rateShop),
    [values.rateShop]
  );

  return {
    isAddressSectionValid,
    isPackagesSectionValid,
    isRateShopSectionValid,
  };
};

export const useMarkSectionAsTouched = () => {
  const form = useShipForm();

  return {
    markAddressesAsTouched: () => {
      form.setFieldTouched(`addresses.returnAddressId`, true);
      form.setFieldTouched(`addresses.shipFromAddressId`, true);
      form.setFieldTouched(`addresses.shipToAddress.company`, true);
      form.setFieldTouched(`addresses.shipToAddress.name`, true);
      form.setFieldTouched(`addresses.shipToAddress.street1`, true);
      form.setFieldTouched(`addresses.shipToAddress.street2`, true);
      form.setFieldTouched(`addresses.shipToAddress.city`, true);
      form.setFieldTouched(`addresses.shipToAddress.state`, true);
      form.setFieldTouched(`addresses.shipToAddress.zip`, true);
      form.setFieldTouched(`addresses.shipToAddress.country`, true);
      form.setFieldTouched(`addresses.shipToAddress.email`, true);
      form.setFieldTouched(`addresses.shipToAddress.phone`, true);
    },

    markPackagesAsTouched: () => {
      form.values.packages.forEach((_, index) => {
        form.setFieldTouched(`packages.${index}.length`, true);
        form.setFieldTouched(`packages.${index}.width`, true);
        form.setFieldTouched(`packages.${index}.height`, true);
        form.setFieldTouched(`packages.${index}.weight`, true);
      });
    },
  };
};
