import { FC } from 'react';
import { ScaleIcon } from '@heroicons/react/outline';
import { PrintNodeStatus, usePrintNode } from '../providers/PrintNodeProvider';

export const ScaleStatusIcon: FC = () => {
  const { scaleStatus } = usePrintNode();

  const text =
    scaleStatus === PrintNodeStatus.initializing
      ? 'Connecting to PrintNode...'
      : scaleStatus === PrintNodeStatus.ready
      ? 'Scale connected'
      : 'No scale connected';
  const color = scaleStatus === PrintNodeStatus.initializing ? 'yellow' : PrintNodeStatus.ready ? 'green' : 'red';

  return (
    <div className="inline-flex items-center justify-center relative h-8" title={text}>
      <svg
        className={`h-3 w-3 text-${color}-400 absolute -top-1 -right-1 ${
          scaleStatus === PrintNodeStatus.initializing ? 'animate-pulse' : ''
        }`}
        fill="currentColor"
        viewBox="0 0 8 8"
      >
        <circle cx={4} cy={4} r={3} />
      </svg>
      <ScaleIcon className={`h-6 text-gray-500`} />
    </div>
  );
};
