import { gql } from '@apollo/client';

export const ADDRESS_DETAILS_FRAGMENT = gql`
  fragment AddressDetails on AddressV2 {
    company
    name
    street1
    street2
    city
    state
    zip
    country
    email
    phone
  }
`;
