import React, { useCallback } from 'react';
import { Link, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { NewRule } from './NewRule';
import { RulesList } from '../components/ShippingRules/RulesList';
import { useListRulesQuery } from '../operations/list-rules.gql';
import { LoadingPanel } from '../components/LoadingPanel';
import { EmptyState } from '../components/EmptyState';
import { Button } from '../components/Button';
import { PlusIcon } from '@heroicons/react/outline';
import { OrderChannelSelect } from '../components/OrderChannelSelect';
import { SectionHeadingWithAction } from '../components/Layout/SectionHeadingWithAction';
import { useAllOrderChannelsQuery } from '../operations/all-order-channels.gql';
import { UpdateRule } from './UpdateRule';

export const ShippingRulesOrderChannelId: React.FC = () => {
  const {
    path,
    params: { integrationId, orderChannelId },
    url,
  } = useRouteMatch<{ integrationId: string; orderChannelId: string }>();

  const history = useHistory();

  const { data: orderChannelsData } = useAllOrderChannelsQuery();

  const handleOrderChannelSelect = useCallback(
    (value: { integrationId: string; orderChannelId: string }) => {
      const newUrl = path
        .replace(':orderChannelId', value.orderChannelId)
        .replace(':integrationId', value.integrationId);

      history.push(newUrl);
    },
    [history, path]
  );

  const { loading, data } = useListRulesQuery({ integrationId, orderChannelId });

  const isEmpty = Boolean(!loading && !data?.rules.items);

  return (
    <Switch>
      <Route path={`${path}/new`} component={NewRule} />
      <Route path={`${path}/:ruleId`} component={UpdateRule} />
      <Route path={path} exact>
        <>
          <SectionHeadingWithAction
            title="Shipping Rules"
            actions={
              <div className="flex space-x-2">
                <OrderChannelSelect
                  integrations={orderChannelsData?.integrations ?? []}
                  integrationId={integrationId}
                  orderChannelId={orderChannelId}
                  onChange={handleOrderChannelSelect}
                />
                {!isEmpty ? (
                  <Link to={`${url}/new`}>
                    <Button type="button" icon={PlusIcon}>
                      Rule
                    </Button>
                  </Link>
                ) : null}
              </div>
            }
          />
          <div className="flex-1 flex flex-col">
            {loading ? (
              <LoadingPanel />
            ) : data?.rules.count ? (
              <RulesList rules={data.rules.items} />
            ) : (
              <EmptyState
                className="flex-1"
                title="No rules yet"
                subtext="Get started by creating a rule"
                action={
                  <Link to={`${url}/new`}>
                    <Button type="button" icon={PlusIcon}>
                      Rule
                    </Button>
                  </Link>
                }
              />
            )}
          </div>
        </>
      </Route>
    </Switch>
  );
};
