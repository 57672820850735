import React, { ComponentType, FC } from 'react';
import classNames from 'classnames';
import { LoadingIndicator } from './LoadingIndicator';
import styles from './Button.module.scss';

export interface ButtonProps
  extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  fullWidth?: boolean;
  icon?: ComponentType<any>;
  loading?: boolean;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  kind?: 'primary' | 'secondary' | 'white' | 'destructive';
}

const getSizeClass = (size: ButtonProps['size']) => {
  switch (size) {
    case 'xs':
      return styles['button--sm'];
    case 'sm':
      return styles['button--sm'];
    case 'md':
      return styles['button--md'];
    case 'lg':
      return styles['button--lg'];
    case 'xl':
      return styles['button--xl'];
  }
};

const getKindClass = (kind: ButtonProps['kind']) => {
  switch (kind) {
    case 'primary':
      return styles['button--primary'];
    case 'secondary':
      return styles['button--secondary'];
    case 'white':
      return styles['button--white'];
    case 'destructive':
      return styles['button--destructive'];
  }
};

export const Button: FC<ButtonProps> = ({
  children,
  className,
  disabled,
  fullWidth,
  icon: Icon,
  loading,
  kind = 'primary',
  size = 'md',
  ...buttonProps
}) => {
  return (
    <>
      <button
        className={classNames(
          styles.button,
          getSizeClass(size),
          getKindClass(kind),
          fullWidth ? styles['button--full-w'] : null,
          className
        )}
        {...buttonProps}
        disabled={disabled || loading}
      >
        {loading ? (
          // <span className="absolute left-0 inset-y-0 flex items-center pl-3">
          <LoadingIndicator
            className={classNames(styles['button-icon'], styles['button-icon--leading'])}
            aria-hidden="true"
          />
        ) : // </span>
        Icon ? (
          // <span className="absolute left-0 inset-y-0 flex items-center pl-3">
          <Icon className={classNames(styles['button-icon'], styles['button-icon--leading'])} aria-hidden="true" />
        ) : // </span>
        null}
        {children}
      </button>
    </>
  );
};
