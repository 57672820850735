import React from 'react';
import { SectionHeadingWithAction } from '../components/Layout/SectionHeadingWithAction';
import { UserForm, UserFormValues } from '../components/UserManagement/UserForm';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useCreateUserMutation } from '../operations/create-user.gql';
import { useErrorModal } from '../utilities/use-error-modal';
import { ErrorModal } from '../components/ErrorModal';

export const NewUser: React.FC = () => {
  const { path } = useRouteMatch();
  const history = useHistory();

  const [createUser, { loading, error }] = useCreateUserMutation();

  const { showErrorModal, handleErrorModalClose } = useErrorModal(error?.message);

  const handleSubmit = async (values: UserFormValues) => {
    try {
      await createUser({
        variables: {
          email: values.email,
          name: values.name || null,
          roles: values.roles,
        },
        refetchQueries: ['AllUsers'],
      });
      history.push('/settings/user-management');
    } catch (err) {
      // TODO: log to sentry or something
    }
  };

  return (
    <div className="flex-1 flex flex-col h-full">
      <main className="flex-1 overflow-y-auto focus:outline-none">
        <div className="relative max-w-xl mx-auto px-6 md:p-6 2xl:p-0 h-full">
          <div className="pt-10 pb-16 h-full">
            <SectionHeadingWithAction
              title="New User"
              breadcrumbs={[
                { path: `/settings/user-management`, label: 'User Management' },
                { path: `${path}`, label: 'New User' },
              ]}
            />
            <UserForm
              initialValues={{
                email: '',
                name: '',
                roles: [],
              }}
              emailDisabled={false}
              submitText="Create User"
              onSubmit={handleSubmit}
              loading={loading}
              error={error?.message}
            />
          </div>
        </div>
      </main>
      <ErrorModal isOpen={showErrorModal} onClose={handleErrorModalClose} message={error?.message} />
    </div>
  );
};
