import React from 'react';

export interface RuleSectionHeadingProps {
  title: string;
  actions: React.ReactNode;
}

export const RuleSectionHeading: React.FC<RuleSectionHeadingProps> = ({ title, actions }) => {
  return (
    <div className="mb-4 flex justify-between items-center">
      <h2 className="font-medium">{title}</h2>
      {actions}
    </div>
  );
};
