import React from 'react';
import { SectionHeadingWithAction } from '../components/Layout/SectionHeadingWithAction';
import { Breadcrumb } from '../components/Breadcrumbs';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { AddressBookAddressForm, AddressValues } from '../components/AddressBook/AddressBookAddressForm';
import { useCreateAddressBookAddressMutation } from '../operations/create-address-book-address.gql';

export const AddressBookNew: React.FC = () => {
  const { url: backUrl } = useRouteMatch('/settings/address-book') ?? { url: '#' };
  const history = useHistory();

  const [createAddress, { loading }] = useCreateAddressBookAddressMutation();

  const breadcrumbs: Breadcrumb[] = [
    { path: backUrl, label: 'Address Book' },
    { path: '#', label: 'New Address' },
  ];

  const handleSubmit = async (values: AddressValues) => {
    try {
      await createAddress({
        variables: {
          input: values,
        },
      });

      history.push(backUrl);
    } catch (err) {
      // TODO: capture in sentry
    }
  };

  const handleCancel = () => history.push(backUrl);

  return (
    <>
      <SectionHeadingWithAction title="New Address" breadcrumbs={breadcrumbs} />
      <AddressBookAddressForm
        initialValues={{
          addressName: '',
          city: '',
          name: '',
          company: '',
          country: '',
          state: '',
          zip: '',
          street1: '',
          street2: '',
          email: '',
          phone: '',
        }}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        saving={loading}
      />
    </>
  );
};
