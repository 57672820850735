import { FC, useState } from 'react';
import { Button } from '../Button';
import { useRefundOrderMutation } from '../../operations/refund-order.gql';
import { useOrder } from '../../providers/Order/OrderProvider';
import { usePrintNode } from '../../providers/PrintNodeProvider';
import { Modal } from '../Modal';
import { CheckIcon, ExclamationIcon } from '@heroicons/react/solid';
import mixpanel from 'mixpanel-browser';
import { usePrintNodeApi } from '../../utilities/print-node';

export interface FulfillHeadingProps {
  orderId: string;
  shipped: boolean;
  labelUrls: string[];
}

export const FulfillHeading: FC<FulfillHeadingProps> = ({ labelUrls, orderId, shipped }) => {
  const { order } = useOrder();
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [refundOrder, { loading, error }] = useRefundOrderMutation();
  const { selectedPrinter } = usePrintNode();
  const [reprintLoading, setReprintLoading] = useState(false);
  const { printNodeApi } = usePrintNodeApi();

  const handleRefund = async () => {
    try {
      mixpanel.track('Void Label Start');
      await refundOrder({ variables: { orderId: order!.id } });
      mixpanel.track('Void Label Success');
      setShowSuccessModal(true);
    } catch (err) {
      mixpanel.track('Void Label Error', { reason: err.message ?? 'Unknown' });
      setShowErrorModal(true);
    }
  };

  const reprintLabels = async () => {
    mixpanel.track('Reprint Label Start');
    if (!selectedPrinter) {
      mixpanel.track('Reprint Label Failed', { Reason: 'No printer selected.' });
      return alert('Please select a printer.');
    }

    setReprintLoading(true);
    for (const labelUrl of labelUrls) {
      const payload = {
        printerId: selectedPrinter,
        contentType: 'raw_uri',
        content: labelUrl,
      };
      try {
        await printNodeApi?.createPrintJob(payload);
      } catch (err) {
        mixpanel.track('Reprint Label Failed', { Reason: 'createPrintJob returned an error.' });
      }
    }
    mixpanel.track('Reprint Label Finished');
    setReprintLoading(false);
  };

  return (
    <div className="py-6">
      <div className="md:flex md:items-center md:justify-between max-w-6xl mx-auto">
        <div className="flex-1 min-w-0">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
            Order #{orderId} {shipped ? <span className="text-gray-400">(Shipped)</span> : null}
          </h2>
        </div>
        {shipped ? (
          <div className="mt-4 flex md:mt-0 md:ml-4 space-x-4">
            <Button type="button" disabled={labelUrls.length === 0} onClick={reprintLabels} loading={reprintLoading}>
              Reprint Label{labelUrls.length > 1 ? 's' : ''}
            </Button>
            <Button type="button" kind="white" onClick={handleRefund} loading={loading}>
              Void Label{labelUrls.length > 1 ? 's' : ''}
            </Button>
          </div>
        ) : null}
      </div>
      <Modal
        header="Unable to refund shipment"
        Icon={ExclamationIcon}
        kind="error"
        isOpen={showErrorModal}
        message={error?.message ?? ''}
        onClose={() => setShowErrorModal(false)}
      />

      <Modal
        header="Label Voided"
        Icon={CheckIcon}
        kind="success"
        isOpen={showSuccessModal}
        message="Please properly discard the old label."
        onClose={() => setShowSuccessModal(false)}
      />
    </div>
  );
};
