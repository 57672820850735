import React from 'react';
import { ShipForm, ShipFormValues } from '../components/Ship/ShipForm';
import { FormikHelpers } from 'formik';
import { nanoid } from 'nanoid';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { ORDER_V2_QUERY, useOrderV2Query } from '../operations/order-v2.gql';
import { usePurchaseLabelV2Mutation } from '../operations/purchase-label-v2.gql';
import { usePrintNode } from '../providers/PrintNodeProvider';
import mixpanel from 'mixpanel-browser';
import { OrderV2Query, OrderV2QueryVariables } from '../operations/__generated__/order-v2.gql.generated';
import { ErrorModal } from '../components/ErrorModal';
import { useErrorModal } from '../utilities/use-error-modal';
import { usePrintNodeApi } from '../utilities/print-node';

export const ShipOrder: React.FC = () => {
  const {
    params: { integrationId, orderId },
  } = useRouteMatch<{ integrationId: string; orderId: string }>();
  const history = useHistory();

  const { data: orderData, loading: orderLoading } = useOrderV2Query({ integrationId, orderId });
  const [purchaseLabel, { data: purchaseLabelData, error }] = usePurchaseLabelV2Mutation();
  const { showErrorModal, handleErrorModalClose } = useErrorModal(error?.message);
  const { printNodeApi } = usePrintNodeApi();
  const { selectedPrinter } = usePrintNode();

  const carrier = purchaseLabelData?.purchaseLabelV2.carrier ?? orderData?.orderV2?.carrier;
  const service = purchaseLabelData?.purchaseLabelV2.service ?? orderData?.orderV2?.service;

  const onSubmit = async (values: ShipFormValues, helpers: FormikHelpers<ShipFormValues>) => {
    helpers.setSubmitting(true);
    try {
      const result = await purchaseLabel({
        variables: {
          input: {
            integrationId,
            orderChannelId: orderData?.orderV2?.orderChannelId,
            orderId: orderData?.orderV2?.orderId,
            salesOrderNumber: orderData?.orderV2?.salesOrderNumber,
            printerId: selectedPrinter?.toString(),
            requestedServiceType: orderData?.orderV2?.requestedServiceType,
            ...(values.packages.length > 1
              ? {
                  epOrderId: values.rateShop.selectedRate!.epOrderId,
                  carrier: values.rateShop.selectedRate!.carrier,
                  service: values.rateShop.selectedRate!.service,
                }
              : {
                  shipmentId: values.rateShop.selectedRate!.shipmentId,
                  selectedRateId: values.rateShop.selectedRate!.id,
                }),
          },
        },
        update: (cache, { data }) => {
          if (!data) {
            return;
          }

          const query = cache.readQuery<OrderV2Query, OrderV2QueryVariables>({
            query: ORDER_V2_QUERY,
            variables: { orderId, integrationId },
          });

          if (!query?.orderV2) {
            return;
          }

          if (query?.orderV2) {
            cache.writeQuery<OrderV2Query, OrderV2QueryVariables>({
              query: ORDER_V2_QUERY,
              variables: { orderId, integrationId },
              data: {
                orderV2: {
                  ...query.orderV2,
                  shipped: true,
                  carrier: data.purchaseLabelV2.carrier,
                  service: data.purchaseLabelV2.service,
                },
              },
            });
          }
        },
      });

      history.push('/ship');

      if (!result.data) {
        console.warn('Mutation returned no data.');
        // TODO: Capture in Sentry
        return;
      }

      if (result.data.purchaseLabelV2.printed) {
        console.log('Label printed via API.');
        return;
      }

      if (!selectedPrinter) {
        console.warn('No printer was selected.');
        // TODO: Capture in Sentry
        return;
      }

      for (const label of result.data.purchaseLabelV2.labels) {
        const payload = {
          printerId: selectedPrinter,
          contentType: 'raw_uri',
          content: label.labelFileUrl,
        };
        console.log('Printing label');
        try {
          await printNodeApi?.createPrintJob(payload);
          mixpanel.track('Print Request Successful');
        } catch {
          mixpanel.track('Print Request Failed');
        }
      }
    } catch (err) {
      // TODO: capture in Sentry
      helpers.setSubmitting(false);
    }
  };

  const initialValues: ShipFormValues | null = orderData?.orderV2
    ? {
        shipped: orderData.orderV2.shipped,
        integrationId,
        orderChannelId: orderData.orderV2.orderChannelId,
        orderId: orderData.orderV2.orderId,
        requestedServiceType: orderData.orderV2.requestedServiceType,
        orderDate: orderData.orderV2.orderDate,
        carrier,
        service,
        addresses: {
          returnAddressId: orderData.orderV2.returnAddressId ?? '',
          shipFromAddressId: orderData.orderV2.shipFromAddressId ?? '',
          shipToAddressId: orderData.orderV2.shipToAddressId ?? null,
          shipToAddress: {
            company: orderData.orderV2.shipToAddress?.company ?? '',
            name: orderData.orderV2.shipToAddress?.name ?? '',
            street1: orderData.orderV2.shipToAddress?.street1 ?? '',
            street2: orderData.orderV2.shipToAddress?.street2 ?? '',
            city: orderData.orderV2.shipToAddress?.city ?? '',
            state: orderData.orderV2.shipToAddress?.state ?? '',
            zip: orderData.orderV2.shipToAddress?.zip ?? '',
            country: orderData.orderV2.shipToAddress?.country ?? 'US',
            email: orderData.orderV2.shipToAddress?.email ?? '',
            phone: orderData.orderV2.shipToAddress?.phone ?? '',
            verifications: orderData.orderV2.shipToAddress?.verifications,
          },
          shipToAddressValidatedAttempted: true,
          shipToAddressValidated: Boolean(orderData.orderV2.shipToAddress?.verifications.delivery?.success),
        },
        packages: orderData.orderV2.parcels.length
          ? orderData.orderV2.parcels.map((parcel) => ({
              key: nanoid(),
              length: parcel.length ?? 0,
              width: parcel.width ?? 0,
              height: parcel.height ?? 0,
              weight: 0,
              weightLocked: false,
              internalId: parcel.internalId,
            }))
          : [{ key: nanoid(), length: 0, width: 0, height: 0, weight: 0, weightLocked: false }],
        rateShop: {
          selectedRate: null,
        },
      }
    : null;

  return orderLoading ? null : initialValues ? (
    <>
      <ShipForm
        key={initialValues.shipped ? 'shipped' : 'shipping'}
        initialValues={initialValues}
        onSubmit={onSubmit}
      />
      <ErrorModal isOpen={showErrorModal} onClose={handleErrorModalClose} message={error?.message} />
    </>
  ) : null;
};
