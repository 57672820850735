import { gql, useMutation } from '@apollo/client';
import { ALL_RULE_DETAILS_FRAGMENT } from './fragments/all-rule-details.gql';
import { CreateRuleMutation, CreateRuleMutationVariables } from './__generated__/create-rule.gql.generated';

export const CREATE_RULE_MUTATION = gql`
  mutation CreateRule($input: CreateRuleInput!) {
    createRule(input: $input) {
      ...AllRuleDetails
    }
  }

  ${ALL_RULE_DETAILS_FRAGMENT}
`;

export const useCreateRuleMutation = () =>
  useMutation<CreateRuleMutation, CreateRuleMutationVariables>(CREATE_RULE_MUTATION, {
    refetchQueries: ['ListRules'],
  });
