import { ApolloClient, from, InMemoryCache } from '@apollo/client';
import { Auth, Logger } from 'aws-amplify';
import { AUTH_TYPE, createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import { onError } from '@apollo/client/link/error';
import { stage } from './stage';
import { AuthOptions } from 'aws-appsync-auth-link/lib/auth-link';

const logger = new Logger('graphql');

const errorLink = onError(({ graphQLErrors, response }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((error: any) => logger.info('GraphQL Error:', error.errorType, ':', error.message));

    if (response) {
      response.errors = graphQLErrors.map(({ message, ...error }: any) => {
        if (error.errorType === 'Unauthorized') {
          return { message: 'You are not authorized to access this resource.', ...error };
        }
        return { message, ...error };
      });
    }
  }
});

interface AppSyncConfig {
  url: string;
  region: string;
  auth: AuthOptions;
}

const config: AppSyncConfig = {
  url: stage.awsConfig.appsyncEndpoint,
  region: stage.awsConfig.appsyncRegion,
  auth: {
    type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
    jwtToken: async () => {
      const session = await Auth.currentSession();
      return session.getIdToken().getJwtToken();
    },
  },
};

export const appSyncClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([errorLink, createAuthLink(config), createSubscriptionHandshakeLink(config)]),
});
