import React, { FC } from 'react';
import { useField } from 'formik';
import { InputGroup, InputProps } from '../InputGroup';

export interface FormInputProps extends Omit<InputProps, 'error'> {
  name: string;
}

export const FormInput: FC<FormInputProps> = ({ className, label, ref, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <InputGroup
      className={className}
      label={label}
      id={props.name}
      error={meta.touched ? meta.error : null}
      {...props}
      {...field}
    />
  );
};
