import React, { useEffect } from 'react';
import { FormSelect } from '../../Form/FormSelect';
import { DeleteButton } from '../atoms/DeleteButton';
import { RuleDataType, TriggerFieldOption, RuleOperator } from '../../../types/__generated__/graphql';
import { useField } from 'formik';
import { StringField } from './StringField';
import { InputGroup } from '../../InputGroup';
import { NumberField } from './NumberField';
import { useTriggerValueOptionsLazyQuery } from '../../../operations/trigger-value-options.gql';
import { BooleanField } from './BooleanField';
import { TimeField } from './TimeField';
import { useUpdateEffect } from 'react-use';

interface TriggerRowProps {
  triggerIndex: number;
  onDelete: () => void;
  showAnd?: boolean;
  fieldOptions: TriggerFieldOption[];
  disabled?: boolean;
}

export const RuleTriggerRow: React.FC<TriggerRowProps> = ({
  disabled,
  triggerIndex,
  fieldOptions,
  onDelete,
  showAnd,
}) => {
  const [field] = useField(`triggers[${triggerIndex}].field`);
  const [operator, , operatorHelpers] = useField(`triggers[${triggerIndex}].operator`);
  const [, , valueHelpers] = useField(`triggers[${triggerIndex}].value`);
  const [
    valueOptionsQuery,
    { data: valueOptionsData, loading: valueOptionsLoading },
  ] = useTriggerValueOptionsLazyQuery();

  const operatorsDisabled = Boolean(!field.value);
  const valuesDisabled = Boolean(!operator.value);

  const selectedField = fieldOptions.find((fieldOption) => fieldOption.value === field.value);
  const selectedOperator: RuleOperator | null = (operator.value as RuleOperator) ?? null;
  const operatorOptions = selectedField?.operators ?? [];
  const dataType = selectedField?.dataType ?? null;
  const isMulti = [RuleOperator.In, RuleOperator.Nin].includes(selectedOperator);
  const isStringField = [RuleDataType.String, RuleDataType.Enum].includes(dataType as any);
  const isEnum = dataType === RuleDataType.Enum;
  const valueFieldName = `triggers[${triggerIndex}].value`;
  const valueOptions = isEnum
    ? !valueOptionsLoading && valueOptionsData
      ? valueOptionsData.triggerValueOptions
      : []
    : null;

  useUpdateEffect(() => {
    valueHelpers.setValue(['']);
    valueHelpers.setTouched(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOperator, isMulti]);

  const handleFieldChanged = () => {
    operatorHelpers.setValue('');
    operatorHelpers.setTouched(false);

    valueHelpers.setValue(['']);
    valueHelpers.setTouched(false);
  };

  const handleOperatorChanged = () => {
    valueHelpers.setValue(['']);
    valueHelpers.setTouched(false);
  };

  useEffect(() => {
    if (isEnum && selectedField) {
      valueOptionsQuery({ variables: { triggerField: selectedField.value } });
    }
  }, [isEnum, selectedField, valueOptionsQuery]);

  return (
    <>
      <span className="text-sm text-gray-500 capitalize mt-5 leading-9 text-right w-8">{showAnd ? 'AND' : 'IF'}</span>
      <FormSelect
        label="Field"
        name={`triggers[${triggerIndex}].field`}
        hideErrorMessage
        onChange={handleFieldChanged}
        disabled={disabled}
      >
        <option value="">Select a field</option>
        {fieldOptions.map((fieldOption) => (
          <option key={fieldOption.value} value={fieldOption.value}>
            {fieldOption.label}
          </option>
        ))}
      </FormSelect>
      <FormSelect
        label="Operator"
        name={`triggers[${triggerIndex}].operator`}
        hideErrorMessage
        disabled={disabled || operatorsDisabled}
        onChange={handleOperatorChanged}
      >
        <option value="">Select an option</option>
        {operatorOptions.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </FormSelect>
      {selectedField && selectedOperator ? (
        isStringField ? (
          <StringField disabled={disabled} label="Value" multi={isMulti} name={valueFieldName} options={valueOptions} />
        ) : dataType === RuleDataType.Boolean ? (
          <BooleanField disabled={disabled} label="Value" name={valueFieldName} />
        ) : dataType === RuleDataType.Number ? (
          <NumberField disabled={disabled} label="Value" name={valueFieldName} />
        ) : dataType === RuleDataType.Time ? (
          <TimeField disabled={disabled} label="Value" name={valueFieldName} hideErrorMessage />
        ) : null
      ) : (
        <InputGroup label="Value" type="text" hideErrorMessage disabled={disabled || valuesDisabled} />
      )}

      <DeleteButton onClick={onDelete} disabled={disabled} className="mt-5" />
    </>
  );
};
