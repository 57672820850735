import { useEffect, useState } from 'react';

export const useErrorModal = (errorMessage: string | undefined) => {
  const [showErrorModal, setShowErrorModal] = useState(false);

  useEffect(() => {
    if (errorMessage) {
      setShowErrorModal(true);
    }
  }, [errorMessage]);

  const handleErrorModalClose = () => setShowErrorModal(false);

  return { showErrorModal, handleErrorModalClose };
};
