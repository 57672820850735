import { FC } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { RouteProps } from 'react-router';
import { useUser } from '../providers/UserContext';

export const PrivateRoute: FC<RouteProps> = ({ children, ...rest }) => {
  const { user } = useUser();
  return (
    <Route
      {...rest}
      render={({ location }) => (user ? children : <Redirect to={{ pathname: '/login', state: { from: location } }} />)}
    />
  );
};
