import { gql, useMutation } from '@apollo/client';
import {
  SaveEnabledCarriersMutation,
  SaveEnabledCarriersMutationVariables,
} from './__generated__/save-enabled-carriers.gql.generated';

export const SAVE_ENABLED_CARRIERS_MUTATION = gql`
  mutation SaveEnabledCarriers(
    $integrationId: ID!
    $orderChannelId: ID!
    $carrierAccounts: [EnabledCarrierAccountInput!]!
  ) {
    setEnabledCarrierAccountIds(
      input: { carrierAccounts: $carrierAccounts, integrationId: $integrationId, orderChannelId: $orderChannelId }
    ) {
      carrierAccountIds
    }
  }
`;

export const useSaveEnabledCarriersMutation = () =>
  useMutation<SaveEnabledCarriersMutation, SaveEnabledCarriersMutationVariables>(SAVE_ENABLED_CARRIERS_MUTATION);
