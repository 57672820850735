import React, { useEffect } from 'react';
import { FormSelect } from '../../Form/FormSelect';
import { DeleteButton } from '../atoms/DeleteButton';
import { ModifierField, ModifierFieldOption } from '../../../types/__generated__/graphql';
import { useField } from 'formik';
import { useModifierValueOptionsLazyQuery } from '../../../operations/modifier-value-options.gql';

export interface RuleModifierRowProps {
  loading?: boolean;
  modifierOptions: ModifierFieldOption[];
  modifierIndex: number;
  onDelete: () => void;
  disabled?: boolean;
}

export const RuleModifierRow: React.FC<RuleModifierRowProps> = ({
  loading,
  modifierIndex,
  modifierOptions,
  onDelete,
  disabled,
}) => {
  const modifierName = `modifiers[${modifierIndex}].modifier`;
  const valueName = `modifiers[${modifierIndex}].value`;
  const [modifier] = useField(modifierName);
  const [, , valueHelpers] = useField(valueName);
  const [
    valueOptionsQuery,
    { data: valueOptionsData, loading: valueOptionsLoading },
  ] = useModifierValueOptionsLazyQuery();

  const modifierOptionsWithPlaceholder = [
    { value: '', label: 'Select an option...', disabled: true },
    ...modifierOptions,
  ];

  useEffect(() => {
    if (modifier.value) {
      valueOptionsQuery({ variables: { field: modifier.value as ModifierField } });
    }
  }, [modifier.value, valueOptionsQuery]);

  const handleModifierChange = () => {
    valueHelpers.setValue('');
    valueHelpers.setTouched(false);
  };

  const valueOptions = valueOptionsData?.modifierValueOptions ?? [];
  const valueOptionsWithPlaceholder = [{ value: '', label: 'Select an option...', disabled: true }, ...valueOptions];

  return (
    <>
      <FormSelect
        label="Modifier"
        name={modifierName}
        hideErrorMessage
        options={modifierOptionsWithPlaceholder}
        disabled={loading || disabled}
        onChange={handleModifierChange}
      />
      <FormSelect
        label="Value"
        name={valueName}
        options={valueOptionsWithPlaceholder}
        disabled={valueOptionsLoading || disabled}
      />
      <DeleteButton onClick={onDelete} disabled={disabled} className="mt-5" />
    </>
  );
};
