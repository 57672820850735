import React, { ComponentType, FC, useEffect, useState } from 'react';
import {
  ClipboardCheckIcon,
  CogIcon,
  DocumentIcon,
  DocumentReportIcon,
  PresentationChartLineIcon,
  PrinterIcon,
} from '@heroicons/react/outline';
import { MobileMenu } from '../components/Layout/MobileMenu';
import { NarrowSidebar } from '../components/Layout/NarrowSidebar';
import { Header } from '../components/Layout/Header';
import { Redirect, Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { Fulfill } from './Fulfill';
import { Settings } from './Settings';
import { Manifest } from './Manifest';
import { CreateManifest } from './CreateManifest';
import { Dashboard } from './Dashboard';
import { useFeatureFlag } from '../providers/FeatureFlagProvider';
import { Ship } from './Ship';
import { OrderV2 } from './OrderV2';
import { CreateManifestV2 } from './CreateManifestV2';
import { ManifestV2 } from './ManifestV2';
import { QualityControl } from './QualityControl';
import { QualityControlOrder } from './QualityControlOrder';
import { Reports } from './Reports';
import { useUser } from '../providers/UserContext';
import { ShipAdHoc } from './ShipAdHoc';

export interface NavigationItem {
  name: string;
  href: string;
  icon: ComponentType<any>;
}

export const Home: FC = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { pathname } = useLocation();
  const history = useHistory();
  const { groups } = useUser();
  const { path } = useRouteMatch();
  const isAdmin = groups.includes('admin');

  const shipV2Flag = useFeatureFlag('ShipV2');
  const dashboardFlag = useFeatureFlag('Dashboard');
  const qualityControlFlag = useFeatureFlag('QualityControl');
  const reportingFlag = useFeatureFlag('Reporting');

  const sidebarNavigation: NavigationItem[] = [
    ...(dashboardFlag ? [{ name: 'Dashboard', href: '/dashboard', icon: PresentationChartLineIcon }] : []),
    shipV2Flag
      ? { name: 'Ship', href: '/ship', icon: PrinterIcon }
      : { name: 'Ship', href: '/fulfill', icon: PrinterIcon },
    ...(qualityControlFlag ? [{ name: 'Quality Control', href: '/quality-control', icon: ClipboardCheckIcon }] : []),
    { name: 'Manifest', href: '/manifest', icon: DocumentIcon },
    ...(reportingFlag && isAdmin ? [{ name: 'Reports', href: '/reports', icon: DocumentReportIcon }] : []),
    { name: 'Settings', href: '/settings', icon: CogIcon },
  ];

  useEffect(() => {
    if (shipV2Flag && pathname.includes('fulfill')) {
      history.replace(`${path}ship`);
    }
  }, [history, path, pathname, shipV2Flag]);

  return (
    <div className="h-screen bg-gray-100 flex overflow-hidden">
      {/* Narrow sidebar */}
      <NarrowSidebar navigationItems={sidebarNavigation} />
      <MobileMenu
        isOpen={mobileMenuOpen}
        navigationItems={sidebarNavigation}
        onClose={() => setMobileMenuOpen(false)}
      />
      {/* Content area */}
      <div className="flex-1 flex flex-col overflow-hidden">
        <Header onMobileMenuOpen={() => setMobileMenuOpen(true)} />
        <Switch>
          {shipV2Flag ? (
            [
              <Route key="custom" path={`${path}ship/custom`} component={ShipAdHoc} />,
              <Route key="ship" path={`${path}ship`} component={Ship} />,
              <Route key="order" path={`${path}order/:integrationId/:orderId`} component={OrderV2} />,
            ]
          ) : (
            <Route path={`${path}fulfill`} component={Fulfill} />
          )}
          {qualityControlFlag
            ? [
                <Route
                  key="qco"
                  path={`${path}quality-control/:integrationId/:orderId`}
                  component={QualityControlOrder}
                />,
                <Route key="qc" path={`${path}quality-control`} component={QualityControl} />,
              ]
            : null}
          <Route path={`${path}manifest/create`}>{shipV2Flag ? <CreateManifestV2 /> : <CreateManifest />}</Route>
          <Route path={`${path}manifest`}>{shipV2Flag ? <ManifestV2 /> : <Manifest />}</Route>
          <Route path={`${path}settings`}>
            <Settings />
          </Route>
          <Route path={`${path}dashboard`}>
            <Dashboard />
          </Route>
          {reportingFlag && isAdmin ? <Route path={`${path}reports`} component={Reports} /> : null}
          <Route path={path} exact>
            {shipV2Flag ? <Redirect to={`${path}ship`} /> : <Redirect to={`${path}fulfill`} />}
          </Route>
        </Switch>
      </div>
    </div>
  );
};
