import React from 'react';
import { Field } from 'formik';
import { LockOpenIcon, LockClosedIcon } from '@heroicons/react/solid';
import { DeleteButton } from '../../ShippingRules/atoms/DeleteButton';
import { InputGroup } from '../../InputGroup';
import { useShipForm } from '../ShipForm';
import classNames from 'classnames';

export interface PackageRowProps {
  index: number;
  onDelete?: () => void;
  disabled?: boolean;
}

export const PackageRow: React.FC<PackageRowProps> = ({ index, disabled, onDelete }) => {
  const { values, getFieldMeta, setFieldValue } = useShipForm();

  // const { data: packagingOptionsData, loading: packagingOptionsLoading } = useModifierValueOptionsQuery(
  //   ModifierField.SetPackageType
  // );
  //
  // const packagingOptions = packagingOptionsData?.modifierValueOptions ?? [];

  const weightLocked = values.packages[index].weightLocked;

  const handleWeightLockToggle = () => {
    setFieldValue(`packages.${index}.weightLocked`, !weightLocked);
  };

  return (
    <>
      <span className="col-span-1">{index + 1}</span>

      {/*<FormSelect name={`packages.${index}.predefinedPackaging`} disabled={packagingOptionsLoading}>*/}
      {/*  <option value="">Custom</option>*/}
      {/*  {packagingOptions.map((option) => (*/}
      {/*    <option key={option.value} value={option.value}>*/}
      {/*      {option.label}*/}
      {/*    </option>*/}
      {/*  ))}*/}
      {/*</FormSelect>*/}

      <div className="rounded-md shadow-sm -space-y-px col-span-3">
        <div className="flex -space-x-px">
          <div className="w-1/3 flex-1 min-w-0 relative">
            <label htmlFor={`packages.${index}.length`} className="sr-only">
              Length
            </label>
            <Field
              type="number"
              name={`packages.${index}.length`}
              id={`packages.${index}.length`}
              as={InputGroup}
              controlClassName="rounded-none rounded-tl-md rounded-bl-md"
              style={{ paddingLeft: '1.75rem' }}
              hideErrorMessage
              error={getFieldMeta(`packages.${index}.length`).touched && getFieldMeta(`packages.${index}.length`).error}
              disabled={disabled}
            />
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <span className="text-gray-500 sm:text-sm">L</span>
            </div>
          </div>
          <div className="flex-1 min-w-0 relative">
            <label htmlFor={`packages.${index}.width`} className="sr-only">
              Width
            </label>
            <Field
              type="number"
              name={`packages.${index}.width`}
              id={`packages.${index}.width`}
              as={InputGroup}
              controlClassName="rounded-none"
              style={{ paddingLeft: '1.75rem' }}
              hideErrorMessage
              error={getFieldMeta(`packages.${index}.width`).touched && getFieldMeta(`packages.${index}.width`).error}
              disabled={disabled}
            />
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <span className="text-gray-500 sm:text-sm">W</span>
            </div>
          </div>
          <div className="flex-1 min-w-0 relative">
            <label htmlFor={`packages.${index}.height`} className="sr-only">
              Height
            </label>
            <Field
              type="number"
              name={`packages.${index}.height`}
              id={`packages.${index}.height`}
              as={InputGroup}
              controlClassName="rounded-none rounded-tr-md rounded-br-md"
              style={{ paddingLeft: '1.75rem' }}
              hideErrorMessage
              error={getFieldMeta(`packages.${index}.height`).touched && getFieldMeta(`packages.${index}.height`).error}
              disabled={disabled}
            />
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <span className="text-gray-500 sm:text-sm">H</span>
            </div>
          </div>
        </div>
      </div>

      <div className="relative rounded-md shadow-sm col-span-1">
        <label htmlFor={`packages.${index}.weight`} className="sr-only">
          Weight
        </label>
        <Field
          type="number"
          name={`packages.${index}.weight`}
          id={`packages.${index}.weight`}
          as={InputGroup}
          controlClassName="rounded-md"
          step={0.01}
          placeholder="0.00"
          hideErrorMessage
          error={getFieldMeta(`packages.${index}.weight`).touched && getFieldMeta(`packages.${index}.weight`).error}
          disabled={disabled || weightLocked}
        />
        <div className="absolute inset-y-0 right-0 flex items-center hidden 2xl:flex">
          <button
            type="button"
            className={classNames(
              '-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border text-sm font-medium rounded-r-md text-gray-700 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500',
              {
                'bg-gray-50 hover:bg-gray-100 border-gray-300': !weightLocked,
                'bg-blue-500 border-blue-500 hover:bg-blue-400': weightLocked,
              }
            )}
            onClick={handleWeightLockToggle}
            disabled={disabled}
          >
            {weightLocked ? (
              <LockClosedIcon className="h-5 w-5 text-white" aria-hidden="true" />
            ) : (
              <LockOpenIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            )}
          </button>
        </div>
      </div>

      <DeleteButton type="button" onClick={onDelete} />
    </>
  );
};
