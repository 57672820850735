import React, { FC } from 'react';
import classNames from 'classnames';

export const CardContent: FC<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>> = ({
  children,
  className,
  ...divProps
}) => (
  <div className={classNames('px-4 py-5 sm:p-6', className)} {...divProps}>
    {children}
  </div>
);
