import React, { useState } from 'react';
import styles from './TagInput.module.scss';
import classNames from 'classnames';
import { XIcon } from '@heroicons/react/outline';

export interface TagInputProps
  extends Omit<
    React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
    'onChange' | 'value' | 'className'
  > {
  className?: string;
  id?: string;
  label?: string;
  value: string[];
  onChange: (newValues: string[]) => void;
  error?: string | null;
  hideErrorMessage?: boolean;
}

export const TagInput: React.FC<TagInputProps> = ({
  className,
  id,
  label,
  onChange,
  value,
  onBlur,
  onKeyDown,
  error,
  hideErrorMessage,
  disabled,
  ...inputProps
}) => {
  const [inputValue, setInputValue] = useState('');

  const addValue = (newValue: string) => {
    if (!value.includes(newValue)) {
      onChange([...value, newValue]);
    }
  };

  const removeValue = (toDelete: string) => {
    onChange(value.filter((v) => v !== toDelete));
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        addValue(inputValue);
        setInputValue('');
        event.preventDefault();
    }
    onKeyDown?.(event);
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    onBlur?.(event);

    if (!inputValue) {
      return;
    }

    addValue(inputValue);
    setInputValue('');
    event.preventDefault();
  };

  return (
    <div className={className}>
      {label ? (
        <label className="block text-sm font-medium text-gray-700" htmlFor={id}>
          {label}
        </label>
      ) : null}
      <div className={classNames(styles.control, styles.rounded, { [styles.error]: error })}>
        {value.length ? (
          <div className="flex flex-wrap mb-1">
            {value.map((v) => (
              <span key={v} className="rounded-sm bg-gray-200 text-xs flex mb-1 mr-1">
                <span className="flex-1 py-1 pl-2 pr-1">{v}</span>
                <button
                  type="button"
                  className="flex-shrink-0 flex items-center justify-center p-1 hover:bg-red-100 hover:text-red-500 focus:outline-none disabled:bg-transparent disabled:text-gray-700 disabled:cursor-not-allowed"
                  disabled={disabled}
                  onClick={() => removeValue(v)}
                >
                  <XIcon className="w-3 h-3" />
                </button>
              </span>
            ))}
          </div>
        ) : null}
        <input
          autoComplete="off"
          aria-autocomplete="none"
          className={classNames(styles.input)}
          type="text"
          onKeyDown={handleKeyDown}
          onChange={(e) => setInputValue(e.target.value)}
          value={inputValue}
          placeholder="Type a value and hit enter"
          onBlur={handleBlur}
          disabled={disabled}
          {...inputProps}
        />
      </div>
      {error && !hideErrorMessage ? (
        <p className="mt-2 text-sm text-red-600" id={`${id}-error`}>
          {error}
        </p>
      ) : null}
    </div>
  );
};
