/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ]
  }
  ```
*/
import { FC, FormEvent, useState } from 'react';
import { InputGroup } from '../components/InputGroup';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { Button } from '../components/Button';
import { useUser } from '../providers/UserContext';
import { InlineAlert } from '../components/InlineAlert';
import { ReactComponent as Logo } from '../assets/LogoWhiteOnBlue.svg';

export const ChangePassword: FC = () => {
  const [name, setName] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState<null | string>(null);

  const { tempUser } = useUser();
  const { state } = useLocation<{ from: string }>();
  const { push } = useHistory();

  const validate = (): boolean => {
    if (confirmPassword !== newPassword) {
      setError("The two passwords didn't match");
      return false;
    }

    return true;
  };

  const handleSubmit = async (evt: FormEvent) => {
    evt.preventDefault();

    if (!validate()) {
      return;
    }

    try {
      await Auth.completeNewPassword(tempUser, newPassword, { name });
      push(state?.from ?? '/');
    } catch (err) {
      setError(err.message ?? 'Something went wrong.');
    }
  };

  return (
    <>
      {!tempUser ? <Redirect to="/login" /> : null}
      <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <Logo className="mx-auto h-12 w-auto" />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Password Reset</h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form className="space-y-6" onSubmit={handleSubmit}>
              <InputGroup
                label="Name"
                id="name"
                name="name"
                type="text"
                placeholder="John Doe"
                value={name}
                onChange={(evt) => setName(evt.target.value)}
              />

              <InputGroup type="hidden" name="username" value={tempUser?.username} />

              <InputGroup
                label="New Password"
                id="newPassword"
                name="newPassword"
                type="password"
                value={newPassword}
                onChange={(evt) => setNewPassword(evt.target.value)}
              />

              <InputGroup
                label="Confirm Password"
                id="confirmPassword"
                name="confirmPassword"
                type="password"
                value={confirmPassword}
                onChange={(evt) => setConfirmPassword(evt.target.value)}
              />

              <div>
                <Button type="submit" className="w-full">
                  Reset Password
                </Button>
              </div>
              {error ? <InlineAlert title={error} /> : null}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
