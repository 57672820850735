import { gql, useMutation } from '@apollo/client';
import {
  CreateShipStationIntegrationMutation,
  CreateShipStationIntegrationMutationVariables,
} from './__generated__/create-ship-station-integration.gql.generated';

export const CREATE_SHIP_STATION_INTEGRATION_MUTATION = gql`
  mutation CreateShipStationIntegration($description: String!, $apiKey: String!, $apiSecret: String!) {
    createShipStationIntegration(input: { description: $description, apiKey: $apiKey, apiSecret: $apiSecret }) {
      id
      type
      description
      readable
      apiKey
      apiSecret
    }
  }
`;

export const useCreateShipStationIntegrationMutation = () =>
  useMutation<CreateShipStationIntegrationMutation, CreateShipStationIntegrationMutationVariables>(
    CREATE_SHIP_STATION_INTEGRATION_MUTATION,
    { refetchQueries: ['AllIntegrations'] }
  );
