import React from 'react';
import { SectionHeadingWithAction } from '../components/Layout/SectionHeadingWithAction';
import { Link, Route, Switch, useRouteMatch } from 'react-router-dom';
import { Button } from '../components/Button';
import { EmptyState } from '../components/EmptyState';
import { PlusIcon } from '@heroicons/react/solid';
import { NewIntegration } from './NewIntegration';
import { useAlIntegrationsQuery } from '../operations/all-integrations.gql';
import { LoadingPanel } from '../components/LoadingPanel';
import { IntegrationTable } from '../components/Integrations/IntegrationTable';

export const Integrations: React.FC = () => {
  const { path } = useRouteMatch();
  const newIntegrationPath = `${path}/new`;

  const { data, loading } = useAlIntegrationsQuery();

  const isEmpty = !loading && !data?.integrations.length;

  return (
    <Switch>
      <Route path={newIntegrationPath} component={NewIntegration} />
      <Route path={path} exact>
        <div className="flex-1 flex flex-col h-full">
          <main className="flex-1 overflow-y-auto focus:outline-none bg-gray-100">
            <div className="relative max-w-4xl mx-auto px-6 md:p-6 2xl:p-0 h-full">
              <div className="pt-10 pb-16 flex flex-col h-full">
                <SectionHeadingWithAction
                  title="Integrations"
                  actions={
                    isEmpty ? null : (
                      <Link to={newIntegrationPath}>
                        <Button>New Integration</Button>
                      </Link>
                    )
                  }
                />
                {loading ? (
                  <LoadingPanel />
                ) : data?.integrations.length ? (
                  <IntegrationTable integrations={data.integrations} />
                ) : isEmpty ? (
                  <EmptyState
                    title="No integrations"
                    subtext="Get started by adding a new integration."
                    action={
                      <Link to={newIntegrationPath}>
                        <Button icon={PlusIcon}>New Integration</Button>
                      </Link>
                    }
                  />
                ) : null}

                {/*{loading ? <LoadingPanel /> : error ? <ErrorState /> : <UserTable users={users} />}*/}
              </div>
            </div>
          </main>
        </div>
      </Route>
    </Switch>
  );
};
