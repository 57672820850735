import { gql } from '@apollo/client/core';
import { useQuery } from '@apollo/client';
import { OrderEventsQuery, OrderEventsQueryVariables } from './__generated__/order-events.gql.generated';

export const ORDER_EVENTS_QUERY = gql`
  query OrderEvents($tplcOrderId: ID!) {
    orderEvents(tplcOrderId: $tplcOrderId) {
      createdAt
      createdBy
      metadataJson
      name
      orderId
    }
  }
`;

export const useOrderEventsQuery = (orderId: string) => {
  return useQuery<OrderEventsQuery, OrderEventsQueryVariables>(ORDER_EVENTS_QUERY, {
    variables: { tplcOrderId: orderId },
  });
};
