import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { ShippingRulesOrderChannelId } from './ShippingRulesOrderChannelId';

export const ShippingRules: React.FC = () => {
  const { path } = useRouteMatch();
  const orderChannelIdPath = `${path}/integration/:integrationId/order-channel/:orderChannelId`;

  return (
    <div className="flex-1 flex flex flex-col h-full bg-gray-100">
      <main className="flex-1 flex flex-col overflow-y-auto focus:outline-none">
        <div className="relative px-6 flex flex-col flex-1">
          <div className="pt-10 pb-6 flex flex-col flex-1">
            <Switch>
              <Route path={orderChannelIdPath} component={ShippingRulesOrderChannelId} />
              <Route path={path} exact>
                <Redirect to={`${path}/integration/global/order-channel/global`} />
              </Route>
            </Switch>
          </div>
        </div>
      </main>
    </div>
  );
};
