import React, { useState } from 'react';
import { useCarrierAccountsQuery } from '../operations/carrier-accounts.gql';
import { useEnabledCarrierIdsQuery } from '../operations/enabled-carrier-ids.gql';
import { LoadingPanel } from '../components/LoadingPanel';
import { ErrorState } from '../components/ErrorState';
import { EnabledCarriersForm } from '../components/ShippingRules/EnabledCarriersForm';
import { SectionHeadingWithAction } from '../components/Layout/SectionHeadingWithAction';
import { useAllOrderChannelsQuery } from '../operations/all-order-channels.gql';
import { OrderChannelSelect } from '../components/OrderChannelSelect';

export const CarrierAccounts: React.FC = () => {
  const [integrationId, setIntegrationId] = useState('global');
  const [orderChannelId, setOrderChannelId] = useState('global');

  const { data: orderChannelsData } = useAllOrderChannelsQuery();
  const { data: allCarrierAccountsData, loading: allCarrierAccountsLoading, error } = useCarrierAccountsQuery();
  const { data: enabledCarriersData, loading: enabledCarriersLoading } = useEnabledCarrierIdsQuery(
    integrationId,
    orderChannelId
  );

  const loading = allCarrierAccountsLoading || enabledCarriersLoading;

  const handleOrderChannelChange = (value: { integrationId: string; orderChannelId: string }) => {
    setIntegrationId(value.integrationId);
    setOrderChannelId(value.orderChannelId);
  };

  return (
    <div className="flex-1 flex flex-col h-full bg-gray-100">
      <main className="flex-1 overflow-y-auto focus:outline-none">
        <div className="relative max-w-4xl mx-auto px-6 2xl:px-0 h-full">
          <div className="pt-10 pb-16 h-full">
            <SectionHeadingWithAction
              title="Carrier Accounts"
              actions={
                <OrderChannelSelect
                  integrations={orderChannelsData?.integrations ?? []}
                  orderChannelId={orderChannelId}
                  integrationId={integrationId}
                  onChange={handleOrderChannelChange}
                />
              }
            />
            {loading ? (
              <LoadingPanel />
            ) : error ? (
              <ErrorState>{error.message ?? 'An unknown error occurred'}</ErrorState>
            ) : allCarrierAccountsData && enabledCarriersData ? (
              <EnabledCarriersForm
                key={`${integrationId}:${orderChannelId}`}
                initialEnabledCarrierIds={enabledCarriersData.enabledCarrierAccounts}
                carrierAccounts={allCarrierAccountsData.carrierAccounts}
                integrationId={integrationId}
                orderChannelId={orderChannelId}
              />
            ) : null}
          </div>
        </div>
      </main>
    </div>
  );
};
