import { gql, useMutation } from '@apollo/client';
import { REPORT_DETAILS_FRAGMENT } from './fragments/report-details.gql';
import { CreateReportMutation } from './__generated__/create-report.gql.generated';
import { CreateReportMutationVariables } from './__generated__/create-report.gql.generated';

export const CREATE_REPORT_MUTATION = gql`
  mutation CreateReport($input: ReportInput!) {
    createReport(input: $input) {
      ...ReportDetails
    }
  }

  ${REPORT_DETAILS_FRAGMENT}
`;

export const useCreateReportMutation = () =>
  useMutation<CreateReportMutation, CreateReportMutationVariables>(CREATE_REPORT_MUTATION);
