import React from 'react';
import { SectionHeadingWithAction } from '../components/Layout/SectionHeadingWithAction';
import { useAddressBookAddressesQuery } from '../operations/address-book-addresses.gql';
import { GeneralSettingsForm, GeneralSettingsValues } from '../components/GeneralSettings/GeneralSettingsForm';
import { useGeneralSettingsQuery } from '../operations/general-settings.gql';
import { LoadingPanel } from '../components/LoadingPanel';
import { useUpdateGeneralSettingsMutation } from '../operations/update-general-settings.gql';
import { FormikHelpers } from 'formik';

export const GeneralSettings: React.FC = () => {
  const { data: generalSettingsData, loading: generalSettingsLoading } = useGeneralSettingsQuery();
  const { data: addressBookData, loading: addressBookLoading } = useAddressBookAddressesQuery({ limit: 50 });
  const [updateSettings, { loading: saving }] = useUpdateGeneralSettingsMutation();

  const handleSubmit = async (values: GeneralSettingsValues, helpers: FormikHelpers<GeneralSettingsValues>) => {
    try {
      await updateSettings({ variables: { input: values } });
      helpers.resetForm({ values });
    } catch (err) {
      // TODO: Capture in sentry
    }
  };

  const addressOptions =
    addressBookData?.addressBookAddresses.items.map((item) => ({
      label: item.addressName,
      value: item.id,
    })) ?? [];

  const loading = addressBookLoading || generalSettingsLoading;

  const initialValues: GeneralSettingsValues | null = generalSettingsData
    ? {
        defaultShipFromAddressId: generalSettingsData.generalSettings.defaultShipFromAddressId,
        defaultReturnAddressId: generalSettingsData.generalSettings.defaultReturnAddressId,
        customsSigner: generalSettingsData.generalSettings.customsSigner,
        defaultEmail: generalSettingsData.generalSettings.defaultEmail,
        defaultPhoneNumber: generalSettingsData.generalSettings.defaultPhoneNumber,
      }
    : null;

  return (
    <div className="flex-1 flex flex flex-col h-full bg-gray-100">
      <main className="flex-1 flex flex-col overflow-y-auto focus:outline-none">
        <div className="relative max-w-4xl mx-auto px-6 2xl:px-0 w-full flex flex-col flex-1">
          <div className="pt-10 pb-6 flex flex-col flex-1">
            <SectionHeadingWithAction title="General Settings" />
            {loading ? (
              <LoadingPanel />
            ) : initialValues ? (
              <GeneralSettingsForm
                initialValues={initialValues}
                onSubmit={handleSubmit}
                disabled={loading || saving}
                addressOptions={addressOptions}
              />
            ) : null}
          </div>
        </div>
      </main>
    </div>
  );
};
