import React, { useEffect, useState } from 'react';
import { usePrintNode } from '../../providers/PrintNodeProvider';
import { useDebounce, useEffectOnce, useUpdateEffect } from 'react-use';
import { useFormValidator, useShipForm } from './ShipForm';
import { useHistory, useLocation } from 'react-router-dom';

export const FormAutomator: React.FC = () => {
  const form = useShipForm();
  const { isPackagesSectionValid, isAddressSectionValid } = useFormValidator();
  const { scaleMeasurement } = usePrintNode();
  const [weightReadEnabled, setWeightReadEnabled] = useState(true);
  const [navigatedFromPackages, setNavigatedFromPackages] = useState(false);
  const history = useHistory();
  const location = useLocation();

  useDebounce(
    () => {
      const nextIndex = form.values.packages.findIndex((parcel) => !parcel.weightLocked);
      if (scaleMeasurement && scaleMeasurement > 0 && weightReadEnabled && nextIndex >= 0) {
        console.log(`Setting weight for package ${nextIndex}:`, scaleMeasurement);
        form.setFieldValue(`packages.${nextIndex}.weight`, scaleMeasurement);
        form.setFieldValue(`packages.${nextIndex}.weightLocked`, true);
        setWeightReadEnabled(false);
      }
    },
    1000,
    [scaleMeasurement, form.values.packages]
  );

  useEffect(() => {
    if (!weightReadEnabled && scaleMeasurement !== null && scaleMeasurement <= 0) {
      setWeightReadEnabled(true);
    }
  }, [weightReadEnabled, scaleMeasurement]);

  useEffectOnce(() => {
    if (isAddressSectionValid) {
      history.push('packages');
    }
  });

  useUpdateEffect(() => {
    if (
      location.pathname.endsWith('packages') &&
      isPackagesSectionValid &&
      !navigatedFromPackages &&
      scaleMeasurement
    ) {
      history.push('rate-shop');
      setNavigatedFromPackages(true);
    }
  }, [location.pathname, isPackagesSectionValid]);

  return null;
};
