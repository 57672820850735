import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { OrderLabelsQueryVariables } from './__generated__/order-labels.gql.generated';
import { OrderLabelsQuery } from './__generated__/order-labels.gql.generated';

export const ORDER_LABELS_QUERY = gql`
  query OrderLabels($integrationId: ID!, $orderId: ID!) {
    orderLabels(input: { integrationId: $integrationId, orderId: $orderId }) {
      labelFileType
      labelFileUrl
    }
  }
`;

export const useOrderLabelsQuery = ({ integrationId, orderId }: { integrationId: string; orderId: string }) =>
  useQuery<OrderLabelsQuery, OrderLabelsQueryVariables>(ORDER_LABELS_QUERY, { variables: { integrationId, orderId } });

export const useOrderLabelsLazyQuery = () =>
  useLazyQuery<OrderLabelsQuery, OrderLabelsQueryVariables>(ORDER_LABELS_QUERY, { fetchPolicy: 'network-only' });
