import { gql } from '@apollo/client/core';
import { useQuery } from '@apollo/client';
import { ManifestsQuery, ManifestsQueryVariables } from './__generated__/manifests.gql.generated';

export const MANIFESTS_QUERY = gql`
  query Manifests($facilityId: Int!) {
    manifests(locationId: $facilityId) {
      id
      createdAt
      carrier
      status
      formUrl
      locationId
    }
  }
`;

export const useManifestsQuery = (facilityId: string | null) => {
  return useQuery<ManifestsQuery, ManifestsQueryVariables>(MANIFESTS_QUERY, {
    variables: { facilityId: +facilityId! },
    notifyOnNetworkStatusChange: true,
    skip: Boolean(facilityId === null),
  });
};
