import React from 'react';
import { OrderShipmentsQuery } from '../../operations/__generated__/order-shipments.gql.generated';
import { Card } from '../Card';
import { CardContent } from '../CardContent';
import { CardHeading } from '../CardHeading';
import { LoadingPanel } from '../LoadingPanel';
import { ShipmentList } from './ShipmentList';

export interface ShipmentsSummaryParams {
  loading: boolean;
  shipments: OrderShipmentsQuery['orderShipments'];
}

export const ShipmentsSummary: React.FC<ShipmentsSummaryParams> = ({ loading, shipments }) => {
  return (
    <Card>
      <CardHeading>Shipments</CardHeading>
      {loading ? (
        <CardContent>
          <LoadingPanel />
        </CardContent>
      ) : (
        <ShipmentList shipments={shipments} />
      )}
    </Card>
  );
};
