import React, { forwardRef } from 'react';
import classNames from 'classnames';
import styles from './Input.module.scss';

export interface InputProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {}

export const Input = forwardRef<HTMLInputElement, InputProps>(({ className, ...inputProps }, ref) => {
  const rounded = !className?.includes('rounded');

  return (
    <input ref={ref} className={classNames(styles.input, { [styles.rounded]: rounded }, className)} {...inputProps} />
  );
});
