import React, { ChangeEvent } from 'react';
import { formatOrderChannelName } from '../utilities/format-order-channel-name';
import { Select } from './Select';
import { AllOrderChannelsQuery } from '../operations/__generated__/all-order-channels.gql.generated';

export interface OrderChannelSelectProps {
  integrations: AllOrderChannelsQuery['integrations'];
  integrationId: string;
  orderChannelId: string;
  onChange: (value: { integrationId: string; orderChannelId: string }) => void;
}

export const OrderChannelSelect: React.FC<OrderChannelSelectProps> = ({
  integrations,
  integrationId,
  orderChannelId,
  onChange,
}) => {
  const orderChannelOptions =
    integrations.reduce((options, integration) => {
      integration.orderChannels.forEach((orderChannel) => {
        options.push({
          label: formatOrderChannelName(orderChannel.name, integration.type),
          value: `${integration.id}:${orderChannel.id}`,
        });
      });
      return options;
    }, [] as { label: string; value: string }[]) ?? [];

  const handleOrderChannelSelect = (event: ChangeEvent<HTMLSelectElement>) => {
    const parts = event.target.value.split(':');

    onChange({
      integrationId: parts[0],
      orderChannelId: parts[1],
    });
  };

  return (
    <Select value={`${integrationId}:${orderChannelId}`} onChange={handleOrderChannelSelect}>
      <option value="global:global">Global</option>
      {orderChannelOptions.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </Select>
  );
};
