import React from 'react';
import { Modal } from './Modal';
import { ExclamationIcon } from '@heroicons/react/outline';

interface ErrorModalProps {
  isOpen: boolean;
  message?: string | null;
  onClose: () => void;
}

export const ErrorModal: React.FC<ErrorModalProps> = ({ isOpen, message, onClose }) => {
  const computedMessage = message || 'An unknown error occurred. Please contact support if the issue persists.';
  return (
    <Modal
      header="An error occurred"
      Icon={ExclamationIcon}
      kind="error"
      isOpen={isOpen}
      message={computedMessage}
      onClose={onClose}
    />
  );
};
