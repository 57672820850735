import { gql, useQuery } from '@apollo/client';
import { REPORT_DETAILS_FRAGMENT } from './fragments/report-details.gql';
import { ReportsQuery, ReportsQueryVariables } from './__generated__/reports.gql.generated';

export const REPORTS_QUERY = gql`
  query Reports($input: ListReportsInput!) {
    reports(input: $input) {
      items {
        ...ReportDetails
      }
      count
      nextToken
    }
  }

  ${REPORT_DETAILS_FRAGMENT}
`;

export const useReportsQuery = (variables: ReportsQueryVariables) =>
  useQuery<ReportsQuery, ReportsQueryVariables>(REPORTS_QUERY, { variables });
