import { gql, useMutation } from '@apollo/client';
import {
  DeleteRateAdjustmentMutation,
  DeleteRateAdjustmentMutationVariables,
} from './__generated__/delete-rate-adjustment.gql.generated';

export const DELETE_RATE_ADJUSTMENT_MUTATION = gql`
  mutation DeleteRateAdjustment($integrationId: ID!, $orderChannelId: ID!) {
    deleteRateAdjustment(integrationId: $integrationId, orderChannelId: $orderChannelId)
  }
`;

export const useDeleteRateAdjustmentMutation = () =>
  useMutation<DeleteRateAdjustmentMutation, DeleteRateAdjustmentMutationVariables>(DELETE_RATE_ADJUSTMENT_MUTATION);
