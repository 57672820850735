import React from 'react';
import ReactDOM from 'react-dom';
import '@fontsource/inter/variable-full.css';
import './index.css';
import './config/amplify';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AppSyncProvider } from './providers/AppSyncProvider';
import 'react-datepicker/dist/react-datepicker.css';
import mixpanel from 'mixpanel-browser';

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN!);

ReactDOM.render(
  <React.StrictMode>
    <AppSyncProvider>
      <App />
    </AppSyncProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
