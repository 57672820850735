import { gql, useSubscription } from '@apollo/client';
import {
  TenantReportsSubscription,
  TenantReportsSubscriptionVariables,
} from './__generated__/reports-subscription.gql.generated';
import { REPORT_DETAILS_FRAGMENT } from './fragments/report-details.gql';

export const TENANT_REPORTS_SUBSCRIPTION = gql`
  subscription TenantReports($tenantId: ID!) {
    reportUpdated(tenantId: $tenantId) {
      ...ReportDetails
    }
  }

  ${REPORT_DETAILS_FRAGMENT}
`;

export const useTenantReportsSubscription = (tenantId: string) =>
  useSubscription<TenantReportsSubscription, TenantReportsSubscriptionVariables>(TENANT_REPORTS_SUBSCRIPTION, {
    variables: { tenantId },
  });
