import { ReactComponent as USPSLogo } from '../assets/carrier-logos/usps.svg';
import { ReactComponent as UPSLogo } from '../assets/carrier-logos/ups.svg';
import { ReactComponent as FedExLogo } from '../assets/carrier-logos/fedex.svg';
import { ReactComponent as DHLLogo } from '../assets/carrier-logos/dhl.svg';
import { ReactComponent as PassportLogo } from '../assets/carrier-logos/passport.svg';
import { ReactComponent as BetterTrucksLogo } from '../assets/carrier-logos/better-trucks.svg';
import React from 'react';
import { CarrierAccountType } from '../types/__generated__/graphql';

export const getCarrierLogo = (carrier: string): React.ComponentType<any> => {
  switch (carrier.toLowerCase()) {
    case 'usps':
      return USPSLogo;
    case 'ups':
    case 'upssurepost':
      return UPSLogo;
    case 'fedex':
    case 'fedexsmartpost':
      return FedExLogo;
    case 'dhlecs':
      return DHLLogo;
    case 'passport':
      return PassportLogo;
    case 'bettertrucks':
      return BetterTrucksLogo;
    default:
      throw new Error('Carrier not supported.');
  }
};

export const getCarrierLogoElement = (carrier: string, className?: string): React.ReactNode => {
  const Logo = getCarrierLogo(carrier);

  if (!Logo) {
    return null;
  }

  return <Logo className={className} />;
};

export const CarrierAccountTypeToLogoMap: { [key in CarrierAccountType]: React.ComponentType<any> } = {
  [CarrierAccountType.BetterTrucksAccount]: BetterTrucksLogo,
  [CarrierAccountType.DhlEcsAccount]: DHLLogo,
  [CarrierAccountType.FedexAccount]: FedExLogo,
  [CarrierAccountType.FedexSmartpostAccount]: FedExLogo,
  [CarrierAccountType.UpsAccount]: UPSLogo,
  [CarrierAccountType.UpsSurepostAccount]: UPSLogo,
  [CarrierAccountType.UspsAccount]: USPSLogo,
  [CarrierAccountType.Unknown]: USPSLogo, // TODO: make this a generic logo lol.
};
