import React, { useCallback } from 'react';
import { RuleForm, RuleFormValues } from '../components/ShippingRules/RuleForm';
import { SectionHeadingWithAction } from '../components/Layout/SectionHeadingWithAction';
import { Breadcrumb } from '../components/Breadcrumbs';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useAllOrderChannelsQuery } from '../operations/all-order-channels.gql';
import { OrderChannelSelect } from '../components/OrderChannelSelect';
import {
  EasyPostCarrier,
  ModifierField,
  RateFilterField,
  RuleOperator,
  TriggerField,
} from '../types/__generated__/graphql';
import { useUpdateRuleMutation } from '../operations/update-rule.gql';
import { useGetRuleQuery } from '../operations/get-rule.gql';
import { LoadingPanel } from '../components/LoadingPanel';
import { AllRuleDetailsFragment } from '../operations/fragments/__generated__/all-rule-details.gql.generated';
import { nanoid } from 'nanoid';
import { useDeleteRuleMutation } from '../operations/delete-rule.gql';

const convertRuleToInitialValues = (rule: AllRuleDetailsFragment): RuleFormValues => ({
  name: rule.name,
  smartRateConfiguration: rule.smartRateConfiguration
    ? {
        deliveryDays: rule.smartRateConfiguration.deliveryDays,
        percentile: rule.smartRateConfiguration.percentile,
        defaultService: rule.smartRateConfiguration.defaultService
          ? `${rule.smartRateConfiguration.defaultCarrier}:${rule.smartRateConfiguration.defaultService}`
          : '',
      }
    : null,
  triggers: rule.triggers.map((trigger) => ({
    ...trigger,
    key: nanoid(),
  })),
  modifiers: rule.modifiers.map((modifier) => ({
    modifier: modifier.field,
    value: modifier.value,
    key: nanoid(),
  })),
  rateFilters: rule.rateFilters.map((rateFilter) => ({
    ...rateFilter,
    key: nanoid(),
  })),
});

export const UpdateRule: React.FC = () => {
  const {
    params: { integrationId, orderChannelId, ruleId },
    path,
    url,
  } = useRouteMatch<{ integrationId: string; orderChannelId: string; ruleId: string }>();

  const { url: rulesUrl } =
    useRouteMatch('/settings/shipping-rules/integration/:integrationId/order-channel/:orderChannelId') ?? {};

  const { data: orderChannelsData } = useAllOrderChannelsQuery();
  const { data: ruleData, loading: ruleLoading } = useGetRuleQuery(ruleId);
  const [updateRule, { loading: updateRuleLoading }] = useUpdateRuleMutation();
  const [deleteRule, { loading: deleteRuleLoading }] = useDeleteRuleMutation();

  const history = useHistory();

  const handleOrderChannelSelect = useCallback(
    (value: { integrationId: string; orderChannelId: string }) => {
      const newUrl = path
        .replace(':orderChannelId', value.orderChannelId)
        .replace(':integrationId', value.integrationId);

      history.push(newUrl);
    },
    [history, path]
  );

  const handleSubmit = async (values: RuleFormValues) => {
    const convertValue = (value: Date | number | string | string[]): string[] => {
      if (value instanceof Date) {
        return [value.toISOString()];
      }

      if (Array.isArray(value)) {
        return value;
      }

      return [value.toString()];
    };

    try {
      await updateRule({
        variables: {
          input: {
            id: ruleId,
            rule: {
              name: values.name,
              triggers: values.triggers.map((trigger) => ({
                field: trigger.field as TriggerField,
                operator: trigger.operator as RuleOperator,
                value: convertValue(trigger.value),
              })),
              modifiers: values.modifiers.map((modifier) => ({
                field: modifier.modifier as ModifierField,
                value: Array.isArray(modifier.value) ? modifier.value : [modifier.value],
              })),
              rateFilters: values.rateFilters.map((rateFilter) => ({
                field: rateFilter.field as RateFilterField,
                operator: rateFilter.operator as RuleOperator,
                value: rateFilter.value,
              })),
              smartRateConfiguration: values.smartRateConfiguration
                ? {
                    deliveryDays: values.smartRateConfiguration.deliveryDays,
                    percentile: values.smartRateConfiguration.percentile,
                    ...(values.smartRateConfiguration.defaultService
                      ? {
                          defaultCarrier: values.smartRateConfiguration.defaultService.split(':')[0] as EasyPostCarrier,
                          defaultService: values.smartRateConfiguration.defaultService.split(':')[1],
                        }
                      : {}),
                  }
                : null,
            },
          },
        },
      });

      history.push(rulesUrl ?? '#');
    } catch (err) {
      // TODO: capture in sentry
    }
  };

  const handleDelete = async () => {
    try {
      await deleteRule({ variables: { id: ruleId } });

      history.push(rulesUrl ?? '#');
    } catch (err) {
      // TODO: capture error in sentry
    }
  };

  const initialValues = ruleData ? convertRuleToInitialValues(ruleData.rule) : null;

  const breadcrumbs: Breadcrumb[] = [
    { path: rulesUrl ?? '#', label: 'Shipping Rules' },
    { path: url, label: 'Edit Rule' },
  ];

  return (
    <>
      <SectionHeadingWithAction
        title="Edit Rule"
        breadcrumbs={breadcrumbs}
        actions={
          <OrderChannelSelect
            integrations={orderChannelsData?.integrations ?? []}
            integrationId={integrationId}
            orderChannelId={orderChannelId}
            onChange={handleOrderChannelSelect}
          />
        }
      />
      {ruleLoading ? (
        <LoadingPanel />
      ) : initialValues ? (
        <RuleForm
          initialValues={initialValues}
          edit
          saving={updateRuleLoading}
          deleting={deleteRuleLoading}
          onSubmit={handleSubmit}
          onDelete={handleDelete}
        />
      ) : null}
    </>
  );
};
