import React from 'react';
import { QualityControlFormValues } from './QualityControlForm';
import { MinusIcon } from '@heroicons/react/outline';
import classNames from 'classnames';

export interface QualityControlRowProps {
  item: QualityControlFormValues['parcels'][number]['items'][number];
  onDecrease: () => void;
}

export const QualityControlRow: React.FC<QualityControlRowProps> = ({ item, onDecrease }) => {
  const valid = item.expectedQuantity === item.scannedQuantity;

  return (
    <>
      <div className="flex flex-col overflow-ellipsis overflow-hidden col-start-1">
        <span className="font-bold text-sm">{item.name ?? item.sku ?? 'No item name/SKU'}</span>
        {item.description ? <span className="text-sm">Description: {item.description}</span> : null}
        {item.name && item.sku ? <span className="text-sm text-gray-500">SKU: {item.sku}</span> : null}
        {item.upc ? <span className="text-sm text-gray-500">UPC: {item.upc}</span> : null}
      </div>
      <span
        className={classNames(
          'self-center justify-self-center text-2xl font-bold',
          valid ? 'text-green-600' : 'text-red-500'
        )}
      >
        {item.expectedQuantity}
      </span>
      <span
        className={classNames(
          'self-center justify-self-center flex items-center text-2xl font-bold',
          valid ? 'text-green-600' : 'text-red-500'
        )}
      >
        {item.scannedQuantity}
      </span>
      <button
        type="button"
        className="flex items-center justify-center bg-red-200 p-2 rounded outline-none border border-transparent focus:outline-none focus:ring-1 focus:ring-red-500 focus:border-red-500 self-center"
        onClick={onDecrease}
      >
        <MinusIcon className="w-5 h-5" />
      </button>
    </>
  );
};
