import { gql, useMutation } from '@apollo/client';
import { SetRateAdjustmentMutationVariables } from './__generated__/set-rate-adjustment.gql.generated';
import { SetRateAdjustmentMutation } from './__generated__/set-rate-adjustment.gql.generated';
import { RATE_ADJUSTMENT_FRAGMENT } from './fragments/rate-adjustment.gql';

export const SET_RATE_ADJUSTMENT_MUTATION = gql`
  mutation SetRateAdjustment($input: SetRateAdjustmentInput!) {
    setRateAdjustment(input: $input) {
      ...RateAdjustment
    }
  }

  ${RATE_ADJUSTMENT_FRAGMENT}
`;

export const useSetRateAdjustmentMutation = () =>
  useMutation<SetRateAdjustmentMutation, SetRateAdjustmentMutationVariables>(SET_RATE_ADJUSTMENT_MUTATION);
