import { gql } from '@apollo/client';

export const ORDER_DETAILS_FRAGMENT = gql`
  fragment OrderDetails on OrderV2 {
    id
    integrationId
    orderChannelId
    orderId
    salesOrderNumber
    parcels {
      height
      length
      width
      weight
      internalId
      items {
        name
        sku
        upc
        description
        quantity
      }
    }
    shipFromAddressId
    returnAddressId
    shipToAddressId
    shipToAddress {
      company
      name
      street1
      street2
      city
      state
      zip
      country
      email
      phone
      verifications {
        delivery {
          errors {
            field
            message
          }
          success
        }
      }
    }
    requestedServiceType
    trackingNumbers
    orderDate
    carrier
    service
    shipped
  }
`;
