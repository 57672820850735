import React from 'react';
import { Button } from '../../Button';
import { PlusIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import styles from './RuleTriggerSection.module.scss';
import { RuleTriggerRow } from './RuleTriggerRow';
import { FieldArray } from 'formik';
import { RuleSectionHeading } from '../atoms/RuleSectionHeading';
import { RuleSection } from '../atoms/RuleSection';
import { RuleSectionEmptyState } from '../atoms/RuleSectionEmptyState';
import { TriggerValues, useRulesForm } from '../RuleForm';
import { nanoid } from 'nanoid';
import { useTriggerFieldOptionsQuery } from '../../../operations/trigger-field-options.gql';

const initialTriggerValues = (): TriggerValues => ({
  key: nanoid(5),
  field: '',
  operator: '',
  value: [''],
});

export interface RuleTriggerSectionProps {
  disabled?: boolean;
}

export const RuleTriggerSection: React.FC<RuleTriggerSectionProps> = ({ disabled }) => {
  const form = useRulesForm();
  const isEmpty = !form.values.triggers.length;
  const { data } = useTriggerFieldOptionsQuery();

  const triggerFieldOptions = data?.triggerFieldOptions ?? [];

  return (
    <FieldArray name={`triggers`}>
      {({ push, remove }) => (
        <RuleSection>
          <RuleSectionHeading
            title="Rule Triggers"
            actions={
              <Button
                type="button"
                kind="white"
                size="sm"
                icon={PlusIcon}
                onClick={() => push(initialTriggerValues())}
                disabled={disabled}
              >
                Trigger
              </Button>
            }
          />
          {isEmpty ? (
            <RuleSectionEmptyState
              title="No triggers added."
              message="This rule will always run if no triggers are defined."
            />
          ) : (
            <div className={classNames('mb-8', styles.triggerGrid)}>
              {form.values.triggers.map((trigger, triggerIndex) => (
                <RuleTriggerRow
                  key={trigger.key}
                  triggerIndex={triggerIndex}
                  onDelete={() => remove(triggerIndex)}
                  showAnd={triggerIndex !== 0}
                  fieldOptions={triggerFieldOptions}
                  disabled={disabled}
                />
              ))}
            </div>
          )}
        </RuleSection>
      )}
    </FieldArray>
  );
};
