import { createContext, FC, useContext } from 'react';
import { useGetOrderQuery } from '../../operations/order.gql';
import { GetOrderQuery } from '../../operations/__generated__/order.gql.generated';

interface OrderContextValue {
  order: GetOrderQuery['order'];
  loading: boolean;
  error: string | null;
}

export const OrderContext = createContext<OrderContextValue | undefined>(undefined);

export interface OrderProviderProps {
  orderId: string;
}

export const OrderProvider: FC<OrderProviderProps> = ({ children, orderId }) => {
  const { data, loading, error } = useGetOrderQuery(orderId);
  const order = data?.order ?? null;

  const errorMessage = error?.message ?? null;

  return <OrderContext.Provider value={{ order, loading, error: errorMessage }}>{children}</OrderContext.Provider>;
};

export const useOrder = () => {
  const order = useContext(OrderContext);

  if (order === undefined) {
    throw new Error('useOrder must be used within an OrderProvider!');
  }

  return order;
};
