import React, { useState } from 'react';
import { UpdateAddressSlideOver } from './UpdateAddressSlideOver';

export interface AddressProps {
  address: {
    companyName?: string | null;
    name?: string | null;
    address1?: string | null;
    address2?: string | null;
    city?: string | null;
    state?: string | null;
    postalCode?: string | null;
    country?: string | null;
    email?: string | null;
    phoneNumber?: string | null;
    isValid?: boolean | null;
    errors?: string[] | null;
  };
}

export const Address: React.FC<AddressProps> = ({ address }) => {
  const [isUpdateSlideOverOpen, setIsUpdateSlideOverOpen] = useState(false);
  const cityStateZip = `${address.city?.trim() ?? 'Unknown'}, ${address.state?.trim() ?? 'Unknown'} ${
    address.postalCode?.trim() ?? 'Unknown'
  }`;

  return (
    <address className="flex-1">
      {address.companyName?.trim() ? (
        <span>
          {address.companyName?.trim()} <br />
        </span>
      ) : null}
      {address.name?.trim() ? (
        <span>
          {address.name?.trim()} <br />
        </span>
      ) : null}
      {address.address1?.trim() ? (
        <span>
          {address.address1?.trim()} <br />
        </span>
      ) : null}
      {address.address2?.trim() ? (
        <span>
          {address.address2?.trim()} <br />
        </span>
      ) : null}
      <span>
        {cityStateZip} <br />
      </span>
      {address.country?.trim() ? (
        <span>
          {address.country?.trim()} <br />
        </span>
      ) : null}
      {address.isValid === false ? (
        <div className="text-red-700">
          <strong>Invalid Address</strong>
          <br />
          {address.errors?.map((error, index) => (
            <div key={index}>{error}</div>
          ))}
          {/*<Button type="button" kind="white" size="xs" className="mt-2" onClick={() => setIsUpdateSlideOverOpen(true)}>*/}
          {/*  Update address*/}
          {/*</Button>*/}
        </div>
      ) : null}
      <UpdateAddressSlideOver open={isUpdateSlideOverOpen} onClose={() => setIsUpdateSlideOverOpen(false)} />
    </address>
  );
};
