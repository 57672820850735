import { FC } from 'react';

export const RateSkeletonLoader: FC = () => {
  return (
    <div className="relative block rounded-lg border border-gray-300 bg-white shadow-sm px-6 py-4 cursor-pointer sm:flex sm:justify-between focus:outline-none animate-pulse">
      <div className="flex items-center w-full">
        <div className="text-sm w-full">
          <div className="bg-gray-200 h-5 w-1/3" />
          <div className="bg-gray-100 h-4 w-1/2 mt-2" />
        </div>
      </div>
      <div className="mt-2 flex text-sm sm:mt-0 sm:block sm:ml-4 sm:text-right">
        <div className="bg-gray-200 h-5 w-16" />
        <div className="bg-gray-100 h-4 w-16 mt-2" />
      </div>
    </div>
  );
};
