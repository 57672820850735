import React from 'react';
import { RuleSectionHeading } from '../atoms/RuleSectionHeading';
import { Button } from '../../Button';
import { RuleSectionEmptyState } from '../atoms/RuleSectionEmptyState';
import { SmartRateConfiguration } from './SmartRateConfiguration';
import { RuleSection } from '../atoms/RuleSection';
import { SmartRateConfigurationValues, useRulesForm } from '../RuleForm';
import { SmartRatePercentile } from '../../../types/__generated__/graphql';

const initialSmartRateConfiguration = (): SmartRateConfigurationValues => ({
  deliveryDays: 0,
  percentile: SmartRatePercentile.Percentile_85,
  defaultService: '',
});

export interface SmartRateSectionProps {
  disabled?: boolean;
}

export const SmartRateSection: React.FC<SmartRateSectionProps> = ({ disabled }) => {
  const form = useRulesForm();
  const smartRateEnabled = Boolean(form.values.smartRateConfiguration);

  const enableSmartRate = () => {
    form.setFieldValue(`smartRateConfiguration`, initialSmartRateConfiguration());
  };
  const disableSmartRate = () => {
    form.setFieldValue(`smartRateConfiguration`, null);
  };

  return (
    <RuleSection>
      <RuleSectionHeading
        title="SmartRate Configuration"
        actions={
          smartRateEnabled ? (
            <Button type="button" kind="white" onClick={disableSmartRate} disabled={disabled}>
              Disable SmartRate
            </Button>
          ) : (
            <Button type="button" kind="white" onClick={enableSmartRate} disabled={disabled}>
              Enable SmartRate
            </Button>
          )
        }
      />
      {!smartRateEnabled ? (
        <RuleSectionEmptyState
          title="SmartRate disabled."
          message="SmartRate allows you to ship with higher delivery time accuracy, higher savings, or a combination of the
              two. Enable SmartRate to access huge savings potential."
        />
      ) : (
        <SmartRateConfiguration disabled={disabled} />
      )}
    </RuleSection>
  );
};
