import { gql, useQuery } from '@apollo/client';
import { OrderShipmentsQuery, OrderShipmentsQueryVariables } from './__generated__/order-shipments.gql.generated';

export const ORDER_SHIPMENTS_QUERY = gql`
  query OrderShipments($integrationId: ID!, $orderId: ID!) {
    orderShipments(input: { integrationId: $integrationId, orderId: $orderId }) {
      id
      cost
      trackingNumber
      carrier
      service
      createdAt
      status
      refundRequested
      refundStatus
      uspsZone
    }
  }
`;

export const useOrderShipmentsQuery = ({
  integrationId,
  orderId,
  disabled,
}: {
  integrationId: string;
  orderId: string;
  disabled: boolean;
}) =>
  useQuery<OrderShipmentsQuery, OrderShipmentsQueryVariables>(ORDER_SHIPMENTS_QUERY, {
    variables: { integrationId, orderId },
    skip: disabled,
    fetchPolicy: 'cache-and-network',
  });
