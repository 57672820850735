import React from 'react';
import { ReactComponent as DeliverySvg } from '../../assets/illustrations/delivery.svg';

export const FulFillEmptyState: React.FC = () => (
  <div className="flex-1 flex flex-col items-center justify-center max-w-xl self-center">
    <DeliverySvg className="w-full h-auto mb-8" />
    <h1 className="text-2xl sm:text-3xl font-bold mb-2">Order Fulfillment</h1>
    <p className="text-gray-500 max-w-xl">
      Use the <strong>search bar</strong> at the top of the page to search for an order.
    </p>
  </div>
);
