import React from 'react';
import { UserRole } from '../../types/__generated__/graphql';
import * as yup from 'yup';
import { ErrorMessage, Form, Formik } from 'formik';
import { FormInput } from '../Form/FormInput';
import { FormCheckbox } from '../Form/FormCheckbox';
import { Button } from '../Button';
import { InlineAlert } from '../InlineAlert';

const roles: { label: string; description: string; value: UserRole }[] = [
  {
    label: 'Admin',
    description: 'Access to user management, carrier accounts, and shipping rules.',
    value: UserRole.Admin,
  },
  {
    label: 'Shipper',
    description: 'Can fulfill shipments and manifest.',
    value: UserRole.Shipper,
  },
];

const schema = yup.object({
  email: yup.string().required(),
  name: yup.string().required(),
  roles: yup
    .array()
    .of(yup.string().oneOf(Object.values(UserRole)))
    .required()
    .min(1, 'Please select at least one role.'),
});

export interface UserFormValues {
  email: string;
  name: string;
  roles: UserRole[];
}

export interface UserFormProps {
  initialValues: UserFormValues;
  emailDisabled: boolean;
  onSubmit: (values: UserFormValues) => void;
  submitText: string;
  loading?: boolean;
  error?: string | null;
}

export const UserForm: React.FC<UserFormProps> = ({
  initialValues,
  emailDisabled,
  submitText,
  onSubmit,
  loading,
  error,
}) => {
  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={schema}>
      {({ isSubmitting, errors }) => (
        <Form className="space-y-4">
          {error ? <InlineAlert type="error" title="Uh oh!" errors={[error]} /> : null}
          <FormInput name="email" type="email" label="Email" disabled={emailDisabled} />
          <FormInput name="name" type="text" label="Name" />
          <div className="space-y-1">
            <h3 className="text-lg leading-6 font-medium text-gray-900">User Roles</h3>
            <p className="max-w-2xl text-sm text-gray-500">Select which roles this user in order to limit access.</p>
          </div>
          {roles.map((role) => (
            <FormCheckbox
              key={role.value}
              name="roles"
              label={role.label}
              description={role.description}
              value={role.value}
            />
          ))}
          {errors.roles ? (
            <div className="text-sm text-red-600">
              <ErrorMessage name="roles" />
            </div>
          ) : null}

          <div className="flex justify-end">
            <Button loading={loading} type="submit">
              {submitText}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
