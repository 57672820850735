import { gql } from '@apollo/client/core';
import { useSubscription } from '@apollo/client';
import { ManifestUpdatedV2Subscription } from './__generated__/manifest-updated-v2.gql.generated';

export const MANIFEST_UPDATED_V2_SUBSCRIPTION = gql`
  subscription ManifestUpdatedV2 {
    updatedManifestV2 {
      id
      formUrl
      status
    }
  }
`;

export const useManifestUpdatedV2Subscription = () => {
  return useSubscription<ManifestUpdatedV2Subscription>(MANIFEST_UPDATED_V2_SUBSCRIPTION);
};
