/* This example requires Tailwind CSS v2.0+ */
import React from 'react';
import { Breadcrumb, Breadcrumbs } from '../Breadcrumbs';

export interface SectionHeadingWithActionProps {
  title: string;
  actions?: React.ReactNode;
  breadcrumbs?: Breadcrumb[];
}

export const SectionHeadingWithAction: React.FC<SectionHeadingWithActionProps> = ({ actions, breadcrumbs, title }) => {
  return (
    <>
      {breadcrumbs?.length ? <Breadcrumbs breadcrumbs={breadcrumbs} /> : null}
      <div className="pb-5 mb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
        <h3 className="text-lg leading-6 font-medium text-gray-900">{title}</h3>
        {actions ? <div className="mt-3 sm:mt-0 sm:ml-4">{actions}</div> : null}
      </div>
    </>
  );
};
