import { gql, useMutation } from '@apollo/client';
import { DeleteUserMutation, DeleteUserMutationVariables } from './__generated__/delete-user.gql.generated';

export const DELETE_USER_MUTATION = gql`
  mutation DeleteUser($id: ID!) {
    deleteUser(id: $id)
  }
`;

export const useDeleteUserMutation = () =>
  useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DELETE_USER_MUTATION);
