import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { AddressBookList } from './AddressBookList';
import { AddressBookNew } from './AddressBookNew';
import { AddressBookEdit } from './AddressBookEdit';

export const AddressBook: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <div className="flex-1 flex flex flex-col h-full bg-gray-100">
      <main className="flex-1 flex flex-col overflow-y-auto focus:outline-none">
        <div className="relative max-w-4xl mx-auto px-6 2xl:px-0 w-full flex flex-col flex-1">
          <div className="pt-10 pb-6 flex flex-col flex-1">
            <Switch>
              <Route path={`${path}/new`} component={AddressBookNew} />
              <Route path={`${path}/:addressId`} component={AddressBookEdit} />
              <Route path={path} exact component={AddressBookList} />
            </Switch>
          </div>
        </div>
      </main>
    </div>
  );
};
