import React, { useEffect, useState } from 'react';
import { CarrierAccount } from '../../types/__generated__/graphql';
import { CarrierRow } from '../CarriersAndServices/CarrierRow';
import { Form, Formik } from 'formik';
import { Button } from '../Button';
import { useSaveEnabledCarriersMutation } from '../../operations/save-enabled-carriers.gql';
import { ErrorModal } from '../ErrorModal';
import { ENABLED_CARRIER_IDS_QUERY } from '../../operations/enabled-carrier-ids.gql';
import {
  EnabledCarrierIdsQuery,
  EnabledCarrierIdsQueryVariables,
} from '../../operations/__generated__/enabled-carrier-ids.gql.generated';
import { Card } from '../Card';
import { CardHeading } from '../CardHeading';
import { CardFooter } from '../CardFooter';
import { BannerAlert } from '../BannerAlert';
import { CardContent } from '../CardContent';

interface EnabledCarriersFormValues {
  enabledCarrierIds: string[];
}

export interface EnabledCarriersFormProps {
  initialEnabledCarrierIds: string[];
  carrierAccounts: CarrierAccount[];
  integrationId: string;
  orderChannelId: string;
}

export const EnabledCarriersForm: React.FC<EnabledCarriersFormProps> = ({
  carrierAccounts,
  initialEnabledCarrierIds,
  integrationId,
  orderChannelId,
}) => {
  const [save, { loading, error }] = useSaveEnabledCarriersMutation();
  const [showErrorModal, setShowErrorModal] = useState(false);
  const handleSubmit = async (values: EnabledCarriersFormValues) => {
    try {
      await save({
        variables: {
          integrationId,
          orderChannelId,
          carrierAccounts: values.enabledCarrierIds.map((id) => ({
            id,
            carrier: carrierAccounts.find((account) => account.id === id)!.carrier,
          })),
        },
        update(cache, result) {
          if (result.data) {
            cache.writeQuery<EnabledCarrierIdsQuery, EnabledCarrierIdsQueryVariables>({
              query: ENABLED_CARRIER_IDS_QUERY,
              variables: { integrationId, orderChannelId },
              data: {
                enabledCarrierAccounts: result.data.setEnabledCarrierAccountIds.carrierAccountIds,
              },
            });
          }
        },
      });
    } catch (err) {
      // TODO: report to sentry
    }
  };

  const initialValues: EnabledCarriersFormValues = {
    enabledCarrierIds: initialEnabledCarrierIds,
  };

  const hideErrorModal = () => {
    setShowErrorModal(false);
  };

  useEffect(() => {
    if (error) {
      setShowErrorModal(true);
    }
  }, [error]);

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ values, setFieldValue, dirty }) => {
        const handleChange = (id: string, enabled: boolean) => {
          if (enabled && !values.enabledCarrierIds.includes(id)) {
            setFieldValue('enabledCarrierIds', [...values.enabledCarrierIds, id]);
          } else if (!enabled && values.enabledCarrierIds.includes(id)) {
            const index = values.enabledCarrierIds.indexOf(id);
            setFieldValue('enabledCarrierIds', [
              ...values.enabledCarrierIds.slice(0, index),
              ...values.enabledCarrierIds.slice(index + 1),
            ]);
          }
        };

        return (
          <Form>
            <Card>
              <CardHeading subtitle="Select which carriers are enabled for rate shopping.">Carriers</CardHeading>
              <CardContent className="space-y-4">
                {integrationId === 'global' ? (
                  <BannerAlert message="Order channel is currently set to Global. This configuration will be used for any channel that does not specify their own." />
                ) : null}
                {carrierAccounts.map(({ name, carrier, description, id }) => (
                  <CarrierRow
                    key={id}
                    accountName={name}
                    carrier={carrier}
                    description={description}
                    checked={values.enabledCarrierIds.includes(id)}
                    onChange={(enabled) => handleChange(id, enabled)}
                    disabled={loading}
                  />
                ))}
                <ErrorModal isOpen={showErrorModal} onClose={hideErrorModal} message={error?.message} />
              </CardContent>
              <CardFooter className="flex justify-end">
                <Button type="submit" disabled={!dirty} loading={loading}>
                  Save
                </Button>
              </CardFooter>
            </Card>
          </Form>
        );
      }}
    </Formik>
  );
};
