import React, { forwardRef } from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/solid';
import styles from './Input.module.scss';
import classNames from 'classnames';

export interface InputProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  error?: string | null;
  label?: string;
  icon?: React.ComponentType<any>;
  hideErrorMessage?: boolean;
  controlClassName?: string;
  trailingAddon?: string;
}

export const InputGroup = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      error,
      label,
      icon: Icon,
      className,
      controlClassName,
      hideErrorMessage,
      type = 'text',
      trailingAddon,
      ...inputProps
    },
    ref
  ) => (
    <div className={className}>
      {label ? (
        <label htmlFor={inputProps.id} className="block text-sm font-medium text-gray-700 mb-1">
          {label}
        </label>
      ) : null}
      <div
        className={classNames(
          'relative shadow-sm',
          { 'rounded-md': !controlClassName?.includes('rounded') },
          controlClassName
        )}
      >
        <input
          ref={ref}
          className={classNames(
            styles.input,
            {
              [styles['input--error']]: error,
              [styles['input--trailing-addon']]: trailingAddon,
              [styles['input--icon']]: Icon,
              [styles.rounded]: !controlClassName?.includes('rounded'),
            },
            controlClassName
          )}
          type={type}
          {...inputProps}
          aria-invalid={Boolean(error)}
        />
        {Icon ? (
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none z-10">
            <Icon className={`h-5 w-5 ${error ? 'text-red-400' : 'text-gray-400'}`} aria-hidden="true" />
          </div>
        ) : null}
        {error ? (
          <div
            className={classNames(
              'absolute inset-y-0 right-0 flex items-center pointer-events-none z-10',
              trailingAddon ? 'pr-12' : 'pr-3'
            )}
          >
            <ExclamationCircleIcon className="h-5 w-5 text-red-500 bg-white" aria-hidden="true" />
          </div>
        ) : null}
        {trailingAddon ? (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none z-10">
            <span className="text-gray-500 sm:text-sm" id="price-currency">
              {trailingAddon}
            </span>
          </div>
        ) : null}
      </div>
      {error && !hideErrorMessage ? (
        <p className="mt-2 text-sm text-red-600" id={`${inputProps.id}-error`}>
          {error}
        </p>
      ) : null}
    </div>
  )
);
