import React from 'react';
import { Link } from 'react-router-dom';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import classNames from 'classnames';

export interface Breadcrumb {
  label: string;
  path: string;
}

export interface BreadcrumbsProps {
  breadcrumbs: Breadcrumb[];
}

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ breadcrumbs }) => {
  const backPath = breadcrumbs.length > 1 ? breadcrumbs[breadcrumbs.length - 2].path : null;

  return (
    <div>
      {backPath ? (
        <nav className="sm:hidden" aria-label="Back">
          <Link to={backPath} className="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700">
            <ChevronLeftIcon className="flex-shrink-0 -ml-1 mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
            Back
          </Link>
        </nav>
      ) : null}
      <nav className="hidden sm:flex" aria-label="Breadcrumb">
        <ol className="flex items-center space-x-4">
          {breadcrumbs.map((breadcrumb, index) => (
            <li key={breadcrumb.path}>
              <div className={index !== 0 ? 'flex items-center' : undefined}>
                {index !== 0 ? (
                  <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                ) : null}
                <Link
                  to={breadcrumb.path}
                  className={classNames('text-sm font-medium text-gray-500 hover:text-gray-700', {
                    'ml-4': index !== 0,
                  })}
                >
                  {breadcrumb.label}
                </Link>
              </div>
            </li>
          ))}
        </ol>
      </nav>
    </div>
  );
};
