import React from 'react';
import classNames from 'classnames';

export interface TableHeaderCellProps {
  className?: string;
}

export const TableHeaderCell: React.FC<TableHeaderCellProps> = ({ children, className }) => {
  return (
    <th
      scope="col"
      className={classNames(
        'px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider',
        { 'text-left': !className?.includes('text-right') },
        className
      )}
    >
      {children}
    </th>
  );
};
