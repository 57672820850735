import React, { FC, useEffect } from 'react';
import { PackageFields } from '../PackageFields';
import { useOrder } from '../../providers/Order/OrderProvider';
import { useOrderForm } from './OrderForm';
import { PrintNodeStatus, usePrintNode } from '../../providers/PrintNodeProvider';
import { Card } from '../Card';
import { CardHeading } from '../CardHeading';
import { CardContent } from '../CardContent';

export const PackageInfo: FC = () => {
  const { order } = useOrder();
  const { scaleStatus, scaleMeasurement } = usePrintNode();
  const { values, setFieldValue } = useOrderForm();

  useEffect(() => {
    if (scaleStatus === PrintNodeStatus.ready && scaleMeasurement !== null && !order?.shipped) {
      setFieldValue('packages.0.weight', scaleMeasurement, true);
    }
  }, [scaleStatus, scaleMeasurement, setFieldValue, order?.shipped]);

  return (
    <Card className="mb-6">
      <CardHeading>Package Info</CardHeading>
      <CardContent>
        <div className="flex">
          {values.packages.map((pkg, index) => (
            <PackageFields key={pkg.id} className="col-span-4" disabled={order?.shipped} index={index} />
          ))}
        </div>
      </CardContent>
    </Card>
  );
};
