import React, { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react';
import { InputGroup } from '../components/InputGroup';
import { LAST_USED_INTEGRATION_KEY } from './Ship';
import { useAlIntegrationsQuery } from '../operations/all-integrations.gql';
import { Select } from '../components/Select';
import { InlineAlert } from '../components/InlineAlert';
import { Button } from '../components/Button';
import { SearchIcon } from '@heroicons/react/outline';
import { Card } from '../components/Card';
import { CardFooter } from '../components/CardFooter';
import { CardContent } from '../components/CardContent';
import { useOrderV2LazyQuery } from '../operations/order-v2.gql';
import { Redirect } from 'react-router-dom';

export const QualityControl: React.FC = () => {
  const [integrationId, setIntegrationId] = useState(localStorage.getItem(LAST_USED_INTEGRATION_KEY) ?? '');
  const [orderId, setOrderId] = useState('');
  const [validationError, setValidationError] = useState<string | null>(null);
  const orderInputRef = useRef<HTMLInputElement | null>(null);
  const { data: integrationData, loading: integrationsLoading } = useAlIntegrationsQuery();
  const [getOrder, { data: orderData, loading: orderLoading, error: orderError }] = useOrderV2LazyQuery();

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    setValidationError(null);

    let convertedOrderId = orderId;

    if (orderId.startsWith('^') || orderId.endsWith('^')) {
      const matches = /\^#\^([a-zA-Z0-9-]*)\^/.exec(orderId);
      if (!matches?.length) {
        setValidationError('Invalid scan. Please try scanning again.');
        return;
      }

      convertedOrderId = Number.parseInt(matches[1], 16).toString();
    }

    getOrder({ variables: { integrationId, orderId: convertedOrderId } });
  };

  const handleOrderIdChange = (e: ChangeEvent<HTMLInputElement>) => {
    setOrderId(e.target.value);
  };

  const handleIntegrationIdChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setIntegrationId(e.target.value);
    localStorage.setItem(LAST_USED_INTEGRATION_KEY, e.target.value);
  };

  useEffect(() => {
    if (integrationData?.integrations.length && !integrationId) {
      setIntegrationId(integrationData.integrations[0].id);
    }
  }, [integrationId, integrationData]);

  useEffect(() => {
    if (orderInputRef.current) {
      orderInputRef.current?.focus();
    }
  }, []);

  return (
    <div className="flex-1 flex flex-col bg-white">
      {orderData?.orderV2 ? (
        <Redirect to={`/quality-control/${integrationId}/${orderData.orderV2.orderId}`} push />
      ) : null}
      <div className="md:hidden border-b">
        <div className="max-w-2xl mx-auto w-full flex items-center min-w-0 px-4 py-4">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">Quality Control</h2>
        </div>
      </div>

      <div className="flex-1 bg-gray-100 px-2 py-4 overflow-y-auto flex flex-col lg:justify-center">
        <form onSubmit={handleSubmit}>
          <Card className="max-w-2xl mx-auto w-full">
            <CardContent className="space-y-4">
              <Select
                label="Integration"
                name="integrationId"
                value={integrationId}
                onChange={handleIntegrationIdChange}
              >
                <option value="" disabled>
                  {integrationsLoading ? 'Loading...' : 'Select an integration'}
                </option>

                {integrationData?.integrations.map((integration) => (
                  <option key={integration.id} value={integration.id}>
                    {integration.description}
                  </option>
                )) ?? null}
              </Select>
              <InputGroup
                ref={orderInputRef}
                name="orderId"
                label="Order ID"
                type="text"
                value={orderId}
                onChange={handleOrderIdChange}
                required
              />
            </CardContent>
            <CardFooter className="flex justify-end">
              <Button type="submit" icon={SearchIcon} loading={orderLoading} disabled={integrationsLoading}>
                Search
              </Button>
            </CardFooter>
          </Card>
          {validationError ? <InlineAlert className="mt-4" type="error" title={validationError} /> : null}
          {orderError ? (
            <InlineAlert className="mt-4" type="error" title={orderError.message} />
          ) : orderData && orderData.orderV2 === null ? (
            <InlineAlert className="mt-4" type="error" title="Order not found" />
          ) : null}
        </form>
      </div>
    </div>
  );
};
