import React from 'react';
import { ManifestTable } from '../components/ManifestTable';
import { Button } from '../components/Button';
import { LoadingPanel } from '../components/LoadingPanel';
import { Link } from 'react-router-dom';
import { PageHeading } from '../components/PageHeading';
import { useManifestUpdatedV2Subscription } from '../operations/manifest-updated-v2.gql';
import { useManifestsV2Query } from '../operations/manifests-v2.gql';
import { ErrorState } from '../components/ErrorState';
import { EmptyState } from '../components/EmptyState';

export const ManifestV2: React.FC = () => {
  useManifestUpdatedV2Subscription();
  const { data, loading, error } = useManifestsV2Query();

  const manifests = data?.manifestsV2.items ?? [];

  return (
    <div className="max-w-7xl mx-auto p-6 lg:p-8 flex-1 flex flex-col w-full bg-gray-100 overflow-y-auto">
      <PageHeading title="Manifests">
        <Link to="/manifest/create">
          <Button>Create</Button>
        </Link>
      </PageHeading>

      {loading ? (
        <LoadingPanel />
      ) : error ? (
        <ErrorState>{error.message}</ErrorState>
      ) : manifests.length ? (
        <ManifestTable manifests={manifests} />
      ) : (
        <EmptyState className="flex-1" title="No manifests yet." subtext="Start by creating a manifest." />
      )}
    </div>
  );
};
