import React, { FC, useState } from 'react';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import * as yup from 'yup';
import { PackageInfo } from './PackageInfo';
import { RateShop } from './RateShop';
import { usePurchaseLabel } from '../../operations/purchase-label.gql';
import { Modal } from '../Modal';
import { ExclamationIcon } from '@heroicons/react/outline';
import { OrderInfo } from './OrderInfo';
import { createHashFromObject } from '../../utilities/create-hash-from-object';
import { usePrintNode } from '../../providers/PrintNodeProvider';
import { OrderTimeline } from './OrderTimeline';
import mixpanel from 'mixpanel-browser';
import { useFacilities } from '../../providers/FacilityProvider';
import { EasyPostCarrier } from '../../types/__generated__/graphql';
import { GetOrderQuery } from '../../operations/__generated__/order.gql.generated';
import { usePrintNodeApi } from '../../utilities/print-node';

const schema = yup.object().shape({
  packages: yup.array().of(
    yup.object().shape({
      length: yup.number().min(0.01, 'Must be >= 0.01').required('Length is required'),
      width: yup.number().min(0.01, 'Must be >= 0.01').required('Width is required'),
      height: yup.number().min(0.01, 'Must be >= 0.01').required('Height is required'),
      weight: yup.number().min(0.01, 'Weight must be >= 0.01').required('Weight is required'),
    })
  ),
  easyPostRateId: yup.string().required('Select a shipping product'),
});

export interface OrderFormValues {
  packages: {
    id: string;
    length: number;
    width: number;
    height: number;
    weight: number;
    hash: number;
  }[];
  addressValidated: boolean;
  easyPostRateId: string | null;
  easyPostShipmentId: string | null;
  selectedCarrier: EasyPostCarrier | null;
  selectedService: string | null;
  allowInvalidAddress: boolean;
}

const getInitialValuesFromOrder = (order: NonNullable<GetOrderQuery['order']>): OrderFormValues => ({
  packages: order.parcels.map((parcel) => ({
    id: parcel.id,
    length: parcel.length,
    width: parcel.width,
    height: parcel.height,
    weight: parcel.weight,
    hash: createHashFromObject({
      length: parcel.length,
      width: parcel.width,
      height: parcel.height,
      weight: parcel.weight,
    }),
  })),
  addressValidated: order?.shipToValidated?.isValid ?? false,
  // if the address is valid, create hash, otherwise don't.
  easyPostRateId: null,
  easyPostShipmentId: null,
  selectedCarrier: null,
  selectedService: null,
  allowInvalidAddress: false,
});

export interface OrderFormProps {
  order: NonNullable<GetOrderQuery['order']>;
}

export const OrderForm: FC<OrderFormProps> = ({ order }) => {
  const [labelPurchase] = usePurchaseLabel();
  const [submitError, setSubmitError] = useState<string | null>(null);
  const { selectedPrinter } = usePrintNode();
  const { selectedFacilityId } = useFacilities();
  const { printNodeApi } = usePrintNodeApi();

  const closeErrorModal = () => setSubmitError(null);
  const isErrorModalOpen = Boolean(submitError);

  const handleSubmit = async (values: OrderFormValues, helpers: FormikHelpers<OrderFormValues>) => {
    console.log('submitting values', values);
    if (!selectedFacilityId) {
      alert('Please select a shipping facility in the top right of the header.');
      return;
    }
    try {
      helpers.setSubmitting(true);
      mixpanel.track('Purchase Label');
      const { data } = await labelPurchase({
        variables: {
          orderId: order.id,
          easyPostShipmentId: values.easyPostShipmentId!,
          carrier: values.selectedCarrier!,
          service: values.selectedService!,
          rateId: values.easyPostRateId!,
          locationId: +selectedFacilityId,
        },
      });
      mixpanel.track('Purchase Label Succeeded');
      if (selectedPrinter === null) {
        console.warn('No printer is selected.');
        alert('No printer selected!');
        return;
      }
      if (data?.purchaseLabel?.labels) {
        for (const label of data.purchaseLabel.labels) {
          const payload = {
            printerId: selectedPrinter,
            contentType: 'raw_uri',
            content: label.labelFileUrl,
          };
          mixpanel.track('Send Print Request');
          printNodeApi
            ?.createPrintJob(payload)
            .then(() => mixpanel.track('Print Request Successful'))
            .catch((err) => {
              console.error(err);
              mixpanel.track('Print Request Failed');
            });
        }
      } else {
        mixpanel.track('No Shipment Labels');
      }
    } catch (err) {
      mixpanel.track('Purchase Label Failed');
      console.error(err);
      setSubmitError(err.message);
    } finally {
      helpers.setSubmitting(false);
    }
  };

  return (
    <>
      <Formik initialValues={getInitialValuesFromOrder(order)} validationSchema={schema} onSubmit={handleSubmit}>
        <Form className="flex-1 flex items-stretch">
          <main className="flex-1 min-h-0 max-w-6xl mx-auto grid grid-cols-3 gap-6">
            {/* Primary column */}
            <div className="col-span-2">
              <OrderInfo />
              <PackageInfo />

              {!order.shipped ? <RateShop /> : null}
            </div>
            <OrderTimeline order={order} />
          </main>

          {/*{!order.shipped ? (*/}
          {/*  <aside className="flex-col hidden w-1/2 max-w-2xl bg-white border-l border-gray-200 overflow-hidden lg:flex"></aside>*/}
          {/*) : null}*/}
        </Form>
      </Formik>
      <Modal
        header="An error occurred"
        Icon={ExclamationIcon}
        kind="error"
        isOpen={isErrorModalOpen}
        message={submitError ?? ''}
        onClose={closeErrorModal}
      />
    </>
  );
};

export const useOrderForm = () => {
  const formik = useFormikContext<OrderFormValues>();

  const packageHashes = formik.values.packages.map((parcel) =>
    createHashFromObject({
      length: parcel.length,
      width: parcel.width,
      height: parcel.height,
      weight: parcel.weight,
    })
  );

  const isPackageDirty = packageHashes.some((hash, index) => formik.values.packages[index].hash !== hash);

  return { ...formik, isPackageDirty };
};
