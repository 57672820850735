/* This example requires Tailwind CSS v2.0+ */
import React, { FC } from 'react';
import classNames from 'classnames';

export interface CardHeadingProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  subtitle?: string;
}

export const CardHeading: FC<CardHeadingProps> = ({ children, className, subtitle, ...divProps }) => {
  return (
    <div className={classNames('bg-white px-4 py-5 sm:px-6', className)} {...divProps}>
      <h3 className="text-lg leading-6 font-medium text-gray-900">{children}</h3>
      {subtitle ? <p className="text-gray-500">{subtitle}</p> : null}
    </div>
  );
};
