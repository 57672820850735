import React from 'react';
import { Breadcrumb, Breadcrumbs } from './Breadcrumbs';

interface PageHeadingProps {
  breadcrumbs?: Breadcrumb[];
  title: string;
}

export const PageHeading: React.FC<PageHeadingProps> = ({ breadcrumbs, children, title }) => {
  return (
    <div className="mb-4">
      {breadcrumbs?.length ? <Breadcrumbs breadcrumbs={breadcrumbs} /> : null}
      <div className="mt-2 md:flex md:items-center md:justify-between">
        <div className="flex-1 min-w-0">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">{title}</h2>
        </div>
        {children ? <div className="mt-4 flex md:mt-0 md:ml-4">{children}</div> : null}
      </div>
    </div>
  );
};
