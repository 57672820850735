import { usePrintNodeApiKeyQuery } from '../operations/print-node-api-key.gql';
import { useEffect, useState } from 'react';

export interface WhoAmI {
  ApiKeys: string[];
  Tags: string[];
  canCreateSubAccounts: boolean;
  childAccounts: number[];
  connected: number[];
  creatorEmail: string | null;
  creatorRef: string | null;
  credits: string | null;
  email: string;
  firstname: string;
  id: number;
  lastname: string;
  numComputers: number;
  permissions: string[];
  state: string;
  totalPrints: 0;
  versions: string[];
}

export interface Computer {
  createTimestamp: string;
  hostname: string;
  id: number;
  inet: string | null;
  inet6: string | null;
  jre: string | null;
  name: string;
  state: 'connected' | 'disconnected';
  version: string;
}

export interface Printer {
  computer: Computer;
  createTimestamp: string;
  default: boolean;
  description: string | null;
  id: number;
  name: string;
  state: string;
}

export interface PrintJobPayload {
  printerId: number;
  contentType: string;
  content: string;
}

export interface Scale {
  productId: number;
  count: null | number;
  deviceName: string;
  port: string;
  vendorId: number;
  computerId: number;
  vendor: string;
  measurement: {
    lb?: number; // 750000000
    oz?: number;
    kg?: number;
    g?: number;
  };
  mass: [number, number | null]; //[340194000,null], micrograms
  deviceNum: number;
  ageOfData: number;
  ntpOffset: null | number;
  clientReportedCreateTimestamp: string;
  product: string;
}

class PrintNodeApi {
  api: any;
  websocket: any;

  constructor(private apiKey: string) {
    this.api = new PrintNode.HTTP(new PrintNode.HTTP.ApiKey(apiKey));
  }

  public connect = (): Promise<void> =>
    new Promise((resolve, reject) => {
      if (this.websocket) {
        return resolve();
      }
      this.websocket = new PrintNode.WebSocket(
        { apiKey: this.apiKey },
        function authenticated(this: any) {
          console.info('Connected to PrintNode WS');
          resolve();
        },
        (err: any) => {
          console.error('Error connected to PrintNode WS:', err);
          reject(err);
        }
      );
    });

  public whoAmI = async (): Promise<WhoAmI> =>
    new Promise((resolve, reject) => {
      this.api.whoami({ success: resolve, error: reject });
    });

  public listComputers = async (): Promise<Computer[]> =>
    new Promise((resolve, reject) => {
      this.api.computers({ success: resolve, error: reject });
    });

  public listPrinters = async (computerId: number): Promise<Printer[]> =>
    new Promise((resolve, reject) => {
      this.api.printers({ success: resolve, error: reject }, { computerSet: `${computerId}` });
    });

  public createPrintJob = async (payload: PrintJobPayload): Promise<void> =>
    new Promise((resolve, reject) => {
      this.api.createPrintjob({ success: resolve, error: reject }, payload);
    });

  public listScales = async (computerId: number): Promise<Scale[]> =>
    new Promise((resolve, reject) => {
      this.api.scales({ success: resolve, error: reject }, { computerId: `${computerId}` });
    });

  public listenToScale = (computerId: string, scale: Scale, handler: (scale: Scale) => void): string => {
    console.log('listening to scales on computer', computerId);

    const subscription = this.websocket.getScales({
      computerId,
      deviceName: scale.deviceName,
      deviceNum: scale.deviceNum,
    });

    this.websocket.subscribe(`scales`, handler);

    return subscription;
  };

  public stopListening(subscription?: string) {
    this.websocket?.removeServerSubscription(subscription);
  }
}

export const usePrintNodeApi = () => {
  const [printNodeApi, setPrintNodeApi] = useState<PrintNodeApi | null>(null);
  const { data, loading } = usePrintNodeApiKeyQuery();

  useEffect(() => {
    if (data?.printNodeApiKey) {
      setPrintNodeApi(new PrintNodeApi(data.printNodeApiKey));
    }
  }, [data]);

  return {
    printNodeApi,
    loading,
  };
};
