import React from 'react';
import { useFacilities } from '../providers/FacilityProvider';
import { Select } from './Select';

export const FacilitySelect: React.FC = () => {
  const { selectedFacilityId, setSelectedFacilityId, facilities, facilitiesLoading } = useFacilities();

  const options = facilities.map((facility) => ({ label: `${facility.name} (${facility.id})`, value: facility.id }));

  return (
    <Select
      value={selectedFacilityId ?? undefined}
      onChange={(evt) => setSelectedFacilityId(evt.target.value)}
      disabled={facilitiesLoading}
    >
      {facilitiesLoading ? (
        <option>Loading facilities...</option>
      ) : (
        options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))
      )}
    </Select>
  );
};
