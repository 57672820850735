import { FC, useEffect } from 'react';
import { OrderForm } from '../components/Order/OrderForm';
import { FulfillHeading } from '../components/Fulfill/FulfillHeading';
import { useOrder } from '../providers/Order/OrderProvider';
import { OrderLoadingState } from '../components/Order/OrderLoadingState';
import { OrderNotFoundState } from '../components/Order/OrderNotFoundState';
import { OrderErrorState } from '../components/Order/OrderErrorState';
import mixpanel from 'mixpanel-browser';

export const Order: FC = () => {
  const { order, loading, error } = useOrder();

  const labelUrls = order?.parcels?.filter((parcel) => parcel.labelUrl).map((parcel) => parcel.labelUrl!) ?? [];

  useEffect(() => {
    if (error) {
      const errorType = error.toLowerCase().includes('order address') ? 'Address Validation' : 'Unknown';
      mixpanel.track('Load Order Error', { 'Error Type': errorType });
    }
  }, [error]);

  return loading ? (
    <OrderLoadingState />
  ) : order ? (
    <>
      <FulfillHeading orderId={order.id} shipped={order.shipped} labelUrls={labelUrls} />
      <OrderForm order={order} />
    </>
  ) : error ? (
    <OrderErrorState error={error} />
  ) : (
    <OrderNotFoundState />
  );
};
