import { gql, useQuery } from '@apollo/client';
import { ADDRESS_DETAILS_FRAGMENT } from './fragments/address-details.gql';
import { AddressBookAddressQueryVariables } from './__generated__/address-book-address.gql.generated';
import { AddressBookAddressQuery } from './__generated__/address-book-address.gql.generated';

export const ADDRESS_BOOK_ADDRESS_QUERY = gql`
  query AddressBookAddress($id: ID!) {
    addressBookAddress(id: $id) {
      id
      addressName
      address {
        ...AddressDetails
      }
    }
  }

  ${ADDRESS_DETAILS_FRAGMENT}
`;

export const useAddressBookAddressQuery = (id: string) =>
  useQuery<AddressBookAddressQuery, AddressBookAddressQueryVariables>(ADDRESS_BOOK_ADDRESS_QUERY, {
    variables: { id },
  });
