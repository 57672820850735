import React from 'react';
import { ReactComponent as ErrorSvg } from '../../assets/illustrations/warning.svg';

interface OrderErrorStateProps {
  error: string;
}

export const OrderErrorState: React.FC<OrderErrorStateProps> = ({ error }) => (
  <div className="flex-1 flex flex-col items-center justify-center max-w-xl self-center text-center">
    <ErrorSvg className="w-full h-auto mb-8" />
    <h1 className="text-2xl sm:text-3xl font-bold mb-2 text-red-700">Error Loading Order</h1>
    <p className="text-red-900 max-w-xl" style={{ whiteSpace: 'pre' }}>
      {error}
    </p>
  </div>
);
