import { gql, useMutation } from '@apollo/client';
import {
  CreateLocateInvIntegrationMutation,
  CreateLocateInvIntegrationMutationVariables,
} from './__generated__/create-locate-inv-integration.gql.generated';

export const CREATE_LOCATE_INV_INTEGRATION_MUTATION = gql`
  mutation CreateLocateInvIntegration($description: String!, $apiKey: String!, $apiUrl: String!) {
    createLocateInvIntegration(input: { description: $description, apiKey: $apiKey, apiUrl: $apiUrl }) {
      id
      type
      description
      readable
      apiKey
      apiUrl
    }
  }
`;

export const useCreateLocateInvIntegrationMutation = () =>
  useMutation<CreateLocateInvIntegrationMutation, CreateLocateInvIntegrationMutationVariables>(
    CREATE_LOCATE_INV_INTEGRATION_MUTATION,
    { refetchQueries: ['AllIntegrations'] }
  );
