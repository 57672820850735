import React, { useState } from 'react';
import { ManifestTable } from '../components/ManifestTable';
import { Button } from '../components/Button';
import { useManifestsQuery } from '../operations/manifests.gql';
import { LoadingPanel } from '../components/LoadingPanel';
import { Modal } from '../components/Modal';
import { ExclamationIcon } from '@heroicons/react/outline';
import { Link } from 'react-router-dom';
import { useFacilities } from '../providers/FacilityProvider';
import { useManifestUpdatedSubscription } from '../operations/manifest-updated.gql';
import { PageHeading } from '../components/PageHeading';

export const Manifest: React.FC = () => {
  const { selectedFacilityId } = useFacilities();
  useManifestUpdatedSubscription();
  const { data, loading, error } = useManifestsQuery(selectedFacilityId);
  const [createError, setCreateError] = useState<string | null>(null);

  const manifests = data?.manifests;

  return (
    <div className="max-w-7xl mx-auto p-6 lg:p-8 flex-1 w-full bg-gray-100">
      <PageHeading title="Manifests">
        <Link to="/manifest/create">
          <Button>Create</Button>
        </Link>
      </PageHeading>

      <Modal
        header="Unable to create manifest"
        Icon={ExclamationIcon}
        kind="error"
        isOpen={Boolean(createError)}
        message={createError ?? ''}
        onClose={() => setCreateError(null)}
      />

      {/*<CreateManifestModal isOpen={isModalOpen} onClose={handleClose} onCreate={handleCreate} />*/}

      {!selectedFacilityId ? (
        'Please select a facility.'
      ) : loading ? (
        <LoadingPanel />
      ) : error ? (
        <span>'An error occurred loading the data'</span>
      ) : manifests?.length ? (
        <ManifestTable manifests={manifests} />
      ) : (
        <span>No manifests</span>
      )}
    </div>
  );
};
