import { gql, useMutation } from '@apollo/client';
import {
  PurchaseLabelV2Mutation,
  PurchaseLabelV2MutationVariables,
} from './__generated__/purchase-label-v2.gql.generated';

export const PURCHASE_LABEL_V2_MUTATION = gql`
  mutation PurchaseLabelV2($input: PurchaseLabelV2Input!) {
    purchaseLabelV2(input: $input) {
      labels {
        labelFileType
        labelFileUrl
      }
      trackingNumbers
      carrier
      service
      printed
    }
  }
`;

export const usePurchaseLabelV2Mutation = () =>
  useMutation<PurchaseLabelV2Mutation, PurchaseLabelV2MutationVariables>(PURCHASE_LABEL_V2_MUTATION);
