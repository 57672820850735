/* This example requires Tailwind CSS v2.0+ */
import { CheckCircleIcon, ExclamationIcon, InformationCircleIcon, XCircleIcon } from '@heroicons/react/solid';
import { FC } from 'react';
import classNames from 'classnames';

type AlertType = 'warning' | 'error' | 'success' | 'info';

interface InlineAlertProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  type?: AlertType;
  title: string;
  errors?: string[];
}

const getIconFromType = (type: AlertType) => {
  switch (type) {
    case 'error':
      return XCircleIcon;
    case 'info':
      return InformationCircleIcon;
    case 'success':
      return CheckCircleIcon;
    case 'warning':
      return ExclamationIcon;
  }
};

const getColorFromType = (type: AlertType) => {
  switch (type) {
    case 'error':
      return 'red';
    case 'info':
      return 'blue';
    case 'success':
      return 'green';
    case 'warning':
      return 'yellow';
  }
};

export const InlineAlert: FC<InlineAlertProps> = ({ className, errors, title, type = 'error' }) => {
  const Icon = getIconFromType(type);
  const color = getColorFromType(type);

  return (
    <div className={classNames(`rounded-md bg-${color}-50 p-4 border border-${color}-500`, className)}>
      <div className="flex">
        <div className="flex-shrink-0">
          <Icon className={`h-5 w-5 text-${color}-400`} aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className={`text-sm font-medium text-${color}-800`}>{title}</h3>
          {errors && errors.length ? (
            <div className={`mt-2 text-sm text-${color}-700`}>
              <ul className="list-disc pl-5 space-y-1">
                {errors.map((error, index) => (
                  <li key={index}>{error}</li>
                ))}
              </ul>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
