import React from 'react';
import { OrderDetailsHeader } from '../components/OrderDetails/OrderDetailsHeader';
import { useOrderV2Query } from '../operations/order-v2.gql';
import { useRouteMatch } from 'react-router-dom';
import { OrderSummary } from '../components/OrderDetails/OrderSummary';
import { useRefundOrderV2Mutation } from '../operations/refund-order-v2.gql';
import { ErrorModal } from '../components/ErrorModal';
import { useErrorModal } from '../utilities/use-error-modal';
import { useConfirmationModal } from '../utilities/use-confirmation-modal';
import { ExclamationCircleIcon } from '@heroicons/react/solid';
import { ConfirmationModal } from '../components/ConfirmationModal';
import { useOrderShipmentsQuery } from '../operations/order-shipments.gql';
import { ShipmentsSummary } from '../components/OrderDetails/ShipmentsSummary';
import { useOrderLabelsQuery } from '../operations/order-labels.gql';
import { usePrintNode } from '../providers/PrintNodeProvider';
import mixpanel from 'mixpanel-browser';
import { Modal } from '../components/Modal';
import { usePrintNodeApi } from '../utilities/print-node';

export const OrderDetails: React.FC = () => {
  const {
    params: { integrationId, orderId },
  } = useRouteMatch<{ integrationId: string; orderId: string }>();
  const { data: orderData, loading: orderDataLoading, refetch: refetchOrder } = useOrderV2Query({
    integrationId,
    orderId,
  });
  const { data: shipmentData, loading: shipmentDataLoading, refetch: refetchShipments } = useOrderShipmentsQuery({
    integrationId,
    orderId: orderData?.orderV2?.orderId ?? orderId,
    disabled: !orderData?.orderV2?.orderId,
  });
  const [refundOrder, { data: refundData, loading: refunding, error: refundError }] = useRefundOrderV2Mutation();
  const { data: orderLabelsData, loading: orderLabelsLoading } = useOrderLabelsQuery({ integrationId, orderId });
  const { showErrorModal, handleErrorModalClose } = useErrorModal(refundError?.message);
  const { showErrorModal: showWarningModal, handleErrorModalClose: handleWarningModalClose } = useErrorModal(
    refundData?.refundOrderV2.message ?? undefined
  );
  const { printNodeApi } = usePrintNodeApi();
  const { selectedPrinter } = usePrintNode();

  const order = orderData?.orderV2;

  const handleRefundOrder = async () => {
    if (!orderData || !orderData.orderV2?.orderChannelId) {
      return;
    }

    const { orderChannelId } = orderData.orderV2;

    try {
      const response = await refundOrder({
        variables: {
          input: { integrationId, orderChannelId, orderId: orderData?.orderV2?.orderId ?? orderId },
        },
      });

      if (!response.data?.refundOrderV2.message) {
        refetchOrder();
      } else {
        refetchShipments();
      }
    } catch (err) {
      // TODO: Capture error in sentry
    }
  };

  const handleReprintLabels = async () => {
    if (!orderLabelsData || !selectedPrinter) {
      return;
    }

    for (const label of orderLabelsData.orderLabels) {
      const payload = {
        printerId: selectedPrinter,
        contentType: 'raw_uri',
        content: label.labelFileUrl,
      };
      console.log('Printing label');
      try {
        await printNodeApi?.createPrintJob(payload);
        mixpanel.track('Print Request Successful');
      } catch {
        mixpanel.track('Print Request Failed');
      }
    }
  };

  const { openConfirmationModal, props: confirmationModalProps } = useConfirmationModal({
    header: 'Are you sure?',
    acceptButtonKind: 'destructive',
    acceptButtonText: 'Void',
    kind: 'error',
    Icon: ExclamationCircleIcon,
    message:
      'This will void the shipments for this order, and the labels must be regenerated. This action cannot be undone.',
    onAccept: handleRefundOrder,
  });

  const shipments = shipmentData?.orderShipments ?? [];

  return (
    <>
      <ErrorModal isOpen={showErrorModal} onClose={handleErrorModalClose} message={refundError?.message} />
      <Modal
        header="Refund Notice"
        Icon={ExclamationCircleIcon}
        kind="warning"
        isOpen={showWarningModal}
        message={refundData?.refundOrderV2.message ?? ''}
        onClose={handleWarningModalClose}
      />
      <ConfirmationModal {...confirmationModalProps} />
      {orderDataLoading ? null : order ? (
        <div className="flex-1 flex flex-col overflow-hidden">
          <OrderDetailsHeader
            orderId={orderId}
            integrationId={integrationId}
            orderChannelId={order.orderChannelId}
            orderDate={order.orderDate}
            requestedServiceType={order.requestedServiceType}
            refunding={refunding}
            onVoid={openConfirmationModal}
            onReprint={handleReprintLabels}
            reprintDisabled={orderLabelsLoading}
          />

          <div className="flex-1 overflow-y-auto">
            <div className="flex mx-auto w-full max-w-7xl p-8">
              <div className="flex-1 flex flex-col space-y-8 flex-shrink-0">
                <OrderSummary order={order} />
                <ShipmentsSummary loading={shipmentDataLoading} shipments={shipments} />
              </div>
              {/*<div className="flex flex-col ml-8" style={{ width: '30%' }}>*/}
              {/*  Side panel*/}
              {/*</div>*/}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
