import { gql, useMutation } from '@apollo/client';
import {
  ChangeRulePriorityMutation,
  ChangeRulePriorityMutationVariables,
} from './__generated__/change-rule-priority.gql.generated';

export const CHANGE_RULE_PRIORITY = gql`
  mutation ChangeRulePriority($id: ID!, $increase: Boolean!) {
    changeRulePriority(id: $id, increase: $increase)
  }
`;

export const useChangeRulePriorityMutation = () =>
  useMutation<ChangeRulePriorityMutation, ChangeRulePriorityMutationVariables>(CHANGE_RULE_PRIORITY, {
    refetchQueries: ['ListRules'],
  });
