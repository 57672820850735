import { gql, useMutation } from '@apollo/client';
import { UpdateUserMutationVariables } from './__generated__/update-user.gql.generated';
import { UpdateUserMutation } from './__generated__/update-user.gql.generated';

export const UPDATE_USER_MUTATION = gql`
  mutation UpdateUser($id: ID!, $updates: UpdateUserInput!) {
    updateUser(id: $id, updates: $updates) {
      id
      roles
      name
    }
  }
`;

export const useUpdateUserMutation = () =>
  useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UPDATE_USER_MUTATION);
