import React from 'react';
import { TableHead } from '../TableHead';
import { TableHeaderCell } from '../TableHeaderCell';
import { TableCell } from '../TableCell';
import { ReportDetailsFragment } from '../../operations/fragments/__generated__/report-details.gql.generated';

export interface AddressBookTableProps {
  reports: ReportDetailsFragment[];
}

export const ReportTable: React.FC<AddressBookTableProps> = ({ reports }) => {
  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <TableHead>
                <tr>
                  <TableHeaderCell>Created At</TableHeaderCell>
                  <TableHeaderCell>Report Type</TableHeaderCell>
                  <TableHeaderCell>Report Name</TableHeaderCell>
                  <TableHeaderCell>Status</TableHeaderCell>
                  <TableHeaderCell className="sr-only">Download</TableHeaderCell>
                </tr>
              </TableHead>

              <tbody>
                {reports.map((report, index) => (
                  <tr key={report.id} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                    <TableCell>{new Date(report.createdAt).toLocaleString()}</TableCell>
                    <TableCell>{report.type}</TableCell>
                    <TableCell>{report.name}</TableCell>
                    <TableCell>{report.status}</TableCell>
                    <TableCell>
                      {report.fileUrl ? (
                        <a
                          href={report.fileUrl}
                          target="_blank"
                          rel="noreferrer"
                          className="text-blue-600 hover:text-blue-900"
                        >
                          Download
                        </a>
                      ) : null}
                    </TableCell>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
