import { gql, useMutation } from '@apollo/client';
import {
  CreateTplcIntegrationMutation,
  CreateTplcIntegrationMutationVariables,
} from './__generated__/create-tplc-integration.gql.generated';

export const CREATE_TPLC_INTEGRATION_MUTATION = gql`
  mutation CreateTplcIntegration(
    $description: String!
    $clientId: String!
    $clientSecret: String!
    $userLogin: String!
  ) {
    createTplcIntegration(
      input: { description: $description, clientId: $clientId, clientSecret: $clientSecret, userLogin: $userLogin }
    ) {
      id
      type
      description
      readable
      clientId
      clientSecret
      userLogin
    }
  }
`;

export const useCreateTplcIntegration = () =>
  useMutation<CreateTplcIntegrationMutation, CreateTplcIntegrationMutationVariables>(CREATE_TPLC_INTEGRATION_MUTATION, {
    refetchQueries: ['AllIntegrations'],
  });
