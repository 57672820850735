/* This example requires Tailwind CSS v2.0+ */
import { CalendarIcon } from '@heroicons/react/solid';
import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { AllRuleDetailsFragment } from '../../operations/fragments/__generated__/all-rule-details.gql.generated';
import { Badge } from '../Badge';
import { ChevronDoubleUpIcon } from '@heroicons/react/outline';
import { PriorityButton } from './atoms/priority-button';
import { useChangeRulePriorityMutation } from '../../operations/change-rule-priority.gql';

export interface RulesListProps {
  rules: AllRuleDetailsFragment[];
}

export const RulesList: React.FC<RulesListProps> = ({ rules }) => {
  const [changePriority, { loading: prioritiesUpdating }] = useChangeRulePriorityMutation();
  const { url } = useRouteMatch();

  const handleChangePriority = async (id: string, direction: 'up' | 'down') => {
    try {
      await changePriority({
        variables: {
          id,
          increase: direction === 'up',
        },
      });
    } catch (err) {
      //TODO: Capture in sentry
    }
  };

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-md">
      <ul className="divide-y divide-gray-200">
        {rules.map((rule, index, arr) => (
          <li key={rule.id} className="flex group">
            <div className="flex flex-col justify-around pl-4 pr-2 py-4 flex-shrink-0 group-hover:bg-gray-50">
              <PriorityButton
                direction="up"
                disabled={prioritiesUpdating || index === 0}
                onClick={() => handleChangePriority(rule.id, 'up')}
              />
              <PriorityButton
                direction="down"
                disabled={prioritiesUpdating || index === arr.length - 1}
                onClick={() => handleChangePriority(rule.id, 'down')}
              />
            </div>
            <Link to={`${url}/${rule.id}`} className="block flex-1 group-hover:bg-gray-50">
              <div className="pl-2 pr-4 py-4 sm:pr-6 sm:pl-2">
                <div className="flex items-center justify-between">
                  <p className="text-sm font-medium text-blue-600 truncate">{rule.name}</p>
                  <div className="ml-2 flex-shrink-0 flex">
                    {rule.active ? <Badge color="green">Active</Badge> : <Badge color="red">Inactive</Badge>}
                  </div>
                </div>
                <div className="mt-2 sm:flex sm:justify-between">
                  <div className="sm:flex">
                    <p className="flex items-center text-sm text-gray-500">
                      <ChevronDoubleUpIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                      {index + 1}
                    </p>
                    <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                      Triggers <Badge className="ml-2"> {rule.triggers.length} </Badge>
                    </p>
                    <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                      Modifiers <Badge className="ml-2"> {rule.modifiers.length} </Badge>
                    </p>
                    <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                      Rate Filters <Badge className="ml-2"> {rule.rateFilters.length} </Badge>
                    </p>
                    <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                      SmartRate <Badge className="ml-2"> {rule.smartRateConfiguration ? 'Yes' : 'No'} </Badge>
                    </p>
                  </div>
                  <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                    <CalendarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                    <p>
                      Last modified on{' '}
                      <time dateTime={rule.updatedAt}>{new Date(rule.updatedAt).toLocaleString()}</time>
                    </p>
                    <p>{/*Closing on <time dateTime={rule.closeDate}>{rule.closeDateFull}</time>*/}</p>
                  </div>
                </div>
              </div>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};
