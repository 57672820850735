import { gql, useQuery } from '@apollo/client';
import { ALL_RULE_DETAILS_FRAGMENT } from './fragments/all-rule-details.gql';
import { GetRuleQuery, GetRuleQueryVariables } from './__generated__/get-rule.gql.generated';

export const GET_RULE_QUERY = gql`
  query GetRule($id: ID!) {
    rule(id: $id) {
      ...AllRuleDetails
    }
  }
  ${ALL_RULE_DETAILS_FRAGMENT}
`;

export const useGetRuleQuery = (id: string) =>
  useQuery<GetRuleQuery, GetRuleQueryVariables>(GET_RULE_QUERY, {
    variables: { id },
    notifyOnNetworkStatusChange: true,
  });
