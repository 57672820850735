import { ErrorMessage, Field } from 'formik';
import React, { FC } from 'react';
import classNames from 'classnames';
import { Input } from './Input';
import { LockOpenIcon } from '@heroicons/react/solid';
import styles from './PackageFields.module.scss';

export interface DimensionFieldsProps
  extends React.DetailedHTMLProps<React.FieldsetHTMLAttributes<HTMLFieldSetElement>, HTMLFieldSetElement> {
  disabled?: boolean;
  index: number;
}

export const PackageFields: FC<DimensionFieldsProps> = ({ className, disabled, index }) => {
  return (
    <>
      <fieldset className={classNames(styles.packageFields, className)} disabled={disabled}>
        <span className="col-span-1">#</span>
        <span className="block text-sm font-medium text-gray-700 col-span-3">Dimensions (inches)</span>
        <span className="block text-sm font-medium text-gray-700 col-span-1">Weight (lbs)</span>

        <>
          <span className="col-span-1">1</span>
          <div className="mt-1 rounded-md shadow-sm -space-y-px col-span-3">
            <div className="flex -space-x-px">
              <div className="w-1/3 flex-1 min-w-0 relative">
                <label htmlFor={`packages.${index}.length`} className="sr-only">
                  Length
                </label>
                <Field
                  type="number"
                  name={`packages.${index}.length`}
                  id={`packages.${index}.length`}
                  as={Input}
                  className="rounded-none rounded-tl-md rounded-bl-md"
                  style={{ paddingLeft: '1.75rem' }}
                />
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <span className="text-gray-500 sm:text-sm">L</span>
                </div>
              </div>
              <div className="flex-1 min-w-0 relative">
                <label htmlFor={`packages.${index}.width`} className="sr-only">
                  Width
                </label>
                <Field
                  type="number"
                  name={`packages.${index}.width`}
                  id={`packages.${index}.width`}
                  as={Input}
                  className="rounded-none"
                  style={{ paddingLeft: '1.75rem' }}
                />
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <span className="text-gray-500 sm:text-sm">W</span>
                </div>
              </div>
              <div className="flex-1 min-w-0 relative">
                <label htmlFor={`packages.${index}.height`} className="sr-only">
                  Height
                </label>
                <Field
                  type="number"
                  name={`packages.${index}.height`}
                  id={`packages.${index}.height`}
                  as={Input}
                  className="rounded-none rounded-tr-md rounded-br-md"
                  style={{ paddingLeft: '1.75rem' }}
                />
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <span className="text-gray-500 sm:text-sm">H</span>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-1 relative rounded-md shadow-sm col-span-1">
            <label htmlFor={`packages.${index}.weight`} className="sr-only">
              Weight
            </label>
            <Field
              type="number"
              name={`packages.${index}.weight`}
              id={`packages.${index}.weight`}
              as={Input}
              className="rounded-md"
              step={0.01}
              placeholder="0.00"
            />
            <div className="absolute inset-y-0 right-0 flex items-center hidden 2xl:flex">
              <button className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
                <LockOpenIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </button>
            </div>
          </div>
        </>

        <ErrorMessage className="col-span-1 col-start-5 text-red-700" component="div" name="packages.0.weight" />
      </fieldset>
    </>
  );
};
