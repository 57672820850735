import React from 'react';
import { ShipFormContent } from '../atoms/ShipFormContent';
import { ShipFormFooter } from '../atoms/ShipFormFooter';
import { Button } from '../../Button';
import { PackageFieldsV2 } from '../elements/PackageFieldsV2';
import { useHistory } from 'react-router-dom';
import { useFormValidator, useMarkSectionAsTouched, useShipForm } from '../ShipForm';

export const PackagesSection: React.FC = () => {
  const history = useHistory();
  const { isPackagesSectionValid, isAddressSectionValid } = useFormValidator();
  const { markAddressesAsTouched, markPackagesAsTouched } = useMarkSectionAsTouched();
  const { isSubmitting } = useShipForm();

  const handleNextClick = async () => {
    markPackagesAsTouched();
    markAddressesAsTouched();

    if (!isPackagesSectionValid) {
      return;
    } else if (!isAddressSectionValid) {
      return history.push('addresses');
    }

    history.push('rate-shop');
  };

  return (
    <>
      <ShipFormContent>
        <PackageFieldsV2 disabled={isSubmitting} />
      </ShipFormContent>
      <ShipFormFooter>
        <Button type="button" onClick={handleNextClick} disabled={isSubmitting} size="lg">
          Next
        </Button>
      </ShipFormFooter>
    </>
  );
};
