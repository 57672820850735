import React from 'react';
import { FormInput } from '../../Form/FormInput';
import { FormSelect } from '../../Form/FormSelect';
import { FormTagInput } from '../../Form/FormTagInput';
import { FormMultiSelect } from '../../Form/FormMultiSelect';

interface StringFieldProps {
  label: string;
  name: string;
  multi?: boolean;
  options?: { value: string; label: string }[] | null;
  disabled?: boolean;
}

export const StringField: React.FC<StringFieldProps> = ({ disabled, name, label, multi, options }) => {
  const optionsWithDefault = options ? [{ label: 'Select an option...', value: '' }, ...options] : null;

  return multi && !optionsWithDefault?.length ? (
    <FormTagInput label={label} name={name} disabled={disabled} hideErrorMessage />
  ) : multi && optionsWithDefault?.length ? (
    <FormMultiSelect name={name} options={options!} label={label} disabled={disabled} />
  ) : optionsWithDefault?.length ? (
    <FormSelect label={label} name={name} options={optionsWithDefault} hideErrorMessage disabled={disabled} />
  ) : (
    <FormInput label={label} name={name} type="text" hideErrorMessage disabled={disabled} />
  );
};
