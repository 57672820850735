import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Login } from './pages/Login';
import { ForgotPassword } from './pages/ForgotPassword';
import { UserProvider } from './providers/UserProvider';
import { PrivateRoute } from './components/PrivateRoute';
import { ChangePassword } from './pages/ChangePassword';
import { Home } from './pages/Home';
import { PrintNodeProvider } from './providers/PrintNodeProvider';
import { FacilityProvider } from './providers/FacilityProvider';
import { FeatureFlagProvider } from './providers/FeatureFlagProvider';

function App() {
  return (
    <UserProvider>
      <FeatureFlagProvider>
        <Router>
          <Switch>
            <Route path="/login">
              <Login />
            </Route>
            <Route path="/forgot-password">
              <ForgotPassword />
            </Route>
            <Route path="/change-password">
              <ChangePassword />
            </Route>
            <PrivateRoute path="/">
              <FacilityProvider>
                <PrintNodeProvider>
                  <Home />
                </PrintNodeProvider>
              </FacilityProvider>
            </PrivateRoute>
          </Switch>
        </Router>
      </FeatureFlagProvider>
    </UserProvider>
  );
}

export default App;
