import { gql, useLazyQuery, useQuery } from '@apollo/client';
import {
  TriggerValueOptionsQuery,
  TriggerValueOptionsQueryVariables,
} from './__generated__/trigger-value-options.gql.generated';
import { TriggerField } from '../types/__generated__/graphql';

export const TRIGGER_VALUE_OPTIONS = gql`
  query TriggerValueOptions($triggerField: TriggerField!) {
    triggerValueOptions(field: $triggerField) {
      label
      value
    }
  }
`;

export const useTriggerValueOptionsLazyQuery = () =>
  useLazyQuery<TriggerValueOptionsQuery, TriggerValueOptionsQueryVariables>(TRIGGER_VALUE_OPTIONS, {
    notifyOnNetworkStatusChange: true,
  });

export const useTriggerValueOptionsQuery = (triggerField: TriggerField) =>
  useQuery<TriggerValueOptionsQuery, TriggerValueOptionsQueryVariables>(TRIGGER_VALUE_OPTIONS, {
    variables: {
      triggerField,
    },
    notifyOnNetworkStatusChange: true,
  });
