/* This example requires Tailwind CSS v2.0+ */
import React from 'react';
import { CalendarIcon, ShoppingBagIcon, TruckIcon } from '@heroicons/react/solid';
import { useShipForm } from '../ShipForm';
import { useAllOrderChannelsQuery } from '../../../operations/all-order-channels.gql';

export const ShipFormHeader: React.FC = () => {
  const { values } = useShipForm();
  const { data: orderChannelData } = useAllOrderChannelsQuery();

  const integration = orderChannelData?.integrations.find((integration) => integration.id === values.integrationId);
  const orderChannel = integration?.orderChannels.find((channel) => channel.id === values.orderChannelId);
  const orderDate = values.orderDate ? new Date(values.orderDate).toDateString() : null;

  const title = values.orderId ? `Order # ${values.orderId}` : 'Custom Shipment';

  return (
    <div className="lg:flex lg:items-center lg:justify-between px-8 py-4 border-b border-gray-300">
      <div className="flex-1 min-w-0">
        <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">{title}</h2>
        <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
          {orderChannel ? (
            <div className="mt-2 flex items-center text-sm text-gray-500">
              <ShoppingBagIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
              {orderChannel.name}
            </div>
          ) : null}
          {values.requestedServiceType ? (
            <div className="mt-2 flex items-center text-sm text-gray-500">
              <TruckIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
              {values.requestedServiceType}
            </div>
          ) : null}
          {orderDate ? (
            <div className="mt-2 flex items-center text-sm text-gray-500">
              <CalendarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
              Ordered on {orderDate}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
