import { gql, useQuery } from '@apollo/client';
import { ListRulesQuery, ListRulesQueryVariables } from './__generated__/list-rules.gql.generated';
import { SortOrder } from '../types/__generated__/graphql';
import { ALL_RULE_DETAILS_FRAGMENT } from './fragments/all-rule-details.gql';

export const LIST_RULES_QUERY = gql`
  query ListRules($input: ListRulesInput!) {
    rules(input: $input) {
      count
      limit
      nextToken
      items {
        ...AllRuleDetails
      }
    }
  }
  ${ALL_RULE_DETAILS_FRAGMENT}
`;

export interface UseListRulesQueryParams {
  integrationId: string;
  orderChannelId: string;
}

export const useListRulesQuery = (params: UseListRulesQueryParams) =>
  useQuery<ListRulesQuery, ListRulesQueryVariables>(LIST_RULES_QUERY, {
    variables: {
      input: {
        ...params,
        pagination: {
          limit: 50,
          nextToken: null,
        },
        sortOrder: SortOrder.Asc,
      },
    },
    notifyOnNetworkStatusChange: true,
  });
