import { gql } from '@apollo/client/core';
import { useLazyQuery } from '@apollo/client';
import { RateShopQuery, RateShopQueryVariables } from './__generated__/rate-shop.gql.generated';

export const RATE_SHOP_QUERY = gql`
  query RateShop($orderId: ID!, $easyPostAddressId: ID!, $parcels: [ParcelV2Input!]!) {
    rateShop(input: { tplcOrderId: $orderId, easyPostAddressId: $easyPostAddressId, parcels: $parcels }) {
      id
      easyPostShipmentId
      carrier
      cost
      serviceCode
      deliveryDays
      deliveryDateGuaranteed
      timeInTransit {
        percentile_50
        percentile_75
        percentile_85
        percentile_90
        percentile_95
        percentile_97
        percentile_99
      }
    }
  }
`;

export const useRateShopLazyQuery = () => {
  return useLazyQuery<RateShopQuery, RateShopQueryVariables>(RATE_SHOP_QUERY, { fetchPolicy: 'network-only' });
};
