import React from 'react';
import { TableHead } from '../TableHead';
import { TableHeaderCell } from '../TableHeaderCell';
import { TableCell } from '../TableCell';
import { Link, useRouteMatch } from 'react-router-dom';

export interface AddressBookTableProps {
  addresses: {
    id: string;
    addressName: string;
    createdAt: string;
    updatedAt: string;
  }[];
}

const formatDate = (date: string) => new Date(date).toLocaleString();

export const AddressBookTable: React.FC<AddressBookTableProps> = ({ addresses }) => {
  const { url } = useRouteMatch();

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <TableHead>
                <tr>
                  <TableHeaderCell>Address Name</TableHeaderCell>
                  <TableHeaderCell>Created At</TableHeaderCell>
                  <TableHeaderCell>Updated At</TableHeaderCell>
                  <TableHeaderCell />
                </tr>
              </TableHead>

              <tbody>
                {addresses.map((address, index) => (
                  <tr key={address.id} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                    <TableCell>{address.addressName}</TableCell>
                    <TableCell>{formatDate(address.createdAt)}</TableCell>
                    <TableCell>{formatDate(address.updatedAt)}</TableCell>
                    <TableCell>
                      <Link to={`${url}/${address.id}`} className="text-blue-600 hover:text-blue-900">
                        View/Edit
                      </Link>
                    </TableCell>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
