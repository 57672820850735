import React from 'react';
import { TrashIcon } from '@heroicons/react/outline';
import classNames from 'classnames';

export interface DeleteButtonProps
  extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {}

export const DeleteButton: React.FC<DeleteButtonProps> = ({ className, ...buttonProps }) => {
  return (
    <button
      className={classNames(
        'text-gray-500 hover:text-red-400 w-9 h-9 flex items-center justify-center rounded focus:outline-none focus:ring-2 focus:ring-red-500 disabled:cursor-not-allowed',
        className
      )}
      type="button"
      {...buttonProps}
    >
      <TrashIcon className="w-5 h-5" />
    </button>
  );
};
