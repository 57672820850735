import React, { FC, FormEvent, Fragment, useEffect, useState } from 'react';
import { ChatIcon, MenuAlt2Icon } from '@heroicons/react/outline';
import { SearchIcon } from '@heroicons/react/solid';
import { Menu, Transition } from '@headlessui/react';
import { PrinterStatusIcon } from '../PrinterStatusIcon';
import { ScaleStatusIcon } from '../ScaleStatusIcon';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { FacilitySelect } from '../FacilitySelect';
import mixpanel from 'mixpanel-browser';
import { useAlIntegrationsQuery } from '../../operations/all-integrations.gql';
import { useFeatureFlag } from '../../providers/FeatureFlagProvider';
import { LAST_USED_INTEGRATION_KEY } from '../../pages/Ship';
import { useUser } from '../../providers/UserContext';

export interface HeaderProps {
  onMobileMenuOpen: () => void;
}

export const Header: FC<HeaderProps> = ({ onMobileMenuOpen }) => {
  const [integrationId, setIntegrationId] = useState(localStorage.getItem(LAST_USED_INTEGRATION_KEY) ?? '');
  const [orderId, setOrderId] = useState('');
  const history = useHistory();
  const shipV2Flag = useFeatureFlag('ShipV2');
  const { data: integrationData } = useAlIntegrationsQuery();
  const { user } = useUser();

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    mixpanel.track('Search Order');

    shipV2Flag
      ? history.push(`/ship?orderId=${orderId}&integrationId=${integrationId}`)
      : history.push(`/fulfill/${orderId}`);
    setOrderId('');
  };

  useEffect(() => {
    if (integrationData?.integrations.length && !integrationId) {
      setIntegrationId(integrationData.integrations[0].id);
    }
  }, [integrationData, integrationId]);

  const signOut = async () => {
    await Auth.signOut();
  };

  const initials = user.attributes?.name
    ? user.attributes.name
        .split(' ')
        .map((part: string) => part[0])
        .join('')
        .toUpperCase()
    : user.attributes?.email[0].toUpperCase() ?? '';

  return (
    <header className="w-full">
      <div className="relative z-10 flex-shrink-0 h-16 bg-white border-b border-gray-200 shadow-sm flex">
        <button
          type="button"
          className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500 md:hidden"
          onClick={onMobileMenuOpen}
        >
          <span className="sr-only">Open sidebar</span>
          <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
        </button>
        <div className="flex-1 flex justify-between px-4 sm:px-6">
          <span className="flex-1 md:hidden" />
          <div className="flex-1 hidden md:flex">
            <form className="w-full flex md:ml-0" onSubmit={handleSubmit}>
              <label htmlFor="search_field" className="sr-only">
                Search orders
              </label>
              {shipV2Flag ? null : (
                <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center">
                    <SearchIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
                  </div>
                  <input
                    name="search_field"
                    id="search_field"
                    className="h-full w-full border-transparent py-2 pl-8 pr-3 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-0 focus:border-transparent focus:placeholder-gray-400"
                    placeholder="Search orders"
                    type="search"
                    required
                    value={orderId}
                    onChange={(evt) => setOrderId(evt.target.value)}
                  />
                </div>
              )}
            </form>
          </div>
          <div className="ml-2 flex items-center space-x-2 sm:ml-6 sm:space-x-4">
            <ScaleStatusIcon />
            <PrinterStatusIcon />
            {shipV2Flag ? null : <FacilitySelect />}
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSeU73zPyrbH6S2XjcZ_x3c4vu9pOLn2ewpmP7GnDAlJLPcoTw/viewform?usp=sf_link"
              target="_blank"
              rel="noreferrer"
              title="Leave feedback"
              className="w-8 h-8 bg-white flex justify-center items-center focus:outline-none focus:ring-2 focus:ring-blue-500 rounded-full"
            >
              <ChatIcon className="w-6 h-6 text-gray-500" />
            </a>
            {/* Profile dropdown */}
            <Menu as="div" className="relative flex-shrink-0">
              {({ open }) => (
                <>
                  <div>
                    <Menu.Button className="bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                      <span className="sr-only">Open user menu</span>
                      <span className="h-8 w-8 bg-blue-200 flex items-center justify-center rounded-full">
                        {initials}
                      </span>
                    </Menu.Button>
                  </div>
                  <Transition
                    show={open}
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items
                      static
                      className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                    >
                      <div className="text-sm px-4 py-2 border-b text-gray-500">
                        {user.attributes?.name ?? user.attributes?.email ?? ''}
                      </div>
                      <Menu.Item>
                        <button
                          type="button"
                          className="block px-4 py-2 text-sm text-gray-700 w-full text-left"
                          onClick={signOut}
                        >
                          Sign out
                        </button>
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </>
              )}
            </Menu>
          </div>
        </div>
      </div>
    </header>
  );
};
