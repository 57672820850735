import { FC, useEffect, useMemo, useState } from 'react';
import { Auth } from 'aws-amplify';
import { UserContext } from './UserContext';
import { Hub, Logger } from 'aws-amplify';

const logger = new Logger('AuthLogger');

export const UserProvider: FC = ({ children }) => {
  const [user, setUser] = useState<null | any>(null);
  const [loading, setLoading] = useState(true);
  const [tempUser, setTempUser] = useState<null | any>(null);
  const [groups, setGroups] = useState<string[]>([]);

  useEffect(() => {
    setLoading(true);
    Auth.currentAuthenticatedUser()
      .then((user) => {
        setUser(user);
        if (user) {
          setGroups(user.signInUserSession.accessToken.payload['cognito:groups']);
        }
      })
      .catch(() => setUser(null))
      .finally(() => setLoading(false));

    Hub.listen('auth', ({ payload }) => {
      switch (payload.event) {
        case 'signIn': {
          setUser(payload.data);
          break;
        }
        case 'signOut': {
          setUser(null);
          break;
        }
        default: {
          logger.info('unhandled event', payload.event);
        }
      }
    });
  }, []);

  const values = useMemo(() => ({ user, tempUser, setTempUser, groups }), [user, tempUser, setTempUser, groups]);

  return <UserContext.Provider value={values}>{loading ? null : children}</UserContext.Provider>;
};
