import { IntegrationType } from '../types/__generated__/graphql';

export const formatOrderChannelName = (name: string, integrationType: IntegrationType) => {
  switch (integrationType) {
    case IntegrationType.Tplc:
      return `(3PLC) ${name}`;
    case IntegrationType.ShipStation:
      return `(SS) ${name}`;
    default:
      return name;
  }
};
