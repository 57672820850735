const {
  REACT_APP_APPSYNC_ENDPOINT,
  REACT_APP_APPSYNC_REGION,
  REACT_APP_USER_POOL_ID,
  REACT_APP_WEB_CLIENT_ID,
} = process.env;

export const stage = {
  awsConfig: {
    appsyncEndpoint: REACT_APP_APPSYNC_ENDPOINT!,
    appsyncRegion: REACT_APP_APPSYNC_REGION!,
    userPoolId: REACT_APP_USER_POOL_ID!,
    userPoolWebClientId: REACT_APP_WEB_CLIENT_ID!,
  },
};
