import { gql, useQuery } from '@apollo/client';
import { AllUsersQuery, AllUsersQueryVariables } from './__generated__/all-users.gql.generated';

export const ALL_USERS_QUERY = gql`
  query AllUsers($pagination: PaginationInput!) {
    allUsers(pagination: $pagination) {
      limit
      nextToken
      items {
        id
        email
        name
        roles
      }
    }
  }
`;

export const useAllUsersQuery = (limit: number, nextToken: string | null) => {
  return useQuery<AllUsersQuery, AllUsersQueryVariables>(ALL_USERS_QUERY, {
    variables: { pagination: { limit, nextToken } },
  });
};
