import React from 'react';
import { SectionHeadingWithAction } from '../components/Layout/SectionHeadingWithAction';
import { UserForm, UserFormValues } from '../components/UserManagement/UserForm';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useUpdateUserMutation } from '../operations/update-user.gql';
import { useUserQuery } from '../operations/user.gql';
import { LoadingPanel } from '../components/LoadingPanel';
import { useErrorModal } from '../utilities/use-error-modal';
import { ErrorModal } from '../components/ErrorModal';

export const EditUser: React.FC = () => {
  const {
    path,
    params: { userId },
  } = useRouteMatch<{ userId: string }>();
  const history = useHistory();

  const { data: userData, loading: userLoading } = useUserQuery(userId);
  const [updateUser, { loading, error }] = useUpdateUserMutation();
  const { showErrorModal, handleErrorModalClose } = useErrorModal(error?.message);

  const handleSubmit = async (values: UserFormValues) => {
    try {
      await updateUser({
        variables: {
          id: userId,
          updates: {
            name: values.name,
            roles: values.roles,
          },
        },
        refetchQueries: ['AllUsers'],
      });
      history.push('/settings/user-management');
    } catch (err) {
      // TODO: log to sentry or something
    }
  };

  return (
    <div className="flex-1 flex flex-col h-full">
      <main className="flex-1 overflow-y-auto focus:outline-none">
        <div className="relative max-w-xl mx-auto px-6 md:p-6 2xl:p-0 h-full">
          <div className="pt-10 pb-16 h-full">
            <SectionHeadingWithAction
              title="New User"
              breadcrumbs={[
                { path: `/settings/user-management`, label: 'User Management' },
                { path: `${path}`, label: 'Edit User' },
              ]}
            />
            {userLoading ? (
              <LoadingPanel className="flex-1" />
            ) : userData ? (
              <UserForm
                initialValues={{
                  email: userData.user.email,
                  name: userData.user.name ?? '',
                  roles: userData.user.roles,
                }}
                emailDisabled={true}
                submitText="Update User"
                onSubmit={handleSubmit}
                loading={loading}
                error={error?.message}
              />
            ) : null}
          </div>
        </div>
      </main>
      <ErrorModal isOpen={showErrorModal} onClose={handleErrorModalClose} message={error?.message} />
    </div>
  );
};
