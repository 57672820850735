import React from 'react';
import { FormInput } from '../../Form/FormInput';

export interface ServiceAdjustmentSectionProps {
  accountId: string;
  defaultCarrierAdjustment: string | number;
  service: {
    label: string;
    value: string;
  };
}

export const ServiceAdjustmentSection: React.FC<ServiceAdjustmentSectionProps> = ({
  accountId,
  defaultCarrierAdjustment,
  service,
}) => {
  return (
    <>
      <div className="col-span-2 pl-8">
        <span>{service.label}</span>
      </div>

      <FormInput
        className="col-span-1"
        name={`carrierAdjustments.${accountId}.serviceAdjustments.${service.value}`}
        type="number"
        id={`carrier-adjustment-${accountId}-${service.value}`}
        trailingAddon="%"
        placeholder={defaultCarrierAdjustment.toString()}
        hideErrorMessage
      />

      <span />
    </>
  );
};
