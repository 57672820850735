import { gql, useQuery } from '@apollo/client';
import {
  TriggerFieldOptionsQuery,
  TriggerFieldOptionsQueryVariables,
} from './__generated__/trigger-field-options.gql.generated';

export const TRIGGER_FIELD_OPTIONS = gql`
  query TriggerFieldOptions {
    triggerFieldOptions {
      label
      value
      dataType
      operators {
        label
        value
      }
    }
  }
`;

export const useTriggerFieldOptionsQuery = () =>
  useQuery<TriggerFieldOptionsQuery, TriggerFieldOptionsQueryVariables>(TRIGGER_FIELD_OPTIONS);
