import React from 'react';

export const ShipFormFooter: React.FC = ({ children }) => {
  return (
    <footer className="flex-shrink-0 border-t flex justify-center">
      <div className="max-w-7xl w-full px-4 sm:px-6 lg:px-8 py-6 flex justify-end space-x-4 items-center">
        {children}
      </div>
    </footer>
  );
};
