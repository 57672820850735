import React from 'react';
import * as yup from 'yup';
import { Form, Formik, FormikHelpers } from 'formik';
import { Card } from '../Card';
import { CardContent } from '../CardContent';
import { FormSelect } from '../Form/FormSelect';
import { CardFooter } from '../CardFooter';
import { Button } from '../Button';
import { FormInput } from '../Form/FormInput';

export interface GeneralSettingsValues {
  defaultShipFromAddressId?: string | null;
  defaultReturnAddressId?: string | null;
  customsSigner?: string | null;
  defaultEmail?: string | null;
  defaultPhoneNumber?: string | null;
}

const validationSchema = yup.object().shape({
  defaultShipFromAddressId: yup.string().optional(),
  defaultReturnAddressId: yup.string().optional(),
  customsSigner: yup.string().optional(),
  defaultEmail: yup.string().optional(),
  defaultPhoneNumber: yup.string().optional(),
});

export interface GeneralSettingsFormProps {
  addressOptions: { label: string; value: string }[];
  disabled: boolean;
  initialValues: GeneralSettingsValues;
  onSubmit: (values: GeneralSettingsValues, helpers: FormikHelpers<GeneralSettingsValues>) => void;
}

export const GeneralSettingsForm: React.FC<GeneralSettingsFormProps> = ({
  addressOptions,
  disabled,
  initialValues,
  onSubmit,
}) => {
  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ dirty }) => (
        <Form>
          <Card>
            <CardContent className="grid grid-cols-6 gap-4">
              <FormSelect
                className="col-span-3"
                label="Default Ship From Address"
                name="defaultShipFromAddressId"
                disabled={disabled}
              >
                <option value="">Select an option...</option>
                {addressOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </FormSelect>
              <FormSelect
                className="col-span-3"
                label="Default Return Address"
                name="defaultReturnAddressId"
                disabled={disabled}
              >
                <option value="">Select an option...</option>
                {addressOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </FormSelect>
              <FormInput
                className="col-span-3"
                name="customsSigner"
                label="Customs Signer"
                disabled={disabled}
                id="customsSigner"
              />
              <FormInput
                className="col-span-3"
                name="defaultEmail"
                label="Default Email"
                disabled={disabled}
                id="defaultEmail"
              />
              <FormInput
                className="col-span-3"
                name="defaultPhoneNumber"
                label="Default Phone Number"
                disabled={disabled}
                id="defaultPhoneNumber"
              />
            </CardContent>
            <CardFooter className="flex justify-end space-x-4">
              <Button type="submit" loading={disabled} disabled={!dirty}>
                Save
              </Button>
            </CardFooter>
          </Card>
        </Form>
      )}
    </Formik>
  );
};
