import { gql, useQuery } from '@apollo/client';
import { GeneralSettingsQuery, GeneralSettingsQueryVariables } from './__generated__/general-settings.gql.generated';

export const GENERAL_SETTINGS_QUERY = gql`
  query GeneralSettings {
    generalSettings {
      defaultReturnAddressId
      defaultShipFromAddressId
      customsSigner
      defaultEmail
      defaultPhoneNumber
    }
  }
`;

export const useGeneralSettingsQuery = () =>
  useQuery<GeneralSettingsQuery, GeneralSettingsQueryVariables>(GENERAL_SETTINGS_QUERY);
