import { gql } from '@apollo/client/core';
import { useQuery } from '@apollo/client';
import { AllFacilitiesQuery, AllFacilitiesQueryVariables } from './__generated__/all-facilities.gql.generated';

export const ALL_FACILITIES_QUERY = gql`
  query AllFacilities {
    allFacilities {
      id
      name
    }
  }
`;

export const useAllFacilitiesQuery = () => {
  return useQuery<AllFacilitiesQuery, AllFacilitiesQueryVariables>(ALL_FACILITIES_QUERY);
};
