import { gql } from '@apollo/client';

export const ALL_RULE_DETAILS_FRAGMENT = gql`
  fragment AllRuleDetails on Rule {
    id
    name
    createdAt
    updatedAt
    active
    priority
    triggers {
      field
      operator
      value
    }
    modifiers {
      field
      value
    }
    rateFilters {
      field
      operator
      value
    }
    smartRateConfiguration {
      deliveryDays
      percentile
      defaultCarrier
      defaultService
    }
  }
`;
