import { gql, useQuery } from '@apollo/client';
import { PrintNodeApiKeyQuery, PrintNodeApiKeyQueryVariables } from './__generated__/print-node-api-key.gql.generated';

export const PRINT_NODE_API_KEY_QUERY = gql`
  query PrintNodeApiKey {
    printNodeApiKey
  }
`;

export const usePrintNodeApiKeyQuery = () =>
  useQuery<PrintNodeApiKeyQuery, PrintNodeApiKeyQueryVariables>(PRINT_NODE_API_KEY_QUERY);
