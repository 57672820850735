import { gql, useQuery } from '@apollo/client';
import { UserQuery, UserQueryVariables } from './__generated__/user.gql.generated';

export const USER_QUERY = gql`
  query User($id: ID!) {
    user(id: $id) {
      id
      email
      name
      roles
    }
  }
`;

export const useUserQuery = (id: string) => useQuery<UserQuery, UserQueryVariables>(USER_QUERY, { variables: { id } });
