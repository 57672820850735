import React, { useState } from 'react';
import { SectionHeadingWithAction } from '../components/Layout/SectionHeadingWithAction';
import { useRouteMatch } from 'react-router-dom';
import { IntegrationType } from '../types/__generated__/graphql';
import { Select } from '../components/Select';
import { NewTplcIntegrationFormController } from '../components/Integrations/NewTplcIntegrationFormController';
import { NewShipStationIntegrationFormController } from '../components/Integrations/NewShipStationIntegrationFormController';
import { NewLocateInvIntegrationFormController } from '../components/Integrations/NewLocateInvIntegrationFormController';

const integrationTypes = [
  { name: '3PL Central', type: IntegrationType.Tplc },
  { name: 'ShipStation', type: IntegrationType.ShipStation },
  { name: 'Locate Inventory', type: IntegrationType.LocateInv },
];

export const NewIntegration: React.FC = () => {
  const [selectedIntegrationType, setSelectedIntegrationType] = useState('');
  const { path } = useRouteMatch();

  return (
    <div className="flex-1 flex flex-col h-full">
      <main className="flex-1 overflow-y-auto focus:outline-none">
        <div className="relative max-w-xl mx-auto px-6 md:p-6 2xl:p-0 h-full">
          <div className="pt-10 pb-16 h-full">
            <SectionHeadingWithAction
              title="New Integration"
              breadcrumbs={[
                { path: `/settings/integrations`, label: 'Integrations' },
                { path: `${path}`, label: 'New Integration' },
              ]}
            />
            <Select
              className="mb-8"
              value={selectedIntegrationType}
              onChange={(e) => setSelectedIntegrationType(e.target.value)}
            >
              <option value="">Select an integration</option>
              {integrationTypes.map(({ name, type }) => (
                <option key={type} value={type}>
                  {name}
                </option>
              ))}
            </Select>
            {selectedIntegrationType === IntegrationType.Tplc ? (
              <NewTplcIntegrationFormController />
            ) : selectedIntegrationType === IntegrationType.ShipStation ? (
              <NewShipStationIntegrationFormController />
            ) : selectedIntegrationType === IntegrationType.LocateInv ? (
              <NewLocateInvIntegrationFormController />
            ) : null}
          </div>
        </div>
      </main>
    </div>
  );
};
