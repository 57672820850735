import { gql } from '@apollo/client/core';
import { useQuery } from '@apollo/client';
import { SortOrder } from '../types/__generated__/graphql';
import { ManifestsV2Query, ManifestsV2QueryVariables } from './__generated__/manifests-v2.gql.generated';

export const MANIFESTS_V2_QUERY = gql`
  query ManifestsV2($limit: Int!, $sortOrder: SortOrder!, $nextToken: String) {
    manifestsV2(input: { limit: $limit, sortOrder: $sortOrder, nextToken: $nextToken }) {
      limit
      sortOrder
      nextToken
      count
      items {
        id
        createdAt
        carrier
        status
        formUrl
      }
    }
  }
`;

export const useManifestsV2Query = () => {
  return useQuery<ManifestsV2Query, ManifestsV2QueryVariables>(MANIFESTS_V2_QUERY, {
    variables: { limit: 50, sortOrder: SortOrder.Desc, nextToken: null },
  });
};
