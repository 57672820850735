import React from 'react';
import { SectionHeadingWithAction } from '../components/Layout/SectionHeadingWithAction';
import { Link, Route, Switch, useRouteMatch } from 'react-router-dom';
import { CreateReport } from './CreateReport';
import { Button } from '../components/Button';
import { useReportsQuery } from '../operations/reports.gql';
import { SortOrder } from '../types/__generated__/graphql';
import { LoadingPanel } from '../components/LoadingPanel';
import { ReportTable } from '../components/Reports/ReportTable';
import { useTenantReportsSubscription } from '../operations/reports-subscription.gql';
import { useUser } from '../providers/UserContext';

export const Reports: React.FC = () => {
  const { url } = useRouteMatch();
  const { user } = useUser();
  useTenantReportsSubscription(user.attributes['custom:tenantId']);
  const { data, loading } = useReportsQuery({ input: { nextToken: null, limit: 50, sortOrder: SortOrder.Desc } });

  const reports = data?.reports.items ?? [];

  return (
    <Switch>
      <Route path={`${url}/create`} component={CreateReport} />
      <div className="flex-1 flex flex flex-col h-full bg-gray-100">
        <main className="flex-1 flex flex-col overflow-y-auto focus:outline-none">
          <div className="relative max-w-4xl mx-auto px-6 2xl:px-0 w-full flex flex-col flex-1">
            <div className="pt-10 pb-6 flex flex-col flex-1">
              <SectionHeadingWithAction
                title="Reports"
                actions={
                  <Link to={`${url}/create`}>
                    <Button>New Report</Button>
                  </Link>
                }
              />
              {loading ? (
                <LoadingPanel className="flex-1" />
              ) : reports.length ? (
                <ReportTable reports={reports} />
              ) : null}
            </div>
          </div>
        </main>
      </div>
    </Switch>
  );
};
