import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { useOrderV2Query } from '../operations/order-v2.gql';
import { LoadingPanel } from '../components/LoadingPanel';
import { OrderDetails } from './OrderDetails';
import { ShipOrder } from './ShipOrder';

export const OrderV2: React.FC = () => {
  const {
    url,
    path,
    params: { integrationId, orderId },
  } = useRouteMatch<{ integrationId: string; orderId: string }>();

  const { data: orderData, loading: orderLoading } = useOrderV2Query({ orderId, integrationId });

  return (
    <>
      {orderLoading ? (
        <LoadingPanel className="flex-1" />
      ) : !orderData?.orderV2 ? (
        <div>Order not found.</div>
      ) : orderData.orderV2.shipped ? (
        <Redirect to={`${url}/details`} />
      ) : (
        <Redirect to={`${url}/ship`} />
      )}

      <Switch>
        <Route path={`${path}/details`} component={OrderDetails} />
        <Route path={`${path}/ship`} component={ShipOrder} />
        <Route>{null}</Route>
      </Switch>
    </>
  );
};
