import React from 'react';
import { Card } from '../Card';
import { Button } from '../Button';
import 'rc-slider/assets/index.css';
import { FormInput } from '../Form/FormInput';
import { RuleTriggerSection } from './rule-triggers/RuleTriggerSection';
import { SmartRateSection } from './smart-rate/SmartRateSection';
import { RuleModifierSection } from './rule-modifiers/RuleModifierSection';
import { RateFiltersSection } from './rate-filters/RateFiltersSection';
import { CardFooter } from '../CardFooter';

export interface RuleCardProps {
  showDeleteButton: boolean;
  onDelete?: () => void;
  saving?: boolean;
  deleting?: boolean;
}

export const RuleCard: React.FC<RuleCardProps> = ({ saving, deleting, showDeleteButton, onDelete }) => {
  const disabled = saving || deleting;

  return (
    <Card>
      <div className="bg-white px-4 py-5 sm:px-6 flex justify-between items-start">
        <FormInput
          name="name"
          className="w-full"
          label="Rule Name"
          type="text"
          placeholder="e.g. Two Day Shipping"
          disabled={disabled}
        />
      </div>

      <div className="divide-y">
        {/* Rule Triggers */}

        <RuleTriggerSection disabled={disabled} />

        {/* Modifiers */}
        <RuleModifierSection disabled={disabled} />

        {/* Rate Filters */}
        <RateFiltersSection disabled={disabled} />

        {/*SmartRate configuration*/}
        <SmartRateSection disabled={disabled} />
      </div>

      <CardFooter className="flex justify-end items-center space-x-4">
        {showDeleteButton ? (
          <Button
            className="flex-shrink-0"
            type="button"
            kind="destructive"
            onClick={onDelete}
            disabled={saving}
            loading={deleting}
          >
            Delete Rule
          </Button>
        ) : null}
        <Button className="flex-shrink-0" type="submit" loading={saving} disabled={deleting}>
          Save
        </Button>
      </CardFooter>
    </Card>
  );
};
