/* This example requires Tailwind CSS v2.0+ */
import React from 'react';
import { CalendarIcon, ShoppingBagIcon, TruckIcon } from '@heroicons/react/solid';
import { useAllOrderChannelsQuery } from '../../operations/all-order-channels.gql';
import { Button } from '../Button';

export interface OrderDetailsHeaderProps {
  refunding?: boolean;
  integrationId: string;
  onReprint?: () => void;
  onVoid?: () => void;
  orderChannelId?: string | null;
  orderDate?: string | null;
  orderId: string;
  requestedServiceType?: string | null;
  reprintDisabled: boolean;
}

export const OrderDetailsHeader: React.FC<OrderDetailsHeaderProps> = ({
  integrationId,
  onReprint,
  onVoid,
  orderChannelId,
  orderDate,
  orderId,
  refunding,
  requestedServiceType,
  reprintDisabled,
}) => {
  const { data: orderChannelData } = useAllOrderChannelsQuery();

  const integration = orderChannelData?.integrations.find((integration) => integration.id === integrationId);
  const orderChannel = orderChannelId
    ? integration?.orderChannels.find((channel) => channel.id === orderChannelId)
    : null;

  return (
    <div className="lg:flex lg:items-center lg:justify-between px-8 py-4 bg-white border-b border-gray-300">
      <div className="flex-1 min-w-0">
        <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">Order #{orderId}</h2>
        <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
          {orderChannel ? (
            <div className="mt-2 flex items-center text-sm text-gray-500">
              <ShoppingBagIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
              {orderChannel.name}
            </div>
          ) : null}
          {requestedServiceType ? (
            <div className="mt-2 flex items-center text-sm text-gray-500">
              <TruckIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
              {requestedServiceType}
            </div>
          ) : null}
          {orderDate ? (
            <div className="mt-2 flex items-center text-sm text-gray-500">
              <CalendarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
              Ordered on {new Date().toLocaleDateString()}
            </div>
          ) : null}
        </div>
      </div>
      <div className="mt-5 flex lg:mt-0 lg:ml-4 space-x-4">
        <Button type="button" kind="secondary" disabled={refunding || reprintDisabled} onClick={onReprint}>
          Reprint label(s)
        </Button>

        <Button type="button" kind="destructive" loading={refunding} onClick={onVoid}>
          Void label(s)
        </Button>
      </div>
    </div>
  );
};
