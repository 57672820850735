import React from 'react';

export const OrderTimelineSkeletonLoader: React.FC = () => {
  return (
    <div className="-mb-8 animate-pulse">
      <div className="relative pb-8">
        <span className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
        <div className="relative flex space-x-3">
          <div>
            <div className="h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white bg-gray-200" />
          </div>
          <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
            <div className="flex-1">
              <div className="h-4 bg-gray-200 mb-2 w-full" />
              <div className="h-4 bg-gray-200 w-1/2" />
            </div>

            <div className="w-20">
              <div className="bg-gray-200 w-full h-4 mb-2" />
              <div className="bg-gray-200 w-full h-4" />
            </div>
          </div>
        </div>
      </div>
      <div className="relative pb-8">
        <div className="relative flex space-x-3">
          <div>
            <div className="h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white bg-gray-200" />
          </div>
          <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
            <div className="flex-1">
              <div className="h-4 bg-gray-200 mb-2 w-full" />
              <div className="h-4 bg-gray-200 w-1/2" />
            </div>

            <div className="w-20">
              <div className="bg-gray-200 w-full h-4 mb-2" />
              <div className="bg-gray-200 w-full h-4" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
