import { gql } from '@apollo/client';

export const RATE_ADJUSTMENT_FRAGMENT = gql`
  fragment RateAdjustment on RateAdjustment {
    id
    adjustment
    carrierAdjustments {
      accountId
      adjustment
      serviceAdjustments {
        adjustment
        service
      }
    }
  }
`