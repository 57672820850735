import React from 'react';
import { AddressDetailsFragment } from '../operations/fragments/__generated__/address-details.gql.generated';
import { formatAddressEnding } from '../utilities/format-address-ending';

export interface AddressDetailsProps {
  address: AddressDetailsFragment;
}

export const AddressDetails: React.FC<AddressDetailsProps> = ({ address }) => {
  return (
    <address className="flex flex-col">
      {address.company ? <span>{address.company}</span> : null}
      {address.name ? <span>{address.name}</span> : null}
      {address.street1 ? <span>{address.street1}</span> : null}
      {address.street2 ? <span>{address.street2}</span> : null}
      <span>{formatAddressEnding(address)}</span>
    </address>
  );
};
