import React from 'react';
import { FormSelect } from '../../Form/FormSelect';

export interface BooleanFieldProps {
  label: string;
  name: string;
  disabled?: boolean;
}

export const BooleanField: React.FC<BooleanFieldProps> = ({ disabled, label, name }) => {
  return (
    <FormSelect
      label={label}
      name={name}
      options={[
        { value: true, label: 'True' },
        { value: false, label: 'False' },
      ]}
      hideErrorMessage
      disabled={disabled}
    />
  );
};
