import React from 'react';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import { FormInput } from '../Form/FormInput';
import { Button } from '../Button';
import { InlineAlert } from '../InlineAlert';

const schema = yup.object({
  description: yup.string().required('Description is a required field.'),
  clientId: yup.string().required('Client ID is a required field.'),
  clientSecret: yup.string().required('Client Secret is a required field.'),
  userLogin: yup.string().required('User Login is a required field.'),
});

export interface TplcIntegrationFormValues {
  description: string;
  clientId: string;
  clientSecret: string;
  userLogin: string;
}

export interface TplcIntegrationFormProps {
  initialValues: TplcIntegrationFormValues;
  onSubmit: (values: TplcIntegrationFormValues) => void;
  submitText: string;
  loading?: boolean;
  error?: string | null;
}

export const TplcIntegrationForm: React.FC<TplcIntegrationFormProps> = ({
  initialValues,
  submitText,
  onSubmit,
  loading,
  error,
}) => {
  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={schema}>
      {({ isSubmitting, errors }) => (
        <Form className="space-y-4">
          {error ? <InlineAlert type="error" title="Uh oh!" errors={[error]} /> : null}
          <FormInput name="description" type="text" label="Description" />
          <FormInput name="clientId" type="text" label="Client ID" />
          <FormInput name="clientSecret" type="text" label="Client Secret" />
          <FormInput name="userLogin" type="text" label="User Login" />

          <div className="flex justify-end">
            <Button loading={loading} type="submit">
              {submitText}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
