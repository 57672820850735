/* This example requires Tailwind CSS v2.0+ */
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { RadioGroup } from '@headlessui/react';
import classNames from 'classnames';
import { Button } from '../Button';
import { PrinterIcon } from '@heroicons/react/outline';
import { useOrderForm } from './OrderForm';
import { ErrorMessage } from 'formik';
import { RateSkeletonLoader } from './RateShop/RateSkeletonLoader';
import { BadgeCheckIcon } from '@heroicons/react/solid';
import { useOrder } from '../../providers/Order/OrderProvider';
import { useRateShopLazyQuery } from '../../operations/rate-shop.gql';
import { createHashFromObject } from '../../utilities/create-hash-from-object';
import { Card } from '../Card';
import mixpanel from 'mixpanel-browser';
import { InvalidAddressModal } from './InvalidAddressModal';
import { getCarrierLogo } from '../../utilities/get-carrier-logo';
import { ErrorModal } from '../ErrorModal';
import { CardContent } from '../CardContent';

export const RateShop: FC = () => {
  const [showInvalidAddressModal, setShowInvalidAddressModal] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const { order, loading } = useOrder();
  const { values, setFieldValue, isSubmitting, isPackageDirty, validateForm, setTouched } = useOrderForm();
  const [rateShop, { loading: rateShopLoading, data: rateShopData, error: rateShopError }] = useRateShopLazyQuery();
  const [showErrorModal, setShowErrorModal] = useState(false);

  useEffect(() => {
    if (rateShopError) {
      setShowErrorModal(true);
    }
  }, [rateShopError]);

  const closeErrorModal = () => {
    setShowErrorModal(false);
  };

  const hideInvalidAddressModal = () => setShowInvalidAddressModal(false);

  const setAllowInvalidAddress = () => {
    mixpanel.track('Allow Invalid Address');
    setFieldValue('allowInvalidAddress', true, true);
    setShowInvalidAddressModal(false);
    fetchRates(true);
  };

  const fetchRates = async (forceInvalidAddress?: boolean) => {
    mixpanel.track('Shop Rates Clicked');
    const errors = await validateForm();

    if (errors.packages) {
      mixpanel.track('Packages Had Errors');
      setTouched({ packages: [{ weight: true }] });
      return;
    }

    if (!order?.shipToValidated.isValid && !values.allowInvalidAddress && !forceInvalidAddress) {
      setShowInvalidAddressModal(true);
      mixpanel.track('Show Invalid Address Modal');
      return;
    }

    mixpanel.track('Submit Rate Shop');
    rateShop({
      variables: {
        orderId: order!.id,
        parcels: values.packages.map(({ id, length, width, height, weight }) => ({
          id,
          length,
          width,
          height,
          weight,
        })),
        easyPostAddressId: order!.shipToValidated.easyPostId!,
      },
    });

    values.packages.forEach((parcel, index) => {
      setFieldValue(
        `packages.${index}.hash`,
        createHashFromObject({
          length: parcel.length,
          width: parcel.width,
          height: parcel.height,
          weight: parcel.weight,
        })
      );
    });
  };

  useEffect(() => {
    if (rateShopData) {
      mixpanel.track('Received Rates');
    }
  }, [rateShopData]);

  const rates = useMemo(() => {
    if (!rateShopData?.rateShop) {
      return [];
    }

    return [...rateShopData.rateShop]
      .sort((a, b) => a.cost - b.cost)
      .map((rate) => ({
        ...rate,
        Logo: getCarrierLogo(rate.carrier),
      }));
  }, [rateShopData]);

  const selectRate = useCallback(
    (id: string) => {
      const rate = rates.find((rate) => rate.id === id)!;
      setFieldValue('easyPostRateId', rate.id);
      setFieldValue('easyPostShipmentId', rate.easyPostShipmentId);
      setFieldValue('selectedCarrier', rate.carrier);
      setFieldValue('selectedService', rate.serviceCode);
    },
    [rates, setFieldValue]
  );

  const handleUserRateSelect = (id: string) => {
    if (id !== values.easyPostRateId) {
      mixpanel.track('User selected custom rate');
    }
    selectRate(id);
  };

  useEffect(() => {
    if ((rates?.length ?? 0) > 0) {
      selectRate(rates[0].id);
    }
  }, [rates, selectRate]);

  const buyButtonDisabled = loading || rates.length === 0 || isPackageDirty;

  return (
    <>
      <Card>
        <div className="pb-5 border-gray-200 sm:flex sm:items-center sm:justify-between pt-5 px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">Rate Shop</h3>
          <div className="mt-3 flex sm:mt-0 sm:ml-4 space-x-4">
            <Button
              type="button"
              onClick={() => fetchRates()}
              kind="secondary"
              loading={rateShopLoading}
              disabled={Boolean(rates.length) && !isPackageDirty}
            >
              Rate Shop
            </Button>

            <Button type="submit" loading={isSubmitting} icon={PrinterIcon} disabled={buyButtonDisabled}>
              Buy Label & Print
            </Button>
          </div>
        </div>
        <CardContent className="flex-1 overflow-y-auto">
          {loading || rateShopLoading ? (
            <div className="space-y-4">
              <RateSkeletonLoader />
              <RateSkeletonLoader />
            </div>
          ) : !isPackageDirty && rates.length ? (
            <RadioGroup value={values.easyPostRateId} onChange={handleUserRateSelect}>
              <RadioGroup.Label className="sr-only">Server size</RadioGroup.Label>
              <div className="space-y-4">
                {rates.map((rate, index) => {
                  if (index > 5 && !showAll) {
                    return null;
                  }

                  return (
                    <RadioGroup.Option
                      key={rate.id}
                      value={rate.id}
                      className={({ active }) =>
                        classNames(
                          active ? 'ring-1 ring-offset-2 ring-blue-500' : '',
                          'relative block rounded-lg border border-gray-300 bg-white shadow-sm px-6 py-4 cursor-pointer hover:border-gray-400 sm:flex sm:justify-between focus:outline-none'
                        )
                      }
                    >
                      {({ checked }) => (
                        <>
                          <div className="flex items-center relative">
                            {rate.Logo ? <rate.Logo className="absolute w-12 h-auto" /> : null}
                            <div className="text-sm ml-16">
                              <RadioGroup.Label as="p" className="font-medium text-gray-900">
                                {rate.serviceCode}
                              </RadioGroup.Label>
                              <RadioGroup.Description as="div" className="text-gray-500">
                                <p className="sm:inline">{'N/A'}</p>
                              </RadioGroup.Description>
                            </div>
                          </div>
                          <RadioGroup.Description
                            as="div"
                            className="mt-2 flex text-sm sm:mt-0 sm:block sm:ml-4 sm:text-right"
                          >
                            <div className="font-medium text-gray-900">${rate.cost.toFixed(2)}</div>
                            {rate.deliveryDays ? (
                              <div className="ml-1 text-gray-500 sm:ml-0 flex items-center">
                                {rate.deliveryDateGuaranteed ? (
                                  <span title="Guaranteed">
                                    <BadgeCheckIcon className="w-4 mr-1 text-green-600" />
                                  </span>
                                ) : null}{' '}
                                {rate.deliveryDays} Days
                              </div>
                            ) : null}
                          </RadioGroup.Description>
                          <div
                            className={classNames(
                              checked ? 'border-blue-500' : 'border-transparent',
                              'absolute -inset-px rounded-lg border-2 pointer-events-none'
                            )}
                            aria-hidden="true"
                          />
                        </>
                      )}
                    </RadioGroup.Option>
                  );
                })}
              </div>
              {rates.length > 6 ? (
                <div className="flex justify-center px-4 pt-4">
                  {showAll ? (
                    <Button type="button" kind="white" onClick={() => setShowAll(false)}>
                      Hide
                    </Button>
                  ) : (
                    <Button type="button" kind="white" onClick={() => setShowAll(true)}>
                      Show all
                    </Button>
                  )}
                </div>
              ) : null}
            </RadioGroup>
          ) : (
            <>
              <div className="flex items-center justify-center text-gray-500 h-16">
                Click "Shop Rates" to get the latest rates.
              </div>
              <div className="flex items-center justify-center text-red-600">
                <ErrorMessage name="easyPostRateId" />
              </div>
            </>
          )}
        </CardContent>
      </Card>
      <InvalidAddressModal
        open={showInvalidAddressModal}
        onClose={hideInvalidAddressModal}
        onAccept={setAllowInvalidAddress}
      />
      <ErrorModal isOpen={showErrorModal} onClose={closeErrorModal} message={rateShopError?.message} />
    </>
  );
};
