import React, { useEffect } from 'react';
import { TagInput, TagInputProps } from '../TagInput';
import { useField } from 'formik';

export interface FormTagInputProps extends Omit<TagInputProps, 'value' | 'onChange'> {
  name: string;
  hideErrorMessage?: boolean;
}

export const FormTagInput: React.FC<FormTagInputProps> = ({ name, ...tagInputProps }) => {
  const [field, { error, touched }, helpers] = useField(name);

  const handleChange = (values: string[]) => {
    helpers.setValue(values, true);
    helpers.setTouched(true);
  };

  useEffect(() => {
    if (!Array.isArray(field.value)) {
      helpers.setValue(field.value ? [field.value] : [], true);
    }
  }, [field.value, helpers]);

  const { onChange, value, ...fieldProps } = field;

  const computedValue: string[] = Array.isArray(value) ? value.filter((v) => Boolean(v)) : value ? [value] : [];

  return (
    <TagInput
      value={computedValue}
      onChange={handleChange}
      {...tagInputProps}
      {...fieldProps}
      error={touched ? error : null}
    />
  );
};
