import { gql, useQuery } from '@apollo/client';
import {
  ModifierFieldOptionsQuery,
  ModifierFieldOptionsQueryVariables,
} from './__generated__/modifier-field-options.gql.generated';

export const MODIFIER_FIELD_OPTIONS_QUERY = gql`
  query ModifierFieldOptions {
    modifierFieldOptions {
      label
      value
    }
  }
`;

export const useModifierFieldOptionsQuery = () =>
  useQuery<ModifierFieldOptionsQuery, ModifierFieldOptionsQueryVariables>(MODIFIER_FIELD_OPTIONS_QUERY);
