import { gql } from '@apollo/client/core';
import { useMutation } from '@apollo/client';
import { PurchaseLabelMutation, PurchaseLabelMutationVariables } from './__generated__/purchase-label.gql.generated';

export const PURCHASE_LABEL_MUTATION = gql`
  mutation PurchaseLabel(
    $orderId: ID!
    $rateId: ID!
    $carrier: EasyPostCarrier!
    $service: String!
    $easyPostShipmentId: ID!
    $locationId: Int!
  ) {
    purchaseLabel(
      input: {
        carrier: $carrier
        tplcOrderId: $orderId
        selectedRateId: $rateId
        easyPostShipmentId: $easyPostShipmentId
        service: $service
        locationId: $locationId
      }
    ) {
      order {
        id
        shipped
        trackingCode
        parcels {
          id
          labelUrl
        }
        trackingCode
        serviceType
        carrier
        shippingMethod
      }
      labels {
        labelFileType
        labelFileUrl
      }
    }
  }
`;

export const usePurchaseLabel = () => {
  return useMutation<PurchaseLabelMutation, PurchaseLabelMutationVariables>(PURCHASE_LABEL_MUTATION);
};
