import { gql } from '@apollo/client/core';
import { useQuery } from '@apollo/client';
import { GetOrderQuery, GetOrderQueryVariables } from './__generated__/order.gql.generated';

export const GET_ORDER_QUERY = gql`
  query GetOrder($orderId: ID!) {
    order(tplcOrderId: $orderId) {
      id
      shipped
      trackingCode
      parcels {
        id
        length
        height
        width
        linearUnit
        weight
        weightUnit
        trackingCode
        labelUrl
      }
      shipToOriginal {
        address1
        address2
        city
        companyName
        country
        email
        errors
        isResidential
        isValid
        name
        phoneNumber
        postalCode
        state
      }
      shipToValidated {
        address1
        address2
        city
        companyName
        country
        email
        errors
        isResidential
        isValid
        name
        phoneNumber
        postalCode
        state
        easyPostId
      }
      pickedTimestamp
      packedTimestamp
      shippingMethod
      serviceType
      carrier
    }
  }
`;

export const useGetOrderQuery = (orderId: string) => {
  return useQuery<GetOrderQuery, GetOrderQueryVariables>(GET_ORDER_QUERY, {
    variables: { orderId },
  });
};
