import React from 'react';

export interface RuleSectionEmptyStateProps {
  title: string;
  message: string;
}

export const RuleSectionEmptyState: React.FC<RuleSectionEmptyStateProps> = ({ title, message }) => (
  <div className="pb-8 text-center">
    <div className="text-gray-700">{title}</div>
    <div className="text-sm text-gray-500">{message}</div>
  </div>
);
