/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ]
  }
  ```
*/
import { FC, FormEvent, useState } from 'react';
import { InputGroup } from '../components/InputGroup';
import { ReactComponent as Logo } from '../assets/LogoWhiteOnBlue.svg';
import { Auth } from 'aws-amplify';
import { Button } from '../components/Button';
import { InlineAlert } from '../components/InlineAlert';
import { useHistory } from 'react-router-dom';

export const ForgotPassword: FC = () => {
  const [email, setEmail] = useState('');
  const [confirmationCode, setConfirmationCode] = useState('');
  const [codeSent, setCodeSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [password, setPassword] = useState('');
  const history = useHistory();

  const handleEmailSubmit = async (evt: FormEvent) => {
    evt.preventDefault();
    setCodeSent(false);
    setLoading(true);
    setError('');

    try {
      await Auth.forgotPassword(email);
      setCodeSent(true);
    } catch (err) {
      setError(err.message);
      // TODO: capture in sentry
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmationCodeSubmit = async (evt: FormEvent) => {
    evt.preventDefault();

    setLoading(true);
    setError('');

    try {
      await Auth.forgotPasswordSubmit(email, confirmationCode, password);
      history.push('/');
    } catch (err) {
      // TODO: capture in sentry
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <Logo className="mx-auto h-12 w-auto" />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Password Reset</h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          {codeSent ? (
            <>
              <InlineAlert className="mb-4" type="info" title="A password reset code has been sent" />
              <form key="password-reset-form" className="space-y-6" onSubmit={handleConfirmationCodeSubmit}>
                <InputGroup
                  label="Confirmation Code"
                  id="confirmationCode"
                  name="confirmationCode"
                  type="text"
                  value={confirmationCode}
                  required
                  onChange={(evt) => setConfirmationCode(evt.target.value)}
                />
                <InputGroup
                  label="Email address"
                  id="email"
                  name="email"
                  type="email"
                  value={email}
                  required
                  onChange={(evt) => setEmail(evt.target.value)}
                />
                <InputGroup
                  label="New Password"
                  id="password"
                  name="password"
                  type="password"
                  value={password}
                  required
                  onChange={(evt) => setPassword(evt.target.value)}
                />

                <div>
                  <Button className="w-full justify-center" type="submit" loading={loading}>
                    Reset Password
                  </Button>
                </div>
              </form>
            </>
          ) : (
            <form key="code-request-form" className="space-y-6" onSubmit={handleEmailSubmit}>
              <InputGroup
                label="Email address"
                id="email"
                name="email"
                type="email"
                value={email}
                required
                onChange={(evt) => setEmail(evt.target.value)}
              />

              <div>
                <Button className="w-full justify-center" type="submit" loading={loading}>
                  Request reset code
                </Button>
              </div>
            </form>
          )}
          {error ? <InlineAlert className="mt-4" type="error" title={error} /> : null}
        </div>
      </div>
    </div>
  );
};
