import React, { ChangeEvent, FC } from 'react';
import { useField } from 'formik';
import { Select, SelectProps } from '../Select';

export interface FormSelectProps extends Omit<SelectProps, 'error'> {
  name: string;
}

export const FormSelect: FC<FormSelectProps> = ({ className, label, onChange, ...props }) => {
  const [{ onChange: fieldOnChange, value, ...field }, meta] = useField(props);

  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    fieldOnChange(e);
    onChange?.(e);
  };

  const selected = Array.isArray(value) ? value[0] : value;

  return (
    <Select
      className={className}
      label={label}
      id={props.name}
      error={meta.touched ? meta.error : null}
      value={selected}
      onChange={handleChange}
      {...props}
      {...field}
    />
  );
};
