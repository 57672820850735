/* This example requires Tailwind CSS v2.0+ */
import React, { CSSProperties, useMemo } from 'react';
import { useFormValidator, useShipForm } from '../ShipForm';
import { useLocation } from 'react-router-dom';
import { StepPanelStep } from '../../StepPanel';
import { VerticalSteps } from '../../VerticalSteps';
import classNames from 'classnames';
import { formatAddressEnding } from '../../../utilities/format-address-ending';

export interface ShipFormVerticalStepsProps {
  className?: string;
  style?: CSSProperties;
}

export const ShipFormVerticalSteps: React.FC<ShipFormVerticalStepsProps> = ({ className, style }) => {
  const { isAddressSectionValid, isPackagesSectionValid } = useFormValidator();
  const {
    values: { addresses, packages, shipped },
    isSubmitting,
  } = useShipForm();
  const location = useLocation();

  const steps: StepPanelStep[] = useMemo(() => {
    const packagesActive = Boolean(location.pathname?.endsWith('packages'));
    const packagesDescription =
      packagesActive || !isPackagesSectionValid
        ? 'Package dimensions and weight.'
        : packages.length === 1
        ? `${[packages[0].length, packages[0].width, packages[0].height].join('x')} ${packages[0].weight} lbs`
        : 'Multiple packages.';

    const addressesActive = Boolean(location.pathname?.endsWith('addresses'));
    const addressesDescription =
      addressesActive || !isAddressSectionValid
        ? 'Ship To, Ship From, Return Address.'
        : formatAddressEnding(addresses.shipToAddress);

    return [
      {
        id: '01',
        name: 'Addresses',
        description: addressesDescription,
        href: 'addresses',
        active: addressesActive,
        complete: shipped || isAddressSectionValid,
        disabled: shipped,
      },
      {
        id: '02',
        name: 'Packages',
        description: packagesDescription,
        href: 'packages',
        active: packagesActive,
        complete: shipped || isPackagesSectionValid,
        disabled: shipped,
      },
      {
        id: '03',
        name: 'Rate Shop',
        description: 'Shop rates and print labels.',
        href: 'rate-shop',
        active: Boolean(location.pathname?.endsWith('rate-shop')),
        complete: shipped,
        disabled: shipped || !isAddressSectionValid || !isPackagesSectionValid,
      },
    ];
  }, [addresses.shipToAddress, isAddressSectionValid, isPackagesSectionValid, location.pathname, packages, shipped]);

  return (
    <div className={classNames('px-4 sm:p-6 lg:p-8 border-r border-gray-300', className)} style={style}>
      <VerticalSteps steps={steps} disabled={isSubmitting} />
    </div>
  );
};
