import { gql, useMutation } from '@apollo/client';
import {
  CreateAddressBookAddressMutation,
  CreateAddressBookAddressMutationVariables,
} from './__generated__/create-address-book-address.gql.generated';
import { ADDRESS_DETAILS_FRAGMENT } from './fragments/address-details.gql';

export const CREATE_ADDRESS_BOOK_ADDRESS_MUTATION = gql`
  mutation CreateAddressBookAddress($input: AddressBookAddressInput!) {
    createAddressBookAddress(input: $input) {
      id
      createdAt
      updatedAt
      addressName
      address {
        ...AddressDetails
      }
    }
  }

  ${ADDRESS_DETAILS_FRAGMENT}
`;

export const useCreateAddressBookAddressMutation = () =>
  useMutation<CreateAddressBookAddressMutation, CreateAddressBookAddressMutationVariables>(
    CREATE_ADDRESS_BOOK_ADDRESS_MUTATION,
    { refetchQueries: ['AddressBookAddresses'] }
  );
