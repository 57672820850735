import React from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { useField } from 'formik';
import { InputGroup } from '../InputGroup';
import styles from './FormDatePicker.module.scss';
import classNames from 'classnames';

export interface FormDatePickerProps extends Omit<ReactDatePickerProps, 'onChange'> {
  name: string;
  label?: string;
}

export const FormDatePicker: React.FC<FormDatePickerProps> = ({
  name,
  label,
  wrapperClassName,
  ...datePickerProps
}) => {
  const [{ value, onChange, ...inputProps }, , { setValue }] = useField(name);
  return (
    <ReactDatePicker
      wrapperClassName={classNames(styles.formDatePicker, wrapperClassName)}
      customInput={<InputGroup label={label} />}
      selected={value}
      {...datePickerProps}
      {...inputProps}
      onSelect={(date) => setValue(date, true)}
      onChange={(date) => setValue(date, true)}
    />
  );
};
