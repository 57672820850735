import React, { FC } from 'react';
import classNames from 'classnames';
import { LoadingIndicator } from './LoadingIndicator';

export interface LoadingPanelProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  text?: string;
}

export const LoadingPanel: FC<LoadingPanelProps> = ({ className, text, ...divProps }) => {
  return (
    <div className={classNames('w-full flex items-center justify-center h-16', className)} {...divProps}>
      <LoadingIndicator className="text-blue-500" />
      {text ? <p>{text}</p> : null}
    </div>
  );
};
