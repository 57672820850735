import { gql, useMutation } from '@apollo/client';
import { DeleteRuleMutation, DeleteRuleMutationVariables } from './__generated__/delete-rule.gql.generated';

export const DELETE_RULE_MUTATION = gql`
  mutation DeleteRule($id: ID!) {
    deleteRule(id: $id)
  }
`;

export const useDeleteRuleMutation = () =>
  useMutation<DeleteRuleMutation, DeleteRuleMutationVariables>(DELETE_RULE_MUTATION, {
    refetchQueries: ['ListRules'],
  });
