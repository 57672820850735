import React from 'react';
import { Redirect, useHistory, useRouteMatch } from 'react-router-dom';
import { useOrderV2Query } from '../operations/order-v2.gql';
import { LoadingPanel } from '../components/LoadingPanel';
import { ArrowLeftIcon } from '@heroicons/react/outline';
import { QualityControlForm, QualityControlFormValues } from '../components/quality-control/QualityControlForm';
import { nanoid } from 'nanoid';
import { useCreateQualityControlRecordMutation } from '../operations/create-quality-control-record.gql';
import { FormikHelpers } from 'formik';
import { useErrorModal } from '../utilities/use-error-modal';
import { ErrorModal } from '../components/ErrorModal';

export const QualityControlOrder: React.FC = () => {
  const { params } = useRouteMatch<{ integrationId: string; orderId: string }>();
  const [createQualityControlRecord, { error }] = useCreateQualityControlRecordMutation();
  const { showErrorModal, handleErrorModalClose } = useErrorModal(error?.message);
  const history = useHistory();

  const { data: orderData, loading } = useOrderV2Query(params);

  const handleGoBack = () => {
    history.goBack();
  };

  const handleSubmit = async (values: QualityControlFormValues, helpers: FormikHelpers<QualityControlFormValues>) => {
    try {
      helpers.setSubmitting(true);
      await createQualityControlRecord({ variables: params });

      history.push('/quality-control');
    } catch (err) {
      helpers.setSubmitting(false);
      // TODO: Capture in Sentry
    }
  };

  return (
    <div className="flex-1 flex flex-col bg-white overflow-hidden">
      <div className="border-b">
        <div className="max-w-2xl mx-auto w-full flex items-center min-w-0 px-4 py-4">
          <ArrowLeftIcon className="w-6 h-6 cursor-pointer mr-4" onClick={handleGoBack} />
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
            {orderData?.orderV2 ? `Order #${orderData.orderV2.orderId}` : null}
          </h2>
        </div>
      </div>
      <div className="flex-1 flex flex-col bg-gray-100 overflow-hidden">
        {loading ? (
          <LoadingPanel className="flex-1" />
        ) : orderData?.orderV2 ? (
          <QualityControlForm
            initialValues={{
              parcels: orderData.orderV2.parcels.map((parcel) => ({
                key: nanoid(),
                items: parcel.items.map((item) => ({
                  key: nanoid(),
                  sku: item.sku,
                  name: item.name,
                  upc: item.upc,
                  description: item.description,
                  expectedQuantity: item.quantity ?? 0,
                  scannedQuantity: 0,
                })),
              })),
            }}
            onSubmit={handleSubmit}
          />
        ) : (
          <Redirect to="/quality-control" />
        )}
      </div>
      <ErrorModal isOpen={showErrorModal} onClose={handleErrorModalClose} message={error?.message} />
    </div>
  );
};
