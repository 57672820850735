import { gql, useQuery } from '@apollo/client';
import { RATE_ADJUSTMENT_FRAGMENT } from './fragments/rate-adjustment.gql';
import { RateAdjustmentQuery, RateAdjustmentQueryVariables } from './__generated__/rate-adjustment.gql.generated';

export const RATE_ADJUSTMENT_QUERY = gql`
  query RateAdjustment($integrationId: ID!, $orderChannelId: ID!) {
    rateAdjustment(integrationId: $integrationId, orderChannelId: $orderChannelId) {
      ...RateAdjustment
    }
  }

  ${RATE_ADJUSTMENT_FRAGMENT}
`;

export const useRateAdjustmentQuery = (variables: RateAdjustmentQueryVariables) =>
  useQuery<RateAdjustmentQuery, RateAdjustmentQueryVariables>(RATE_ADJUSTMENT_QUERY, {
    variables,
    notifyOnNetworkStatusChange: true,
  });
