import React, { useState } from 'react';
import { Rate, SmartRateTimeInTransitInfo } from '../../../types/__generated__/graphql';
import { TableHead } from '../../TableHead';
import { TableHeaderCell } from '../../TableHeaderCell';
import { SmartRateIndicator } from '../atoms/SmartRateIndicator';
import classNames from 'classnames';
import { RateRadio } from '../atoms/RateRadio';
import { useField } from 'formik';
import { getCarrierLogoElement } from '../../../utilities/get-carrier-logo';
import { Button } from '../../Button';

export interface RateTableProps {
  rates: Pick<
    Rate,
    | 'id'
    | 'carrier'
    | 'service'
    | 'serviceReadable'
    | 'rate'
    | 'deliveryDays'
    | 'deliveryDaysGuaranteed'
    | 'currency'
    | 'timeInTransit'
  >[];
  disabled?: boolean;
}

const formatCurrency = (value: number, currency?: string | null) => {
  const { format } = new Intl.NumberFormat('en-US', { style: 'currency', currency: currency ?? 'USD' });

  return format(value);
};

const defaultSmartRateInfo: SmartRateTimeInTransitInfo = {
  percentile_50: null,
  percentile_75: null,
  percentile_85: null,
  percentile_90: null,
  percentile_95: null,
  percentile_97: null,
  percentile_99: null,
};

export const RateTable: React.FC<RateTableProps> = ({ rates, disabled }) => {
  const [showAll, setShowAll] = useState(false);
  const [rateField, , { setValue, setTouched }] = useField('rateShop.selectedRate');

  const handleRateSelect = (rate: RateTableProps['rates'][number]) => {
    if (disabled) {
      return;
    }

    setValue(rate, true);
    setTouched(true);
  };

  const slicedRates = showAll ? rates : rates.slice(0, 5);

  return (
    <>
      <div className="overflow-hidden border border-gray-200 sm:rounded-lg flex-shrink-0 overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <TableHead>
            <tr>
              <th />
              <th />
              <TableHeaderCell>Carrier</TableHeaderCell>
              <TableHeaderCell>Service</TableHeaderCell>
              <TableHeaderCell className="text-right">Cost</TableHeaderCell>
              <TableHeaderCell>
                Carrier Est.
                <br />
                Delivery
              </TableHeaderCell>
              <TableHeaderCell>Smart Rate</TableHeaderCell>
            </tr>
          </TableHead>

          <tbody>
            {slicedRates.map((rate, index) => (
              <tr
                key={rate.id}
                className={classNames(
                  rateField.value?.id === rate.id ? 'bg-blue-50' : index % 2 === 0 ? 'bg-white' : 'bg-gray-50',
                  disabled ? 'cursor-not-allowed' : 'cursor-pointer hover:bg-blue-50'
                )}
                onClick={() => handleRateSelect(rate)}
              >
                <td className="align-middle text-center px-6">
                  <RateRadio name="rateShop.selectedRate" value={rate} disabled={disabled} />
                </td>
                <td>
                  <div className="flex justify-end items-center">
                    {getCarrierLogoElement(rate.carrier, 'h-6 w-auto mr-2') ?? null}
                  </div>
                </td>
                <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                  <span>{rate.carrier}</span>
                </td>
                <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                  {rate.serviceReadable ?? rate.service}
                </td>
                <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-900 text-right">
                  {formatCurrency(rate.rate, rate.currency)}
                </td>
                <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                  {rate.deliveryDays ? `${rate.deliveryDays} days` : '-'}
                </td>
                <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                  <SmartRateIndicator smartRate={rate.timeInTransit ?? defaultSmartRateInfo} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {rates.length > 5 ? (
        <div className="flex justify-center pt-4">
          {showAll ? (
            <Button type="button" kind="white" onClick={() => setShowAll(false)}>
              Show less rates
            </Button>
          ) : (
            <Button type="button" kind="white" onClick={() => setShowAll(true)}>
              Show more rates
            </Button>
          )}
        </div>
      ) : null}
    </>
  );
};
