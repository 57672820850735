import React, { useCallback } from 'react';
import Slider from 'rc-slider';
import { RateFilterField, SmartRatePercentile } from '../../../types/__generated__/graphql';
import { useRulesForm } from '../RuleForm';
import { FormInput } from '../../Form/FormInput';
import { FormSelect } from '../../Form/FormSelect';
import { useRateFilterValueOptionsQuery } from '../../../operations/rate-filter-value-options.gql';

const SmartRateMarkToPercentileMap: { [key: number]: SmartRatePercentile } = {
  1: SmartRatePercentile.Percentile_50,
  3: SmartRatePercentile.Percentile_75,
  5: SmartRatePercentile.Percentile_85,
  6: SmartRatePercentile.Percentile_90,
  7: SmartRatePercentile.Percentile_95,
  8: SmartRatePercentile.Percentile_97,
  9: SmartRatePercentile.Percentile_99,
};

const SmartRatePercentileToMark: { [key in SmartRatePercentile]: number } = {
  [SmartRatePercentile.Percentile_50]: 1,
  [SmartRatePercentile.Percentile_75]: 3,
  [SmartRatePercentile.Percentile_85]: 5,
  [SmartRatePercentile.Percentile_90]: 6,
  [SmartRatePercentile.Percentile_95]: 7,
  [SmartRatePercentile.Percentile_97]: 8,
  [SmartRatePercentile.Percentile_99]: 9,
};

const marks = {
  1: '50%',
  3: '75%',
  5: '85%',
  6: '90%',
  7: '95%',
  8: '97%',
  9: '99%',
};

interface SmartRateConfigurationProps {
  disabled?: boolean;
}

export const SmartRateConfiguration: React.FC<SmartRateConfigurationProps> = ({ disabled }) => {
  const form = useRulesForm();
  const { data: serviceOptionsData } = useRateFilterValueOptionsQuery(RateFilterField.CarrierServiceType);

  const handleSliderChange = useCallback(
    (value: number) => {
      const percentile = SmartRateMarkToPercentileMap[value];

      if (!percentile) {
        throw new Error('Percentile selected was not a SmartRate percentile!');
      }

      form.setFieldValue(`smartRateConfiguration.percentile`, percentile);
    },
    [form]
  );

  const markValue = SmartRatePercentileToMark[form.values.smartRateConfiguration!.percentile];

  const serviceOptions = serviceOptionsData?.rateFilterValueOptions ?? [];

  return (
    <div className="grid grid-cols-3 gap-x-8 gap-y-4 mb-8 items-center">
      <FormInput
        className="col-span-3 md:col-span-1"
        label="Delivery Days"
        name={`smartRateConfiguration.deliveryDays`}
        type="number"
        placeholder="Business days in transit"
        disabled={disabled}
      />
      <div className="col-span-3 md:col-span-2 py-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="smart-rate-slider">
            Delivery Time Accuracy
          </label>
        </div>
        <div className="flex justify-between">
          <span className="text-gray-500 text-xs">Higher Savings</span>
          <span className="text-gray-500 text-xs">Higher Accuracy</span>
        </div>
        <Slider
          min={1}
          max={9}
          marks={marks}
          step={null}
          value={markValue}
          onChange={handleSliderChange}
          dotStyle={{ borderColor: '#999999' }}
          activeDotStyle={{ borderColor: '#27aca2' }}
          handleStyle={{ borderColor: '#27aca2' }}
          trackStyle={{ backgroundColor: '#27aca2' }}
          disabled={disabled}
        />
      </div>
      <FormSelect label="Default Service" name="smartRateConfiguration.defaultService">
        <option value="" disabled>
          Select a service
        </option>
        {serviceOptions.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </FormSelect>
    </div>
  );
};
