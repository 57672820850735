import React, { useEffect, useState } from 'react';
import { ShipFormContent } from '../atoms/ShipFormContent';
import { shipFormValidationSchema, useMarkSectionAsTouched, useShipForm } from '../ShipForm';
import { useRateShopV3Query } from '../../../operations/rate-shop-v3.gql';
import * as yup from 'yup';
import { LoadingPanel } from '../../LoadingPanel';
import { ShipFormFooter } from '../atoms/ShipFormFooter';
import { Button } from '../../Button';
import { InlineAlert } from '../../InlineAlert';
import { RateTable } from '../elements/RateTable';
import { RateMessage } from '../../../types/__generated__/graphql';
import { RateShopPillTabs } from '../elements/RateShopPillTabs';
import { useErrorModal } from '../../../utilities/use-error-modal';
import { ErrorModal } from '../../ErrorModal';
import { EmptyState } from '../../EmptyState';

const formatRateMessage = (rateMessage: RateMessage) =>
  rateMessage.carrier ? `[${rateMessage.carrier}]: ${rateMessage.message}` : rateMessage.message;

export const RateShopSection: React.FC = () => {
  const { values, setFieldValue, isSubmitting, submitForm } = useShipForm();
  const [disableQuery, setDisableQuery] = useState(true);
  const [currentTab, setCurrentTab] = useState<'preferred' | 'other'>('preferred');
  const { markAddressesAsTouched, markPackagesAsTouched } = useMarkSectionAsTouched();
  const autoPurchaseEnabled = JSON.parse(localStorage.getItem('AUTO_PURCHASE_ENABLED') ?? 'false');

  useEffect(() => {
    if (
      !yup.reach(shipFormValidationSchema, 'packages').isValidSync(values.packages) ||
      !yup.reach(shipFormValidationSchema, 'addresses').isValidSync(values.addresses)
    ) {
      markPackagesAsTouched();
      markAddressesAsTouched();
      setDisableQuery(true);
    } else {
      setDisableQuery(false);
    }
  }, [markAddressesAsTouched, markPackagesAsTouched, values.addresses, values.packages]);

  const { verifications, ...shipToAddressVariables } = values.addresses.shipToAddress;

  const { data: rateShopData, loading: rateShopLoading, error } = useRateShopV3Query(
    {
      integrationId: values.integrationId,
      orderChannelId: values.orderChannelId,
      orderId: values.orderId,
      shipToAddressId: values.addresses.shipToAddressId,
      shipToAddress: values.addresses.shipToAddressId ? null : shipToAddressVariables,
      shipFromAddressId: values.addresses.shipFromAddressId,
      returnAddressId: values.addresses.returnAddressId,
      requestedServiceType: values.requestedServiceType,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      parcels: values.packages.map(({ key, weight, weightLocked, ...parcel }) => ({ ...parcel, weight: weight * 16 })),
    },
    disableQuery
  );

  const { showErrorModal, handleErrorModalClose } = useErrorModal(error?.message);

  useEffect(() => {
    if (rateShopData) {
      const preferredRate = rateShopData.rateShopV3.rates.find((rate) => rate.preferredRate);
      if (preferredRate) {
        setFieldValue('rateShop.selectedRate', preferredRate);
      }
    }
    // eslint-disable-next-line
  }, [rateShopData]);

  useEffect(() => {
    if (autoPurchaseEnabled && values.rateShop.selectedRate) {
      console.log('auto purchasing');
      submitForm();
    }
  }, [autoPurchaseEnabled, submitForm, values.rateShop.selectedRate]);

  const rates = rateShopData?.rateShopV3.rates ?? [];

  const filteredRates = currentTab === 'preferred' ? rates.filter((rate) => rate.preferredRate) : rates;

  return (
    <>
      {rateShopLoading ? (
        <LoadingPanel className="flex-1" />
      ) : (
        <ShipFormContent>
          <RateShopPillTabs currentTab={currentTab} onChange={setCurrentTab} />
          {filteredRates.length ? (
            <RateTable rates={filteredRates} disabled={isSubmitting} />
          ) : currentTab === 'preferred' ? (
            <EmptyState
              className="max-w-xs mx-auto"
              style={{ height: '75%' }}
              title="No preferred rates were returned"
              subtext="This could be due to various reasons, including the rules for this particular order channel and service type."
            />
          ) : null}
          {rateShopData?.rateShopV3.messages.length ? (
            <InlineAlert
              className="mt-4"
              type="warning"
              title="Rate shopping messages:"
              errors={rateShopData.rateShopV3.messages.map(formatRateMessage)}
            />
          ) : null}
        </ShipFormContent>
      )}
      <ShipFormFooter>
        <Button type="submit" loading={isSubmitting} size="lg">
          Purchase Label
        </Button>
      </ShipFormFooter>
      <ErrorModal isOpen={showErrorModal} onClose={handleErrorModalClose} message={error?.message} />
    </>
  );
};
