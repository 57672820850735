import React from 'react';
import { CarrierAccountSegment, useRateAdjustmentForm } from '../RateAdjustmentForm';
import { FormInput } from '../../Form/FormInput';
import { Button } from '../../Button';
import { MinusIcon, PlusIcon } from '@heroicons/react/outline';
import { useCarrierAccountServicesQuery } from '../../../operations/carrier-account-services.gql';
import { ServiceAdjustmentSection } from './ServiceAdjustmentSection';

export interface CarrierAdjustmentSectionProps {
  account: CarrierAccountSegment;
}

export const CarrierAdjustmentSection: React.FC<CarrierAdjustmentSectionProps> = ({ account }) => {
  const { values, setFieldValue } = useRateAdjustmentForm();

  const { data: servicesData, loading: servicesLoading } = useCarrierAccountServicesQuery(account.carrier);

  const handleEnableCarrierServices = (account: CarrierAccountSegment) => {
    setFieldValue(
      `carrierAdjustments.${account.id}.serviceAdjustments`,
      servicesData?.carrierAccountServices.reduce(
        (map, service) => ({
          ...map,
          [service.value]: '',
        }),
        {}
      )
    );
  };

  const handleDisableCarrierServices = (account: CarrierAccountSegment) => {
    setFieldValue(`carrierAdjustments.${account.id}.serviceAdjustments`, undefined);
  };

  const servicesEnabled = Boolean(values.carrierAdjustments?.[account.id]?.serviceAdjustments);

  const carrierAdjustment = values.carrierAdjustments?.[account.id]?.adjustment ?? '';
  const serviceLevelDefault = carrierAdjustment !== '' ? carrierAdjustment : values.adjustment.toString();

  return (
    <>
      <div className="col-span-2 flex flex-col">
        <span>{account.name}</span>
        <span className="text-sm text-gray-500">{account.description}</span>
      </div>

      <FormInput
        className="col-span-1"
        name={`carrierAdjustments.${account.id}.adjustment`}
        type="number"
        id={`carrier-adjustment-${account.id}`}
        trailingAddon="%"
        placeholder={values.adjustment.toString()}
        hideErrorMessage
      />

      <span>
        {values.carrierAdjustments?.[account.id]?.serviceAdjustments ? (
          <Button
            type="button"
            kind="destructive"
            icon={MinusIcon}
            onClick={() => handleDisableCarrierServices(account)}
          >
            Services
          </Button>
        ) : (
          <Button
            type="button"
            kind="white"
            icon={PlusIcon}
            onClick={() => handleEnableCarrierServices(account)}
            disabled={servicesLoading}
          >
            Services
          </Button>
        )}
      </span>

      {servicesEnabled && servicesData
        ? servicesData.carrierAccountServices.map((service) => (
            <ServiceAdjustmentSection
              key={service.value}
              accountId={account.id}
              defaultCarrierAdjustment={serviceLevelDefault}
              service={service}
            />
          ))
        : null}
    </>
  );
};
