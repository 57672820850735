import React from 'react';
import { ReactComponent as EmptySvg } from '../../assets/illustrations/empty.svg';

export const OrderNotFoundState: React.FC = () => (
  <div className="flex-1 flex flex-col items-center justify-center max-w-xl self-center">
    <EmptySvg className="w-full h-auto mb-8" />
    <h1 className="text-2xl sm:text-3xl font-bold mb-2 text-red-700">Order Not Found</h1>
    <p className="text-red-900 max-w-xl">Uh oh! That order was not found. Please check the Order ID and try again.</p>
  </div>
);
