import React, { useCallback } from 'react';
import { RuleForm, RuleFormValues } from '../components/ShippingRules/RuleForm';
import { SectionHeadingWithAction } from '../components/Layout/SectionHeadingWithAction';
import { Breadcrumb } from '../components/Breadcrumbs';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useAllOrderChannelsQuery } from '../operations/all-order-channels.gql';
import { OrderChannelSelect } from '../components/OrderChannelSelect';
import { useCreateRuleMutation } from '../operations/create-rule.gql';
import {
  EasyPostCarrier,
  ModifierField,
  RateFilterField,
  RuleOperator,
  TriggerField,
} from '../types/__generated__/graphql';

export const NewRule: React.FC = () => {
  const {
    params: { integrationId, orderChannelId },
    path,
    url,
  } = useRouteMatch<{ integrationId: string; orderChannelId: string }>();
  const { url: rulesUrl } =
    useRouteMatch('/settings/shipping-rules/integration/:integrationId/order-channel/:orderChannelId') ?? {};

  const { data: orderChannelsData } = useAllOrderChannelsQuery();
  const [createRule, { loading: createRuleLoading }] = useCreateRuleMutation();

  const history = useHistory();

  const handleOrderChannelSelect = useCallback(
    (value: { integrationId: string; orderChannelId: string }) => {
      const newUrl = path
        .replace(':orderChannelId', value.orderChannelId)
        .replace(':integrationId', value.integrationId);

      history.push(newUrl);
    },
    [history, path]
  );

  const handleSubmit = async (values: RuleFormValues) => {
    console.log('creating new rule with values:', values);

    const convertValue = (value: Date | number | string | string[]): string[] => {
      if (value instanceof Date) {
        return [value.toISOString()];
      }

      if (Array.isArray(value)) {
        return value;
      }

      return [value.toString()];
    };

    try {
      await createRule({
        variables: {
          input: {
            orderChannelId,
            integrationId,
            rule: {
              name: values.name,
              triggers: values.triggers.map((trigger) => ({
                field: trigger.field as TriggerField,
                operator: trigger.operator as RuleOperator,
                value: convertValue(trigger.value),
              })),
              modifiers: values.modifiers.map((modifier) => ({
                field: modifier.modifier as ModifierField,
                value: Array.isArray(modifier.value) ? modifier.value : [modifier.value],
              })),
              rateFilters: values.rateFilters.map((rateFilter) => ({
                field: rateFilter.field as RateFilterField,
                operator: rateFilter.operator as RuleOperator,
                value: rateFilter.value,
              })),
              smartRateConfiguration: values.smartRateConfiguration
                ? {
                    deliveryDays: values.smartRateConfiguration.deliveryDays,
                    percentile: values.smartRateConfiguration.percentile,
                    ...(values.smartRateConfiguration.defaultService
                      ? {
                          defaultCarrier: values.smartRateConfiguration.defaultService.split(':')[0] as EasyPostCarrier,
                          defaultService: values.smartRateConfiguration.defaultService.split(':')[1],
                        }
                      : {}),
                  }
                : null,
            },
          },
        },
      });

      history.push(rulesUrl ?? '#');
    } catch (err) {
      // TODO: capture in sentry
    }
  };

  const breadcrumbs: Breadcrumb[] = [
    { path: rulesUrl ?? '#', label: 'Shipping Rules' },
    { path: url, label: 'New Rule' },
  ];

  return (
    <>
      <SectionHeadingWithAction
        title="New Rule"
        breadcrumbs={breadcrumbs}
        actions={
          <OrderChannelSelect
            integrations={orderChannelsData?.integrations ?? []}
            integrationId={integrationId}
            orderChannelId={orderChannelId}
            onChange={handleOrderChannelSelect}
          />
        }
      />
      <RuleForm
        initialValues={{
          name: '',
          triggers: [],
          modifiers: [],
          rateFilters: [],
          smartRateConfiguration: null,
        }}
        onSubmit={handleSubmit}
        saving={createRuleLoading}
      />
    </>
  );
};
