import React from 'react';
import Select, { components, Props } from 'react-select';

type OptionType = {
  label: string;
  value: string;
};

type GroupType = {
  groupName: string;
  options: OptionType[];
};

export interface MultiSelectProps extends Props<OptionType, true, GroupType> {
  label: string;
  error?: string | null;
  hideErrorMessage?: boolean;
}

const Input = (props: any) => (
  <components.Input {...props} inputClassName="outline-none border-none shadow-none focus:ring-transparent" />
);

export const MultiSelect: React.FC<MultiSelectProps> = ({
  className,
  id,
  label,
  error,
  hideErrorMessage,
  ...props
}) => {
  return (
    <div className={className}>
      <label className="block text-sm font-medium text-gray-700" htmlFor={id}>
        {label}
      </label>
      <Select<OptionType, true, GroupType>
        components={{ Input }}
        isMulti={true}
        {...props}
        id={id}
        formatGroupLabel={(group) => group.groupName}
      />
      {error && !hideErrorMessage ? (
        <p className="mt-2 text-sm text-red-600" id={`${id}-error`}>
          {error}
        </p>
      ) : null}
    </div>
  );
};
