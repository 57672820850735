import { gql, useQuery } from '@apollo/client';
import {
  EnabledCarrierIdsQuery,
  EnabledCarrierIdsQueryVariables,
} from './__generated__/enabled-carrier-ids.gql.generated';

export const ENABLED_CARRIER_IDS_QUERY = gql`
  query EnabledCarrierIds($integrationId: ID!, $orderChannelId: ID!) {
    enabledCarrierAccounts(integrationId: $integrationId, orderChannelId: $orderChannelId)
  }
`;

export const useEnabledCarrierIdsQuery = (integrationId: string, orderChannelId: string) =>
  useQuery<EnabledCarrierIdsQuery, EnabledCarrierIdsQueryVariables>(ENABLED_CARRIER_IDS_QUERY, {
    variables: { integrationId, orderChannelId },
  });
