import React from 'react';
import classNames from 'classnames';

export interface RateShopPillTabsProps {
  currentTab: 'preferred' | 'other';
  onChange: (tab: 'preferred' | 'other') => void;
}

export const RateShopPillTabs: React.FC<RateShopPillTabsProps> = ({ currentTab, onChange }) => {
  const tabs = [
    { name: 'Preferred Rates', value: 'preferred', disabled: false },
    { name: 'All Rates', value: 'other', disabled: false },
  ];

  return (
    <div className="mb-4">
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
          defaultValue={currentTab}
          onChange={(evt) => onChange(evt.target.value as any)}
        >
          {tabs.map((tab) => (
            <option key={tab.name} value={tab.value}>
              {tab.name}
            </option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav className="flex space-x-4" aria-label="Tabs">
          {tabs.map((tab) => (
            <button
              key={tab.name}
              type="button"
              onClick={() => onChange(tab.value as any)}
              className={classNames(
                currentTab === tab.value
                  ? 'bg-blue-100 text-blue-700'
                  : !tab.disabled
                  ? 'text-gray-500 hover:text-gray-700'
                  : 'text-gray-300 cursor-not-allowed',
                'px-3 py-2 font-medium text-sm rounded-md'
              )}
            >
              {tab.name}
            </button>
          ))}
        </nav>
      </div>
    </div>
  );
};
