import React from 'react';
import { useHistory } from 'react-router-dom';
import { ShipStationIntegrationForm, ShipStationIntegrationFormValues } from './ShipStationIntegrationForm';
import { useCreateShipStationIntegrationMutation } from '../../operations/create-ship-station-integration.gql';

export const NewShipStationIntegrationFormController: React.FC = () => {
  const history = useHistory();
  const [create, { loading, error }] = useCreateShipStationIntegrationMutation();

  const handleSubmit = async (values: ShipStationIntegrationFormValues) => {
    try {
      await create({
        variables: values,
      });
      history.push('/settings/integrations');
    } catch (err) {
      // TODO: Report to sentry
    }
  };

  return (
    <ShipStationIntegrationForm
      initialValues={{ apiKey: '', apiSecret: '', description: '' }}
      onSubmit={handleSubmit}
      loading={loading}
      submitText="Submit"
      error={error?.message ?? null}
    />
  );
};
