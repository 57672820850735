import { FieldArray } from 'formik';
import React, { FC } from 'react';
import classNames from 'classnames';
import styles from './PackageFieldsV2.module.scss';
import { useShipForm } from '../ShipForm';
import { PackageRow } from '../atoms/PackageRow';
import { nanoid } from 'nanoid';
import { PlusIcon } from '@heroicons/react/outline';
import { Button } from '../../Button';

export interface DimensionFieldsProps
  extends React.DetailedHTMLProps<React.FieldsetHTMLAttributes<HTMLFieldSetElement>, HTMLFieldSetElement> {
  disabled?: boolean;
  parent?: string;
}

export const PackageFieldsV2: FC<DimensionFieldsProps> = ({ className, disabled }) => {
  const form = useShipForm();

  return (
    <FieldArray name="packages">
      {({ push, remove }) => {
        const addPackage = () => {
          push({
            key: nanoid(),
            length: 0,
            width: 0,
            height: 0,
            weight: 0,
          });
        };

        return (
          <>
            <fieldset className={classNames(styles.packageFields, className)} disabled={disabled}>
              <span className="col-span-1">#</span>
              <span className="block text-sm font-medium text-gray-700 col-span-3">Dimensions (inches)</span>
              <span className="block text-sm font-medium text-gray-700 col-span-1">Weight (lbs)</span>
              <span />

              {form.values.packages.map((parcel, index) => (
                <PackageRow key={parcel.key} index={index} onDelete={() => remove(index)} />
              ))}
            </fieldset>
            <div className="flex justify-end mt-4">
              <Button type="button" icon={PlusIcon} kind="secondary" onClick={addPackage}>
                Add Package
              </Button>
            </div>
          </>
        );
      }}
    </FieldArray>
  );
};
