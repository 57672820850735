import { gql, useMutation } from '@apollo/client';
import { ADDRESS_DETAILS_FRAGMENT } from './fragments/address-details.gql';
import {
  ValidateAddressMutation,
  ValidateAddressMutationVariables,
} from './__generated__/validate-address.gql.generated';

export const VALIDATE_ADDRESS_MUTATION = gql`
  mutation ValidateAddress($address: AddressV2Input!) {
    validateAddress(address: $address) {
      ...AddressDetails

      easyPostId
      verifications {
        delivery {
          success
          errors {
            field
            message
          }
        }
      }
    }
  }

  ${ADDRESS_DETAILS_FRAGMENT}
`;

export const useValidateAddressMutation = () =>
  useMutation<ValidateAddressMutation, ValidateAddressMutationVariables>(VALIDATE_ADDRESS_MUTATION);
