import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useAllFacilitiesQuery } from '../operations/all-facilities.gql';
import { AllFacilitiesQuery } from '../operations/__generated__/all-facilities.gql.generated';

export interface FacilityState {
  facilities: AllFacilitiesQuery['allFacilities'];
  facilitiesLoading: boolean;
  selectedFacilityId: string | null;
  setSelectedFacilityId: (id: string) => void;
}

export const FacilityContext = React.createContext<FacilityState | undefined>(undefined);

const defaultFacilityId = (): string | null => {
  const storedId = localStorage.getItem('selectedFacilityId');

  return storedId ?? null;
};

export const FacilityProvider: React.FC = React.memo(({ children }) => {
  const [selectedFacilityId, _setSelectedFacilityId] = useState<string | null>(defaultFacilityId());

  const { data, loading } = useAllFacilitiesQuery();

  const setSelectedFacilityId = useCallback(
    (id: string | null) => {
      _setSelectedFacilityId(id);
      if (id) {
        localStorage.setItem('selectedFacilityId', id);
      }
    },
    [_setSelectedFacilityId]
  );

  const value = useMemo<FacilityState>(
    () => ({
      selectedFacilityId,
      facilities: data?.allFacilities ?? [],
      facilitiesLoading: loading,
      setSelectedFacilityId,
    }),
    [selectedFacilityId, data, loading, setSelectedFacilityId]
  );

  useEffect(() => {
    if (data?.allFacilities.length && !selectedFacilityId) {
      setSelectedFacilityId(data.allFacilities[0].id);
    }
  }, [data, selectedFacilityId, setSelectedFacilityId]);

  return <FacilityContext.Provider value={value}>{children}</FacilityContext.Provider>;
});

export const useFacilities = () => {
  const context = useContext(FacilityContext);

  if (!context) {
    throw new Error('useFacility must be used within a FacilityProvider!');
  }

  return context;
};
