import { gql, useQuery } from '@apollo/client';
import { AllIntegrationsQuery, AllIntegrationsQueryVariables } from './__generated__/all-integrations.gql.generated';

export const ALL_INTEGRATIONS_QUERY = gql`
  query AllIntegrations {
    integrations {
      id
      description
      readable
      type

      ... on TplcIntegration {
        clientId
        clientSecret
        userLogin
      }

      ... on ShipStationIntegration {
        apiKey
        apiSecret
      }
    }
  }
`;

export const useAlIntegrationsQuery = () =>
  useQuery<AllIntegrationsQuery, AllIntegrationsQueryVariables>(ALL_INTEGRATIONS_QUERY);
