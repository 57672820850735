import { Field, useFormikContext } from 'formik';
import React, { FC } from 'react';
import countryCodes from '../assets/country-codes.json';
import { InputGroup } from './InputGroup';

export interface AddressFieldsProps {
  label?: string;
  disabled?: boolean;
  parent?: string;
}

export const AddressFields: FC<AddressFieldsProps> = ({ disabled, label, parent }) => {
  const { getFieldMeta } = useFormikContext<any>();

  return (
    <fieldset className="bg-white" disabled={disabled}>
      <legend className="block text-sm font-medium text-gray-700">{label ?? 'Address'}</legend>
      <div className="mt-1 rounded-md shadow-sm -space-y-px">
        <div className="flex -space-x-px">
          <div className="w-1/2 flex-1 min-w-0">
            <label htmlFor="company" className="sr-only">
              Company
            </label>
            <Field
              type="text"
              name={`${parent ? parent + '.' : ''}company`}
              id="company"
              as={InputGroup}
              controlClassName="rounded-none rounded-tl-md"
              placeholder="Company"
              hideErrorMessage
              error={
                getFieldMeta(`${parent ? parent + '.' : ''}company`).touched &&
                getFieldMeta(`${parent ? parent + '.' : ''}company`).error
              }
            />
          </div>
          <div className="flex-1 min-w-0">
            <label htmlFor="name" className="sr-only">
              Name
            </label>
            <Field
              type="text"
              name={`${parent ? parent + '.' : ''}name`}
              id="name"
              as={InputGroup}
              controlClassName="rounded-none rounded-tr-md"
              placeholder="Name"
              hideErrorMessage
              error={
                getFieldMeta(`${parent ? parent + '.' : ''}name`).touched &&
                getFieldMeta(`${parent ? parent + '.' : ''}name`).error
              }
            />
          </div>
        </div>
        <div>
          <label htmlFor="street1" className="sr-only">
            Address 1
          </label>
          <Field
            type="text"
            name={`${parent ? parent + '.' : ''}street1`}
            id="street1"
            as={InputGroup}
            controlClassName="rounded-none"
            placeholder="Address line 1"
            hideErrorMessage
            error={
              getFieldMeta(`${parent ? parent + '.' : ''}street1`).touched &&
              getFieldMeta(`${parent ? parent + '.' : ''}street1`).error
            }
          />
        </div>
        <div>
          <label htmlFor="street2" className="sr-only">
            Address 2
          </label>
          <Field
            type="text"
            name={`${parent ? parent + '.' : ''}street2`}
            id="street2"
            as={InputGroup}
            controlClassName="rounded-none"
            placeholder="Address line 2"
            hideErrorMessage
            error={
              getFieldMeta(`${parent ? parent + '.' : ''}street2`).touched &&
              getFieldMeta(`${parent ? parent + '.' : ''}street2`).error
            }
          />
        </div>
        <div className="flex -space-x-px">
          <div className="w-1/2 flex-1 min-w-0">
            <label htmlFor="city" className="sr-only">
              City
            </label>
            <Field
              type="text"
              name={`${parent ? parent + '.' : ''}city`}
              id="city"
              as={InputGroup}
              controlClassName="rounded-none"
              placeholder="City"
              hideErrorMessage
              error={
                getFieldMeta(`${parent ? parent + '.' : ''}city`).touched &&
                getFieldMeta(`${parent ? parent + '.' : ''}city`).error
              }
            />
          </div>
          <div className="flex-1 min-w-0">
            <label htmlFor="state" className="sr-only">
              State
            </label>
            <Field
              type="text"
              name={`${parent ? parent + '.' : ''}state`}
              id="state"
              as={InputGroup}
              controlClassName="rounded-none"
              placeholder="State"
              hideErrorMessage
              error={
                getFieldMeta(`${parent ? parent + '.' : ''}state`).touched &&
                getFieldMeta(`${parent ? parent + '.' : ''}state`).error
              }
            />
          </div>
        </div>
        <div className="flex -space-x-px">
          <div className="w-1/2 flex-1 min-w-0">
            <label htmlFor="zip" className="sr-only">
              Postal code
            </label>
            <Field
              type="text"
              name={`${parent ? parent + '.' : ''}zip`}
              id="zip"
              as={InputGroup}
              controlClassName="rounded-none"
              placeholder="Postal code"
              hideErrorMessage
              error={
                getFieldMeta(`${parent ? parent + '.' : ''}zip`).touched &&
                getFieldMeta(`${parent ? parent + '.' : ''}zip`).error
              }
            />
          </div>
          <div className="flex-1 min-w-0">
            <label htmlFor="country" className="sr-only">
              Country
            </label>
            <Field
              as="select"
              id="country"
              name={`${parent ? parent + '.' : ''}country`}
              className="focus:ring-blue-500 focus:border-blue-500 relative block w-full rounded-none bg-transparent focus:z-10 sm:text-sm border-gray-300 disabled:bg-gray-50"
            >
              {countryCodes.map(({ label, value }) => (
                <option key={value} label={label} value={value}>
                  {label}
                </option>
              ))}
              <option>USA</option>
              <option>Canada</option>
            </Field>
          </div>
        </div>

        <div className="flex -space-x-px">
          <div className="w-1/2 flex-1 min-w-0">
            <label htmlFor="email" className="sr-only">
              Email
            </label>
            <Field
              type="email"
              name={`${parent ? parent + '.' : ''}email`}
              id="email"
              as={InputGroup}
              controlClassName="rounded-none rounded-bl-md"
              placeholder="Email"
              hideErrorMessage
              error={
                getFieldMeta(`${parent ? parent + '.' : ''}email`).touched &&
                getFieldMeta(`${parent ? parent + '.' : ''}email`).error
              }
            />
          </div>
          <div className="flex-1 min-w-0">
            <label htmlFor="phone" className="sr-only">
              Phone
            </label>
            <Field
              type="tel"
              name={`${parent ? parent + '.' : ''}phone`}
              id="phone"
              as={InputGroup}
              controlClassName="rounded-none rounded-br-md"
              placeholder="Phone"
              hideErrorMessage
              error={
                getFieldMeta(`${parent ? parent + '.' : ''}phone`).touched &&
                getFieldMeta(`${parent ? parent + '.' : ''}phone`).error
              }
            />
          </div>
        </div>
      </div>
    </fieldset>
  );
};
