import { gql } from '@apollo/client';

export const REPORT_DETAILS_FRAGMENT = gql`
  fragment ReportDetails on Report {
    id
    integrationId
    orderChannelId
    type
    name
    createdAt
    updatedAt
    startDate
    endDate
    status
    fileUrl
  }
`;
