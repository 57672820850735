import React from 'react';
import { SmartRateTimeInTransitInfo } from '../../../types/__generated__/graphql';

interface SmartRateDataPointProps {
  deliveryDays?: number | null;
  percentile: string;
}

const SmartRateDataPoint: React.FC<SmartRateDataPointProps> = ({ deliveryDays, percentile }) => {
  return (
    <div className="flex flex-col items-center justify-between px-2">
      <span className="h-6 leading-6 font-medium">{deliveryDays ?? '-'}</span>
      <span className="h-6 leading-6 bg-gray-500 h-1.5 w-1.5 rounded-full" style={{ height: '7px', width: '7px' }} />
      <span className="h-6 text-xs text-gray-400" style={{ lineHeight: '1.5rem' }}>
        {percentile}%
      </span>
    </div>
  );
};

export interface SmartRateIndicatorProps {
  smartRate: SmartRateTimeInTransitInfo;
}

export const SmartRateIndicator: React.FC<SmartRateIndicatorProps> = ({ smartRate: { __typename, ...smartRate } }) => {
  return (
    <div className="relative w-full flex justify-between">
      {Object.keys(smartRate).map((key) => (
        <SmartRateDataPoint
          key={key}
          deliveryDays={smartRate[key as keyof typeof smartRate]}
          percentile={key.split('_')[1]}
        />
      ))}

      <span
        className="absolute bg-gray-500 left-0 right-0 top-1/2"
        style={{ height: '1px', transform: 'translateY(-1px)' }}
      />
    </div>
  );
};
